
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { StepIconProps as MuiStepIconProps } from '@mui/material/StepIcon';

interface StepIconProps extends MuiStepIconProps {
  customColor?: string;
  stepNumber:number;
}
function CustomStepIcon(props: StepIconProps) {
    const { active, completed, error, customColor, stepNumber } = props;
  
    if (error) {
      return <ErrorIcon style={{ color: customColor || '#d32f2f' }} />;
    } else if (completed) {
      return <CheckCircleIcon style={{ color: customColor || '#4540DB' }} />;
    } else if (active) {
      return <CircularNumberIcon   number={stepNumber} color={customColor || '#4540DB'} />;
    } else {
      return  <CircularNumberIcon number={stepNumber}  /> ;
    }
  }
  
  export default CustomStepIcon;

  interface CircularNumberIconProps{
    number:number;
    color?:string
  }
  export const CircularNumberIcon = ( {number, color = 'rgba(0, 0, 0, 0.38)'}:CircularNumberIconProps ) => (
    <svg width="20" height="20" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={color} />
      <text x="20" y="28" textAnchor="middle" fontSize="22" fontWeight="bold" fill="white">
        {number}
      </text>
    </svg>
  );
  
 