import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import TeamMemberAddEditForm from "./TeamMemberAddEditForm";
import { FormMode } from "../../models/app/AppModels";
import { Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './TeamMemberStyle.scss'
interface Props {
  openPopup: boolean;
  handlePopupClose: any;
  teamMemberId: string;
  onSuccess: any;
}
export const EditTeamPopup: React.FC<Props> = ({
  openPopup,
  handlePopupClose,
  teamMemberId,
  onSuccess,
}) => {

  const onEditSuccess = ()=>{
    onSuccess()
  }
  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={() => {
          handlePopupClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         <Grid container>
             <Grid xs={6}>
               <Typography className="edit-heading">Edit Team Member</Typography>
             </Grid>
             <Grid xs={6}   style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handlePopupClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        
             </Grid>
          </Grid>

        </DialogTitle>

        <DialogContent>
          <TeamMemberAddEditForm
            mode={FormMode.EDIT}
            teamMemberId={teamMemberId}
            source="Client"
            onSuccess={onEditSuccess}
            onFailure={() => {}}
            onClose={() => {
              handlePopupClose();
            }}
          />
        </DialogContent>
      </Dialog>

    </div>
  );
};
export default EditTeamPopup;
