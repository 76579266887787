import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  createMigrate,
  createTransform,
  persistReducer,
  persistStore
} from "redux-persist"
import storage from "redux-persist/es/storage"
import ContractEarningFilterStateSlice from '../features/Earnings/ContractEarningFilterStateSlice'
import FindJobsSearchSlice from '../features/Jobs/FindJobsSearchSlice'
import NavigationSlice from '../features/Layout/NavigationSlice'
import { candidateReducer } from '../features/candidateSlice'
import adminDashboardCount from '../features/dashboard/adminDashboardSlice'
import adminGraphicalDashboard from '../features/dashboard/adminGraphicalDashboardSlice'
import recruiterDashboardSlice from '../features/dashboard/recruiterDashboardSlice'
import recruiterGraphicalDashboard from '../features/dashboard/recruiterGraphicalDashboardSlice'
import editModeSlice from '../features/editMode/editModeSlice'
import paymentUpdateSlice from '../features/paymentDetails/paymenUpdateSlice'
import userPermissionSlice from '../features/permission/userPermissionSlice'
import recruiterSlice from '../features/recruiterSlice'
import activeRecruiterSubmissionRowSlice from '../features/recruiterSubmission/activeRecruiterSubmissionRowSlice'
import activeRecruiterSubmissionSlice from '../features/recruiterSubmission/activeRecruiterSubmissionSlice'
import rejectedRecruiterSubmissionRowSlice from '../features/recruiterSubmission/rejectedRecruiterSubmissionRowSlice'
import rejectedRecruiterSubmissionSlice from '../features/recruiterSubmission/rejectedRecruiterSubmissionSlice'
import loggedInUserDetailsSlice from '../features/userDetails/loggedInUserDetailsSlice'

//client

import ClientSignUpFormSlice from '../features/Client/ClientSignUp/ClientSignUpFormSlice'
import ManageClientStateSlice from '../features/Client/ManageClient/ManageClientStateSlice'
import { clientApi, clientReducer } from '../features/Client/clientSlice'
import { teamMemberReducer, MemberApi } from '../features/Client/teamMemberSlice'

// Team Member
import AddEditTeamMemberFormSlice from '../features/TeamMember/AddEditTeamMemberFormSlice'
//job
import AddJobStateSlice from '../features/Jobs/AddJobStateSlice'
import { clientJobApi, clientJobReducer, savedJobJobApi } from '../features/Jobs/clientJobSlice'

// Recruiter
import { adminListingReducer, adminsAPI } from '../features/AdminListing/adminListingSlice'
import CandidateInterviewAvailabilityStateSlice from '../features/Candidate/CandidateInterviewAvailabilityStateSlice'
import CandidateListStateSlice from '../features/Candidate/CandidateListStateSlice'
import BreadcrumbsStateSlice from '../features/Common/BreadcrumbsStateSlice'
import PendingSubmissionListStateSlice from '../features/PendingSubmission/PendingSubmissionListStateSlice'
import RecruiterListStateSlice from '../features/Recruiter/RecruiterListStateSlice'
import { submissionApi, submissionReducer } from '../features/submission/submissionSlice'

export const rootReducer = combineReducers({
  editMode : editModeSlice,
    //recruiter data
    recruiter : recruiterSlice,
    paymentUpdate : paymentUpdateSlice,
    recruiterDashboard : recruiterDashboardSlice,
    recruiterGraphicalData : recruiterGraphicalDashboard,
    adminDashboard : adminDashboardCount,
    adminGraphicalData : adminGraphicalDashboard,
    navigationState:NavigationSlice,
    activeRecruiterSubmissionState : activeRecruiterSubmissionSlice,
    rejectedRecruiterSubmissionState : rejectedRecruiterSubmissionSlice,
    activeRecruiterSubmissionRow:activeRecruiterSubmissionRowSlice,
    rejectedRecruiterSubmissionRow:rejectedRecruiterSubmissionRowSlice,
    findJobsSearchSlice:FindJobsSearchSlice,
    loggedInUserDetails:loggedInUserDetailsSlice,
    candidateReducer:candidateReducer,
    userPermissions: userPermissionSlice,

    //earning reducers
    contractEarningFilterState:ContractEarningFilterStateSlice,

    //client reducers
    clientSignUpFormState:ClientSignUpFormSlice,
    ManageClientFormState:ManageClientStateSlice,
    clientReducer,
    clientJobReducer,
    submissionReducer,
    adminListingReducer,

    //Team Member
    AddEditTeamMemberFormState:AddEditTeamMemberFormSlice,
    
    // job reducer
    addJobState:AddJobStateSlice,
    teamMemberReducer,

    // Recruiter Reducer
    recruiterListState:RecruiterListStateSlice,

    // Candidate Reducer
    CandidateListState:CandidateListStateSlice,
    CandidateInterviewAvailabilityState: CandidateInterviewAvailabilityStateSlice,

    // Submission Reducers
    pendingSubmission:PendingSubmissionListStateSlice,

    //common component Reducers
    BreadcrumbsState: BreadcrumbsStateSlice,
    
    //API reducers
    [clientApi.reducerPath]:clientApi.reducer,
    [clientJobApi.reducerPath]:clientJobApi.reducer,
    [savedJobJobApi.reducerPath]:savedJobJobApi.reducer,
    [MemberApi.reducerPath]:MemberApi.reducer,
    [submissionApi.reducerPath]:submissionApi.reducer,
    [adminsAPI.reducerPath]:adminsAPI.reducer

})
    

const TransformCredentials = createTransform(
  (inboundState, key) => {
    return {
      ...inboundState,
    };
  },
  (outboundState, key) => {
    return {
      ...outboundState,
    };
  },
  {}
);

const MIGRATION_DEBUG = true;
const migrations = {
  1: (state) => ({
    ...state._persist,
  }),
  2: (state) => ({
    ...state._persist,
  }),
  3: (state) => ({
    ...state._persist,
  }),
};

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["candidateReducer","contractEarningFilterState"],
  version: 17,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
  transforms: [TransformCredentials],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware:  getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
 

})

export const persistCallBack = () => {
  // intentionally does nothing
};

export const persistor = persistStore(store, null, persistCallBack);

export default store