import {
  Collapse,
  FormControl,
  Input,
  InputLabel,
  Radio,
  TextField
} from "@material-ui/core";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import {
  Alert,
  CircularProgress,
  InputAdornment,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { GetJobById } from "../../../Services/jobService";
import {
  DocumentType,
  UploadAttachment,
  UploadResourceModel
} from "../../../Services/uploadPhotoService";
import useApi from "../../../hooks/useApi";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import moment from "moment";
import { GetSubmissionsById } from "../../../Services/candidateService";
import { getScoreCardPdfFile, getSubmissionChangeLogPdfFile } from "../../../Services/getPdfReportService";
import {
  EximiusMatchProfile,
  GetCandidateDataByEmail,
  GetRecruiterById,
  RecruiterSaveJob,
  SaveResumeByRecruiter,
  SendEmailForUpdateSubmission,
  SubmitResumeByRecruiter,
  UpdateResumeByRecruiter,
} from "../../../Services/recruiterService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import {
  searchCities,
  searchOptions,
} from "../../../constants/googlePlaceOption";

import { PermissionsEnums } from "../../../data/permission";
import { preferredCountries } from "../../../data/phoneInput/preferredCountries";
import rawCountries from "../../../data/phoneInput/rawCountries";
import { JobOriginIds } from "../../../models/Job/JobDetailsModel";
import { DocumentTypeEnum, UserAuth, UserAuthByRoleName } from "../../../models/interfaces";
import { EximiusScoreDataReturn } from "../../../models/recruiterModel";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import getAddressForAutoFill from "../../../utils/getAddressForAutoFill";
import { downloadFile, getFileFromUrl, getFilePreviewUrl, getNevLink } from "../../../utils/getFileFromUrl";
import getPayRate from "../../../utils/getPayRate";
import { isEmployerNameRequired } from "../../../utils/isEmployerNameRequired";
import { isValidFileExtention } from "../../../utils/isValidFileExtensions";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import JobTitle from "../../Common/JobTitle";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import PreviewFileDialog from "../../Common/PreviewFileDialog";
import { UploadAttachments } from "../../Common/UploadAttachments";
import AppHelmet from "../../Common/meta/AppHelmet";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { CompensationCalculatorBox } from "../CompensationCalculatorBox";
import { CandidateEmployment } from "./CandidateEmployment";
import CandidateDialog from "./Dialog/CandidateDialog";
import CandidateRecordDialog from "./Dialog/CandidateRecordDialog";
import ExistingCandidateDialog from "./Dialog/ExistingCandidateDialog";
import ScorecardResume from "../../Common/Scorecard/ScorecardResume";
import { SubmitResumeValidationSchema } from "./SubmitResume/SubmitResumeValidationSchema";
import { candidateInit } from "./SubmitResume/candidateInit";
import { allJobBreadCrumb, 
  findJobsBreadCrumb, 
  findJobsFromJobDetailsBreadCrumb, 
  jobAlertsJobDetailsBreadcrumbs, 
  jobDetailsBreadCrumb, 
  jobalertBreadCrumb, 
  newSubmissionBreadCrumb, 
  pendingsubmission, 
  savedJobBreadCrumb, 
  savedJobListJobBreadCrumb, 
  savedJobListJobDetailsJobBreadCrumb, 
  updateSubmisssion } from "./SubmitResume/SubmitResumeBreadcrumbs";
import CandidateSubmittedSuccessPopup from "./SubmitResume/Popup/CandidateSubmittedSuccessPopup";
import PayrateAlertPopup from "./SubmitResume/Popup/PayrateAlertPopup";
import SaveAndContinueConfirmPopup from "./SubmitResume/Popup/SaveAndContinueConfirmPopup";
import DuplicateCandidateAlertPopup from "./SubmitResume/Popup/DuplicateCandidateAlertPopup";
import SaveAndContinueSuccessPopup from "./SubmitResume/Popup/SaveAndContinueSuccessPopup";
import { getIsScorecardFeatureActive } from "../../../utils/AppConfig";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function SubmitResume() {

  const isScorecardFeatureActive = getIsScorecardFeatureActive();
  const metaDataList = useSelector(
    (state: any) => state.navigationState.metaData
  );
  const [metaData, setMetaData] = React.useState({
    title: "",
    description: "",
  });
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("sm"));
  const dynamicStyles = {
    ...(matchesXS && { width: "33%" }),
    ...(matchesMD && { width: "80%" }),
  };

  const dynamicStylesForEmName = {
    ...(matchesXS && { fontSize: "11px" }),
    ...(matchesMD && { fontSize: "1rem" }),
  };

  let { id, fromPage } = useParams();
  const editMode = ternary(fromPage === "submission" || fromPage === "submissionactive", true, false);
  const navigate = useNavigate();
  const [isFormSubmitting, setIsFormSubmitting] = React.useState({
    submit: false,
    save: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isScoreCardLoading, setIsScoreCardLoading] = React.useState(false);
  const [glasssquidScore, setGlasssquidScore] =
    React.useState<typeof EximiusScoreDataReturn>();
  const [glasssquidScoreStatus, setGlasssquidScoreStatus] =
    React.useState<{statusCode?:number, statusMsg?:string}>();
  const [isAutoPopulatePending, setIsAutoPopulatePending] =
    React.useState(false);

  const [duplicateSubmissionDialogOpen, setDuplicateSubmissionDialogOpen] =
    React.useState(false);
  const [searchParams] = useSearchParams();
  const [otherDocuments, setOtherDocuments] = useState<any[]>([]);
  let submitId = ternary(searchParams.get("id") === "", "", searchParams.get("id"));
  const [candidateDefaultData, setCandidateDefaultData] =
    useState(candidateInit);
  const [scoreResume, setScoreResume] = useState(false);
  const formRef = useRef<FormikProps<typeof initialState>>(null);
  let user = JSON?.parse(localStorage?.getItem("user") || "false");

  const [open, setOpen] = React.useState(false);
  const [saveResumeConfirmDialog, setSaveResumeConfirmDialog] = useState(false);
  const [saveResumeSuccessDialog, setSaveResumeSuccessDialog] = useState(false);
  const [filePreview, setFilePreview] = React.useState<undefined | 
  {
    resourceUrl: string;
    fileName: string;
    fileType?: string;
  }>(undefined);
  const [candidateDetail, setCondidateDetail] = useState({
    candidateGuid: "",
    candidateId: "",
  });
  const [countryShort, setCountryShort] = React.useState("us");
  const [emp_countryShort, setEmpCountryShort] = React.useState("us");
  const [payrateDialog, setPayrateDialog] = React.useState({
    display: false,
    isConfirm: false,
  });
  const [scoreCardPdf, setScoreCardPdf] = React.useState<{pdfFile?:File,isGenerating:boolean}>({ isGenerating:false});
  const [resumeFile, setResumeFile] = React.useState<{
    file: File;
    fileName: string;
  }>();
  const [isNewResumeFile, setIsNewResumeFile] = React.useState(false);
  const [error, setError] = React.useState({ hasError: false, errorMsg: "" });
  const [compensateCal, setCompensateCal] = React.useState(false);
  const [isUploadTouch, setIsUploadTouch] = React.useState(false);
  const [existingCandidate, setExistingCandidate] = React.useState<{
    candidateId: string;
    confirmDialog: boolean;
    canidatDialog: boolean;
    candidateData?: any;
    isLoadingData: boolean;
    UpdateOnSubmit?: boolean;
    UpdateOnSubmitDialog: boolean;
  }>({
    candidateId: "",
    canidatDialog: false,
    confirmDialog: !Boolean(submitId),
    isLoadingData: false,
    UpdateOnSubmitDialog: false,
  });
  
  const [existingSubmission,setExistingSubmission]=React.useState<any>({});


  const getRecruiter = useApi(() => GetRecruiterById(user.userId ?? ""));
  let recruiterDetails = getRecruiter?.data?.entity;

  const getJobsAPI = useApi(() => GetJobById(id ?? ""));
  let jobDetails = getJobsAPI?.data?.entity;

  let isRemoteJob = jobDetails?.remoteJob == "y";

  const initialState = {
    email: valueOrDefault(candidateDefaultData.email, ""),
    firstName: valueOrDefault(candidateDefaultData.firstName, ""),
    lastName: valueOrDefault(candidateDefaultData.lastName, ""),
    middleInitial: valueOrDefault(candidateDefaultData.middleInitial, ""),
    zip: valueOrDefault(candidateDefaultData.zip, ""),
    country: valueOrDefault(candidateDefaultData.country, ""),
    address: valueOrDefault(candidateDefaultData.address, ""),
    city: valueOrDefault(candidateDefaultData.city, ""),
    state: valueOrDefault(candidateDefaultData.state, ""),
    phoneNumbers: {
      id: "",
      primary: "",
      secondary: "",
    },
    phone: valueOrDefault(candidateDefaultData.phone, ""),
    experience: valueOrDefault(candidateDefaultData.experience, ""),
    skills: valueOrDefault(candidateDefaultData.skills, []),
    workAuthorization: valueOrDefault(candidateDefaultData.workAuthorization, ""),
    isRemoteUSA: candidateDefaultData.isRemoteUSA,
    isRemoteJob: isRemoteJob,
    employeeName: valueOrDefault(candidateDefaultData.employeeName, ""),
    employerDetails: valueOrDefault(candidateDefaultData.employerDetails, {} as (typeof candidateDefaultData.employerDetails)),
    payrate: valueOrDefault(candidateDefaultData.payrate, ""),
    payrateType: valueOrDefault(candidateDefaultData.payrateType, "hourly"),
    candidateEmployment: valueOrDefault(candidateDefaultData.candidateEmployment, ""),
    currentWorkLocation: {
      id: valueOrDefault(candidateDefaultData.currentWorkLocation.id, ""),
      city: valueOrDefault(candidateDefaultData.currentWorkLocation.city, ""),
      state: valueOrDefault(candidateDefaultData.currentWorkLocation.state, ""),
    },
    recruiterComments: valueOrDefault(candidateDefaultData.recruiterComments, ""),
    resume: valueOrDefault(candidateDefaultData.resume, ""),
    otherDocInfo: valueOrDefault(candidateDefaultData.otherDocInfo, []),
    acknowledged: false,
    canRelocate: false,
    iConfirm: false,
    informedCandidate: false,
    recruiterPhoneNumbers: {
      primary: valueOrDefault(candidateDefaultData?.recruiterPhoneNumbers?.primary, ""),
      secondary: valueOrDefault(candidateDefaultData?.recruiterPhoneNumbers?.secondary, ""),
    },
  };

  useEffect(() => {
    if (submitId !== null) GetCandidateDetailsById(submitId);

    getJobsAPI.request();
    getRecruiter.request();
  }, []);

  React.useEffect(() => {
    if (existingCandidate.candidateData) {
      fillFormForExistingCandidate(existingCandidate.candidateData);
    }
  }, [existingCandidate.candidateData]);

  const fillFormForExistingCandidate = async (candidateData: any) => {
    formRef.current?.setValues({
      ...formRef.current.values,
      email: valueOrDefault(candidateData.email, ""),
      firstName: valueOrDefault(candidateData.firstName, ""),
      lastName: valueOrDefault(candidateData.lastName, ""),
      middleInitial: valueOrDefault(candidateData.middleInitial, ""),
      zip: valueOrDefault(candidateData.address.postalCode, ""),
      country: valueOrDefault(candidateData.address.countryCode, ""),
      address: valueOrDefault(candidateData.address.streetAddress, ""),
      city: valueOrDefault(candidateData.address.city, ""),
      state: valueOrDefault(candidateData.address.state, ""),
      phoneNumbers: {
        id: "",
        primary: "",
        secondary: "",
      },
      phone: valueOrDefault(candidateData.phone, ""),
      experience: valueOrDefault(candidateData.experience, ""),
      resume: valueOrDefault(candidateData.resumeInfo.fileName, ""),
      otherDocInfo:
        candidateData.otherDocInfo.filter(
          (n: any) => n.documentType !== DocumentType.ResumeScoreCard
        ) || [],
    });

    setOtherDocuments(
      candidateData.otherDocInfo.filter(
        (doc: any) => doc.documentType !== DocumentType.ResumeScoreCard
      )
    );
    if (candidateData.resumeInfo?.resourceUrl) {
      let file = await getFileFromUrl(
        candidateData.resumeInfo?.resourceUrl,
        candidateData.resumeInfo?.fileName
      );
      setResumeFile({
        file: file,
        fileName: candidateData.resumeInfo?.fileName,
      });
    }
    setExistingCandidate({
      ...existingCandidate,
      isLoadingData: false,
      canidatDialog: false,
    });
    setCondidateDetail({
      candidateGuid: candidateData.id,
      candidateId: candidateData.candidateId,
    });
  };

  const handlePayRateChange = (val: any) => {
    formRef.current?.setFieldValue("payrate", val);
  };
  React.useEffect(() => {
    if (jobDetails) {
      formRef.current?.setFieldValue(
        "payrateType",
        jobDetails?.package.packageType || "hourly"
      );
      if (jobDetails?.manualData?.skills?.length > 0) {
        formRef.current?.setFieldValue("isSkillsExist", true);
      } else {
        formRef.current?.setFieldValue("isSkillsExist", false);
      }
      //if jobtype is fulltime job then full time job should be auto checked under candidate employment

      if (jobDetails?.jobAssignmentType == "Full Time") {
        formRef.current?.setFieldValue("candidateEmployment", "FullTime");
      }
      if (
        jobDetails?.jobAssignmentType == "Contract" &&
        jobDetails?.taxTerms == "W2"
      ) {

        formRef.current?.setFieldValue("candidateEmployment", "W2_Employee");
      }
    }
  }, [getJobsAPI.data]);

  useEffect(() => {
    let tempData = metaDataList.find(
      (n: any) => n.path && n.path.toLowerCase() === "/submitresume"
    );
    if (tempData && jobDetails && jobDetails.id) {
      setMetaData({
        title: tempData.metaTitle
          .replace("#jobId", jobDetails?.id)
          .replace("#jobTitle", jobDetails?.jobTitle),
        description: jobDetails?.jobShortDesc,
      });
    }
  }, [metaDataList, getJobsAPI.data]);

  useEffect(() => {
    if (recruiterDetails && recruiterDetails.status === "Pending") {
      navigate(getNevLink(`/jobdetail/${id}/${fromPage}`));
    } else if (recruiterDetails && recruiterDetails.status !== "Approved") {
      navigate(getNevLink(getNevLink('/dashboard')));
    }
  }, [recruiterDetails]);

  const handleClose = () => {
    setOpen(false);
  };

  let userRole = getLoggedInUserRole();
  useEffect(() => {
    formRef.current?.validateForm();
  }, [formRef.current?.values.iConfirm]);
  
  useEffect(() => {
    formRef.current?.validateForm();
  }, [formRef.current?.values.informedCandidate]);

  const handleUploadClick = (files: FileList | null, type: string) => {
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    if (type === "Resume") {
      setResumeFile({
        file: files[0],
        fileName: files[0].name,
      });
      setIsNewResumeFile(true);
    }
  };

  function uploadScorePdf(model : any, scorePdf : File) {
    UploadAttachment({
      documentType: DocumentTypeEnum.Resume,
      file: scorePdf,
    })
    .then((pdfRes) => {
      model.otherDocInfo.push({
        resourceUrl: pdfRes.data.entity.absoluteUri,
        fileName: pdfRes.data.entity.fileName,
        newFileName: pdfRes.data.entity.newFileName,
        documentType: "ResumeScoreCard",
      });

      submitResume(model);
    })
    .catch((error) => {
      setError({
        hasError: true,
        errorMsg: "Unexpected error, try again",
      });
      setIsFormSubmitting({ ...isFormSubmitting, submit: false });
    });
  }

  async function handleScorePdf(model : any) {
    try {
      if (
        glasssquidScoreStatus?.statusCode &&
        glasssquidScoreStatus?.statusCode === 200 &&
        glasssquidScore
      ) {
        let scorePdf = scoreCardPdf?.pdfFile;
        if (!scorePdf) {
          scorePdf = await getScoreCardPdfFile({data:glasssquidScore.messageList[0],  
            status: "",
            jobId: jobDetails?.id,
            jobTitle: jobDetails?.jobTitle});
        }

        uploadScorePdf(model, scorePdf);
      } else {
        submitResume({ ...model, IsScoreCardPending: true });
      }
    } catch (err) {
      setError({
        hasError: true,
        errorMsg: "Unexpected error, try again",
      });
      setIsFormSubmitting({ ...isFormSubmitting, submit: false });
    }
  }

  function handleNewResumeSubmit(model : any, resumeUploadModel : UploadResourceModel) {
    model.otherDocInfo = model.otherDocInfo.filter(
      (x : any) => x.documentType !== "ResumeScoreCard"
    );

    UploadAttachment(resumeUploadModel)
      .then(async (response) => {
        model.resumeInfo.fileName = response.data.entity.fileName;
        model.resumeInfo.resourceUrl = response.data.entity.absoluteUri;
        model.resumeInfo.newFileName = response.data.entity.newFileName;
        
        await handleScorePdf(model);
      })
      .catch(() => {
        setError({
          hasError: true,
          errorMsg: "Unexpected error, try again",
        });
        setIsFormSubmitting({ ...isFormSubmitting, submit: false });
      });
  }

  const handleResumeSubmit = async (values: typeof initialState) => {
    setIsFormSubmitting({ ...isFormSubmitting, submit: true });
    
    let model = {
      recruiterGuid: ternary(
        editMode, 
        candidateDefaultData?.recruiterGuid, 
        recruiterDetails?.id
      ),
      recruiterId: ternary(
        editMode, 
        candidateDefaultData?.recruiterId, 
        recruiterDetails?.recruiterId
      ),
      recruiterEmail: ternary(
        editMode, 
        candidateDefaultData?.recruiterEmail, 
        recruiterDetails?.email
      ),
      recruiterFirstName: ternary(
        editMode, 
        candidateDefaultData?.recruiterFirstName, 
        recruiterDetails?.firstName
      ),
      recruiterMiddleName: ternary(
        editMode,
        candidateDefaultData?.recruiterMiddleName,
        recruiterDetails?.middleName
      ),
      recruiterLastName: ternary(
        editMode,
        candidateDefaultData?.recruiterLastName,
        recruiterDetails?.lastName
      ),
      recruiterPhoneNumbers: {
        primary: ternary(
          editMode,
          candidateDefaultData?.recruiterPhoneNumbers?.primary,
          recruiterDetails?.phoneNumbers?.primary
        ),
        secondary: ternary(
          editMode,
          candidateDefaultData?.recruiterPhoneNumbers?.secondary,
          recruiterDetails?.phoneNumbers?.secondary
        ),
      },
      accountManagerDetails: {
        recruiterEmail: jobDetails?.recruiterEmail,
        recruiterName: jobDetails?.recruiterName,
        recruiterWorkPhoneNo: jobDetails?.recruiterWorkPhoneNo,
        salesPersonEmail: jobDetails.salesPersonEmail,
        salesPersonWorkPhoneNo: jobDetails.salesPersonWorkPhoneNo,
        salesPersonName: jobDetails.salesPersonName,
        recruiterId:valueOrDefault(jobDetails?.recruiterId, ""),
        salesPersonId:valueOrDefault(jobDetails?.salesPersonId, ""),
      },
      jobId: jobDetails.id,
      jobTitle: jobDetails.jobTitle,
      jobClientName: jobDetails.clientName,
      jobEndClientName: jobDetails.endClientName,
      jobCity: jobDetails.city,
      jobStateCode: jobDetails.stateCode,
      jobStateName: jobDetails.stateName,
      externalSourceJOBID: jobDetails.jobId.toString(),
      email: values.email,
      firstName: values.firstName,
      middleName: values.middleInitial,
      lastName: values.lastName,
      address: {
        id: values.city,
        streetAddress: values.address,
        city: values.city,
        state: values.state,
        postalCode: values.zip,
        countryCode: values.country,
      },
      phone: values.phone,
      experience: values.experience,
      workAuthorizationStatus: values.workAuthorization,
      isRemoteUSA: values.isRemoteUSA,

      employerName: values.employeeName,
      employerDetails: {
        name: values.employerDetails.name,
        email: values.employerDetails.email,
        reference: values.employerDetails.reference,
        phoneNumber: values.employerDetails.phoneNumber,
        address: {
          id: values.employerDetails?.address?.city,
          streetAddress: values.employerDetails?.address?.streetAddress,
          city: values.employerDetails?.address?.city,
          state: values.employerDetails?.address?.state,
          postalCode: values.employerDetails?.address?.zip,
          countryCode: values.employerDetails?.address?.country,
        },
      },
      currentWorkLocation: {
        id: values.currentWorkLocation.id,
        city: values.currentWorkLocation.city,
        state: values.currentWorkLocation.state,
      },
      canRelocate: values.canRelocate,
      skillsMatch: values.skills,
      payRate: values.payrate,
      payRateType: values.payrateType,
      packageCurrency: jobDetails.package.packageCurrency,
      employmentType: values.candidateEmployment,
      resumeInfo: {
        resourceUrl: ternary(
          isNewResumeFile === false,
          candidateDefaultData?.resumeInfoObj?.resourceUrl,
          ""
        ),
        fileName: ternary(
          isNewResumeFile === false,
          candidateDefaultData?.resumeInfoObj?.fileName,
          ""
        ),
        newFileName: ternary(
          isNewResumeFile === false,
          candidateDefaultData?.resumeInfoObj?.newFileName,
          ""
        ),
      },
      otherDocInfo: values.otherDocInfo,

      iConfirm: values.iConfirm,
      informedCandidate: values.informedCandidate,
      redirectUrl: window.location.origin + "/CandidateAcknowledge",
      candidateId: ternary(
        candidateDetail.candidateId !== "",
        candidateDetail.candidateId,
        candidateDefaultData.candidateId
      ),
      candidateGuid: ternary(
        candidateDetail.candidateGuid !== "",
        candidateDetail.candidateGuid,
        candidateDefaultData.candidateGuid
      ),
      id: ternary(
        candidateDefaultData?.id !== "",
        candidateDefaultData?.id,
        ""
      ),
      //required field needs to be added
      recruiterFullName: ternary(
        editMode,
        candidateDefaultData?.recruiterFirstName +
        " " +
        candidateDefaultData?.recruiterMiddleName +
        " " +
        candidateDefaultData?.recruiterLastName,
        getUserDetails().userName
      ),
      contractType: jobDetails.jobType,
      jobEndDate: jobDetails.jobEndDate,
      jobPostedDate: jobDetails.postingDate,
      remoteJob: jobDetails?.remoteJob,
      isUpdateCase: ternary(editMode, true, false),
      jobProviderReferenceId: jobDetails.referenceNumber,
      jobProviderName: jobDetails.jobOrigin,
      comment: {
        comment: values.recruiterComments,
        userRole: ternary(userRole == UserAuth.Admin, UserAuthByRoleName?.Admin, UserAuthByRoleName?.Recruiter),
        commentHeading: ternary(
          (userRole == UserAuth.Admin || userRole == UserAuth.SuperAdmin),
          "Admin Comment",
          "Recruiter Comment"
        ),
        addedBy: getUserDetails().id,
      },
      updateCandidate: existingCandidate.UpdateOnSubmit,
      jobOriginId:jobDetails.jobOriginId,
      ...(jobDetails.jobOriginId === JobOriginIds.jobRialto) && 
      {
        clientId : valueOrDefault(jobDetails?.clientId, ""),
        clientEmail : valueOrDefault(jobDetails.companyInformation?.companyEmail, ""),
        clientPhone : valueOrDefault(jobDetails.companyInformation?.clientPhone, ""),

      }
    };

    const resumeUploadModel: UploadResourceModel = {
      documentType: DocumentTypeEnum.Resume,
      file: resumeFile?.file as File,
    };

    if (existingCandidate.candidateData) {
      model.resumeInfo = {
        resourceUrl: ternary(
          isNewResumeFile === false,
          existingCandidate.candidateData?.resumeInfo?.resourceUrl,
          ""
        ),
        fileName: ternary(
          isNewResumeFile === false,
          existingCandidate.candidateData?.resumeInfo?.fileName,
          ""
        ),
        newFileName: ternary(
          isNewResumeFile === false,
          existingCandidate.candidateData?.resumeInfo?.newFileName,
          ""
        ),
      };
    }

    model.otherDocInfo = model.otherDocInfo.filter((x) => x.fileName != "");
    if (isNewResumeFile) {
      handleNewResumeSubmit(model, resumeUploadModel);
    } else {
      let scoreCard = candidateDefaultData?.otherDocInfo.find(
        (doc: any) => doc.documentType === "ResumeScoreCard"
      );
      model.otherDocInfo = model.otherDocInfo.filter(
        (x) => x.documentType !== "ResumeScoreCard"
      );
      if (scoreCard) {
        model.otherDocInfo.push(scoreCard);
        submitResume(model);
      } else {
        await handleScorePdf(model);
      }
    }
  };

  const submitResume = (model: any) => {
    const resumePromise = editMode
    ? UpdateResumeByRecruiter(model)
    : SubmitResumeByRecruiter(model);

    resumePromise
      .then(async (response:any) => {
        if (response.status === 200) {
          if (
            response.data.message.appStatusDescription ===
            "Resume has been submitted successfully"
          ) {
            setOpen(true);
          } else if (response.data.message.appStatusCode === "RC_MdER") {
            setError({
              hasError: true,
              errorMsg: response.data.message.appStatusDescription,
            });
            setScoreResume(false);
            window.scrollTo(0, 0);
          } else if (response.data.message.appStatusCode === "RC_SUB_EXTS") {
            setError({
              hasError: false,
              errorMsg: response.data.message.appStatusDescription,
            });
            setDuplicateSubmissionDialogOpen(true);
            setScoreResume(false);
          } else if (response.data.message.appStatusCode === "RC_RES_UPD") {
            //Share Pdf of update submission detail
            let responseData = response.data.entity;
            let jobDetailPdf = await getSubmissionChangeLogPdfFile({
              currentDetails:existingSubmission,
              modifiedDetails:responseData
            });
            let formData = new FormData();
            formData.append("recruiterEmail", responseData?.recruiterEmail);
            formData.append("candidateEmail", responseData?.email);
            formData.append(
              "recruiterFirstName",
              responseData.recruiterFirstName
            );
            formData.append(
              "recruiterLastName",
              responseData.recruiterLastName
            );
            formData.append("candidateFirstName", responseData?.firstName);
            formData.append("candidateLastName", responseData.lastName);
            formData.append("jobTitle", responseData.jobTitle);
            formData.append("jobId", responseData.jobId);
            formData.append("recruiterPhoneNumber",  responseData.recruiterPhoneNumbers?.primary);
            formData.append("candidatePhoneNumber",  responseData.phone);
            formData.append("submissionDetailFile", jobDetailPdf);
            formData.append("role", user.loggedInUserRole);
            SendEmailForUpdateSubmission(formData).finally(() => {
              setOpen(true);
            });
          } else {
            setError({
              hasError: true,
              errorMsg: "Unexpected error, try again",
            });
            setScoreResume(false);
            window.scrollTo(0, 0);
          }
        } else {
          setError({
            hasError: true,
            errorMsg: "Unexpected error, try again",
          });
          setScoreResume(false);
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFormSubmitting({ ...isFormSubmitting, submit: false });
      });
    if (!editMode) {
      RecruiterSaveJob({ recruiterId: user.userId, jobId: jobDetails.id })
        .then((x) => {
          console.log("Job saved");
        })
        .catch((x) => {
          console.log("ERROR");
        });
    }
  };

  useEffect(() => {
    formRef.current?.setFieldValue(
      "canRelocate",
      candidateDefaultData.canRelocate
    );
  }, [candidateDefaultData.canRelocate]);

  const GetCandidateDetailsById = (cid: any) => {
    GetSubmissionsById(cid)
      .then(async (response: any) => {
        if (response.status === 200) {
          setExistingSubmission(response?.data?.entity);
          setCandidateDefaultData({
            email: valueOrDefault(response?.data?.entity?.email, ""),
            firstName: valueOrDefault(response?.data?.entity?.firstName, ""),
            lastName: valueOrDefault(response?.data?.entity?.lastName, ""),
            middleInitial: valueOrDefault(response?.data?.entity?.middleName, ""),
            zip: valueOrDefault(response?.data?.entity?.address?.postalCode, ""),
            country: valueOrDefault(response?.data?.entity?.address?.countryCode, ""),
            address: valueOrDefault(response?.data?.entity?.address?.streetAddress, ""),
            city: valueOrDefault(response?.data?.entity?.address?.city, ""),
            state: valueOrDefault(response?.data?.entity?.address?.state, ""),
            phoneNumbers: {
              id: valueOrDefault(response?.data?.entity?.phoneNumbers?.id, ""),
              primary: valueOrDefault(response?.data?.entity?.phoneNumbers?.primary, ""),
              secondary: valueOrDefault(response?.data?.entity?.phoneNumbers?.secondary, ""),
            },
            phone: valueOrDefault(response?.data?.entity?.phone, ""),
            experience: valueOrDefault(response?.data?.entity?.experience, ""),
            skills: response?.data?.entity?.skillsMatch || [],
            workAuthorization:
              valueOrDefault(response?.data?.entity?.workAuthorizationStatus, ""),
            isRemoteUSA: response?.data?.entity?.isRemoteUSA || false,
            employeeName: valueOrDefault(response?.data?.entity?.employerName, ""),
            employerDetails: {
              name: valueOrDefault(response?.data?.entity?.employerDetails?.name, ""),
              email: valueOrDefault(response?.data?.entity?.employerDetails?.email, ""),
              reference:
                valueOrDefault(response?.data?.entity?.employerDetails?.reference, ""),
              phoneNumber:
                valueOrDefault(response?.data?.entity?.employerDetails?.phoneNumber, ""),
              address: {
                zip: valueOrDefault(
                  response?.data?.entity?.employerDetails?.address?.postalCode,
                  ""
                ),
                country: valueOrDefault(
                  response?.data?.entity?.employerDetails?.address?.countryCode,
                  ""
                ),
                streetAddress: valueOrDefault(
                  response?.data?.entity?.employerDetails?.address?.streetAddress,
                  ""
                ),
                city: valueOrDefault(
                  response?.data?.entity?.employerDetails?.address?.city,
                  ""
                ),
                state: valueOrDefault(
                  response?.data?.entity?.employerDetails?.address?.state,
                  ""
                ),
              },
            },
            payrate: valueOrDefault(response?.data?.entity?.payRate, ""),
            payrateType: response?.data?.entity?.payrateType || "hourly",
            candidateEmployment: valueOrDefault(response?.data?.entity?.employmentType, ""),
            currentWorkLocation: {
              id: valueOrDefault(response?.data?.entity?.currentWorkLocation?.id, ""),
              city: valueOrDefault(response?.data?.entity?.currentWorkLocation?.city, ""),
              state: valueOrDefault(response?.data?.entity?.currentWorkLocation?.state, ""),
            },
            recruiterComments: valueOrDefault(response?.data?.entity?.comment?.comment, ""),
            resume: valueOrDefault(response?.data?.entity?.resumeInfo?.fileName, ""),
            otherDocument: response?.data?.entity?.otherDocInfo || [],
            resumeInfoObj: {
              resourceUrl:
                valueOrDefault(response?.data?.entity?.resumeInfo?.resourceUrl, ""),
              fileName: valueOrDefault(response?.data?.entity?.resumeInfo?.fileName, ""),
              newFileName:
                valueOrDefault(response?.data?.entity?.resumeInfo?.newFileName, ""),
            },

            otherDocInfo: response?.data?.entity?.otherDocInfo,
            candidateId: response?.data?.entity?.candidateId,
            candidateGuid: response?.data?.entity?.candidateGuid,
            id: response?.data?.entity?.id,
            canRelocate: response?.data?.entity?.canRelocate,
            recruiterEmail: response?.data?.entity?.recruiterEmail,
            recruiterFirstName: response?.data?.entity?.recruiterFirstName,
            recruiterMiddleName: response?.data?.entity?.recruiterMiddleName,
            recruiterLastName: response?.data?.entity?.recruiterLastName,
            recruiterGuid:response?.data?.entity?.recruiterGuid,
            recruiterId:response?.data?.entity?.recruiterId,
            comments:response?.data?.entity?.comments,
            recruiterPhoneNumbers : { 
              primary: response?.data?.entity?.recruiterPhoneNumbers?.primary, 
              secondary: response?.data?.entity?.recruiterPhoneNumbers?.secondary
            }
          });
          setOtherDocuments(
            response?.data?.entity?.otherDocInfo.filter(
              (doc: any) => doc.documentType !== "ResumeScoreCard"
            )
          );
          if (response?.data?.entity?.resumeInfo?.resourceUrl) {
            let file = await getFileFromUrl(
              response?.data?.entity?.resumeInfo?.resourceUrl,
              response?.data?.entity?.resumeInfo?.fileName
            );
            setResumeFile({
              file: file,
              fileName: response?.data?.entity?.resumeInfo?.fileName,
            });
          }

          formRef.current?.setFieldValue(
            "candidateEmployment",
            response?.data?.entity?.employmentType
          );
          let workAuth = response?.data?.entity?.workAuthorizationStatus;
          formRef.current?.setFieldValue("workAuthorization", workAuth);
          formRef.current?.setFieldValue(
            "isRemoteUSA",
            response?.data?.entity?.isRemoteUSA
          );

          formRef.current?.setFieldValue(
            "canRelocate",
            response?.data?.entity?.canRelocate
          );

          formRef.current?.setFieldValue(
            "resume",
            response?.data?.entity?.resumeInfo?.fileName
          );
          formRef.current?.setFieldValue(
            "otherDocument",
            response?.data?.entity?.otherDocInfo?.[0].fileName
          );

          setCondidateDetail({
            candidateGuid: response?.data?.entity?.candidateGuid,
            candidateId:  response?.data?.entity?.candidateId,
          });
        } else {
          setError({
            hasError: true,
            errorMsg: "Unexpected error, try again",
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };


  const handleSaveResume = (model: any) => {
    if (validateSaveResume(formRef.current?.values)) {
      setSaveResumeConfirmDialog(true);
    }
  };

  const validateSaveResume = (formData: any) => {
    let validSaveResume = true;

    if (formData?.firstName === "") {
      validSaveResume = false;
      formRef.current?.setFieldTouched("firstName", true);
    }

    if (formData?.lastName === "") {
      validSaveResume = false;
      formRef.current?.setFieldTouched("lastName", true);
    }

    if (formData?.email === "") {
      validSaveResume = false;
      formRef.current?.setFieldTouched("email", true);
    }

    formRef.current?.setFieldTouched("address", false);
    formRef.current?.setFieldTouched("city", false);
    formRef.current?.setFieldTouched("state", false);
    formRef.current?.setFieldTouched("zip", false);
    formRef.current?.setFieldTouched("country", false);
    formRef.current?.setFieldTouched("phone", false);
    formRef.current?.setFieldTouched("experience", false);
    formRef.current?.setFieldTouched("canRelocate", false);
    formRef.current?.setFieldTouched("resume", false);
    formRef.current?.setFieldTouched("payrate", false);
    formRef.current?.setFieldTouched("payrateType", false);
    formRef.current?.setFieldTouched("candidateEmployment", false);
    formRef.current?.setFieldTouched("recruiterComments", false);
    formRef.current?.setFieldTouched("workAuthorization", false);
    formRef.current?.setFieldTouched("skills", false);
    formRef.current?.setFieldTouched("currentWorkLocation", false);
    formRef.current?.setFieldTouched("iConfirm", false);
    formRef.current?.setFieldTouched("informedCandidate", false);
    formRef.current?.setFieldTouched("employeeName", false);
    formRef.current?.setFieldTouched("employerDetails.name", false);
    formRef.current?.setFieldTouched("employerDetails.email", false);
    formRef.current?.setFieldTouched("employerDetails.phoneNumber", false);
    formRef.current?.setFieldTouched("employerDetails.reference", false);
    formRef.current?.setFieldTouched(
      "employerDetails.address.streetAddress",
      false
    );
    formRef.current?.setFieldTouched("employerDetails.address.city", false);
    formRef.current?.setFieldTouched("employerDetails.address.state", false);
    formRef.current?.setFieldTouched("employerDetails.address.zip", false);
    formRef.current?.setFieldTouched("employerDetails.address.country", false);
    setIsUploadTouch(false);

    return validSaveResume;
  };

  function uploadScorePdfSave(model : any, scorePdf : File) {
    UploadAttachment({
      documentType: DocumentTypeEnum.Resume,
      file: scorePdf,
    })
    .then((pdfRes) => {
      model?.otherDocInfo?.push({
        resourceUrl: pdfRes.data.entity.absoluteUri,
        fileName: pdfRes.data.entity.fileName,
        newFileName: pdfRes.data.entity.newFileName,
        documentType: "ResumeScoreCard",
      });

      saveResume(model);
    })
    .catch(() => {
      setError({
        hasError: true,
        errorMsg: "Unexpected error, try again",
      });
      setIsFormSubmitting({ ...isFormSubmitting, save: false });
    });
  }

  async function handleScorePdfSave(model : any) {
    if (
      glasssquidScoreStatus?.statusCode &&
      glasssquidScoreStatus?.statusCode === 200 &&
      glasssquidScore
    ) {
      let scorePdf = scoreCardPdf?.pdfFile;
      if (!scorePdf) {
        scorePdf = await getScoreCardPdfFile({data:glasssquidScore.messageList[0],  
          status: "",
          jobId: jobDetails?.id,
          jobTitle: jobDetails?.jobTitle});
      }

      uploadScorePdfSave(model, scorePdf);
    } else {
      saveResume(model);
    }
  }

  function handleNewResumeSave(model : any, resumeUploadModel : UploadResourceModel) {
    model.otherDocInfo = model.otherDocInfo.filter(
      (x : any) => x.documentType !== "ResumeScoreCard"
    );

    UploadAttachment(resumeUploadModel)
      .then(async (response) => {
        model.resumeInfo.fileName = response.data.entity.fileName;
        model.resumeInfo.resourceUrl = response.data.entity.absoluteUri;
        model.resumeInfo.newFileName = response.data.entity.newFileName;
        
        await handleScorePdfSave(model);
      });
  }

  const handleSaveConfirmationYes = async () => {
    //model for saving resume
    let saveModel = {
      id: candidateDefaultData.id,
      recruiterGuid: recruiterDetails.id,
      recruiterId: recruiterDetails.recruiterId,
      recruiterEmail: recruiterDetails.email,
      recruiterPhoneNumbers: {
        primary: recruiterDetails.phoneNumbers.primary,
        secondary: recruiterDetails.phoneNumbers.secondary,
      },
      jobId: jobDetails.id,
      jobTitle: jobDetails.jobTitle,
      jobClientName: jobDetails.clientName,
      jobEndClientName: jobDetails.endClientName,
      jobCity: jobDetails.city,
      jobStateCode: jobDetails.stateCode,
      jobStateName: jobDetails.stateName,
      externalSourceJOBID: jobDetails.jobId.toString(),
      email: formRef.current?.values.email,
      firstName: formRef.current?.values.firstName,
      middleName: formRef.current?.values.middleInitial,
      lastName: formRef.current?.values.lastName,
      address: {
        id: formRef.current?.values.city,
        streetAddress: formRef.current?.values.address,
        city: formRef.current?.values.city,
        state: formRef.current?.values.state,
        postalCode: formRef.current?.values.zip,
        countryCode: formRef.current?.values.country,
      },
      phone: formRef.current?.values.phone,
      experience: formRef.current?.values.experience,
      workAuthorizationStatus: formRef.current?.values.workAuthorization,
      isRemoteUSA: formRef.current?.values.isRemoteUSA,
      employerName: formRef.current?.values.employeeName,
      currentWorkLocation: {
        id: formRef.current?.values.currentWorkLocation.id,
        city: formRef.current?.values.currentWorkLocation.city,
        state: formRef.current?.values.currentWorkLocation.state,
      },
      canRelocate: formRef.current?.values.canRelocate,
      skillsMatch: formRef.current?.values.skills,
      payRate: formRef.current?.values.payrate,
      payRateType: formRef.current?.values.payrateType,
      packageCurrency: jobDetails.package.packageCurrency,
      employmentType: formRef.current?.values.candidateEmployment,
      resumeInfo: {
        resourceUrl: candidateDefaultData.resumeInfoObj.resourceUrl,
        fileName: candidateDefaultData.resumeInfoObj.fileName,
        newFileName: candidateDefaultData.resumeInfoObj.newFileName,
      },
      otherDocInfo: formRef.current?.values.otherDocInfo,
      iConfirm: formRef.current?.values.iConfirm,
      informedCandidate: formRef.current?.values.informedCandidate,
      redirectUrl: window.location.origin + "/CandidateAcknowledge",
      candidateId: candidateDefaultData.candidateId,
      candidateGuid: candidateDefaultData.candidateGuid,
      //required field needs to be added
      recruiterFullName: getUserDetails().userName,
      recruiterFirstName: recruiterDetails.firstName,
      recruiterMiddleName: recruiterDetails.middleName,
      recruiterLastName: recruiterDetails.lastName,
      contractType: jobDetails.jobType,
      jobEndDate: jobDetails.jobEndDate,
      jobPostedDate: jobDetails.postingDate,
      remoteJob: jobDetails?.remoteJob,
      accountManagerDetails: {
        recruiterEmail: jobDetails?.recruiterEmail,
        recruiterName: jobDetails?.recruiterName,
        recruiterWorkPhoneNo: jobDetails?.recruiterWorkPhoneNo,
        salesPersonEmail: jobDetails.salesPersonEmail,
        salesPersonWorkPhoneNo: jobDetails.salesPersonWorkPhoneNo,
        salesPersonName: jobDetails.salesPersonName,
        recruiterId: valueOrDefault(jobDetails?.recruiterId, ""),
        salesPersonId: valueOrDefault(jobDetails?.salesPersonId, ""),
      },
      jobProviderReferenceId: jobDetails.referenceNumber,
      jobProviderName: jobDetails.jobOrigin,
      employerDetails: {
        name: formRef.current?.values?.employerDetails?.name,
        email: formRef.current?.values?.employerDetails?.email,
        reference: formRef.current?.values?.employerDetails?.reference,
        phoneNumber: formRef.current?.values?.employerDetails?.phoneNumber,
        address: {
          id: formRef.current?.values?.employerDetails.address.city,
          streetAddress:
            formRef.current?.values?.employerDetails?.address?.streetAddress,
          city: formRef.current?.values?.employerDetails?.address?.city,
          state: formRef.current?.values?.employerDetails?.address?.state,
          postalCode: formRef.current?.values?.employerDetails?.address?.zip,
          countryCode:
            formRef.current?.values?.employerDetails?.address?.country,
        },
      },
      comment: ternary(
        formRef.current?.values?.recruiterComments != "",
        {
          comment: formRef.current?.values.recruiterComments,
          userRole: ternary(
            userRole == UserAuth.Admin,
            UserAuthByRoleName.Admin,
            UserAuthByRoleName?.Recruiter
          ),
          commentHeading: ternary(
            (userRole == UserAuth.Admin || userRole == UserAuth.SuperAdmin),
            "Admin Comment",
            "Recruiter Comment"
          ),
          addedBy: getUserDetails().id,
        },
        undefined
      ),
      jobOriginId: jobDetails.jobOriginId,
      ...(jobDetails.jobOriginId === JobOriginIds.jobRialto && {
        clientId: valueOrDefault(jobDetails?.clientId, ""),
        clientEmail: valueOrDefault(jobDetails.companyInformation?.companyEmail, ""),
        clientPhone: valueOrDefault(jobDetails.companyInformation?.clientPhone, ""),
      }),
    };

    if (existingCandidate.candidateData) {
      saveModel.resumeInfo = {
        resourceUrl: ternary(
          isNewResumeFile === false,
          existingCandidate.candidateData?.resumeInfo?.resourceUrl,
          ""
        ),
        fileName: ternary(
          isNewResumeFile === false,
          existingCandidate.candidateData?.resumeInfo?.fileName,
          ""
        ),
        newFileName: ternary(
          isNewResumeFile === false,
          existingCandidate.candidateData?.resumeInfo?.newFileName,
          ""
        ),
      };
    }

    const resumeUploadModel: UploadResourceModel = {
      documentType: DocumentTypeEnum.Resume,
      file: resumeFile?.file as File,
    };

    saveModel.otherDocInfo = saveModel.otherDocInfo!.filter(
      (x) => x.fileName != ""
    );

    setIsFormSubmitting({ ...isFormSubmitting, save: true });

    if (isNewResumeFile) {
      handleNewResumeSave(saveModel, resumeUploadModel);
    } else {
      let scoreCard = candidateDefaultData?.otherDocInfo.find(
        (doc: any) => doc.documentType === "ResumeScoreCard"
      );
      saveModel.otherDocInfo = saveModel.otherDocInfo.filter(
        (x) => x.documentType !== "ResumeScoreCard"
      );
      if (scoreCard) {
        saveModel.otherDocInfo.push(scoreCard);
        saveResume(saveModel);
      } else {
        await handleScorePdfSave(saveModel);
      }
    }
    setSaveResumeConfirmDialog(false);
  };

  const handleSaveConfirmationNo = () => {
    setSaveResumeConfirmDialog(false);
  };

  const saveResume = (model: any) => {
    setIsFormSubmitting({ ...isFormSubmitting, save: true });
    SaveResumeByRecruiter(model)
      .then((response: any) => {
        if (response.status === 200) {
          if (response.data.message.appStatusCode === "RC_RES_SAV") {
            setSaveResumeSuccessDialog(true);
            setCandidateDefaultData({
              ...candidateDefaultData,
              candidateGuid: response?.data?.entity?.candidateGuid,
              candidateId: response?.data?.entity?.candidateId,
            });
          } else if (response.data.message.appStatusCode === "RC_MdER") {
            setError({
              hasError: true,
              errorMsg: response.data.message.appStatusDescription,
            });
          } else if (response.data.message.appStatusCode === "RC_SUB_EXTS") {
            setError({
              hasError: false,
              errorMsg: response?.data?.message?.appStatusDescription,
            });
            setDuplicateSubmissionDialogOpen(true);
            setScoreResume(false);
          } else {
            setError({
              hasError: true,
              errorMsg: "Unexpected error, try again",
            });
          }
        } else {
          setError({
            hasError: true,
            errorMsg: "Unexpected error, try again",
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsFormSubmitting({ ...isFormSubmitting, save: false });
      });
  };

  const GetCandidateDataIfExist = (
    obj: {
      email: string;
      firstName: string;
      lastName: string;
    },
    setFieldValue?: any
  ) => {
    if (obj.email !== "" && obj.firstName !== "" && obj.lastName !== "") {
      GetCandidateDataByEmail({
        email: obj.email,
        fistname: obj.firstName,
        lastname: obj.lastName,
        recruiterId:userRole === UserAuth.Admin && editMode ? candidateDefaultData.recruiterGuid : user.userId
      }).then((res) => {
        if (res.data?.entity) {
          let candidateData = res.data.entity;
          setCondidateDetail({
            candidateGuid: candidateData.id,
            candidateId: candidateData.candidateId,
          });
        }
      });
    }
  };

  const handleSelect = async (address: any) => {
    let resp = await getAddressForAutoFill(address);

    formRef.current?.setFieldValue("address", valueOrDefault(resp?.formattedAddress, ""));

    setCountryShort(resp?.countryCode?.toLowerCase());
    formRef.current?.setFieldValue("zip", valueOrDefault(resp?.pin, ""));
    formRef.current?.setFieldValue("country", valueOrDefault(resp?.country, ""));
    formRef.current?.setFieldValue("state", valueOrDefault(resp?.state, ""));
    formRef.current?.setFieldValue("city", valueOrDefault(resp?.city, ""));
    if (resp?.country) {
      formRef.current?.setFieldTouched("country", false);
    }
    if (resp?.pin) {
      formRef.current?.setFieldTouched("zip", false);
    }
    if (resp?.state) {
      formRef.current?.setFieldTouched("state", false);
    }
    if (resp?.city) {
      formRef.current?.setFieldTouched("city", false);
    }
  };

  const handleSelect1 = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formRef.current?.setFieldValue(
      "employerDetails.address.streetAddress",
      valueOrDefault(resp?.formattedAddress, "")
    );
    setEmpCountryShort(resp?.countryCode?.toLowerCase());
    formRef.current?.setFieldValue(
      "employerDetails.address.zip",
      valueOrDefault(resp?.pin, "")
    );
    formRef.current?.setFieldValue(
      "employerDetails.address.country",
      valueOrDefault(resp?.country, "")
    );
    formRef.current?.setFieldValue(
      "employerDetails.address.state",
      valueOrDefault(resp?.state, "")
    );
    formRef.current?.setFieldValue(
      "employerDetails.address.city",
      valueOrDefault(resp?.city, "")
    );
    if (resp?.country) {
      formRef.current?.setFieldTouched(
        "employerDetails.address.country",
        false
      );
    }
    if (resp?.pin) {
      formRef.current?.setFieldTouched("employerDetails.address.zip", false);
    }
    if (resp?.state) {
      formRef.current?.setFieldTouched("employerDetails.address.state", false);
    }
    if (resp?.city) {
      formRef.current?.setFieldTouched("employerDetails.address.city", false);
    }
  };

  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <div className="submit-resume-address">
      <FormControl
        variant="standard"
        error={
          formRef.current?.touched.address &&
          Boolean(formRef.current?.errors.address)
        }
        fullWidth
        required
      >
        <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input {...getInputProps()} name="address.streetAddress" type="text"  inputProps={{"data-testid":"addrAuto-input"}}/>
        <ErrorMessage
          errorText={
            formRef.current?.touched?.address &&
            formRef.current?.errors?.address
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            key={suggestion.description}
            {...getSuggestionItemProps(suggestion)}
          >
            <span onChange={handleSelect} style={{ cursor: "pointer" }} data-testid="address-autocomplete">
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const employerrenderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => {
    return (
      <div className="submit-resume-address">
        <FormControl
          className="sub-res-add"
          variant="standard"
          error={
            formRef.current?.touched.employerDetails?.address?.streetAddress &&
            Boolean(
              formRef.current?.errors.employerDetails?.address?.streetAddress
            )
          }
        >
          <InputLabel htmlFor="employerDetails.address.streetAddress">
            Employer Address
            {isEmployerNameRequired(
              formRef.current?.values?.workAuthorization ?? "",
              formRef.current?.values?.candidateEmployment ?? ""
            ) && <Asterisk />}
          </InputLabel>
          <Input
            {...getInputProps()}
            name="employerDetails.address.streetAddress"
            type="text"
          />
          <ErrorMessage
            errorText={
              formRef.current?.touched?.employerDetails?.address
                ?.streetAddress &&
              formRef.current?.errors?.employerDetails?.address?.streetAddress
            }
          />
        </FormControl>
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion: any) => (
            <div
              key={suggestion.description}
              {...getSuggestionItemProps(suggestion)}
            >
              <span onChange={handleSelect1} style={{ cursor: "pointer" }}>
                {suggestion.description}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const autoCompleteGetWorkLocation = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formRef.current?.setFieldValue("currentWorkLocation.city", valueOrDefault(resp.city, ""));
    formRef.current?.setFieldValue(
      "currentWorkLocation.state",
      valueOrDefault(resp.state, "")
    );
    formRef.current?.setFieldValue("currentWorkLocation.id", valueOrDefault(resp.city, ""));
  };

  const renderFuncCurrentWorkLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <>
      <FormControl
        variant="standard"
        error={
          formRef.current?.touched.currentWorkLocation?.city &&
          Boolean(formRef.current?.errors.currentWorkLocation?.city)
        }
        required
      >
        <InputLabel htmlFor="address-input">City</InputLabel>
        <Input
          {...getInputProps()}
          name="currentWorkLocation.city"
          type="text"
          onBlur={formRef.current?.handleBlur}
        />
        <ErrorMessage
          errorText={
            formRef.current?.touched.currentWorkLocation?.city &&
            formRef.current?.errors.currentWorkLocation?.city
          }
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion: any) => (
            <div {...getSuggestionItemProps(suggestion)}>
              <span
                onChange={autoCompleteGetWorkLocation}
                style={{ cursor: "pointer" }}
              >
                {suggestion.description}
              </span>
            </div>
          ))}
        </div>
      </FormControl>
    </>
  );

  const onResumeFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsUploadTouch(true);
    if (e.currentTarget.files) {
      if (!isValidFileExtention(e.currentTarget.files[0].name)) {
        formRef?.current?.setFieldError("resume", "Invalid file extension");
      } else if (e.currentTarget.files[0].size > 2000000) {
        formRef?.current?.setFieldError("resume", "File size too large");
      } else {
        handleUploadClick(e.currentTarget.files || null, "Resume");
        formRef?.current?.setFieldValue(
          "resume",
          e.currentTarget.files ? e.currentTarget.files[0].name : ""
        );
        setScoreCardPdf({pdfFile:undefined,isGenerating:false});
        if(isScorecardFeatureActive){
          setScoreResume(true);
        }
        
      }
    }
  };

  const handleOnSubmit = (values: any) => {
    if (
      existingCandidate.UpdateOnSubmit === undefined &&
      candidateDetail.candidateId !== ""
    ) {
      setExistingCandidate({
        ...existingCandidate,
        UpdateOnSubmitDialog: true,
      });
    } else {
      if (
        values.payrate > jobDetails?.package?.packageValue &&
        !payrateDialog.isConfirm
      ) {
        setPayrateDialog({ display: true, isConfirm: false });
      } else {
        handleResumeSubmit(values);
      }
    }
  };


  const validateFormik = async (values: typeof initialState) => {
    const errors: Record<string, any> = {};
  
    const resumeData = glasssquidScore?.messageList?.[0]?.resumeData;
  
    if (resumeData) {
      validateEmail(values.email, resumeData.emailAddress, errors);
      validateName(values.firstName, values.lastName, resumeData.name, errors);
      validateExperience(values.experience, resumeData.yearsOfExperienceInNumbers, errors);
    }
  
    return errors;
  };
  
  const validateEmail = (value: string, resumeEmail: string, errors: Record<string, any>) => {
    if (value !== resumeEmail) {
      errors.email = "Email does not match with the resume. Please update the email";
    }
  };
  
  const validateName = (firstName: string, lastName: string, resumeName: string, errors: Record<string, any>) => {
    const nameParts = resumeName.split(" ");
    const resumeFirstName = nameParts[0];
    const resumeLastName = nameParts[nameParts.length - 1];
  
    if (nameParts.length === 1) {
      if (firstName !== resumeFirstName) {
        errors.firstName = "First Name does not match with the resume. Please update the first name";
      }
    } else {
      if (firstName !== resumeFirstName) {
        errors.firstName = "First Name does not match with the resume. Please update the first name";
      }
      if (lastName !== resumeLastName) {
        errors.lastName = "Last Name does not match with the resume. Please update the last name";
      }
    }
  };
  
  const validateExperience = (value: string, resumeExperience: number, errors: Record<string, any>) => {
    if (resumeExperience !== undefined && value?.toString() !== resumeExperience.toString()) {
      errors.experience = "Experience does not match with the resume. Please update the experience";
    }
  };
  

  let formattedPayRate = getPayRate(
    jobDetails?.package?.packageType,
    jobDetails?.package?.packageValue,
    jobDetails?.package?.packageCurrency
  );

  useEffect(() => {
    if(resumeFile?.file && isScorecardFeatureActive){
       getGlasssquidScore();
        if(isNewResumeFile)
          setScoreResume(true);
    }

  }, [resumeFile]);

  useEffect(() => {
    if ( isScorecardFeatureActive && glasssquidScore ) {
      setTimeout(async () => {
        let scoreCardFile=await getScoreCardPdfFile({data:glasssquidScore.messageList[0],  
        status: "",
        jobId: jobDetails?.id,
        jobTitle: jobDetails?.jobTitle})
        setScoreCardPdf({pdfFile:scoreCardFile,isGenerating:false});
      }, 1000);
    }
  }, [glasssquidScore]);

  function getGlasssquidScore() {
    let formData = new FormData();
    formData.append("JobId", jobDetails?.id);
    formData.append("File", resumeFile?.file as File);
    formData.append("skillsPct", "14.2");
    formData.append("jobTitlePct", "14.2");
    formData.append("technologyPct", "14.2");
    formData.append("toolsPct", "14.2");
    formData.append("educationPct", "14.2");
    formData.append("certificationPct", "14.2");
    formData.append("industryPct", "14.2");
    formData.append("feature", "ScoreCard");    

    // Append data to the FormData object for V2 Version
    formData.append("EximScore", "");
    formData.append("JobData", valueOrDefault(jobDetails?.jobDescriptionNoHtml,""));
    formData.append("ResumeData", "");
    formData.append("UnweightedMatchedScores", "");
    formData.append("JobTitle", valueOrDefault(jobDetails?.jobTitle, ""));

    // For arrays, 
    jobDetails?.manualData?.skills?.map((item:any) => {      
     return  formData.append("Skills", valueOrDefault(item.skill, "")) 
    })

    jobDetails?.manualData?.CertificationAndLicenses?.map((item:any) => {      
      return  formData.append("CertificationAndLicenses", valueOrDefault(item.certification, "")) 
     })

     jobDetails?.education?.map((item:any) => {      
      return  formData.append("Education", valueOrDefault(item?.degreeName, "")) 
     })
    
    if(jobDetails?.tools && jobDetails?.tools !==""){
      formData.append("Tools", "");
    }
    if(jobDetails?.technology !==""){
      formData.append("Technology", valueOrDefault(jobDetails?.technology, ""));
    }
    
    formData.append("Industry", valueOrDefault(jobDetails?.industry, ""));
    formData.append("Domain", jobDetails?.domain);

    setIsScoreCardLoading(true);
    setScoreCardPdf({isGenerating:true})
    EximiusMatchProfile(formData)
      .then((response) => {
        if(response.data.status === 200)
        {
          setGlasssquidScore(response.data.entity);
        }
        
        setIsAutoPopulatePending(true);
          setGlasssquidScoreStatus({
            statusCode:response.data.status,
            statusMsg: response.data.message
          })
      })
      .finally(async () => {
        setIsScoreCardLoading(false);
      });
  }

  const handleUploadAttachmentsOnChange = (files: never[]) => {
    formRef.current?.setFieldValue("otherDocInfo", files);
  };

  const handleDuplicateDialogConfirmationNo = () => {
    setDuplicateSubmissionDialogOpen(false);
  };

  const downloadScoreCard = () => {
    if (scoreCardPdf?.pdfFile) {
      let csvURL = window.URL.createObjectURL(scoreCardPdf.pdfFile);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "Score-card.pdf");
      tempLink.click();
    }
  };

  const determineBreadCrumbItems = (fromPage:string | undefined) => {
    switch (fromPage) {
      case 'jobalertsdetails':
        return jobAlertsJobDetailsBreadcrumbs(jobDetails);
      case 'jobalerts':
        return jobalertBreadCrumb;
      case 'savedjobs':
        return savedJobBreadCrumb;
      case 'recruiter':
          return savedJobListJobBreadCrumb;
      case 'recruiterSavedJobdetails':
          return savedJobListJobDetailsJobBreadCrumb(jobDetails);          
      case 'jobs':
        return allJobBreadCrumb;
      case 'pendingsubmission':
        return pendingsubmission;
      case 'submission':
        return updateSubmisssion;
      case 'submissionactive':
      case 'submissionarchived':
        return newSubmissionBreadCrumb(fromPage);
      case 'findjobsExtdetails':
        return findJobsFromJobDetailsBreadCrumb(jobDetails, fromPage);
      case 'findjobs':
        return findJobsBreadCrumb;
      default:
        return jobDetailsBreadCrumb(jobDetails, fromPage);
    }
  };

  function displayExistingResumeFile() : boolean {
    return !isNewResumeFile &&
      (
        (
          existingCandidate.candidateData?.resumeInfo?.resourceUrl !== undefined && 
          existingCandidate.candidateData?.resumeInfo?.resourceUrl !== ""
        ) 
        || 
        (
          candidateDefaultData.resumeInfoObj.resourceUrl !== undefined && 
          candidateDefaultData.resumeInfoObj.resourceUrl !== ""
        )
      );
  }

  function scoreCardPreview() {
    if (scoreCardPdf?.pdfFile) {
      setFilePreview({
        resourceUrl: window.URL.createObjectURL(scoreCardPdf?.pdfFile),
        fileName: scoreCardPdf?.pdfFile?.name,
        fileType: "application/pdf"
      });
    }
  }

  const handlePreviewResume = () => {
    const candidateResumeInfo = existingCandidate.candidateData?.resumeInfo;
    const defaultResumeInfo = candidateDefaultData.resumeInfoObj;
  
    let resourceUrl = candidateResumeInfo?.resourceUrl ?? defaultResumeInfo.resourceUrl;
    let resourceName = candidateResumeInfo?.fileName ?? defaultResumeInfo.fileName;
  
    getFilePreviewUrl(resourceUrl)
      .then(filePreviewUrl => setFilePreview({
        resourceUrl: filePreviewUrl,
        fileName: resourceName
      }));
  };

  const handleDownloadResume = () => {
    const candidateResumeInfo = existingCandidate.candidateData?.resumeInfo;
    const defaultResumeInfo = candidateDefaultData.resumeInfoObj;
  
    let resourceUrl = candidateResumeInfo?.resourceUrl ?? defaultResumeInfo.resourceUrl;
    let resourceName = candidateResumeInfo?.fileName ?? defaultResumeInfo.fileName;
  
    downloadFile(resourceUrl, resourceName);
  };

  const renderWarningIcon = () => {
    const statusCode = glasssquidScoreStatus?.statusCode;
  
    if (statusCode && statusCode !== 200) {
      return (
        <WarningAmberIcon
          style={{ color: '#FFCC00' }}
          sx={{ verticalAlign: "bottom", pr: "5px" }}
        />
      );
    }
  
    return null;
  };

  const checkFieldValidity = (touched:any, errors:any, fieldName:string) => {
    return touched[fieldName] && Boolean(errors[fieldName]);
  };

  const renderErrorMessage = (errors: any, fieldName: string) => {
    let errorMessage = "";
  
    switch (fieldName) {
      case "phone":
        errorMessage = errors?.phone;
        break;
      case "payrate":
        errorMessage = errors?.payrate;
        break;
      case "workAuthorization":
        errorMessage = errors?.workAuthorization;
        break;
      case "skills":
        errorMessage = errors?.skills;
        break;
    }
  
    return (
      <div
        className="error-msg-for-comment"
        style={{
          color: "red",
          width: "min-content",
          minWidth: "100%"
        }}
      >
        {errorMessage}
      </div>
    );
  };

  return (
    <>
      <AppHelmet
        title={metaData.title}
        metaDescription={metaData.description}
      ></AppHelmet>
      <div style={{ display: scoreResume ? "none" : "block",maxWidth:1078, margin:"-8px 0 0 -8px" }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Item>
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={determineBreadCrumbItems(fromPage) }
              ></AppBreadCrumb>            
                <Typography
                  variant="h6"
                  component="h1"
                  display="inline"
                  className="roboto-font letter-spacing-normal primary-color-text"
                  sx={{
                    paddingTop: "0px",
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                    textDecorationColor: "#4540db",
                  }}
                >
                  You are submitting candidate details for{" "}
                  <JobTitle
                    title={jobDetails?.jobTitle}
                    isFeatured={jobDetails?.isFeatured}
                    jobId={jobDetails?.id}
                  />
                </Typography>
            </Item>
            {error?.hasError && 
              <Item>
                <Collapse in={error?.hasError}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setError({ hasError: false, errorMsg: "" });
                    }}
                  >
                    {error.errorMsg}
                  </Alert>
                </Collapse>
              </Item>
            }
            <Item>
              <Formik
                initialValues={initialState}
                validationSchema={SubmitResumeValidationSchema}
                validate={validateFormik}
                onSubmit={(values) => {
                  handleOnSubmit(values);
                }}
                innerRef={formRef}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  setTouched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  submitForm,
                  setFieldError,
                  setFieldTouched,
                  validateForm,
                  isValid,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Container
                      disableGutters
                      className="submit-resume-form css-1ekb41w"
                    >
                      <Grid container sx={{ justifyContent: "left" }}>                        
                        <Grid
                          container
                          xs={12}
                          className={ternary(editMode, "Update-ele", "")}
                        >
                          {/* if edit or update mode hide the upload resume section */}

                          <Grid item xs={12} md={10}>
                            <Item elevation={0}>
                              <Box mt={{ xs: 1, sm: 2, md: 0 }}>
                                <Box className="form-upload-resume" mt={0} mb={4}>
                                  
                                  <FormControl
                                    variant="standard"
                                    required
                                    error={
                                      isUploadTouch && Boolean(errors.resume)
                                    }
                                  >
                                    <InputLabel htmlFor="city-input">
                                      Resume
                                    </InputLabel>
                                    <Input
                                      name="city-input"
                                      type="text"
                                      readOnly
                                      value={values.resume}
                                    />
                                    { isUploadTouch && Boolean(errors?.resume) && (
                                      <div style={{ color: "#f44336" }}>
                                        {errors.resume}
                                      </div>
                                    )}
                                  </FormControl>

                                  {displayExistingResumeFile()
                                  &&
                                  <>
                                    <VisibilityOutlinedIcon
                                      onClick={handlePreviewResume}
                                      color="primary"
                                      className="pointer"
                                      sx={{pl: 1, verticalAlign: "bottom"}}
                                      data-testid="preview-resume"
                                    />
                                    <SaveAltOutlinedIcon
                                      onClick={handleDownloadResume}
                                      color="primary"
                                      className="pointer"
                                      sx={{pl: 1, verticalAlign: "bottom",marginBottom:"3px"}}
                                      data-testid="download-resume"
                                    />
                                  </>
                                  }
                                  <Button
                                    variant="contained"
                                    component="label"
                                    className="roboto-font upload-btn"
                                    style={{
                                      fontSize:"12px",
                                      marginTop:"13px",
                                      marginLeft:"13px",
                                      paddingLeft:"7px",
                                      paddingRight:"15px"
                                    }}
                                  >
                                    <FileUploadOutlinedIcon /> Attach
                                    Resume
                                    <input
                                      type="file"
                                      hidden
                                      accept={".txt,.rtf,.doc,.docx,.pdf"}
                                      onChange={onResumeFileChange}
                                      onClick={(e) => {
                                        e.currentTarget.value = "";
                                      }}
                                      data-testid="resume-input"
                                    />
                                  </Button>
                                  
                                  {(resumeFile?.file  && isScorecardFeatureActive) && (
                                    <Tooltip
                                    title={ternary(
                                      glasssquidScoreStatus?.statusCode!==200,
                                      "We apologize for the inconvenience. The score card generation service is currently unavailable, but rest assured that your score card will be generated once the system is back online. You will receive an email notification when it is ready for download. Thank you for your understanding.",
                                      "Score card"
                                    )}
                                  >
                                    <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="roboto-font"
                                    style={{ display: "inline-flex", alignItems: "flex-end" }}
                                    >
                                    <div
                                      className={ternary(scoreCardPdf.isGenerating,"disabled-div","")}
                                      style={{ paddingLeft:"20px", position:"relative", top:"12px" }}
                                    >
                                      <span style={{paddingRight:5}}>Score-card.pdf</span>
                                      <VisibilityOutlinedIcon
                                        onClick={() => {
                                          scoreCardPreview();
                                        }}
                                        color="primary"
                                        className="pointer"
                                        sx={{verticalAlign: "bottom"}}
                                        data-testid="view-scorecard"
                                      />
                                      <SaveAltOutlinedIcon
                                        onClick={downloadScoreCard}
                                        color="primary"
                                        className="pointer"
                                        sx={{verticalAlign: "bottom", pr: "5px"}}
                                        data-testid="download-scorecard-icon"
                                      />
                                      {
                                        scoreCardPdf.isGenerating && !(glasssquidScoreStatus?.statusCode) &&
                                        <CircularProgress  size={"16px"} color={"inherit"}/>
                                        }
                                        {renderWarningIcon()}
                                      </div>
                                    </Typography>
                                    </Tooltip>
                                    )
                                  }
                                </Box>
                                <Box
                                  className="form-three-child"
                                  sx={{ position: "relative", top: "-25px" }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="fileUploadHelper-TextLabel"
                                  >
                                    (Max file size: 2MB as
                                    <span>
                                      {" "}
                                      .txt, .rtf, .doc, .docx, .pdf
                                    </span>
                                    )
                                  </Typography>
                                </Box>
                              </Box>
                            </Item>
                          </Grid>
      
                        </Grid>
                          <Grid className="submitresume-margintop" item xs={12} md={12} >
                            <Item elevation={0}>
                              <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                sx={{
                                  flexDirection: "row",
                                  "& .MuiFormControl-root": {
                                    marginTop: "15px",
                                    marginBottom: "3px",
                                  },
                                }}
                              >
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={1.8}>
                                  <FormControl className="custom-pading-right"
                                    variant="standard"
                                    required
                                    error={
                                      touched.firstName &&
                                      Boolean(errors.firstName)
                                    }
                                    fullWidth
                                  >
                                    <InputLabel htmlFor="first-name-input">
                                      First Name
                                    </InputLabel>
                                    <Input
                                      name="firstName"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        GetCandidateDataIfExist(
                                          { ...values },
                                          setFieldValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={values.firstName}
                                      inputProps={{
                                        "data-testid":"firstName-input"
                                      }}
                                      
                                    />
                                    <ErrorMessage
                                      errorText={
                                        touched.firstName && errors.firstName
                                      }
                                    />
                                  </FormControl>
                              </Grid>
                                  
                              <Grid item xs={12} md={1.8}>
                                  <FormControl  className="custom-pading-right" variant="standard" fullWidth>
                                    <InputLabel htmlFor="middle-initial-input">
                                      Middle initial
                                    </InputLabel>
                                    <Input
                                      name="middleInitial"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.middleInitial}
                                    />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={12} md={1.8}>
                                  <FormControl className="custom-pading-right"
                                    variant="standard"
                                    required
                                    error={
                                      touched.lastName && Boolean(errors.lastName)
                                    }
                                    fullWidth
                                  >
                                    <InputLabel htmlFor="last-name-input">
                                      Last Name
                                    </InputLabel>
                                    <Input
                                      name="lastName"
                                      type="text"
                                      onChange={(e) => {
                                        handleChange(e);
                                        GetCandidateDataIfExist(
                                          { ...values },
                                          setFieldValue
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={values.lastName}
                                      inputProps={{
                                        "data-testid":"lastName-input"
                                      }}
                                    />
                                    <ErrorMessage
                                      errorText={
                                        touched.lastName && errors.lastName
                                      }
                                    />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={12} md={5.6}>
                                  <FormControl className="custom-pading-right"
                                  variant="standard"
                                  fullWidth
                                  required
                                  error={touched.email && Boolean(errors.email)}
                                >
                                  <InputLabel htmlFor="email-input">
                                    Candidate Email
                                  </InputLabel>
                                  <Input
                                    name="email"
                                    autoComplete="off"
                                    type="text"
                                    disabled={Boolean(editMode)}
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      GetCandidateDataIfExist(
                                        { ...values },
                                        setFieldValue
                                      );
                                    }}
                                    value={values.email}
                                    inputProps={{
                                      "data-testid":"useremail-input"
                                    }}
                                  />
                                  <ErrorMessage
                                    errorText={touched.email && errors.email}
                                  />
                                </FormControl>
                              </Grid>
                                                            
                           <Grid item xs={12} md={12}>
                             <Box className="oneline">
                               <Grid item md={5.5}>
                                  <PlacesAutocomplete  className="oneline1"
                                  searchOptions={searchOptions}
                                  value={values.address}
                                  onChange={(ev: any) => {
                                    setFieldValue("address", ev);
                                  }}
                                  onSelect={handleSelect}
                                >
                                  {renderFunc}
                                  </PlacesAutocomplete>
                                </Grid>

                                <Box className="form-four-child oneline1">
                                <Grid item xs={12} md={2} className="custom-grid-set">
                                  <FormControl className="custom-form-set"
                                    variant="standard"
                                    required
                                    error={touched.city && Boolean(errors.city)}
                                  >
                                    <InputLabel htmlFor="city">City</InputLabel>
                                    <Input
                                      name="city"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.city}
                                    />
                                    <ErrorMessage
                                      errorText={touched.city && errors.city}
                                    />
                                  </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={2} className="custom-grid-set">
                                  <FormControl className="custom-form-set"
                                    variant="standard"
                                    required
                                    error={touched.state && Boolean(errors.state)}
                                  >
                                    <InputLabel htmlFor="state-input">
                                      State
                                    </InputLabel>
                                    <Input
                                      name="state"
                                      type="text"
                                      onChange={(val) => {
                                        setFieldValue("address","");
                                        setFieldValue("state",val.target.value)}}
                                      onBlur={handleBlur}
                                      value={values.state}
                                    />
                                    <ErrorMessage
                                      errorText={touched.state && errors.state}
                                    />
                                  </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={2} className="custom-grid-set">
                                  <FormControl className="custom-form-set"
                                    variant="standard"
                                    required
                                    error={
                                      touched.country && Boolean(errors.country)
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    <InputLabel htmlFor="country">
                                      Country
                                    </InputLabel>
                                    <Input
                                      name="country"
                                      type="text"
                                      onChange={(val) => {
                                        setFieldValue("address","");
                                        setFieldValue("country",val.target.value)}}
                                      onBlur={handleBlur}
                                      value={values.country}
                                    />
                                    <ErrorMessage
                                      errorText={
                                        touched.country && errors.country
                                      }
                                    />
                                  </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={2} className="custom-grid-set">
                                  <FormControl className="custom-form-set"
                                    variant="standard"
                                    required
                                    error={touched.zip && Boolean(errors.zip)}
                                  >
                                    <InputLabel htmlFor="zip">Zip</InputLabel>
                                    <Input
                                      name="zip"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.zip}
                                    />
                                    <ErrorMessage
                                      errorText={touched.zip && errors.zip}
                                    />
                                  </FormControl>
                                  </Grid>
                                  
                                </Box>
                                </Box>
                                </Grid>
                              
                                  <Grid item xs={12} md={3} className="custom-total-exp">
                                  <FormControl
                                    variant="standard"
                                    required
                                    error={touched.phone && Boolean(errors.phone)}
                                  >
                                    <span className="labelTransform">
                                      Phone <Asterisk />
                                    </span>
                                    <PhoneInput

                                      containerClass="phoneCountryCustom"
                                      placeholder="Phone"
                                      country={countryShort}
                                      onChange={(ev: any) => {
                                        setFieldValue("phone", ev);
                                      }}
                                      onlyCountries={rawCountries.map(x=>x[2].toString())}

                                      value={values.phone}
                                      preferredCountries={preferredCountries}
                                      preserveOrder= {["preferredCountries"]}
                                    />{" "}                                  
                                    {  checkFieldValidity(touched,errors,'phone')  
                                      ? renderErrorMessage(errors,"phone") 
                                      : null
                                    }                                  
                                  </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={3} className="custom-total-exp">
                                  <FormControl
                                    variant="standard"
                                    style={{ marginTop: 24 }}
                                    required
                                    error={
                                      touched.experience &&
                                      Boolean(errors.experience)
                                    }
                                  >
                                    <InputLabel htmlFor="experience" shrink>
                                      Total Experience
                                    </InputLabel>
                                    <Input
                                      name="experience"
                                      endAdornment={
                                        <InputAdornment className="roboto-font" position="start">
                                          Years
                                        </InputAdornment>
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.experience}
                                      style={{
                                        width: dynamicStyles.width,
                                        marginTop: 25,
                                      }}
                                    />
                                    <ErrorMessage
                                      errorText={
                                        touched.experience && errors.experience
                                      }
                                    />
                                  </FormControl>
                                  </Grid>
                                </Grid>
                              <Grid item xs={12} md={12}>
                                <Item elevation={0} className="can-emp-pading">
                                  <Box
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                    sx={{
                                      "& .MuiFormControl-root": {
                                        marginTop: "15px",
                                        marginBottom: "3px",
                                      },
                                    }}
                                    className="candidateemp-row"
                                  >
                                    <Grid item xs={12} md={4}>
                                      <CandidateEmployment
                                        touched={touched}
                                        errors={errors}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        jobDetails={jobDetails}
                                      />
                                    </Grid>
                                    <Grid
                                      className="oneline2"
                                      item
                                      xs={12}
                                      md={7}
                                    >
                                      <RadioGroup name="payrateType">
                                        <Box
                                          className="form-three-child"
                                          mt={{ xs: 1, sm: 2, md: 3 }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            component="div"
                                            className="roboto-font"
                                          >
                                            Pay Rate Expected (Maximum Pay Rate
                                            for this position:{" "}
                                            {formattedPayRate.packageValueAndCurrency +
                                              " " +
                                              formattedPayRate.packageType}
                                            ){" "}
                                            <span className="MuiFormLabel-asterisk">
                                              *
                                            </span>
                                            {  checkFieldValidity(touched,errors,'payrate')  
                                              ? renderErrorMessage(errors,"payrate") 
                                              : null
                                            }                                       
                                          </Typography>
                                          <FormControl
                                            variant="standard"
                                            className="payrate-input"
                                            required
                                            error={
                                              touched.payrate &&
                                              Boolean(errors.payrate)
                                            }
                                          >
                                            <Input
                                              name="payrate"
                                              placeholder={`$`}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "payrate",
                                                  e.currentTarget.value
                                                );                                              
                                              }}
                                              onBlur={handleBlur}
                                              value={Math.floor(
                                                Number(values?.payrate)
                                              )}
                                              inputProps={{ min: 0, max: 10 }}
                                            />
                                          </FormControl>
                                          <FormControlLabel
                                            className="roboto-font"
                                            control={
                                              <Radio
                                                checked={
                                                  values.payrateType?.toLowerCase() === "hourly"
                                                }
                                                disabled
                                                name="payrateType"
                                                onChange={handleChange}
                                                value={"hourly"}
                                                size="small"
                                                className="roboto-font radio-sml"
                                                style={{ paddingTop: 15 }}
                                              />
                                            }
                                            label="Hourly"
                                          />
                                          <FormControlLabel
                                          className="roboto-font"
                                            control={
                                              <Radio
                                                checked={
                                                  values.payrateType?.toLowerCase() === "daily"
                                                }
                                                disabled
                                                name="payrateType"
                                                onChange={handleChange}
                                                value={"daily"}
                                                size="small"
                                                className="roboto-font radio-sml"
                                                style={{ paddingTop: 15 }}
                                              />
                                            }
                                            label="Daily"
                                          />
                                          <FormControlLabel
                                            className="roboto-font"
                                            control={
                                              <Radio
                                                checked={
                                                  values.payrateType?.toLowerCase() === "monthly"
                                                }
                                                disabled
                                                name="payrateType"
                                                onChange={handleChange}
                                                value={"monthly"}
                                                size="small"
                                                className="roboto-font radio-sml"
                                                style={{ paddingTop: 15 }}
                                              />
                                            }
                                            label="Monthly"
                                          />
                                          <FormControlLabel
                                            className="roboto-font"
                                            control={
                                              <Radio
                                                checked={
                                                  values.payrateType?.toLowerCase() ===
                                                  "annually"
                                                }
                                                disabled
                                                name="payrateType"
                                                onChange={handleChange}
                                                value={"annually"}
                                                size="small"
                                                className="roboto-font radio-sml"
                                                style={{ paddingTop: 15 }}
                                              />
                                            }
                                            label="Annually"
                                          />
                                        </Box>
                                        {/* In case Bill rate is 0 for any job,, make Compensation calculation link hide from Submit Resume screen */}
                                        <ModuleComponent moduleId={PermissionsEnums.Compensation_Calculator}>
                                        {jobDetails?.billRatePckg?.netBillRate !==
                                          0 &&
                                          jobDetails?.jobType !== "Full Time" &&
                                          values.candidateEmployment && (
                                            <Box>
                                              <Typography
                                                data-testid="pointer"
                                                className="roboto-font"
                                                color="primary"
                                                style={{
                                                  cursor: "pointer",
                                                  textDecoration: "underline",
                                                }}
                                                onClick={() => {
                                                  setCompensateCal(true);
                                                }}
                                              >
                                                How much will I be Compensated?
                                              </Typography>
                                            </Box>
                                          )}
                                          </ModuleComponent>
                                      </RadioGroup>
                                    </Grid>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid container xs={12} md={12} className="pt-0">
                                {compensateCal ? (
                                  <CompensationCalculatorBox
                                    jobDetails={jobDetails}
                                    changeHandler={handlePayRateChange}
                                    rateEntered={values.payrate}
                                    onClose={() => setCompensateCal(false)}
                                    selectedCandidateEmployment={
                                      values.candidateEmployment
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </Grid>
                              <Box className="form-three-child Work-Auth-mt" mt={3}>
                                <Typography
                                  variant="subtitle1"
                                  component="div"
                                  className="roboto-font"
                                >
                                  Work Authorization Status{" "}
                                  <span className="MuiFormLabel-asterisk">*</span>
                                  {  checkFieldValidity(touched,errors,'workAuthorization')  
                                    ? renderErrorMessage(errors,"workAuthorization") 
                                    : null
                                  }                                
                                </Typography>
                                {jobDetails?.jobWorkAuthorization.map(
                                  (item: any, i: any) => (
                                    <>
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checked={
                                              values.workAuthorization ===
                                              item.trim()
                                            }
                                            name="workAuthorization"
                                            onChange={(e) => {
                                              setFieldValue(
                                                "workAuthorization",
                                                item.trim()
                                              );
                                            }}
                                            value={item}
                                            size="small"
                                            className="roboto-font radio-sml"
                                            style={{ marginTop: "6px" }}
                                          />
                                        }
                                        label={item}
                                        className="roboto-font radio-sml"
                                      />
                                    </>
                                  )
                                )}
                                {/* cwx1597: if Remote Job option is yes, Remote (Global) option should appear with work authorization section as a radio button. */}
                                {isRemoteJob && (
                                  <>
                                    {" "}
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          checked={
                                            values.workAuthorization ===
                                            "Remote (Global)".trim()
                                          }
                                          name="workAuthorization"
                                          onChange={(e) => {
                                            setFieldValue(
                                              "workAuthorization",
                                              "Remote (Global)".trim()
                                            );
                                            setFieldValue("isRemoteUSA", false);
                                            setFieldTouched(
                                              "employerDetails.name",
                                              false
                                            );
                                            setFieldTouched("isRemoteUSA", false);
                                          }}
                                          value={"Remote (Global)"}
                                          size="small"
                                          className="roboto-font radio-sml"
                                          style={{ marginTop: "6px" }}
                                        />
                                      }
                                      label={"Remote (Global)"}
                                      className="roboto-font radio-sml"
                                    />
                                    <br />
                                    {/* In case user selects any of the work authorization statuses except remote (Global), we need to enable Remote (USA) option as a check box (mandate) below work authorization section. */}
                                    <FormControlLabel
                                      key={"7u"}
                                      control={
                                        <Checkbox
                                          disabled={
                                            values.workAuthorization == "" ||
                                            values.workAuthorization ==
                                              "Remote (Global)"
                                          }
                                          name={"isRemoteUSA"}
                                          checked={values.isRemoteUSA}
                                          value={values.isRemoteUSA}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      }
                                      label={"Remote (USA)"}
                                      className="roboto-font checkbox-sml"
                                    />
                                    <ErrorMessage
                                      errorText={
                                        touched.isRemoteUSA && errors.isRemoteUSA
                                      }
                                    />
                                  </>
                                )}
                              </Box>
                              
                              <Grid item xs={12} md={4}>
                                <FormControl
                                  variant="standard"
                                  fullWidth
                                  error={
                                    touched.employerDetails?.name &&
                                    Boolean(errors.employerDetails?.name)
                                  }
                                >
                                  <InputLabel htmlFor="employerDetails.name" className="empName">
                                    Employer Name (if H1B, TN Visa and E3 Visa)
                                    <span
                                      style={{
                                        fontSize: dynamicStylesForEmName.fontSize,
                                      }}
                                    >
                                      {isEmployerNameRequired(
                                        values.workAuthorization,
                                        values.candidateEmployment
                                      ) && <Asterisk />}
                                    </span>
                                  </InputLabel>
                                  <Input
                                    name="employerDetails.name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.employerDetails?.name}
                                  />
                                  <ErrorMessage
                                    errorText={
                                      touched.employerDetails?.name &&
                                      errors.employerDetails?.name
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Box className="oneline">
                                  <Grid item md={4}>
                                    <PlacesAutocomplete
                                      className="oneline1"
                                      searchOptions={searchOptions}
                                      value={
                                        values.employerDetails?.address
                                          ?.streetAddress
                                      }
                                      onChange={(ev: any) => {
                                        setFieldValue(
                                          "employerDetails.address.streetAddress",
                                          ev
                                        );
                                      }}
                                      onSelect={handleSelect1}
                                    >
                                      {employerrenderFunc}
                                    </PlacesAutocomplete>
                                  </Grid>
                                  <Box className="form-four-child oneline1">
                                    <FormControl
                                      variant="standard"
                                      error={
                                        touched.employerDetails?.address?.city &&
                                        Boolean(
                                          errors.employerDetails?.address?.city
                                        )
                                      }
                                    >
                                      <InputLabel htmlFor="employerDetails.address.city">
                                        {" "}
                                        City
                                        <span
                                          style={{
                                            fontSize:
                                              dynamicStylesForEmName.fontSize,
                                          }}
                                        >
                                          {isEmployerNameRequired(
                                            values.workAuthorization,
                                            values.candidateEmployment
                                          ) && <Asterisk />}
                                        </span>
                                      </InputLabel>
                                      <Input
                                        name="employerDetails.address.city"
                                        type="text"
                                        onChange={(ev: any) => {
                                          setFieldValue(
                                            "employerDetails.address.city",
                                            ev.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={
                                          values.employerDetails?.address?.city
                                        }
                                      />
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.address
                                            ?.city &&
                                          errors.employerDetails?.address?.city
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      variant="standard"
                                      fullWidth
                                      error={
                                        touched.employerDetails?.address?.state &&
                                        Boolean(
                                          errors.employerDetails?.address?.state
                                        )
                                      }
                                    >
                                      <InputLabel htmlFor="employerDetails.address.state">
                                        {" "}
                                        State
                                        <span
                                          style={{
                                            fontSize:
                                              dynamicStylesForEmName.fontSize,
                                          }}
                                        >
                                          {isEmployerNameRequired(
                                            values.workAuthorization,
                                            values.candidateEmployment
                                          ) && <Asterisk />}
                                        </span>
                                      </InputLabel>
                                      <Input
                                        name="employerDetails.address.state"
                                        type="text"
                                        onChange={(ev: any) => {
                                          setFieldValue(
                                            "employerDetails.address.state",
                                            ev.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={
                                          values.employerDetails?.address?.state
                                        }
                                      />
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.address
                                            ?.state &&
                                          errors.employerDetails?.address?.state
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      variant="standard"
                                      fullWidth
                                      error={
                                        touched.employerDetails?.address
                                          ?.country &&
                                        Boolean(
                                          errors.employerDetails?.address?.country
                                        )
                                      }
                                    >
                                      <InputLabel htmlFor="employerDetails.address.country">
                                        {" "}
                                        Country
                                        <span
                                          style={{
                                            fontSize:
                                              dynamicStylesForEmName.fontSize,
                                          }}
                                        >
                                          {isEmployerNameRequired(
                                            values.workAuthorization,
                                            values.candidateEmployment
                                          ) && <Asterisk />}
                                        </span>
                                      </InputLabel>
                                      <Input
                                        name="employerDetails.address.country"
                                        type="text"
                                        onChange={(ev: any) => {
                                          setFieldValue(
                                            "employerDetails.address.country",
                                            ev.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={
                                          values.employerDetails?.address?.country
                                        }
                                      />
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.address
                                            ?.country &&
                                          errors.employerDetails?.address?.country
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      variant="standard"
                                      fullWidth
                                      error={
                                        touched.employerDetails?.address?.zip &&
                                        Boolean(
                                          errors.employerDetails?.address?.zip
                                        )
                                      }
                                    >
                                      <InputLabel htmlFor="employerDetails.address.zip">
                                        {" "}
                                        Zip
                                        <span
                                          style={{
                                            fontSize:
                                              dynamicStylesForEmName.fontSize,
                                          }}
                                        >
                                          {isEmployerNameRequired(
                                            values.workAuthorization,
                                            values.candidateEmployment
                                          ) && <Asterisk />}
                                        </span>
                                      </InputLabel>
                                      <Input
                                        name="employerDetails.address.zip"
                                        type="text"
                                        onChange={(ev: any) => {
                                          setFieldValue(
                                            "employerDetails.address.zip",
                                            ev.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value={
                                          values.employerDetails?.address?.zip
                                        }
                                      />
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.address?.zip &&
                                          errors.employerDetails?.address?.zip
                                        }
                                      />
                                    </FormControl>
                                  </Box>
                                </Box>
                                <Grid item xs={12} md={12}>
                                  <Box className="form-four-child form-fur-child-sub">
                                    <FormControl
                                      className="emp-contact-email-width"
                                      variant="standard"
                                      fullWidth
                                      error={
                                        touched.employerDetails?.email &&
                                        Boolean(errors.employerDetails?.email)
                                      }
                                    >
                                      <InputLabel htmlFor="employerDetails.reference">
                                        {" "}
                                        Employer Contact Person
                                        <span
                                          style={{
                                            fontSize:
                                              dynamicStylesForEmName.fontSize,
                                          }}
                                        >
                                          {isEmployerNameRequired(
                                            values.workAuthorization,
                                            values.candidateEmployment
                                          ) && <Asterisk />}
                                        </span>
                                      </InputLabel>
                                      <Input
                                        name="employerDetails.reference"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.employerDetails?.reference}
                                      />
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.reference &&
                                          errors.employerDetails?.reference
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      className="emp-contact-email"
                                      variant="standard"
                                      fullWidth
                                      error={
                                        touched.employerDetails?.email &&
                                        Boolean(errors.employerDetails?.email)
                                      }
                                    >
                                      <InputLabel htmlFor="employerDetails.email">
                                        {" "}
                                        Employer Email
                                        <span
                                          style={{
                                            fontSize:
                                              dynamicStylesForEmName.fontSize,
                                          }}
                                        >
                                          {isEmployerNameRequired(
                                            values.workAuthorization,
                                            values.candidateEmployment
                                          ) && <Asterisk />}
                                        </span>
                                      </InputLabel>
                                      <Input
                                        name="employerDetails.email"
                                        type="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.employerDetails?.email}
                                      />
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.email &&
                                          errors.employerDetails?.email
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      className="emp-details-phone"
                                      variant="standard"
                                      required
                                      error={
                                        touched.employerDetails?.phoneNumber &&
                                        Boolean(
                                          errors.employerDetails?.phoneNumber
                                        )
                                      }
                                    >
                                      <span style={{ marginTop: "-5px" }}>
                                        Employer Phone
                                        {isEmployerNameRequired(
                                          values.workAuthorization,
                                          values.candidateEmployment
                                        ) && <Asterisk />}
                                      </span>
                                      <PhoneInput
                                        containerClass="phoneCountryCustom emp-phn-margn"
                                        placeholder="Phone"
                                        country={emp_countryShort}
                                        onChange={(ev: any) => {
                                          setFieldValue(
                                            "employerDetails.phoneNumber",
                                            ev
                                          );
                                        }}
                                        onlyCountries={rawCountries.map((x) =>
                                          x[2].toString()
                                        )}
                                        value={values.employerDetails.phoneNumber}
                                        preferredCountries={preferredCountries}
                                        preserveOrder={["preferredCountries"]}
                                      />{" "}
                                      <ErrorMessage
                                        errorText={
                                          touched.employerDetails?.phoneNumber &&
                                          errors.employerDetails?.phoneNumber
                                        }
                                      />
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </Grid>

                            
                            <>
                              <Grid item xs={12} md={6}>
                                <Box className="form-three-child" mt={3}>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    className="roboto-font"
                                  >
                                    Candidate Current work location
                                  </Typography>
                                </Box>
                                <Box className="form-three-child">
                                  
                                  <PlacesAutocomplete
                                    searchOptions={ternary<any>(
                                      jobDetails?.remoteJob === "n",
                                      {
                                        ...searchCities,
                                        componentRestrictions: {
                                          country: [jobDetails?.countryCode],
                                        },
                                      },
                                      searchCities
                                    )}
                                    value={values.currentWorkLocation?.city}
                                    onChange={(ev: any) => {
                                      setFieldValue(
                                        "currentWorkLocation.city",
                                        ev
                                      );
                                    }}
                                    onSelect={autoCompleteGetWorkLocation}
                                  >
                                    {renderFuncCurrentWorkLocation}
                                  </PlacesAutocomplete>
                                  <FormControl
                                    variant="standard"
                                    required
                                    error={
                                      touched.currentWorkLocation?.state &&
                                      Boolean(errors.currentWorkLocation?.state)
                                    }
                                  >
                                    <InputLabel htmlFor="currentWorkLocation.state">
                                      State
                                    </InputLabel>
                                    <Input
                                      name="currentWorkLocation.state"
                                      type="text"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.currentWorkLocation.state}
                                    />
                                    <ErrorMessage
                                      errorText={
                                        touched.currentWorkLocation?.state &&
                                        errors.currentWorkLocation?.state
                                      }
                                    />
                                  </FormControl>
                                  <FormControl
                                    variant="standard"
                                    style={{
                                      display: "inline-block",
                                      paddingTop: 15,
                                    }}
                                  >
                                    <InputLabel htmlFor="" shrink={true}>
                                      Willing to relocate
                                    </InputLabel>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          checked={values.canRelocate === true}
                                          name="canRelocate"
                                          onChange={(e) => {
                                            setFieldValue("canRelocate", true);
                                          }}
                                          value={true}
                                          size="small"
                                          className="roboto-font radio-sml"
                                          style={{ paddingTop: 16 }}
                                        />
                                      }
                                      label={"Yes"}
                                      className="roboto-font radio-sml"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          checked={values.canRelocate === false}
                                          name="canRelocate"
                                          onChange={(e) => {
                                            setFieldValue("canRelocate", false);
                                          }}
                                          value={false}
                                          size="small"
                                          className="roboto-font radio-sml"
                                          style={{ paddingTop: 16 }}
                                        />
                                      }
                                      label={"No"}
                                      className="roboto-font radio-sml"
                                    />
                                  </FormControl>
                                </Box>
                              </Grid>
                            </>
                            <Box
                              className="form-three-child"
                              mt={{ xs: 1, sm: 2, md: 3 }}
                            >
                              <Typography
                                variant="subtitle1"
                                component="div"
                                className="roboto-font"
                              >
                                Skills (select all that apply){" "}
                                {ternary(
                                  jobDetails?.manualData?.skills?.length > 0,
                                  <span className="MuiFormLabel-asterisk">
                                    *
                                  </span>,
                                  <>{""}</>
                                )}
                                {  checkFieldValidity(touched,errors,'skills')  
                                    ? renderErrorMessage(errors,"skills") 
                                    : null
                                  }   
                              </Typography>
                              {jobDetails?.manualData?.skills?.length > 0 ? (
                                jobDetails?.manualData?.skills.map(
                                  (item: any, i: any) => (
                                    <>
                                      <FormControlLabel
                                        key={ item?.jobId}
                                        control={
                                          <Checkbox
                                            name={"skills"}
                                            checked={values?.skills?.some(
                                              (x) => x === item.skill
                                            )}
                                            value={item.skill}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        }
                                        label={item.skill}
                                        className="roboto-font checkbox-sml"
                                      />
                                    </>
                                  )
                                )
                              ) : (
                                <>N/A</>
                              )}
                            </Box>
                          </Box>
                        </Item>
                      </Grid>
                      <Grid container xs={12} md={12} className="pt-0">
                        
                        { candidateDefaultData.comments?.length > 0 &&

                        <Grid item xs={12} md={12}>
                            <Item>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                className="roboto-font"
                              >
                                Feedback/Comments <Asterisk/>
                              </Typography>
                              {candidateDefaultData?.comments?.map((item : any, index:number) => {
                                return (
                              <Typography key={ item?.id ?? index} className="roboto-font" mt={1} style={{ color: "black", fontSize: '14px' }}>
                                <Typography className="roboto-font" style={{ color: "black" }}>
                                {ternary(
                                  (item?.addedBy === getUserDetails()?.id),
                                  "Your comment",
                                  item?.commentHeading
                                )}
                                <span className="rec-comment-date"> Posted on (
                                {
                                  moment(item?.createdDate)?.format(" MMMM DD, YYYY, h:mm A")?.trim()
                                })</span>
                              </Typography>
                              <Typography className="roboto-font  letter-spacing-normal primary-color-text">
                                                          
                              </Typography>
                                {item?.comment}
                              </Typography>);
                              })}

                            </Item>
                          </Grid>
                          }
                          <Item elevation={0}>
                            <UploadAttachments
                              classes="roboto-font"
                              wrapperclass="submitresume-paddingleft"
                              onChange={handleUploadAttachmentsOnChange}
                              setFilePreview={setFilePreview}
                              documents={otherDocuments}
                              handleLoading={(val: any) => {
                                setIsLoading(val);
                              }}
                              acceptedFormats={".txt, .rtf, .doc, .docx, .pdf, .zip, .png, .jpg, .jpeg, .pptx"}
                              />
                            </Item>
                        <Grid item xs={12} md={9}>
                          <Item elevation={0}>
                            <Box mt={{ xs: 1, sm: 1, md: 1 }}>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                className="roboto-font flex-div"
                              >
                                <div>
                                  {ternary(
                                    (UserAuth?.Admin === userRole || UserAuth?.SuperAdmin === userRole),
                                    "Admin Comments ",
                                    "Recruiter Comments "
                                  )}
                                  <span className="MuiFormLabel-asterisk">*</span>
                                </div>
                                <span className="roboto-font max-limit">Limit 1000 Characters</span>
                                
                              </Typography>
                              <TextField
                                error={
                                  touched.recruiterComments &&
                                  Boolean(errors.recruiterComments)
                                }
                                placeholder=""
                                multiline
                                rows={4}
                                maxRows={4}
                                fullWidth
                                name="recruiterComments"
                                className="text-area2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.recruiterComments}
                                inputProps={{
                                  'data-testid':"comment-box"
                                }}
                              />
                            </Box>{" "}
                            {errors.recruiterComments &&
                            touched.recruiterComments ? (
                              <div>
                                <p
                                  className="error"
                                  style={{ fontSize:"12px", color:"#ff0000" }}
                                >
                                  Please enter comments, it must be less than 1000 characters
                                </p>
                              </div>
                            ) : null}
                          </Item>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Item elevation={0}>
                            <Box mt={0}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.iConfirm}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "iConfirm",
                                        e.target.checked
                                      );
                                    }}
                                    style={{ paddingTop: "0px" }}
                                    inputProps={{
                                      'data-testid':"acknowledge-checkbox"
                                    } as any}
                                  />
                                }
                                style={{ color: "black" }}
                                label={
                                  <div className="roboto-font" style={{ paddingTop: "14px" }}>
                                    <Asterisk /> I acknowledge that I have
                                    verified availability with the candidate, I
                                    also confirm that this candidate has not
                                    been submitted to the same job in the last
                                    30 days by anyone, and understand that this
                                    submission will be accepted only if the
                                    candidate confirms for this submission via
                                    the RTR (Right-to-represent) email sent by
                                    JobRialto.
                                  </div>
                                }
                                sx={{ alignSelf: "baseline" }}
                              />

                                {errors.iConfirm && touched.iConfirm ? (
                                  <div
                                    style={{
                                      color: "#ff0000",
                                      fontSize:"12px"
                                    }}
                                    className={"roboto-font error-msg-for-comments"}
                                  >
                                    Please acknowledge if you agree to the terms
                                    before submission.
                                  </div>
                                ) : null}
                                <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.informedCandidate}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "informedCandidate",
                                        e.target.checked
                                      );
                                    }}
                                    style={{ paddingTop: "0px" }}
                                  />
                                }
                                style={{ color: "black" }}
                                label={
                                  <div className="roboto-font" style={{ paddingTop: "14px" }}>
                                    <Asterisk /> I have communicated to the candidate that representatives from Job Partner will reach out to them for further details, documentation and updates.
                                  </div>
                                }
                                sx={{ alignSelf: "baseline" }}
                              />
                                {errors.informedCandidate && touched.informedCandidate ? (
                                  <div
                                    style={{
                                      color: "#ff0000",
                                      fontSize:"12px"
                                    }}
                                    className={"roboto-font error-msg-for-comments"}
                                  >
                                    Please confirm that you have informed the candidate about the next steps.
                                  </div>
                                ) : null}
                                <Grid container xs={12} md={12}>
                                  <Grid item xs={12} md={5.5}>
                                    <Item elevation={0}>
                                      <Box pt={4}>
                                        <Typography
                                          variant="caption"
                                          component="div"
                                          className="roboto-font"
                                        >
                                          <sup
                                            className="number-textFontSize"
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            1.{" "}
                                          </sup>{" "}
                                          W2 Employment will be available through
                                          a JobRialto designated partner and will
                                          be subject to the candidate being
                                          authorized to work in the united states
                                          as per USCIS policies.
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          component="div"
                                          className="roboto-font"
                                        >
                                          <sup
                                            className="number-textFontSize"
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            2.{" "}
                                          </sup>{" "}
                                          Will need additional paperwork for
                                          payment information and terms of
                                          engagement.
                                        </Typography>
                                      </Box>
                                    </Item>
                                  </Grid>
                                  <Grid item xs={12} md={6.5} sx={{ px: 0 }}>
                                    <Item className="btn-right-update" elevation={0} sx={{ px: 0 }}>
                                      {editMode ? (
                                        <>
                                          <Button
                                            type="submit"
                                            size="medium"
                                            style={{
                                              fontSize: "0.875rem",
                                              
                                            }}
                                            variant="contained"
                                            className="roboto-font button-large"
                                            onClick={(e) => {
                                              setFieldTouched("experience",true)
                                              setIsUploadTouch(true);
                                            }}
                                            disabled={
                                              isFormSubmitting.submit || isLoading
                                            }
                                            endIcon={ternary(
                                              isFormSubmitting.submit,
                                              <CircularProgress
                                                size={"16px"}
                                                color={"inherit"}
                                              />,
                                              <>{""}</>
                                            )}
                                            data-testid="update-candidate-btn"
                                          >
                                            Update
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            type="submit"
                                            size="medium"
                                            style={{
                                              fontSize: "0.875rem",
                                              marginRight: "10px",
                                            }}
                                            variant="contained"
                                            className="roboto-font button-large"
                                            onClick={(e) => {
                                              setFieldTouched("experience",true)
                                              setIsUploadTouch(true);
                                            }}
                                            disabled={
                                              isFormSubmitting.submit || isLoading
                                            }
                                            endIcon={ternary(
                                              isFormSubmitting.submit,
                                              <CircularProgress
                                                size={"16px"}
                                                color={"inherit"}
                                              />,
                                              <>{""}</>
                                            )}
                                            data-testid="submit-candidate-btn"
                                          >
                                            Submit Candidate
                                          </Button>
                                          <Button
                                            type="button"
                                            size="medium"
                                            style={{ fontSize: "0.875rem" }}
                                            variant="contained"
                                            className="roboto-font button-large"
                                            onClick={(values) => {
                                              handleSaveResume(values);
                                            }}
                                            disabled={
                                              isFormSubmitting.save || isLoading
                                            }
                                            endIcon={ternary(
                                              isFormSubmitting.save,
                                              <CircularProgress
                                                size={"16px"}
                                                color={"inherit"}
                                              />,
                                              <>{""}</>
                                            )}
                                            data-testid="save-candidate-btn"
                                          >
                                            Save & Continue Later
                                          </Button>
                                        </>
                                      )}
                                    </Item>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Item>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Item elevation={0}>
                            <Box
                              component="form"
                              noValidate
                              autoComplete="off"
                              sx={{
                                flexDirection: "row",
                                "& .MuiFormControl-root": {
                                  marginTop: "15px",
                                  marginBottom: "3px",
                                },
                              }}
                            ></Box>
                          </Item>
                        </Grid>
                      </Grid>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Item>
          </Grid>
        </Grid>
      </div>

      {/* Popup When Generate scorecard for candiadate resume */}
      { scoreResume &&
       <ScorecardResume
        resumeFile={resumeFile}
        onResumeFileChange={onResumeFileChange}
        handleOnSubmit={handleOnSubmit}
        breadCrumbOnClick={() => {
          setScoreResume(false);
        }}
        form={formRef.current}
        setFieldValue={formRef.current?.setFieldValue}
        formValues={formRef.current?.values}
        isFormValid={formRef.current?.isValid}
        jobData={jobDetails}
        glasssquidScore={glasssquidScore}
        isLoading={isScoreCardLoading}
        display={scoreResume}
        isAutoPopulate={isAutoPopulatePending}
        setAutopopulate={setIsAutoPopulatePending}
        editMode={editMode}
        showBreadcrumbs={true}
        glasssquidScoreStatus={glasssquidScoreStatus}
      />
      }

      {/* Popup When submitted or updated candidate submission successfully */}
      <CandidateSubmittedSuccessPopup
        open={open}
        handleClose={handleClose}
        editMode={editMode}
      />

      {/* Popup When entered payrate value is grater than job payrate value */}
      <PayrateAlertPopup
        payrateDialog={payrateDialog}
        setPayrateDialog={setPayrateDialog}
        setScoreResume={setScoreResume}
        handleResumeSubmit={handleResumeSubmit}
        formRef={formRef}
      />
  
      {/* Popup When confirm for save and continue later process */}
      <SaveAndContinueConfirmPopup
        saveResumeConfirmDialog={saveResumeConfirmDialog}
        handleSaveConfirmationYes={handleSaveConfirmationYes}
        handleSaveConfirmationNo={handleSaveConfirmationNo}
      />

      {/* Popup When submission already happed with same job and same candidate */}
      <DuplicateCandidateAlertPopup
        duplicateSubmissionDialogOpen={duplicateSubmissionDialogOpen}
        handleDuplicateDialogConfirmationNo={handleDuplicateDialogConfirmationNo}
        error={error}
      />

      {/* Popup When Save and continuesubmission saved successfully */} 
      <SaveAndContinueSuccessPopup
        saveResumeSuccessDialog={saveResumeSuccessDialog}
        handleClose={handleClose}
      />

      {/* Popup When want to go for choose the existing candidate  */} 
      <ExistingCandidateDialog
        isOpen={existingCandidate.confirmDialog}
        onClose={() => {
          setExistingCandidate({ ...existingCandidate, confirmDialog: false });
        }}
        onConfirm={() => {
          setExistingCandidate({
            ...existingCandidate,
            canidatDialog: true,
            confirmDialog: false,
          });
        }}
      ></ExistingCandidateDialog>

      {/* Popup When choose the existing candidate detailsto autofill existing candidate data  */} 
      <CandidateDialog
        isLoadingData={existingCandidate.isLoadingData}
        isOpen={existingCandidate.canidatDialog}
        onClose={() => {
          setExistingCandidate({
            ...existingCandidate,
            confirmDialog: false,
            canidatDialog: false,
          });
        }}
        onConfirm={(candidate: any) => {
          setExistingCandidate({
            ...existingCandidate,
            isLoadingData: true,
            candidateData: candidate,
          });
        }}
      ></CandidateDialog>

      {/* Popup When Override the existing candidate details */}
      <CandidateRecordDialog
        isOpen={existingCandidate.UpdateOnSubmitDialog}
        onClose={() => {
          setExistingCandidate({
            ...existingCandidate,
            UpdateOnSubmitDialog: false,
            UpdateOnSubmit: false,
          });
          formRef.current?.handleSubmit();
        }}
        onConfirm={() => {
          setExistingCandidate({
            ...existingCandidate,
            UpdateOnSubmitDialog: false,
            UpdateOnSubmit: true,
          });
          formRef.current?.handleSubmit();
        }}
      ></CandidateRecordDialog>

      {/* Popup When preview the document on submission page */}    
      {filePreview !== undefined &&
      <PreviewFileDialog
        open={filePreview !== undefined}
        handleOpenClose={() => setFilePreview(undefined)}
        fileName={filePreview.fileName}
        fileURL={filePreview.resourceUrl}
        fileType={filePreview.fileType}
      />
      }
    </>
  );
}
