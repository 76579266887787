import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { DeleteIncompleteClientSignup } from "../../../Services/clientService";

interface Props {
  openPopup: boolean;
  handleRejectConfirmationCancel: any;
  handleConfirmationClose: any;
  onConfirmReject: any;
  item: any;
  handleClose: any;
  onClientDeleteSuccess: any;
}

export const ConfirmClientDelete: React.FC<Props> = ({
  openPopup,
  handleRejectConfirmationCancel,
  handleConfirmationClose,
  item,
  handleClose,
  onClientDeleteSuccess,
}) => {
  const onConfirmDelete = async () => {
    try {
      let response = await DeleteIncompleteClientSignup(item.id);
      if (response.data.status == 200) {
        handleConfirmationClose();
        handleClose();
        onClientDeleteSuccess();
      }
    } catch (error: any) {
      console.log(error, "error");
    }
  };

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={openPopup}
        onClose={handleRejectConfirmationCancel}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleConfirmationClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="center" className="custom-cnfrmdelete-txt">
                    Are you sure, you want to delete
                    <br /> the client?
                  </Typography>
                </Grid>
              </Grid>
            </DialogContentText>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginRight: "24px",
              }}
            >
              <Button
                className="custom-cancel-btn"
                onClick={handleConfirmationClose}
                variant="outlined"
                color="primary"
                style={{ marginRight: "24px", textTransform: "capitalize" }}
              >
                No
              </Button>
              <Button
                className="custom-cancel-btn"
                variant="contained"
                style={{ textTransform: "capitalize" }}
                onClick={onConfirmDelete}
              >
                Delete
              </Button>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};
export default ConfirmClientDelete;
