import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { getJobTaxTerms } from '../../../../Services/jobService';

interface TaxTermAutoCompleteProps {
  disabled?: boolean;
  required?: boolean;
  name: string;
  secondaryFieldName?:string;
  label:string;
  values: any; 
  placeholder:string;
  handleOnChange: (value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  touched?: any;
  errors?: any;
  viewMode?: boolean;
  disableClearable?:boolean;
}

const TaxTermAutoComplete: React.FC<TaxTermAutoCompleteProps> = ({
  disabled,
  required,
  name,
  secondaryFieldName,
  label,
  values,
  placeholder,
  handleOnChange,
  handleBlur,
  touched,
  errors,
  viewMode,
  disableClearable=false
}) => {

    //Component states
    const [jobTaxTermsList, setJobTaxTermsList] = useState<string[]>([]);

    // useEffects
    useEffect(()=>{
        _GetJobTaxTerms();
    },[])

  // GET Master Job TaxTerms
  const _GetJobTaxTerms = async () => {
    getJobTaxTerms()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTaxTermsList(response?.data?.entityList);
        } else {
          setJobTaxTermsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Autocomplete
      readOnly={viewMode}
      disabled={disabled}
      id="taxTerm-standard"
      disableClearable={disableClearable}
      options={jobTaxTermsList?.map((options: any) => options) || []}
      getOptionLabel={(option) => option?.taxTerm}
      value={values}
      onChange={(_, newValue: any) => {        
        handleOnChange(newValue)
      }}
      renderTags={(value, getTagProps) =>
        value.map((option: any, index: any) => (
          <Chip
            color="primary"
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          variant="standard"
          label={label}
          name={name}
          onBlur={handleBlur}
          error={
            touched &&
            Boolean(errors)
          }
          helperText={
            touched &&
            errors
          }
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default TaxTermAutoComplete;
