import React from 'react';
import { Grid, Typography } from '@mui/material';
import { valueOrDefault } from '../../../../../utils/complexityUtils';

interface ScorecardCandidateInfoProps {
  name: string;
  email: string;
  contact: string;
  location: string;
  position:string;
}

const ScorecardCandidateInfo: React.FC<ScorecardCandidateInfoProps> = ({
     name, 
     email, 
     contact, 
     location,
     position,
     }) => {
  return (
    <Grid container spacing={2}>
      {
        name &&
        <Grid item xs={12} sm={6}>
          <Typography  className='scorecardNameEmail-font'>
            Name
          </Typography>
          <Typography  className='scorecardNameEmailInfo-font'>
            {name}
          </Typography>
        </Grid>
        }
     
      {
        email && 
        <Grid item xs={12} sm={6}>
          <Typography  className='scorecardNameEmail-font'>
            Email
          </Typography>
          <Typography  className='scorecardNameEmailInfo-font'>
            {email}
          </Typography>
        </Grid>
      }
      
      {
        contact && 
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Contact
          </Typography>
          <Typography variant="body1"  className='scorecardNameEmailInfo-font'>
            {valueOrDefault(contact[0],"N/A")}
          </Typography>
        </Grid>
      }
     
      {
        location &&

        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Location
        </Typography>
        <Typography variant="body1"  className='scorecardNameEmailInfo-font'>
          {location}
        </Typography>
      </Grid>
      }

      {
        position && 
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            Job Position
        </Typography>
        <Typography variant="body1"  className='scorecardNameEmailInfo-font'>
          {position}
        </Typography>
      </Grid>
      }
     
    </Grid>
  );
};

export default ScorecardCandidateInfo;
