import { createSlice } from "@reduxjs/toolkit";

const initialState:any = {
  resume: "",
  resumeInfo: {
    resourceUrl: "",
    fileName: "",
    newFileName: "",
  },
  jobType: [],
  domains: [],
  industries: [],
  jobTitle: [],
  location: [],
  minimumPayRate: "",
  maximumPayRate: "",
  currency: "",
  resumeFile: {
    file: File,
    fileName: String,
  },
  isSearchResult: false,
  searchJobsId: [] as any,
  recommendationData: [] as any,
  sliderInfo: {
    skills_pct: 14.2,
    job_title_pct: 14.2,
    technology_pct: 14.2,
    tools_pct: 14.2,
    education_pct: 14.2,
    certification_pct: 14.2,
    industry_pct: 14.2,
  },
  workExp: "",
  experienceId: "",
  experienceText: "",
  n: 10,
  workAuthorizations: [],
  SearchRadius: "",
  remote: "",
  allowRelocation: "",
  results: undefined
};
export const findJobsSearchSlice = createSlice({
  name: "findJobsSearchSlice",
  initialState,
  reducers: {
    setFindJobsSearchState: (state, action) => {
      return action.payload;
    },
    resetFindJobsSearchState: (state) => initialState,
    setFindJobsResultState: (state, action) => {
      state.results = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFindJobsSearchState, resetFindJobsSearchState, setFindJobsResultState } =
  findJobsSearchSlice.actions;

export default findJobsSearchSlice.reducer;
