import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClientChangeTrackerTableFilterPopup from './Components/ClientChangeTrackerTableFilterPopup';
import ClientChangeTrackerTable from './Components/ClientChangeTrackerTable';
import { GetClientPaymentChangeTrackerData } from '../../../../Services/invoiceManagerService';

interface ChangeTrackerApiRequest {
    pageIndex: number;
    pageSize: number;
    sortColumn: string;
    sortOrder: "Ascending" | "Descending" | string;
    isSorted: boolean;
    totalRecord: number;
    searchKeyword: string;
    clientIds: string[];
    jobTypeIds: string[];
    jobTaxTermIds: string[];
  }

  interface ClientData {
    Client: string;
    AccountManager: string;
    JobType: string;
    TaxTerms: string | null;
    ClientFee: number | null;
    JobRialtoMargin: number | null;
    RecruiterCommission: number;
    PayrollOverhead: number | null;
    ClientPaymentTerms: string;
    ClientPaymentMode: string;
    OneTimeCommission: number | null;
    CreatedBy: string;
    ModifiedBy: string;
  }

  interface ClientChangeTrackerProps {
    reloadTracker:boolean;
    setReloadTracker: (state:boolean) => void;
    selectedClientDetails:any;
  }

const ClientChangeTracker: React.FC<ClientChangeTrackerProps> = ({
  reloadTracker,
  setReloadTracker,
  selectedClientDetails

}) => {

    const initialState = {
        pageIndex: 1,
        pageSize: 10,
        sortColumn: "",
        sortOrder: "Descending",
        isSorted: true,
        totalRecord: 0,
        searchKeyword: "",
        clientIds: [],
        jobTypeIds: [],
        jobTaxTermIds: [],
      };

      // Filter state

    const [anchorFilter, setAnchorFilter] = useState<any>(false);
    const filterRef = useRef<any>(null);
    
    const [changeTrackerFilterState, setChangeTrackerFilterState] =
        useState<ChangeTrackerApiRequest>(initialState);
    const [changeTrackerDataList, setChangeTrackerDataList] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMoreData, setHasMoreData] = useState<boolean>(false);
    
      useEffect(() => {
        _getChangeTrackerData();
      }, []);

      useEffect(()=>{

        if(reloadTracker){

          setChangeTrackerFilterState({
            ...changeTrackerFilterState,
            pageIndex:1
        });
          _getChangeTrackerData();
        }
      },[reloadTracker])

      useEffect(() => {
        if(changeTrackerFilterState.pageIndex > 1){
            _getChangeTrackerData();
        }
      }, [changeTrackerFilterState.pageIndex]);

      useEffect(() => {
        if(changeTrackerFilterState.pageIndex === 1){
            _getChangeTrackerData();
        }
      }, [
        changeTrackerFilterState?.jobTypeIds,
        changeTrackerFilterState?.jobTaxTermIds,
        changeTrackerFilterState?.clientIds
    ]);

      // Get Client Change tracker
      const _getChangeTrackerData = () => {
        if(changeTrackerFilterState.pageIndex === 1){
            setChangeTrackerDataList([]);
        }
        setIsLoading(true);
            GetClientPaymentChangeTrackerData(changeTrackerFilterState)
            ?.then((response: any) => {
                if (response?.data?.message?.appStatusCode === "RC_RSF") {

                    if (response?.data?.entityList?.length < changeTrackerFilterState?.pageSize) {
                        setHasMoreData(false);
                    } else {
                        setHasMoreData(true);
                    } 
                    if(changeTrackerFilterState.pageIndex === 1){
                        setChangeTrackerDataList([...response?.data?.entityList]);
                    }else{
                        setChangeTrackerDataList([...changeTrackerDataList, ...response?.data?.entityList]);
                    }             
                   
                }
            })
            .catch(() => {})
            .finally(()=>{
                setIsLoading(false);
                setReloadTracker(false);
            });
            ;
        }

    const _fetchMore = ()=>{
        setChangeTrackerFilterState({
            ...changeTrackerFilterState,
            pageIndex: changeTrackerFilterState.pageIndex + 1
        })
    }


  const _handleFilterClick = (event: any)=>{
    setAnchorFilter(anchorFilter ? null : event.currentTarget);
  }


  const _handleJobTypeChange = (selectedJobTypes:any) =>{
    const jobIds = selectedJobTypes.map((item:any) => item.id);

    setChangeTrackerFilterState({
        ...changeTrackerFilterState,
        jobTypeIds:jobIds,
        pageIndex:1
    });
    
  }

  const _handleTaxTermChange = (selectedTaxTerms:any) =>{
    const taxTermsIds = selectedTaxTerms.map((item:any) => item.id);

    setChangeTrackerFilterState({
        ...changeTrackerFilterState,
        jobTaxTermIds:taxTermsIds,
        pageIndex:1
    });
    
  }

  const _handleClientChange = (selectedClients:[]) =>{

    const selectedClientsListIds = selectedClients.map((item:any) => item.id);
    setChangeTrackerFilterState({
      ...changeTrackerFilterState,
      clientIds:selectedClientsListIds,
      pageIndex:1
  });
    
  }

  return (
    <Grid container spacing={3} >
      <Grid item xs={6} style={{display:"flex", justifyContent:"flex-start"}}>
        <Typography
            className='TableHeading'
        >
           Commission
        </Typography>
        </Grid>
        <Grid item xs={6} style={{display:"flex", justifyContent:"flex-end"}}>
        <Button
            variant="text"
            component="div"
            size="large"
            className="v2-base-btn cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={_handleFilterClick}
            data-testid="multifilter-button"
        >
            Filter
        </Button>    
        <ClientChangeTrackerTableFilterPopup  
            ref={filterRef}
            anchorFilter={anchorFilter}
            onClose={_handleFilterClick}
            onClientChange={_handleClientChange}
            handleJobTypeChange={_handleJobTypeChange}
            handleTaxTermChange={_handleTaxTermChange}
            selectedClients={selectedClientDetails}
            selectedJobTypes={[]}
            selectedTaxTerms={[]}

            />  
            </Grid> 
            <Grid item xs={12}>          
            <ClientChangeTrackerTable 
                dataList={changeTrackerDataList}
                fetchMore={_fetchMore}
                hasMore={hasMoreData}
                isLoading={isLoading}
            />
      </Grid>
    </Grid>
  );
};

export default ClientChangeTracker;
