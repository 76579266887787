import {
  Button,
  CircularProgress,
  Paper,
  Step,
  Stepper,
  Typography
} from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ClientSignUpRoadmapFlow from "./components/ClientSignUpRoadmapFlow";
import CompanyInformationForm from "./components/Forms/CompanyInformationForm";
import ContactDetailsForm from "./components/Forms/ContactDetailsForm";

import "./clientSignUp.scss";
import useStyles from "./styles_core";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  ClientCompleteSignUpSubmit,
  GetCompanySizeList,
} from "../../../Services/clientService";
import { UploadAttachment, UploadResourceModel } from "../../../Services/uploadPhotoService";
import { setClientSignUpFormState } from "../../../features/Client/ClientSignUp/ClientSignUpFormSlice";
import { UserLoginRequest } from "../../../models/app/Login/UserLoginModel";
import { DocumentTypeEnum, LoginTypeName } from "../../../models/interfaces";
import { CustomAlert } from "../../ui/CustomAlert";
import SignUpSchema from "./components/FormModel/ValidationSchema";
import AdditionalInfoForm from "./components/Forms/AdditionalInfoForm";
import AddressDetailsForm from "./components/Forms/AddressDetailsForm";
import SignupCompleteSuccessPopup from "./components/Popup/SignupCompleteSuccessPopup";
import { ternary } from "../../../utils/complexityUtils";

const steps = [
  "Company Information",
  "Contact Details",
  "Address Details",
  "Additional Information",
];

export default function ClientSignUp({clientSignUpData}:any) {
  const [searchParams] = useSearchParams();
  let queryId = searchParams.get("id") === null ? null : searchParams.get("id");

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientSignUpFormInitialValues = useSelector(
    (state: any) => state.clientSignUpFormState
  );

  const [authenticateModel, setAuthenticateModel] = React.useState<UserLoginRequest>({
    loginType: LoginTypeName.Email_UserID,
    password: "",
    returnUrl: "",
    username: ""
  });
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [formError, setFormError] = useState<any>();
  const [stepError1, setStepError1] = useState<boolean>(false);
  const [stepError2, setStepError2] = useState<boolean>(false);
  const [stepError3, setStepError3] = useState<boolean>(false);
  const [stepError4, setStepError4] = useState<boolean>(false);

  const[stepTraveling, setStepTraveling] = useState<any>({
    step1:true,
    step2:false,
    step3:false,
    step4:false,
  })
  const [stepCompleted1, setStepCompleted1] = useState<boolean | undefined>(undefined);
  const [stepCompleted2, setStepCompleted2] = useState<boolean | undefined>(undefined);
  const [stepCompleted3, setStepCompleted3] = useState<boolean | undefined>(undefined);
  const [stepCompleted4, setStepCompleted4] = useState<boolean | undefined>(undefined);

  const [companySizeList, setCompnaySizeList] = useState<[]>();
  const [pageStatus, setPageStatus] = useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
    loading: false,
  });

  const [logoFile, setLogoFile] = React.useState<{
    file: File | null;
    fileName: string | null;
  }>();

  const formik = useFormik({
    initialValues: clientSignUpFormInitialValues,
    validationSchema: SignUpSchema,
    onSubmit: (values, actions) => {
      const submitedPayload = values;
      //remove the unwanted key for submit payload
      const { consentPolicyConfirm, ...updatedSubmitPayload } = submitedPayload;
      _handleSubmit(updatedSubmitPayload, actions);
    },
  });

  useEffect(() => {
    dispatch(setClientSignUpFormState({
      ...clientSignUpFormInitialValues,
      email:clientSignUpData?.email,
      id:clientSignUpData?.id,
      loginType:clientSignUpData?.loginType,
      contactDetails: clientSignUpData?.contactDetails,
    }));
    formik.setFieldValue("loginType",clientSignUpData?.loginType);
    formik.setFieldValue("email",clientSignUpData?.email);
    formik.setFieldValue("id",clientSignUpData?.id);
    formik.setFieldValue("contactDetails.firstName", clientSignUpData?.contactDetails?.firstName);
    formik.setFieldValue("contactDetails.middleName", clientSignUpData?.contactDetails?.middleName);
    formik.setFieldValue("contactDetails.lastName", clientSignUpData?.contactDetails?.lastName);
    getCompanySizeList();
  }, []);

  useEffect(() => {
    setFormError(formik.errors);
    _CheckFormFieldError();
  }, [formik?.errors, formik?.touched, formik?.values]);

  function hasStepError1() {
    return (
      (formik.touched?.password && Boolean(formik.errors?.password)) || 
      (formik.touched?.confirmPassword && Boolean(formik.errors?.confirmPassword)) ||
      (formik.touched?.companyName && Boolean(formik.errors?.companyName)) ||
      (formik.touched?.companyWebsite && Boolean(formik.errors?.companyWebsite)) ||
      (formik.touched?.companySize && Boolean(formik.errors?.companySize)) ||
      (formik.touched?.industries && Boolean(formik.errors?.industries))
    );
  }

  function handleStepCompleted1() {
    setStepError1(false)
      
    if(
      formik.values?.companyWebsite !== "" &&
      formik.values?.companyName !== "" &&
      formik.values?.companySize !== "" && 
      formik.values?.industries?.length > 0 &&
      ((queryId !== null && formik?.values?.password !== "" && formik?.values?.confirmPassword !== "") ||
      queryId === null)
    ) {
      setStepCompleted1(true);
    } else {
      setStepCompleted1(false);
    }
  }

  function hasStepError2() {
    return (
      (formik.touched?.contactDetails?.firstName && Boolean(formik.errors?.contactDetails?.firstName)) || 
      (formik.touched?.contactDetails?.middleName && Boolean(formik.errors?.contactDetails?.middleName)) ||
      (formik.touched?.contactDetails?.lastName && Boolean(formik.errors?.contactDetails?.lastName)) ||
      (formik.touched?.contactDetails?.position && Boolean(formik.errors?.contactDetails?.position)) ||
      (formik.touched?.contactDetails?.primaryPhoneNumber && Boolean(formik.errors?.contactDetails?.primaryPhoneNumber))
    );
  }

  function handleStepCompleted2() {
    setStepError2(false);

    if(
      formik.values?.contactDetails?.firstName !=="" &&
      formik.values?.contactDetails?.lastName !=="" && 
      formik.values?.contactDetails?.position  !=="" &&
      formik.values?.contactDetails?.primaryPhoneNumber  !=="" 
    ) {
      setStepCompleted2(true);
    } else {
      setStepCompleted2(false);
    }
  }

  function hasStepError3() {
    return (
      (formik.touched?.address?.streetAddress && Boolean(formik.errors?.address?.streetAddress)) || 
      (formik.touched?.address?.city && Boolean(formik.errors?.address?.city)) ||
      (formik.touched?.address?.state && Boolean(formik.errors?.address?.state)) ||
      (formik.touched?.address?.postalCode && Boolean(formik.errors?.address?.postalCode)) ||
      (formik.touched?.address?.countryCode && Boolean(formik.errors?.address?.countryCode))
    );
  }

  function handleStepCompleted3() {
    setStepError3(false);

    if(
      formik.values?.address?.streetAddress !=="" &&
      formik.values?.address?.city !=="" && 
      formik.values?.address?.state !=="" && 
      formik.values?.address?.postalCode  !=="" &&
      formik.values?.address?.countryCode  !=="" 
    ){
      setStepCompleted3(true);
    } else {
      setStepCompleted3(false);
    }
  }

  function hasStepError4() {
    return (
      (formik.touched?.clientAdditionalDetails?.companyBenefits && Boolean(formik.errors?.clientAdditionalDetails?.companyBenefits)) || 
      (formik.touched?.clientAdditionalDetails?.hiringProcess && Boolean(formik.errors?.clientAdditionalDetails?.hiringProcess)) ||
      (formik.touched?.clientAdditionalDetails?.linkedInProfileUrl && Boolean(formik.errors?.clientAdditionalDetails?.linkedInProfileUrl)) ||
      (formik.touched?.clientAdditionalDetails?.companyOverview && Boolean(formik.errors?.clientAdditionalDetails?.companyOverview)) ||
      (formik.touched?.consentPolicyConfirm && Boolean(formik.errors?.consentPolicyConfirm))
    );
  }

  function handleStepCompleted4() {
    setStepError4(false)

    if(
      formik.values?.clientAdditionalDetails?.hiringProcess !=="" && 
      formik.values?.consentPolicyConfirm === true
    ) {
      setStepCompleted4(true);
    } else {
      setStepCompleted4(false);
    }
  }

  const _CheckFormFieldError = () => {

    //for step1
    if(hasStepError1()) {
      setStepError1(true);
    } else {
      handleStepCompleted1();
    }

    // for step 2
    if(hasStepError2()) {
      setStepError2(true)
    } else {
      handleStepCompleted2();
    }

    // for step 3
    if(hasStepError3()) {
      setStepError3(true);
    } else {
      handleStepCompleted3();
    }

    // for step 4
    if(hasStepError4()) {
      setStepError4(true)
    } else {
      handleStepCompleted4();
    }
  }

  function _handleSubmit(values: any, actions: any) {
    _submitForm(values, actions);
  }

  function _handleNext() {
    
    if(activeStep===0){
      setStepTraveling({...stepTraveling, step2:true})
    }else if(activeStep===1){
      setStepTraveling({...stepTraveling, step3:true})
    }else  if(activeStep===2){
      setStepTraveling({...stepTraveling, step4:true})
    }else{
      setStepTraveling({...stepTraveling, step1:true})
    }

    setActiveStep(activeStep + 1);
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function _handleUploadCompanyLogo (file: File){
    if(file){
      setLogoFile({
        file: file,
        fileName: file.name,
      });
    }else{
      setLogoFile({
        file: null,
        fileName: "",
      });
    }
    
  }

  function _handleClosePopup(){
    setPageStatus({... pageStatus, isSuccess:false, isError:false,  message: "",})
    navigate("/login");
  }

  //get company size list
  const getCompanySizeList = () => {
    const payload = {
      maxValue: 5000,
      rangeValue: 500,
    };
    GetCompanySizeList(payload)
      ?.then((response) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          setCompnaySizeList(response?.data?.entityList);
        } else {
          setCompnaySizeList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // call submit API to submit  complete signup form for client
  const _submitForm = (payload: any, actions: any) => {
    
    setPageStatus({
      isSuccess: false,
      isError: false,
      message: "",
      loading: true,
    })
    const companyLogoUploadModel : UploadResourceModel = {
        documentType: DocumentTypeEnum.UserProfileImg,
        file: logoFile?.file as File,
      };

    if(logoFile?.file){
   
    UploadAttachment(companyLogoUploadModel)
    .then(async (response) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {

        payload = {...payload,
          clientAdditionalDetails :{...payload.clientAdditionalDetails,
            companyLogo : {
              fileName : response.data.entity.fileName,
              resourceUrl:response.data.entity.absoluteUri,
              newFileName:response.data.entity.newFileName
            }
          },
        }
        
        _submitSignUpComplete(payload,actions);
          

      }
    })
    .catch((error) => {
      setPageStatus({
        ...pageStatus,
        isError: true,
        isSuccess: false,
        message: error?.data?.message?.appStatusDescription,
        loading: false,
      });
    });

  }else{
    // if company logo is not uploaded

    // if logo is not mandate if not uploaded
    const modifiedPayload = { ...payload };
    delete modifiedPayload.clientAdditionalDetails.companyLogo;
    
    _submitSignUpComplete(modifiedPayload,actions);
  }
   
  };

  function _submitSignUpComplete(payload:any,actions:any){
    // Set Login request payload if signup success 
    setAuthenticateModel({
      loginType: payload?.loginType,
      password: payload?.password,
      returnUrl:  "/client/dashboard",
      username:  payload?.email
      });

    ClientCompleteSignUpSubmit(payload)
          .then((response) => {
            if (response?.data?.message?.appStatusCode === "CL_RSF") {              
              actions.setSubmitting(false);
              setPageStatus({
                ...pageStatus,
                isSuccess: true,
                isError: false,
                message: response?.data?.message?.appStatusDescription,
                loading: false,
              });
            } else {
              setPageStatus({
                ...pageStatus,
                isError: true,
                isSuccess: false,
                message: response?.data?.message?.appStatusDescription,
                loading: false,
              });
            }
          })
          .catch((error) => {
            setPageStatus({
              ...pageStatus,
              isError: true,
              message: error?.data?.message?.appStatusDescription,
              loading: false,
            });
          });
  }

  function _renderStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <CompanyInformationForm
            formik={formik}
            formError={formError}
            companySizeList={companySizeList}
          />
        );
      case 1:
        return <ContactDetailsForm formik={formik} formError={formError} />;
      case 2:
        return <AddressDetailsForm formik={formik} formError={formError} />;
      case 3:
        return <AdditionalInfoForm formik={formik} formError={formError} handleUploadCompanyLogo={_handleUploadCompanyLogo} logoFile={logoFile}/>;
      default:
        return <div>Not Step Found</div>;
    }
  }

  const _getStepColor = (currentStep:number,activeStep:number,isStepError:boolean|undefined,isStepCompleted:boolean|undefined,)=>{

    if (currentStep === activeStep) {
      return 'primary'; // Valid and inactive
    } else {
      if (isStepError) {
        return 'error'; // Error and inactive
      } else if (!isStepCompleted) {
        return 'warning'; // Inactive and default
      } else {
        return 'primary'; // Default
      }
    }
  }

  function renderCompanyInformationControl() {
    return (
      (stepCompleted1 && !stepError1) ? 
      <CheckCircleIcon className="stepCompletedmarkIcon" /> : 
      <Radio
        color={
          _getStepColor(0, activeStep, stepError1, stepCompleted1)
        }
        checked={stepTraveling.step1 || activeStep === 0}
      />
    );
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography className="clientSignUpPageHeading">
                Sign Up and Start Your Client Onboarding Journey
              </Typography>
              <Typography
                component="p"
                className="clientSignUpPageHeadingSubTitle"
              >
                Sign up for JobRialto's marketplace for clients and streamline your hiring process. Our onboarding process is quick and easy, taking just two simple steps to get you started. 
              </Typography>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                className={classes.stepper + " ClientSignupStepper"}
              >
                <Step key={"1"} className="step-wrapper">
                  <RadioGroup value={0}>
                      <FormControlLabel
                        value={0}
                        className={ternary(0 === activeStep, 'activeStep', 'notActiceStep')}
                        control={renderCompanyInformationControl()}
                        label={"Company Information"}
                      />
                    </RadioGroup> 
                  </Step>
                  <Step key={"1"} className="step-wrapper">
                  <RadioGroup value={0}>
                      <FormControlLabel
                        value={0}
                        className={1 === activeStep ? 'activeStep' : 'notActiceStep'}
                        control={
                          (stepCompleted2 && !stepError2) ? <CheckCircleIcon className="stepCompletedmarkIcon" /> : 
                          <Radio
                          classes={{
                            root: classes?.uncheckedRadio, // Apply the custom CSS class to change the color
                          }}
                            color={
                              _getStepColor(1, activeStep, stepError2, stepCompleted2)
                            }
                            checked={stepTraveling.step2 || activeStep === 1}
                          />
                        }
                        label={"Contact Details"}
                      />
                    </RadioGroup> 
                  </Step>
                  <Step key={"2"} className="step-wrapper">
                  <RadioGroup value={0}>
                      <FormControlLabel
                        value={0}
                        className={2 === activeStep ? 'activeStep' : 'notActiceStep'}
                        control={
                          (stepCompleted3 && !stepError3) ? <CheckCircleIcon className="stepCompletedmarkIcon" /> : 
                          <Radio
                            color={
                              _getStepColor(2, activeStep, stepError3, stepCompleted3)
                            }
                            checked={stepTraveling.step3 || activeStep === 2}
                          />
                        }
                        label={"Address Details"}
                      />
                    </RadioGroup> 
                  </Step>
                  <Step key={"3"} className="step-wrapper">
                  <RadioGroup value={0}>
                      <FormControlLabel
                        value={0}
                        className={3 === activeStep ? 'activeStep' : 'notActiceStep'}
                        control={
                          (stepCompleted4 && !stepError4) ? <CheckCircleIcon className="stepCompletedmarkIcon" /> : 
                          <Radio
                            color={
                              _getStepColor(1, activeStep, stepError4, stepCompleted4)
                            }
                            checked={stepTraveling.step4 || activeStep === 3}
                          />
                        }
                        label={"Additional Information"}
                      />
                    </RadioGroup> 
                  </Step>
                
              </Stepper>
              <>
                <form onSubmit={formik.handleSubmit} noValidate>
                  <Grid container spacing={2}>
                  <Grid item xs={12}>
                      {pageStatus?.isError && (
                        <CustomAlert
                          type="error"
                          message={pageStatus?.message}
                          onClose={()=>{}}
                        ></CustomAlert>
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Paper elevation={0} className="ClientSignupFormBanner">
                        {_renderStepContent(activeStep)}

                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                          mt={{ sm: 2, md: 3, lg: 4 }}
                          className="step-nav-button-stack"
                        >
                          {activeStep !== 0 && (
                            <Button
                              disabled={pageStatus?.loading}
                              onClick={_handleBack}
                              size="small"
                              className="backBtn"
                            >
                              Back
                            </Button>
                          )}
                          {isLastStep ? (
                            <Button
                              disabled={pageStatus?.loading}
                              type="submit"
                              variant="contained"
                              color="primary"
                              className="clientSubmitBtn"
                              size="small"
                              onClick={(e: any) => {
                                console.log(formik.errors);
                                setFormError(formik.errors);
                                formik?.setFieldTouched("email", true);
                              }}
                              endIcon={ternary(
                                pageStatus?.loading,
                                <CircularProgress
                                  size={"16px"}
                                  color={"inherit"}
                                />,
                                <>{""}</>
                              )}
                            >
                              Complete Sign up
                            </Button>
                          ) : (
                            <>
                              <div
                                onClick={_handleNext}
                                className="custom-button"
                              >
                                <span
                                  color="primary"
                                  className={`customNextBtn ${ternary(
                                    pageStatus?.loading,
                                    "disabledCustomBtn",
                                    ""
                                  )}`}
                                >
                                  Next
                                </span>
                              </div>
                            </>
                          )}
                        </Stack>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} mt={2}>
                      <ClientSignUpRoadmapFlow />
                    </Grid>                    
                  </Grid>
                </form>
                
              </>
            </Grid>
            { pageStatus?.isSuccess &&
            <Grid item xs={12}>
              <SignupCompleteSuccessPopup 
                isOpen={pageStatus?.isSuccess}
                handleClose={_handleClosePopup} 
                authenticateModel={authenticateModel}
              />
            </Grid>
            }
          </Grid>
        </Box>
      </Container>
    </>
  );
}
