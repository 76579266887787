
interface Education {
    id: string;
    degreeName: string;
  }

  interface Skill {
    id: string;
    skillName: string;
  }

export interface JobFormValues {
    jobId?: string;
    domain: string;
    jobAssignmentType:string;
    jobType: string;
    jobTitle: string;
    clientName: string;
    location:string;
    city: string;
    state: string;
    country: string;
    experience: number | undefined;
    experienceId:number | null;
    jobExperience:number;
    experienceText?:string;
    education: Education[];
    certifications: [];
    licenses?:[];
    technologies: [];
    tools: [];
    shiftTimingFrom: string;
    shiftTimingTo: string;
    skills: Skill[];
    jobDescription: string;
    model?:string;
    modelName?:string;
  }
  
  export const GenerateJobDetailsFormIntialState: JobFormValues = {
    model: "mixtral-8x7b",
    modelName:"mixtral-8x7b",
    domain: '',
    jobAssignmentType:'',
    jobType: '',
    jobTitle: '',
    clientName: '',
    location:'',
    city: '',
    state: '',
    country: '',
    experience: undefined,
    experienceId: 0,
    jobExperience:0,
    education: [],
    certifications: [],
    technologies: [],
    tools: [],
    shiftTimingFrom: "",
    shiftTimingTo: "",
    skills: [],
    jobDescription: '',
  };