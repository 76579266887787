import { FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useEffect, useState } from "react";
import { GetModeOfDelivery, GetPaymentModes, GetPaymentTerms } from "../../../../../Services/clientService";
import { onlyNumberRegex, uptoTwoDecimalsRegx } from "../../../../../constants/regex";
import useApi from "../../../../../hooks/useApi";
import { TaxTerm } from "../../../../../models/enums/masterTaxTerms";
import JobTypeAutoComplete from "../../../../Common/Form/FormField/JobTypeAutoComplete";
import TaxTermAutoComplete from "../../../../Common/Form/FormField/TaxTermAutoComplete";
import { Asterisk } from "../../../../ui/Asterisk";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import FieldUpdateConfirmPopup from "../Popup/FieldUpdateConfirmPopup";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

interface OtherInformationFormSectionProps {
  handleBlur: (e: React.FocusEvent<any>) => void;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  selectedClientDetails: any;
  setValues:any;
  errors: any;
  touched: any;
  viewMode?:boolean;
  isUpdateAction: boolean;
}

const OtherInformationFormSection: React.FC<OtherInformationFormSectionProps> = ({
  handleBlur,
  values,
  selectedClientDetails,
  setFieldValue,
  setValues,
  errors,
  touched,
  viewMode,
  isUpdateAction
}) => {

  const [fieldPopup, setfieldPopup] = useState<any>({
    isOpen:false,
    popupTitle:"",
    selectedFieldName:"",
    selectedFieldValue:"",
    selectedName:""
  });

  const [FieldEditConsent, setFieldEditConsent] = useState<any>({otherDetails : {
    modeOfDelivery:false,
    paymentTerms:false,
    paymentMode:false,
    oneTimeCommission:false
  }
  })
    const modeOfDelivery = useApi(() => GetModeOfDelivery());
    const paymentTerms = useApi(() => GetPaymentTerms());
    const paymentModes = useApi(() => GetPaymentModes());

    useEffect(()=>{
      if(isUpdateAction){
        setFieldEditConsent({
          modeOfDelivery:false,
          paymentTerms:false,
          paymentMode:false,
          oneTimeCommission:false
        })
      }
    },[isUpdateAction])

    useEffect(() => {
      modeOfDelivery?.request();
      paymentTerms?.request();
      paymentModes?.request();
    }, []);

    useEffect(() => {
         
      _setOtherInformation();
    }, [values.otherDetails?.jobType, values.otherDetails?.jobTaxTerm]);

    const _setOtherInformation = () => {

      if (values.otherDetails?.jobType && values.otherDetails?.jobTaxTerm) 
      {
          // Find the matching data object based on jobType and jobTaxTerm
          const matchingData = selectedClientDetails?.otherDetails?.find(
            (item:any) =>
              item.jobType === values.otherDetails?.jobType &&
              item.jobTaxTerm === values.otherDetails?.jobTaxTerm
          );
          if (matchingData) {
            setValues({
              ...values,
              otherDetails : {
                ...values?.otherDetails,
                jobRialtoCommissionType: valueOrDefault(matchingData?.jobRialtoCommissionType, "USD"),
                jobRialtoCommission: valueOrDefault(matchingData?.jobRialtoCommission, ""),
                payrollOverhead: valueOrDefault(matchingData?.payrollOverhead, undefined),
                recruiterCommissionType: valueOrDefault(matchingData?.recruiterCommissionType, "USD"),
                recruiterCommission: valueOrDefault(matchingData?.recruiterCommission, undefined),
                clientFeeType: valueOrDefault(matchingData?.clientFeeType, "USD"),
                clientFee: valueOrDefault(matchingData?.clientFee, ""),
              }
            });
          }
      }
      else if(values.otherDetails?.jobType === "Full Time" && values.otherDetails?.jobTaxTerm ==="")
      {
          // Find the matching data object based on jobType only case for full time job
          const matchingData = selectedClientDetails?.otherDetails?.find(
            (item:any) =>
              item.jobType === values.otherDetails?.jobType 
          );
          if (matchingData) {          
            setValues({
              ...values,
              otherDetails : {
                ...values?.otherDetails,
                jobRialtoCommissionType: "USD",
                jobRialtoCommission: undefined,
                payrollOverhead: undefined,
                recruiterCommissionType: valueOrDefault(matchingData?.recruiterCommissionType, "USD"),
                recruiterCommission: valueOrDefault(matchingData?.recruiterCommission, undefined),
                clientFeeType: valueOrDefault(matchingData?.clientFeeType, "USD"),
                clientFee: valueOrDefault(matchingData?.clientFee, ""),
                paymentTerms: valueOrDefault(matchingData?.paymentTerms, undefined),
                paymentMode: valueOrDefault(matchingData?.paymentMode, undefined)
              }
            });
          }
      }
  }

      const _handleOnChange = (value: any)=> {
        setFieldValue("otherDetails.jobType", value.jobType);
        setFieldValue("otherDetails.jobTypeId", value.id);

        setFieldValue("otherDetails.jobRialtoCommission","");
        setFieldValue("otherDetails.payrollOverhead","");

        if(value.jobType === "Full Time"){
          setFieldValue("otherDetails.jobTaxTerm", "");
          setFieldValue("otherDetails.jobTaxTermId","");
        }else{          
          setFieldValue("otherDetails.clientFee", "");
        }
      }

      const _handleOnChangeTaxtTerms = (value: any) => {        
        setFieldValue("otherDetails.jobTaxTerm", value?.taxTerm);
        setFieldValue("otherDetails.jobTaxTermId", value?.id);

        if(value?.id !== TaxTerm.W2){
          setFieldValue("otherDetails.payrollOverhead", "");
        }
      }

      const _handleOnClose = () =>{
        setfieldPopup({
          ...fieldPopup,
          isOpen:false,
          popupTitle:"",
          selectedField:"",
          selectedName:""
        })
      }

      const _handleYes = () =>{

        switch (fieldPopup.selectedName) {

          case 'modeOfDelivery':
              setFieldEditConsent({
                ...FieldEditConsent,
                modeOfDelivery: true
              });
              break;
          case 'paymentTerms':
            setFieldEditConsent({
              ...FieldEditConsent,
              paymentTerms: true
            });
            break;
          case 'paymentMode':  
              setFieldEditConsent({
                ...FieldEditConsent,
                paymentMode: true
              });
              break;
          case 'oneTimeCommission':
            setFieldEditConsent({
              ...FieldEditConsent,
              oneTimeCommission: true
            });
            break;
          default:
            // Handle unknown selectedName here
            break;
        }
        
        setFieldValue(
          fieldPopup.selectedFieldName,
          fieldPopup.selectedFieldValue
        );
        _handleOnClose();
      }

      const _handleNo = () =>{
        _handleOnClose();
      }

  return (
    <Paper
      elevation={0}
      style={{ padding: "16px 8px 24px 8px", background: "#fff" }}
    >
      <Grid container spacing={2}>
        {/* Row 1 */}
        <Grid item xs={12}>
          <Typography variant="h6" className="formSectionHeading" >Other Information</Typography>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12} sm={6} md={3} mt={2}>
            <JobTypeAutoComplete
                disableClearable={true}
                required= {true}
                name= "otherDetails.jobType"
                secondaryFieldName="otherDetails.jobTypeId"
                label= "Job Type"
                values= {{
                  id:values?.otherDetails?.jobTypeId,
                  jobType:values?.otherDetails?.jobType
                }} 
                placeholder= "Select Job Type"
                handleOnChange= {_handleOnChange}
                handleBlur= {handleBlur}
                touched= {touched?.otherDetails?.jobType}
                errors= {errors?.otherDetails?.jobType}
                viewMode={viewMode}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3} mt={2}>
            <TaxTermAutoComplete
                viewMode={viewMode}
                disabled={values.otherDetails?.jobType === "Full Time"}
                disableClearable={true}
                required= {true}
                name= "otherDetails.jobTaxTerm"
                secondaryFieldName="otherDetails.jobTaxTermId"
                label= "Tax Terms"
                values= {{
                  id:values.otherDetails?.jobTaxTermId,
                  taxTerm:values.otherDetails?.jobTaxTerm
                }} 
                placeholder= "Select Tax Terms"
                handleOnChange= {_handleOnChangeTaxtTerms}
                handleBlur= {handleBlur}
                touched= {touched?.otherDetails?.jobTaxTerm}
                errors= {errors?.otherDetails?.jobTaxTerm}
            />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={1.8} xl={1.5} mt={2}>
        <FormControl
            required
            fullWidth 
            variant="standard" 
            sx={{ m: 0, minWidth: 120 }}>
            <InputLabel id="clientFee-select-filled-label">Client Fee</InputLabel>
            <Select
                readOnly={viewMode}
                disabled={values.otherDetails?.jobType !== "Full Time"}
                required
                variant="standard"
                inputProps={{ "data-testid": "select-clientfee" }}
                style={{ width: "100%" }}
                labelId="clientFee-select-filled-label"
                id="clientFee-simple-select"
                name="otherDetails.clientFeeType"
                value={values?.otherDetails?.clientFeeType} 
                onChange={
                    (event: SelectChangeEvent<any>) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }
                }
              >
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"Percentage"}>Percentage</MenuItem>
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={1} lg={1.2} xl={1.5} mt={4}>
            <TextField
                disabled={values.otherDetails?.jobType !== "Full Time"}
                onChange={(event:any)=>{
                  if(values?.otherDetails?.clientFeeType ==="USD"){
                    if (onlyNumberRegex?.test(event.target.value)) {
                      setFieldValue("otherDetails.clientFee",event.target.value);
                    }
                  }else{
                    if (uptoTwoDecimalsRegx?.test(event.target.value)) {
                      setFieldValue("otherDetails.clientFee",event.target.value);
                    }
                  }                           
                }}
                onBlur={handleBlur}
                value={values?.otherDetails?.clientFee}
                name="otherDetails.clientFee"
                variant="standard"
                type="number"
                fullWidth
                InputLabelProps={{  shrink: true }}
                inputProps={{"data-testid": "text-clientfee", readOnly : viewMode}}
              />
              <ErrorMessage
                errorText={
                  touched?.otherDetails?.clientFee &&
                  errors?.otherDetails?.clientFee
                }
              />
        </Grid>

        <Grid item xs={6} sm={6} md={2} lg={3} xl={3} mt={2}>
            <TextField
            disabled={values.otherDetails?.jobType === "Full Time"}
            required
            onChange={(event:any)=>{
              if(values?.otherDetails?.jobRialtoCommissionType ==="USD"){
                if (onlyNumberRegex?.test(event.target.value)) {
                  setFieldValue("otherDetails.jobRialtoCommission",event.target.value);
                }
              }else{
                if (uptoTwoDecimalsRegx?.test(event.target.value)) {
                  setFieldValue("otherDetails.jobRialtoCommission",event.target.value);
                }
              }
                           
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start" className="startIcon">%</InputAdornment>,
          }}
            onBlur={handleBlur}
            value={values?.otherDetails?.jobRialtoCommission}
            name="otherDetails.jobRialtoCommission"
            label="JobRialto Margin"
            variant="standard"
            type="number"
            fullWidth    
            inputProps={{"data-testid": "text-commissionType", readOnly: viewMode}}
            error={
              touched?.otherDetails?.jobRialtoCommission &&
              Boolean(errors?.otherDetails?.jobRialtoCommission)
            }
            helperText={
              touched?.otherDetails?.jobRialtoCommission &&
              errors?.otherDetails?.jobRialtoCommission
            }            
            />
        </Grid>

        <Grid item xs={6} sm={6} md={3} mt={2}>
            <TextField
            disabled={values.otherDetails?.jobTaxTerm !== "W2"}
            required
            onChange={(event:ChangeEvent<HTMLInputElement>)=>{
              if (onlyNumberRegex?.test(event.target.value)) {
                setFieldValue("otherDetails.payrollOverhead",event.target.value);
              }
            }}
            onBlur={handleBlur}
            value={values?.otherDetails?.payrollOverhead}
            name="otherDetails.payrollOverhead"
            label="Payroll Overhead"
            variant="standard"
            type="number"
            fullWidth    
            InputProps={{
                startAdornment: <InputAdornment position="start" className="startIcon">%</InputAdornment>,
            }}
            inputProps={{"data-testid": "text-payrollOverhead", readOnly: viewMode}}
            error={
              touched?.otherDetails?.payrollOverhead &&
              Boolean(errors?.otherDetails?.payrollOverhead)
            }
            helperText={
              touched?.otherDetails?.payrollOverhead &&
              errors?.otherDetails?.payrollOverhead
            }
            />
        </Grid>

        {/* Row 2 */}
        <Grid item xs={6} sm={3} md={2} lg={1.8} xl={1.5}  mt={2}>
          <FormControl
            required
            fullWidth 
            variant="standard" 
            sx={{ m: 0, minWidth: 120 }}>
            <InputLabel id="clientFee-select-filled-label">Recruiter Commission</InputLabel>
            <Select
                required
                variant="standard"
                inputProps={{ "data-testid": "select-recruiterCommission", readOnly: viewMode }}
                style={{ width: "100%" }}
                labelId="clientFee-select-filled-label"
                id="clientFee-simple-select"
                name="otherDetails.recruiterCommissionType"
                value={values?.otherDetails?.recruiterCommissionType} 
                onChange={
                    (event: SelectChangeEvent<any>) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }
                }
              >
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"Percentage"}>Percentage</MenuItem>
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} md={1} lg={1.2} xl={1.5} mt={4}>
            <TextField
              onChange={(event:any)=>{
                if(values?.otherDetails?.recruiterCommissionType ==="USD"){
                  if (onlyNumberRegex?.test(event.target.value)) {
                    setFieldValue("otherDetails.recruiterCommission",event.target.value);
                  }
                }else{
                  if (uptoTwoDecimalsRegx?.test(event.target.value)) {
                    setFieldValue("otherDetails.recruiterCommission",event.target.value);
                  }
                }                      
              }}
              onBlur={handleBlur}
              value={values?.otherDetails?.recruiterCommission}
              name="otherDetails.recruiterCommission"
              label=""
              variant="standard"
              type="number"
              fullWidth    
              inputProps={{"data-testid": "text-recruiterCommission", readOnly: viewMode}}
              error={
                touched?.otherDetails?.recruiterCommission &&
                Boolean(errors?.otherDetails?.recruiterCommission)
              }
              helperText={
                touched?.otherDetails?.recruiterCommission &&
                errors?.otherDetails?.recruiterCommission
              }
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3} mt={2}>
            <InputLabel 
              error={touched?.otherDetails?.paymentTerms &&
                Boolean(errors?.otherDetails?.paymentTerms)}
              shrink={true}> 
              Client Payment Terms <Asterisk />
            </InputLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="otherDetails.paymentTerms"
                value={values?.otherDetails?.paymentTerms} 
                onChange={(event)=>{
                  if(
                    !FieldEditConsent.paymentTerms &&
                    selectedClientDetails?.clientCommonPaymentDetails?.paymentTerms !== event.target.value
                    && event.target.value!== undefined &&  selectedClientDetails?.clientCommonPaymentDetails?.paymentTerms !== undefined
                    ){
                    setfieldPopup({
                      ...fieldPopup,
                      isOpen:true,
                      popupTitle:"Are you sure you want to update the payment terms?",
                      selectedFieldName:"otherDetails.paymentTerms",
                      selectedFieldValue:event.target.value,
                      selectedName:"paymentTerms"
                    }) 
                  }else{
                    setFieldValue("otherDetails.paymentTerms",event.target.value)
                  }
                }}
              >
                {paymentTerms?.data?.entityList?.map((x: any) => {
                  return (
                    <FormControlLabel
                      disabled={viewMode}
                      key={x}
                      value={x}
                      control={<Radio />}
                      label={x}
                    />
                  );
                })}
              </RadioGroup>
              <ErrorMessage  errorText={
                touched?.otherDetails?.paymentTerms &&
              errors?.otherDetails?.paymentTerms}/>
         
        </Grid>
        <Grid item xs={12} sm={6} md={3} mt={2}>
        
        <InputLabel 
              error={touched?.otherDetails?.paymentMode &&
                Boolean(errors?.otherDetails?.paymentMode)}
              shrink={true}> 
              Client Payment Mode <Asterisk />
            </InputLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="otherDetails.paymentMode"
                value={values?.otherDetails?.paymentMode} 
                onChange={ (event)=>{

                  if(
                    !FieldEditConsent.paymentMode &&
                    selectedClientDetails?.clientCommonPaymentDetails?.paymentMode !== event.target.value
                    && event.target.value!== undefined &&  selectedClientDetails?.clientCommonPaymentDetails?.paymentMode !== undefined
                    ){
                    setfieldPopup({
                      ...fieldPopup,
                      isOpen:true,
                      popupTitle:"Are you sure you want to update the payment mode?",
                      selectedFieldName:"otherDetails.paymentMode",
                      selectedFieldValue:event.target.value,
                      selectedName:"paymentMode"
                    }) 
                  }else{
                    setFieldValue("otherDetails.paymentMode",event.target.value)
                  }
                }}
              >
                {paymentModes?.data?.entityList?.map((x: any) => {
                  return (
                    <FormControlLabel
                     disabled={viewMode}
                      key={x}
                      value={x}
                      control={<Radio />}
                      label={x}
                    />
                  );
                })}
              </RadioGroup>
              <ErrorMessage  errorText={
                touched?.otherDetails?.paymentMode &&
              errors?.otherDetails?.paymentMode}/>
          
        </Grid>
        {/* Row 3 */}
        <Grid item xs={12} sm={6} md={3} >
            <FormControl 
                fullWidth
                required
                variant="standard"
                error={
                    touched?.otherDetails?.modeOfDelivery &&
                    Boolean(errors?.otherDetails?.modeOfDelivery)
                }
            >            
             <InputLabel 
            htmlFor="modeOfdivery-simple-select-label" 
            >
            Mode Of Delivery 
          </InputLabel>
          <Select
            readOnly={viewMode}
            variant="standard"
            inputProps={{ "data-testid": "select-modeOfDelivery" }}
            style={{ width: "100%" }}
            labelId="modeOfdivery-simple-select-label"
            id="modeOfdivery-simple-select"
            name="otherDetails.modeOfDelivery"
            value={values?.otherDetails?.modeOfDelivery}
            onChange={(event: SelectChangeEvent<any>) => {
                const { name, value } = event.target;
                
                if(
                  !FieldEditConsent.modeOfDelivery &&
                  selectedClientDetails?.clientCommonPaymentDetails?.modeOfDelivery !== event.target.value
                  && event.target.value!== undefined &&  selectedClientDetails?.clientCommonPaymentDetails?.modeOfDelivery !== undefined
                  ){
                  setfieldPopup({
                    ...fieldPopup,
                    isOpen:true,
                    popupTitle:"Are you sure you want to update mode of delivery?",
                    selectedFieldName:"otherDetails.modeOfDelivery",
                    selectedFieldValue:value,
                    selectedName:"modeOfDelivery"
                  }) 
                }else{
                  setFieldValue(name, value);
   
                }

              }}
              onBlur={handleBlur}
          >
            {modeOfDelivery?.data?.entityList?.map((x: any) => {
              return (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              );
            })}
          </Select>
          <ErrorMessage
            errorText={
              touched?.otherDetails?.modeOfDelivery &&
              errors?.otherDetails?.modeOfDelivery
            }
          />
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3} md={2} lg={1.8} xl={1.5}>
        <FormControl
            fullWidth 
            variant="standard" 
            sx={{ m: 0, minWidth: 120 }}>
            <InputLabel id="clientFee-select-filled-label" shrink={true}>One Time Commission</InputLabel>            
            </FormControl>
            <TextField variant="standard" value={"USD"} style={{marginTop:"-4px"}}/>
        </Grid>
        <Grid item xs={6} sm={3} md={1} lg={1.2} xl={1.5} mt={2}>
          
            <TextField
                onChange={(event:any)=>{
                  if (onlyNumberRegex?.test(event.target.value)) {
                    if(
                      !FieldEditConsent.oneTimeCommission &&
                      selectedClientDetails?.clientCommonPaymentDetails?.oneTimeCommission !== event.target.value
                      && event.target.value!== undefined &&  selectedClientDetails?.clientCommonPaymentDetails?.oneTimeCommission !== undefined
                      ){
                      setfieldPopup({
                        ...fieldPopup,
                        isOpen:true,
                        popupTitle:"Are you sure you want to update One Time Commission?",
                        selectedFieldName:"otherDetails.oneTimeCommission",
                        selectedFieldValue:event.target.value,
                        selectedName:"oneTimeCommission"
                      }) 
                    }else{
                      setFieldValue("otherDetails.oneTimeCommission",event.target.value); 
                    }
                  }
                                          
                }}
                onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}
                onBlur={handleBlur}
                value={values?.otherDetails?.oneTimeCommission}
                name="otherDetails.oneTimeCommission"
                variant="standard"
                type="number"
                fullWidth
                InputLabelProps={{  shrink: true }}
                inputProps={{"data-testid": "text-oneTimeCommission", readOnly: viewMode}}
              />
              <ErrorMessage
                errorText={
                  touched?.otherDetails?.oneTimeCommission &&
                  errors?.otherDetails?.oneTimeCommission
                }
              />
        </Grid>
        <Grid item xs={12}>
          <FieldUpdateConfirmPopup 
            isOpen={fieldPopup.isOpen}
            title={fieldPopup?.popupTitle}
            handleClose = {_handleOnClose}
            handleYes= {_handleYes}
            handleNo = {_handleNo}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OtherInformationFormSection;
