import React from "react";
import "./../../Scorecard.scss";
import Grid from "@mui/material/Grid";
import { Divider, Paper } from "@mui/material";
import SectionHeading from "../../../../ui/Form/Heading/SectionHeading";
import { KeywordsCategory } from "./ScorecardMatchingKeywords";
import KeywordChip from "../Keywords/KeywordChip";

interface ScorecardMatchingKeywordsProps {
  skillsKeywords: KeywordsCategory;
  technologyKeywords: KeywordsCategory;
  educationKeywords: KeywordsCategory;
  certificationKeywords: KeywordsCategory;
  industryKeywords: KeywordsCategory;
  toolKeywords: KeywordsCategory;
}

const ScorecardMissingKeywords: React.FC<
  ScorecardMatchingKeywordsProps
> = ({
  skillsKeywords,
  technologyKeywords,
  educationKeywords,
  certificationKeywords,
  industryKeywords,
  toolKeywords

}) => {

  return (
    <>
      <Grid container spacing={2}>
        {/* Row 0 for Key skills keyword */}
        <Grid item xs={12}>
          <SectionHeading title="Missing Keywords" />
          <Divider className="borderedDivider" />
        </Grid>
        {/* Row 1 for Key skills keyword */}

        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
            <Grid container spacing={2} className="keyword-margin">
              <Grid item xs={12}>
                <SectionHeading title="Skills" />
              </Grid>
                <Grid item xs={12}>
                  <KeywordChip 
                    keywords={skillsKeywords?.termsNotFound ?? []} 
                    className="ErrorChip" 
                    />
                </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Row 2 for Technology keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">            
            <Grid container spacing={2} className="keyword-margin">
              <Grid item xs={12}>
                <SectionHeading title="Technology" />
              </Grid>
              <Grid item xs={12}>
                <KeywordChip 
                  keywords={technologyKeywords?.termsNotFound ?? []} 
                  className="ErrorChip" 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Row 3 for Tool Keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">           
            <Grid container spacing={2} className="keyword-margin">
              <Grid item xs={12}>
                <SectionHeading title="Tools" />
              </Grid>
              <Grid item xs={12}>
                <KeywordChip 
                  keywords={toolKeywords?.termsNotFound ?? []} 
                  className="ErrorChip" 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Row 4 for Education Keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">            
            <Grid container spacing={2} className="keyword-margin">
              <Grid item xs={12}>
                <SectionHeading title="Education" />
              </Grid>
              <Grid item xs={12}>
                <KeywordChip 
                  keywords={educationKeywords?.termsNotFound ?? []} 
                  className="ErrorChip" 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Row 5 for certification Keyword */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">
             <Grid container spacing={2} className="keyword-margin">
              <Grid item xs={12}>
                <SectionHeading title="Certification" />
              </Grid>
              <Grid item xs={12}>
                <KeywordChip
                  keywords={certificationKeywords?.termsNotFound ?? []}
                  className="ErrorChip"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Row 6 for Matching Industry Keywords */}
        <Grid item xs={12}>
          <Paper elevation={0} className="keywords-background">           
            <Grid container spacing={2} className="keyword-margin">
              <Grid item xs={12}>
                <SectionHeading title="Industry" />
              </Grid>
              <Grid item xs={12}>
                <KeywordChip 
                  keywords={industryKeywords?.termsNotFound ?? []} 
                  className="ErrorChip" 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ScorecardMissingKeywords;
