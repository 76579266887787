
import axios from './CommonHttpRequest';

let baseUrl = `${process.env.REACT_APP_JOBPROVIDER_API}`;

export const GetJobs =  (data:any) => axios.post(`${baseUrl}api/Jobs/GetJob`, data);
      

export const GetJobById =  (id:string) =>  axios.get(`${baseUrl}api/Jobs/${id}`)
      
export const UpdateViewJob =  async(jobId:string, recruiterId:string) => { 
    let reqBody = {
        jobId: jobId,
        recruiterId: recruiterId
      }
    return await axios.post(`${baseUrl}api/SavedJobs/UpdateViewJob`,reqBody)
}

export const GetSavedJobs = async (data:any) => {
    return await axios.post(`${baseUrl}api/SavedJobs/GetJob`,data)
      
}
export const UpdateJobAsFeatured = async (data:any) => {
    return await axios.put(`${baseUrl}api/FeaturedJobs/UpdateJobAsFeatured`,data)
      
}

export const UpdateJobAsNotActive = async (data:any) => {
    return await axios.put(`${baseUrl}api/Jobs/UpdateJobAsDisabled`,data)
      
}
export const GetFeaturedJobs = async () => {
    return await axios.get(`${baseUrl}api/FeaturedJobs/Get`)
      
}

export const GetAutoSuggessionJobAndClientName = async (data:any) => {
    return await axios.post(`${baseUrl}api/AutoSuggestions/GetSuggestions`,data)
      
}

export const GetJobRecommendation = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Glasssquid/JobRecommendations`,body);
  }

export const GetFindJob = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Eximius/JobRecommendations`,body);
}

export const GetFindCandidate = async (body:any) =>{
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Eximius/FindCandidates`,body);
}

// API function to get job master domains
export const getJobMasterDomains =  async() => axios.get(`${baseUrl}api/job-master/domains`);

// API function to get job master Require degrees
export const getJobRequireDegrees =  async() => axios.get(`${baseUrl}api/job-master/required-degrees`);

// API function to get job master Job Types
export const getJobTypes =  async() => axios.get(`${baseUrl}api/job-master/job-types`);

// API function to get job master Tax Terms
export const getJobTaxTerms =  async() => axios.get(`${baseUrl}api/job-master/tax-terms`);

// API function to get job master Payments method/modes
export const getJobPaymentModes =  async() => axios.get(`${baseUrl}api/job-master/payment-modes`);

// API function to get job master work authorization value
export const getJobWorkAuthorization =  async() => axios.get(`${baseUrl}api/job-master/work-authorization`);


// API function to get job master Application methods
export const getJobApplicationMethods =  async() => axios.get(`${baseUrl}api/job-master/application-methods`);


// API function to get job master require degrees
export const getJobRequireDocuments =  async() => axios.get(`${baseUrl}api/job-master/required-documents`);

// API function to get job master data for work Authorization by id 
export const getJobWorkAuthorizationById =  (taxTermId :string) => axios.get(`${baseUrl}api/job-master/work-authorization/${taxTermId }`);

// API function to get job master experinece  years list
export const getJobWorkExperience =  () => axios.get(`${baseUrl}api/job-master/job-experience`);

// add job API to submit
export const addJob = async (payload: any) => {
    return await axios.post(`${baseUrl}api/Jobs/add`,payload);
  };

  // Update job API to submit
export const editJob = async (payload: any) => {
    return await axios.post(`${baseUrl}api/Jobs/update`,payload);
  };

 // Mark or move the publish job to pending API
 export const setJobAsPending = async (payload: any) => {
    return await axios.post(`${baseUrl}api/Jobs/mark-pending`,payload);
  };

 // Parse Resume API
export const getParseResume = async (payload: any) => {
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Eximius/ParseResume`,payload);
};

 // Parse Resume API
 export const getScoreCard = async (payload: any) => {
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Eximius/ScoreCard`,payload);
};
 

// Generate Job description Eximius API
export const GenarateJobDetailsEximius = async (reqPayload: any) => {
  return await axios.post(`${process.env.REACT_APP_INTEGRATION_API}api/Eximius/JobDetails`,reqPayload);
};

export const StafflineRedirectHitCount = async () => {
  return await axios.put(`${baseUrl}api/Tracking/TrackStafflineProRedirection`)
}