import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import "./popup.scss";
import GenerateJobDetailsPreview, { MessageList } from "../FormSection/GenerateJobDetailsPreview";

interface Props {
  isOpen: boolean;
  handleClose:any
  generatedJobDetailsResult:MessageList[]
}
const PreviewAIGeneratedDetailsPopup :React.FC<Props> = ({ 
  isOpen, 
  handleClose,
  generatedJobDetailsResult
  }) => {

  return (
    <div>
      <Dialog        
        open={isOpen}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="pop-up-title"
      >
         
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          <Typography className="PopupPrimaryTitle"> Generate The Job Details Preview</Typography> 
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className="closePopupCommon"
          >
            
            <CloseIcon />
          </IconButton>         
        </DialogTitle>
        <DialogContent>
        <GenerateJobDetailsPreview
            messageList={generatedJobDetailsResult}
        />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PreviewAIGeneratedDetailsPopup;
