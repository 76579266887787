import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, DialogContentText, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import SuccessIcon from "../../../assets/images/Approved.svg";
interface Props {
  setPageStatus: any;
  pageStatus: any;
  formik: any;
}

export const AddClientSuccessPopup: React.FC<Props> = ({
  setPageStatus,
  pageStatus,
  formik,
}) => {
  return (
    <div>
      <Dialog open={pageStatus.isSuccess}>
        <DialogContent sx={{ bgcolor: "#FFF" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setPageStatus({ ...pageStatus, isSuccess: false });
              formik.resetForm();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText
            component="div"
            id="success-dialog-description"
            textAlign="center"
          >
            <figure>
              <img
                src={SuccessIcon}
                className="PopupStatusIcon"
                alt="Success Icon"
              />
            </figure>
            <Typography component="div" className="PopupPrimaryTitle" my={2}>
              New Client Successfully Added
            </Typography>
            <Typography
              component="div"
              fontWeight={600}
              my={2}
              className="PopupSecondaryTitle"
            >
              The new client added will receive the verify account email in
              their mailbox sent to {formik.values.email} within the next 15
              minutes.
            </Typography>
            <Box my={3}>
              <Button
                variant="contained"
                onClick={() => {
                  setPageStatus({ ...pageStatus, isSuccess: false });
                }}
              >
                Not the correct email address
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AddClientSuccessPopup;
