import React, { FocusEvent } from 'react';
import { Autocomplete, TextField, Chip, Grid, InputLabel } from '@mui/material';
import { shiftTimes } from '../../../../data/ShiftTimes';

interface ShiftTiming {
    shiftTimingFrom:string;
    shiftTimingTo:string;
}
interface Props {
  name?: ShiftTiming;
  required?: boolean;
  handleBlur?: (e: FocusEvent<any, Element>) => void;
  handleOnChange?: (value: any) => void;
  setFieldValue:any;
  selectedValue?: ShiftTiming;
  touched?: {
    shiftTimingFrom:boolean;
    shiftTimingTo:boolean;
  };
  errors?: {
    shiftTimingFrom:string;
    shiftTimingTo:string;
  };
}

const ShiftTimingStartEndAutoComplete: React.FC<Props> = ({
    name={
        shiftTimingFrom:"shiftTimingFrom",
        shiftTimingTo:"shiftTimingTo"
    },
    handleBlur,
    setFieldValue,
    selectedValue={
        shiftTimingFrom:"",
        shiftTimingTo:""
    },
    touched={
        shiftTimingFrom:false,
        shiftTimingTo:false
    },
    errors={
        shiftTimingFrom:"",
        shiftTimingTo:""
    },
  }) => {
  
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} p={0} sx={{ mt: { xs: 0, sm: 0, md: -2 } }}>
             <InputLabel shrink>Shift Timings</InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ mt: { xs: -1, sm: -2, md: -3 } }}>
                <Autocomplete
                    id="shiftTimingFrom-standard"
                    className="shiftTimingInput"
                    disableClearable={true}
                    options={shiftTimes?.to?.map((options: any) => options)}
                    getOptionLabel={(option) => option}
                    value={selectedValue?.shiftTimingFrom}
                    onChange={(_, newValue: any) => {
                    setFieldValue(name.shiftTimingFrom, newValue);
                    }}
                    onBlur={handleBlur}
                    renderTags={(value, getTagProps) =>
                    value.map((option: any, index: any) => (
                        <Chip
                        color="primary"
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                        />
                    ))
                    }
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="From"
                        name={name.shiftTimingFrom}
                        data-testid="autocomplete-shiftTimingFrom"
                        error={
                        touched?.shiftTimingFrom &&
                        Boolean(errors?.shiftTimingFrom)
                        }
                        helperText={
                        touched?.shiftTimingFrom &&
                        errors?.shiftTimingFrom
                        }
                        placeholder=""
                    />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ mt: { xs: 0, sm: -2, md: -3 } }}>
                <Autocomplete
                    id="shiftTimingTo-standard"
                    className=""
                    disableClearable={true}
                    options={shiftTimes?.from?.map((options: any) => options)}
                    getOptionLabel={(option) => option}
                    value={selectedValue?.shiftTimingTo}
                    onChange={(_, newValue: any) => {
                    setFieldValue( name.shiftTimingTo, newValue);
                    }}
                    onBlur={handleBlur}
                    renderTags={(value, getTagProps) =>
                    value.map((option: any, index: any) => (
                        <Chip
                        color="primary"
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                        />
                    ))
                    }
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="To"
                        name={name.shiftTimingTo}
                        data-testid="autocomplete-shiftTimingTo"
                        error={
                        touched?.shiftTimingTo &&
                        Boolean(errors?.shiftTimingTo)
                        }
                        helperText={
                        touched?.shiftTimingTo &&
                        errors?.shiftTimingTo
                        }
                        placeholder=""
                    />
                    )}
                />
            </Grid>
        </Grid>
    );
  };
  
  export default ShiftTimingStartEndAutoComplete;
