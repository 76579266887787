import { FormikProps } from 'formik';
import { JobDetailsDescriptionWithHtml, MessageList } from '../FormSection/GenerateJobDetailsPreview';
import { Experience, JobType, fetchAndFindMatchingIndustry, findEducationByValue, findExperienceByValue, findJobTypeByValue } from '../../../../../utils/ArrayUtils';
import { Education } from '../../../../Common/Form/FormField/EducationAutoComplete';
import { getCertificateNames, isRemoteOrHybrid } from '../../../../../utils/StringUtil';
import { valueOrDefault } from '../../../../../utils/complexityUtils';

export async function FormAutoPopulateDetailsSet(
  AIvalues: MessageList[],
  formRef: React.RefObject<FormikProps<any>>,
  jobWorkExperiencesList: Experience[],
  jobTypesList: JobType[],
  jobRequireDegreesList: Education[],
): Promise<void> {
    
  const values: MessageList = AIvalues[0];
  const MatchedTechnicalSkills: string[] =
    values?.technicalSkills?.map((item) => item.skill) ?? [];
  const MatchedFunctionalSkills: string[] =
    values?.functionalSkills?.map((item) => item.skill) ?? [];
  const matchedExperience = findExperienceByValue(
    jobWorkExperiencesList,
    values?.yearsOfExperience ?? -1
  );
  const matchedJobType = findJobTypeByValue(
    jobTypesList,
    values?.jobAssignmentType ?? ""
  );
  const matchedEducation = findEducationByValue(
    jobRequireDegreesList,
    values?.education?.[0]?.degree ?? ""
  );
  

  const JobDetailsDecriptionValue = JobDetailsDescriptionWithHtml(values);
  const matchedWorkingLocationJobType = isRemoteOrHybrid(
    valueOrDefault(values?.jobType, "")
  );

  // Find and set Industry if matched
  findIndustry( valueOrDefault(values?.industry?.name,""),formRef);

  formRef.current?.setValues({
    ...formRef.current?.values,
    jobDetails: {
      ...formRef.current?.values?.jobDetails,
      jobTitle: valueOrDefault(values?.jobTitle, ""),
      jobDescription: valueOrDefault(JobDetailsDecriptionValue, ""),
      jobDomain: valueOrDefault(values?.domain?.name, ""),
      shiftTimingFrom: valueOrDefault(values?.jobShiftStart, "09:00"),
      shiftTimingTo: valueOrDefault(values?.jobShiftEnd, "17:00"),
      experience: valueOrDefault(values?.yearsOfExperience, undefined),
      requiredSkills: [...MatchedTechnicalSkills, ...MatchedFunctionalSkills],
      experienceId: matchedExperience?.id ?? "",
      ...(matchedEducation !== undefined && {
        requiredDegree: {
          id: matchedEducation.id ?? "",
          degreeName: matchedEducation.degreeName ?? "",
        },
      }),
      desiredCertifications: getCertificateNames(values?.certifications),
      isRemoteJob: matchedWorkingLocationJobType,
    },
    jobTypeAndRate: {
      ...formRef.current?.values?.jobTypeAndRate,
      jobTypeId: matchedJobType?.id ?? "",
      jobType: matchedJobType?.jobType ?? "",
    },
  });
}

const findIndustry = (searchKeyword:string,formRef:any)=>{
    fetchAndFindMatchingIndustry(searchKeyword)?.then(matchingIndustry => {      
      if (matchingIndustry) {
        formRef.current?.setFieldValue("jobDetails.jobIndustry",matchingIndustry?.industryName)
      }
    });
   }
