import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetTeamMembersFilter } from "../../Services/clientService";
import formatPhone from "../../Shared/utils/formatPhone";
import { PermissionsEnums } from "../../data/permission";
import { useSetTeamMemberStatusMutation } from "../../features/Client/teamMemberSlice";
import { toggleEdit } from "../../features/editMode/editModeSlice";
import useApi from "../../hooks/useApi";
import constructName from "../../utils/constructName";
import EditTeamPopup from "../ClientTeam/EditTeamPopup";
import SuccessSnackBarPopup from "../ClientTeam/components/Popup/successSnackBarPopup";
import { ModuleComponent } from "../Common/Permission/ModuleComponent";
import "./TeamsCard.scss";


interface TeamsCardProps {
  clientId?: string;
  editMode : boolean
}

export const TeamsCard: React.FC<TeamsCardProps> = ({ clientId,editMode }) => {
  const [teamService] = useSetTeamMemberStatusMutation();
  const [pageStatus, setPageStatus] = React.useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
    loading: false,
  });
  const [teaMembers, setTeamMembers] = React.useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [teamData, setTeamData] = React.useState({
    popup: false,
    teamId: "",
    isActive: false,
  });
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    status: boolean
  ) => {
    setAnchorEl(event.currentTarget);
    setTeamData({ ...teamData, teamId: id, isActive: status });
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
    setTeamData({ popup: false, teamId: "", isActive: false });
  };
  const handleEditTeam = () => {
    setTeamData({ ...teamData, popup: true });
  };
  const editState = useSelector((state:any) => state.editMode);

  const filter = {
    pageIndex: 1,
    pageSize: 50,
    sortColumn: "",
    sortOrder: 1,
    isSorted: true,
    totalRecord: 0,
    searchKeyword: "",
    locations: [],
    statuses: [],
    clientGuid: clientId,
  }
   
  const allIndustries = useApi(() => GetTeamMembersFilter(filter));
  React.useEffect(() => {
    if (clientId) {
      allIndustries?.request();
    }
  }, [clientId, editState.isEditable]);
  const onEditSuccess = () => {
    dispatch(toggleEdit());
    setTimeout(() => {
      handleClose();
    }, 2000);  };

  const handleTeamMemberStatus = (status: boolean, teamMemberId: string) => {
    handleClose();
    teamService({
      isActive: status,
      teamMemberId: teamMemberId,
    }).then((res :any )=>{
      
      if(res.data?.status === 200){
        setPageStatus({
          isSuccess: true,
          message: res.data?.message?.appStatusDescription,
        })
        setTeamMembers(
          teaMembers.map((n) => {
            if (n.id === teamMemberId) {
              return { ...n, isActive: status };
            } else {
              return n;
            }
          })
        );
      }
    })
  };

  React.useEffect(() => {
    if (allIndustries?.data)
      setTeamMembers(allIndustries.data?.entityList);
  }, [allIndustries?.data]);

  const _handleSuccessClose = ()=>{    
    setPageStatus({
      isSuccess: false,
      isError: false,
      message: "",
      loading: false,
    });
  }

  return (
    <>
      {teaMembers?.length == 0 && (
        <Alert severity="info">No Records Found</Alert>
      )}
      {teaMembers?.map((x: any) => {
        return (
          <Card key={x.id} sx={{ mb: 2, borderRadius: "12px" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={8} sm={8}>
                  <Typography className="team-name" fontSize={"28px"}>
                    {constructName(x.firstName, x.middleName, x.lastName)}
                  </Typography>
                  <Typography
                    className="client-formLable"
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                  >
                    {x.email}
                  </Typography>
                  <Typography className="client-formLable" color={"black"}>
                    <Grid container spacing={2}>
                      <Grid item xs={1} sm={1}>
                        <CallOutlinedIcon
                          style={{
                            color: "blue",
                            fontSize: "16px",
                            marginTop: "2px",
                          }}
                        />{" "}
                        {/* Add Phone icon */}
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        {formatPhone(x.primaryPhoneNumber)}
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {editMode && 
                <Grid item xs={1} sm={1}>
                  <Button
                    data-testid="basic-button"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                      handleClick(e, x?.id, x?.isActive)
                    }
                  >
                    <MoreVertIcon />
                  </Button>
                  <Menu
                    key={x.id}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <ModuleComponent moduleId={PermissionsEnums.Members_Edit}>
                      <MenuItem onClick={() => handleEditTeam()}>
                        Edit
                      </MenuItem>
                    </ModuleComponent>
                   <ModuleComponent moduleId={PermissionsEnums.Members_Verify}>
                      <MenuItem onClick={()=>handleTeamMemberStatus(!teamData.isActive, teamData.teamId)}>
                        {teamData.isActive
                          ? "Inactive"
                          : "Active"}
                      </MenuItem>
                   </ModuleComponent>
                  </Menu>
                </Grid>}
              </Grid>
            </CardContent>
          </Card>
        );
      })}
      <EditTeamPopup
        onSuccess={onEditSuccess}
        teamMemberId={teamData.teamId}
        openPopup={teamData.popup}
        handlePopupClose={handleClose}
      />
      
      {pageStatus?.isSuccess &&
              <SuccessSnackBarPopup open={pageStatus?.isSuccess} message={pageStatus?.message} onClose={_handleSuccessClose} />
              }
    </>
  );
};
