import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import {
  GetEffectiveCompensation,
  SetCompensationPercent,
} from "../../../Services/invoiceManagerService";
import { GetRecruiterById } from "../../../Services/recruiterService";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { onlyNumberRegex } from "../../../constants/regex";
import { editModeKey } from "../../../data/editModeKey/editModeKey";
import { PermissionsEnums } from "../../../data/permission";
import {
  enableIndividualEdit
} from "../../../features/editMode/editModeSlice";
import { CommissionTypes } from "../../../models/interfaces";
import { AppState } from "../../../store/AppState";
import isObjectEmpty from "../../../utils/isObjectEmpty";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import {
  PhoneVerifyPopup,
  VerifyNumberSelector,
} from "../../Common/PhoneVerify/PhoneVerify";
import Paper from "@mui/material/Paper";
import { CustomAlert } from "../../ui/CustomAlert";
import { RecruiterAutocomplete } from "../../ui/RecruiterAutocomplete";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import { ternary } from "../../../utils/complexityUtils";
import { ChangeTracker } from "./ChangeTracker";
const labelStyle = {
  color: "black",
  paddingTop: "12px",
};
export const CompensationForm = ({ isIndividual, editMode }: any) => {
  const { features, modules } = useSelector(
    (state: AppState) => state.userPermissions
  );
  const combinedArray = [...features, ...modules];
  const permissionsArray: PermissionsEnums[] = combinedArray || [];
  const viewMode = !permissionsArray.includes(
    PermissionsEnums.Compensation_Update
  );
  const [searchParams] = useSearchParams();

  const { recruiterData }: any = useSelector((state) => state.recruiter);
  let recruitersFromParams =
    searchParams.get("recruiterGuid") === null
      ? null
      : searchParams.get("recruiterGuid");

  const percentValidation = Yup.number()
    .required("Please enter commission")
    .typeError("Please enter commission")
    .min(0, "Min value 0")
    .max(100, "Max value 100");
  const dispatch = useDispatch();
  let userDetails = getUserDetails();
  const [selectedRecruiter, setselectedRecruiter] = useState({});
  const [reloadChangeTracker, setreloadChangeTracker] = useState(Date.now());
  const [pageStatus, setPageStatus]: any = useState({
    isSuccess: false,
    error: "",
    loading: false,
    data: null,
  });
  const [getCompensation, setgetCompensation] = useState({
    pageIndex: 1,
    pageSize: 20,
    sortColumn: "",
    sortOrder: "Ascending",
    isSorted: false,
    totalRecord: 0,
    searchKeywords: "",
    compensationTypes: [],
    recruiterGuid: "",
    trackerListType: "AllRecruiter",
  });

  const handleChange = (event: any) => {
    const result = event.target.value.replace(/\D/g, "");

    formik.setFieldValue(event.target.name, parseInt(result));
  };

  const validationSchema = Yup.object({
    recruiterGuid: isIndividual
      ? Yup.string().required("Please select Recruiter's name")
      : Yup.string(),

    compensation: Yup.array(
      Yup.object({
        commissionPercentage: percentValidation,
        paymentTerm: Yup.number()
          .required("Please select a payment term")
          .typeError("Please select a payment term"),
        payrollOverhead: percentValidation,
      })
    ),
  });
  let currentDate = new Date();

  const defaultDirectContract = {
    compensationType: CommissionTypes.DirectContract,
    commissionPercentage: 0 as any,
    paymentTerm: 0,
    payrollOverhead: 1,
    createdDate: currentDate,
    recruiterGuid: "",
    createdBy: userDetails.email,
    recruiterFirstName: "",
    recruiterMiddleName: "",
    recruiterLastName: "",
    recruiterEmail: "",
  };
  const defaultW2Employee = {
    compensationType: CommissionTypes.W2_Employee,
    commissionPercentage: 0 as any,
    paymentTerm: 0,
    payrollOverhead: 0,
    createdDate: currentDate,
    recruiterGuid: "",
    createdBy: userDetails.email,
    recruiterFirstName: "",
    recruiterMiddleName: "",
    recruiterLastName: "",
    recruiterEmail: "",
  };
  const defaultFullTime = {
    compensationType: CommissionTypes.FullTime,
    commissionPercentage: 0 as any,
    paymentTerm: 0,
    payrollOverhead: 0,
    createdDate: currentDate,
    recruiterGuid: "",
    createdBy: userDetails.email,
    recruiterFirstName: "",
    recruiterMiddleName: "",
    recruiterLastName: "",
    recruiterEmail: "",
  };

  const defaultOneTimeCommission = {
    compensationType: CommissionTypes.OneTimeCommission,
    commissionPercentage: 0 as any,
    paymentTerm: 0 as any,
    payrollOverhead: 0,
    createdDate: currentDate,
    recruiterGuid: "",
    createdBy: userDetails.email,
    recruiterFirstName: "",
    recruiterMiddleName: "",
    recruiterLastName: "",
    recruiterEmail: "",
    oneTimeCommission: 0,
  };

  const initialState = {
    recruiterGuid: "",
    compensation: [
      defaultDirectContract,
      defaultW2Employee,
      defaultFullTime,
      defaultOneTimeCommission,
    ],
  };
  const [opendialog, setOpendialog] = useState(false);
  const [recruiterInfo, setrecruiterInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
  });

  const [opendialogSelectNumber, setOpenDialogSelectNumber] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(
    process.env.REACT_APP_PRIMARY_NUMBER
  );
  const props_VerifyNumberSelector = {
    ...{
      isOpen: opendialogSelectNumber,
      popupTitle:
        "To save the compensation changes, please select a number to verify with OTP.",
      phoneNumbers: {
        primary: process.env.REACT_APP_PRIMARY_NUMBER,
        secondary: process.env.REACT_APP_SECONDARY_NUMBER,
      },
      selectedNumber:
        selectedNumber === undefined || selectedNumber === ""
          ? recruiterData?.entity?.phoneNumbers?.primary
          : selectedNumber,
      selectedValue: (selectedVal: any) => {
        setSelectedNumber(selectedVal);
      },
      isProceedBtnClick: () => {
        setOpenDialogSelectNumber(false);
        setOpendialog(true);
      },
      setIsNumberSelectorOpen: () => {
        setOpenDialogSelectNumber(false);
      },
    },
  };
  const props_phVerifyPopup = {
    ...{
      phoneNumber: selectedNumber,
      isPrimaryPhone: true,
      isShowTitle: false,
      verifyOTPTitle:
        "To save the compensation changes, please enter OTP below.",
      otherPhoneVerifyStatus: true,
      updatePhoneVerify: (isVerified: any) => {
        if (isVerified) {
          setOpendialog(false);
          formik.handleSubmit();
        }
      },
      isOpen: opendialog,
      setClose: setOpendialog,
    },
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setPageStatus({ isSuccess: false, error: "", loading: true, data: null });
      try {
        let submitData = values;
        submitData.compensation.forEach((comp) => {
          comp.recruiterGuid = values.recruiterGuid;
          comp.recruiterFirstName = recruiterInfo.firstName;
          comp.recruiterMiddleName = recruiterInfo.middleName;
          comp.recruiterLastName = recruiterInfo.lastName;
          comp.recruiterEmail = recruiterInfo.email;
        });
        values.compensation[3].oneTimeCommission = ternary(
          isNaN(values.compensation[3].oneTimeCommission) || values.compensation[3].oneTimeCommission === "",
          0,
          values.compensation[3].oneTimeCommission
        ) 
        const response = await SetCompensationPercent(values);
        if (response.data.status == 200) {
          window.scrollTo(0, 0);
          setPageStatus({
            isSuccess: true,
            error: "",
            loading: false,
            data: response.data,
          });
          setreloadChangeTracker(Date.now());
        } else {
          setPageStatus({
            isSuccess: false,
            error: response.data?.message?.appStatusDescription,
            loading: false,
            data: null,
          });
        }
      } catch (er: any) {
        setPageStatus({
          isSuccess: false,
          error: er.message,
          loading: false,
        });
      }
    },
  });

  const handleRecruiterChange = (e: any, value: any) => {
    setselectedRecruiter(value);
    if (value?.id !== undefined) {
      formik.setFieldValue("recruiterGuid", value?.id);
      setrecruiterInfo({
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        email: value.email,
      });
      setgetCompensation({
        ...getCompensation,
        trackerListType: "IndividualRecruiter",
        recruiterGuid: value?.id,
      });
    } else {
      formik.setFieldValue("recruiterGuid", "");

      setgetCompensation({
        ...getCompensation,
        trackerListType: "AllRecruiter",
        recruiterGuid: "",
      });
    }
  };
  const setIntitalPayrollOverhead = () => {
    formik.setFieldValue("compensation[0].payrollOverhead", 1);
    formik.setFieldValue("compensation[2].payrollOverhead", 1);
  };
  useEffect(() => {
    isIndividual
      ? dispatch(
          enableIndividualEdit({
            pageName: editModeKey.IndividualRecruiter,
          })
        )
      : dispatch(enableIndividualEdit({ pageName: editModeKey.AllRecruiter }));
  }, []);
  useEffect(() => {
    if (recruitersFromParams !== null) {
      GetRecruiterById(recruitersFromParams).then((x) => {
        handleRecruiterChange("", x.data.entity);
      });
    }

    return () => {};
  }, []);

  const loadCompensation = ()=>{
    GetEffectiveCompensation(getCompensation)?.then((response) => {
      const paymentDetails = response?.data?.entityList;
      if (paymentDetails?.length > 0) {
        let fullTime =
          paymentDetails?.find(
            (n: any) => n && n.compensationType === CommissionTypes.FullTime
          ) ?? defaultFullTime;
        let w2Employee =
          paymentDetails?.find(
            (n: any) => n && n.compensationType === CommissionTypes.W2_Employee
          ) ?? defaultW2Employee;
        let directContract =
          paymentDetails?.find(
            (n: any) =>
              n && n.compensationType === CommissionTypes.DirectContract
          ) ?? defaultDirectContract;
        let OneTimeCommission =
          paymentDetails?.find(
            (n: any) =>
              n && n.compensationType === CommissionTypes.OneTimeCommission
          ) ?? defaultOneTimeCommission;

        formik.setFieldValue("compensation", [
          directContract,
          w2Employee,
          fullTime,
          OneTimeCommission,
        ]);
      } else {
        formik.setFieldValue("compensation", initialState.compensation);
      }
    });
  }

  useEffect(() => {
    loadCompensation()
  }, [getCompensation.recruiterGuid]);

  return (
    <div>
      {pageStatus.error && (
        <CustomAlert message={"Something went wrong"} type={"error"} />
      )}
      {pageStatus.isSuccess && (
        <SnackbarPopup
        open={pageStatus.isSuccess}
        message={pageStatus.data?.message?.appStatusDescription} 
        onClose={() => {
          setPageStatus({ ...pageStatus, isSuccess: false });
        }}
      />
        
      )}

     <Paper elevation={1} sx={{padding:2}} >
    
     <Grid container spacing={0}>
  <Grid item xs={12} md={12}>
    {editMode && (
      <Box display="flex" justifyContent="flex-end">
        <ModuleComponent moduleId={PermissionsEnums.Compensation_Update}>
          <Button
            data-testid="cancel-button"
            onClick={() => {
              loadCompensation()
            }}
            variant="outlined"
            style={{ marginRight: "15px" }}
          >
            Cancel
          </Button>
        </ModuleComponent>
        <ModuleComponent moduleId={PermissionsEnums.Compensation_Update}>
          <Button
            data-testid="submit-button"
            disabled={formik.isSubmitting}
            endIcon={
              formik.isSubmitting ? (
                <CircularProgress size={"16px"} color={"inherit"} />
              ) : (
                ""
              )
            }
            onClick={() => {
              setIntitalPayrollOverhead();
              if (formik.isValid) {
                setOpenDialogSelectNumber(true);
              } else {
                formik.handleSubmit();
              }
            }}
            variant="contained"
          >
            Submit
          </Button>
        </ModuleComponent>
      </Box>
    )}
  </Grid>
</Grid>

     
      <Box component="form" noValidate autoComplete="off">
        {isIndividual && (
          <Grid container spacing={0}>
            <Grid item xs={12} md={2.5}>
              <Typography
                variant="h6"
                component="div"
                className="primary-color-text recruiterNameLabel"
              >
                Select Recruiter Here <Asterisk />{" "}
              </Typography>
            </Grid>

            <Grid style={{ paddingTop: "0px" }} item xs={12} md={3.5}>
              <RecruiterAutocomplete
                label={"Type a name here"}
                recruiter={selectedRecruiter}
                onChange={handleRecruiterChange}
                onBlur={formik.handleBlur}
                multiple={false}
              />
              <ErrorMessage
                errorText={
                  formik.touched.recruiterGuid && formik.errors?.recruiterGuid
                }
              />

              <br />
            </Grid>
          </Grid>
        )}
        <Typography variant="h6" component="div" className="primary-color-text">
          Direct Contract (C2C)
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.5}>
            <InputLabel style={labelStyle}>
              Commission(%) <Asterisk />{" "}
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <FormControl
              data-testid="directCommissionPercentage"
              variant="standard"
              style={{ paddingBottom: "12x" }}
              className="state-input"
              fullWidth
              required
              error={
                formik.touched.compensation?.[0]?.commissionPercentage &&
                Boolean(formik.errors.compensation?.[0]?.commissionPercentage)
              }
            >
              <Input
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.compensation[0]?.commissionPercentage}
                readOnly={viewMode}
                name="compensation[0].commissionPercentage"
                type="number"
              />
              <ErrorMessage
                errorText={
                  formik.touched.compensation?.[0]?.commissionPercentage &&
                  formik.errors.compensation?.[0]?.commissionPercentage
                }
              />
            </FormControl>

            <Typography
              variant="subtitle1"
              component="span"
              className=""
              sx={{
                fontSize: "0.75rem",
                textAlign: "right",
              }}
            >
              Calculation :
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className=""
              sx={{
                fontSize: "0.75rem",
                textAlign: "right",
              }}
            >
              (Net Bill Rate - Payrate)*Commission% above
            </Typography>
          </Grid>
          
        </Grid>
      </Box>
      {/* Contract Role */}
      <Box component="form" noValidate autoComplete="off">
        <Typography variant="h6" component="div" className="primary-color-text">
          Contract (W2)
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.5}>
            <InputLabel style={labelStyle}>
              Payroll Overhead(%) <Asterisk />{" "}
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <FormControl
              variant="standard"
              className="state-input"
              fullWidth
              required
              error={
                formik.touched.compensation?.[1]?.payrollOverhead &&
                Boolean(formik.errors.compensation?.[1]?.payrollOverhead)
              }
            >
              <Input
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.compensation[1]?.payrollOverhead}
                readOnly={viewMode}
                name="compensation[1].payrollOverhead"
                type="number"
              />
              <ErrorMessage
                errorText={
                  formik.touched.compensation?.[1]?.payrollOverhead &&
                  formik.errors.compensation?.[1]?.payrollOverhead
                }
              />
            </FormControl>
            <br />
          </Grid>
          
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.5}>
            <InputLabel style={labelStyle}>
              Commission(%) <Asterisk />{" "}
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <FormControl
              variant="standard"
              className="state-input"
              fullWidth
              required
              error={
                formik.touched.compensation?.[1]?.commissionPercentage &&
                Boolean(formik.errors.compensation?.[1]?.commissionPercentage)
              }
            >
              <Input
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.compensation[1]?.commissionPercentage}
                readOnly={viewMode}
                name="compensation[1].commissionPercentage"
                type="number"
              />
              <ErrorMessage
                errorText={
                  formik.touched.compensation?.[1]?.commissionPercentage &&
                  formik.errors.compensation?.[1]?.commissionPercentage
                }
              />
            </FormControl>
            <br />
            <Typography
              variant="subtitle1"
              component="span"
              className=""
              sx={{
                fontSize: "0.75rem",
                textAlign: "right",
              }}
            >
              Calculation : (Net Bill Rate - ( Payrate + Payrate*Payroll
              Overhead)) * Commission% above
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* Full Time Duration */}
      <Box component="form" noValidate autoComplete="off">
        <Typography variant="h6" component="div" className="primary-color-text">
          Full Time
        </Typography>
        <br />
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.5}>
            <InputLabel style={labelStyle}>
              Commission(%) <Asterisk />{" "}
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <FormControl
              variant="standard"
              className="state-input"
              fullWidth
              required
              error={
                formik.touched.compensation?.[2]?.commissionPercentage &&
                Boolean(formik.errors.compensation?.[2]?.commissionPercentage)
              }
            >
              <Input
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.compensation[2]?.commissionPercentage}
                readOnly={viewMode}
                name="compensation[2].commissionPercentage"
                type="number"
              />
              <ErrorMessage
                errorText={
                  formik.touched.compensation?.[2]?.commissionPercentage &&
                  formik.errors.compensation?.[2]?.commissionPercentage
                }
              />
            </FormControl>
            <br />
            <Typography
              variant="subtitle1"
              component="span"
              className=""
              sx={{
                fontSize: "0.75rem",
                textAlign: "right",
              }}
            >
              Calculation :
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className=""
              sx={{
                fontSize: "0.75rem",
                textAlign: "right",
              }}
            >
              (Payrate)*Commission% above
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={4}>
          
          </Grid>

          <Grid item xs={12} sm={2.5} mt={2}>
            <InputLabel
              id="clientFee-select-filled-label"
              shrink={false}
              style={labelStyle}
            >
              One Time Commission(USD){" "}
            </InputLabel>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3} xl={3} mt={2}>
            
            <Input
                onChange={(event:any)=>{

                  if (onlyNumberRegex?.test(event.target.value)) {
                    formik?.setFieldValue("compensation[3].oneTimeCommission",event.target.value);
                  }
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  // Prevent entering 'e' or 'E'
                  if (event.key.toLowerCase() === 'e' || event.key.toLowerCase() === '.') {
                    event.preventDefault();
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.compensation[3]?.oneTimeCommission}
                readOnly={viewMode}
                name="compensation[3].oneTimeCommission"
                type="number"
                fullWidth
              />
          </Grid>
        </Grid>
      </Box>
      </Paper>
      <ChangeTracker
        key={reloadChangeTracker}
        selectedRecruiter={
          isObjectEmpty(selectedRecruiter) ? null : selectedRecruiter
        }
      />

      <PhoneVerifyPopup {...props_phVerifyPopup}></PhoneVerifyPopup>
      <VerifyNumberSelector
        {...props_VerifyNumberSelector}
      ></VerifyNumberSelector>
    </div>
  );
};
CompensationForm.defaultProps = {
  isIndividual: false,
};
