import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
} from '@mui/material';
import { getOptionLabel } from '../../../../utils/StringUtil';
import { getJobWorkExperience } from '../../../../Services/jobService';

export interface workExperience {
    id: string;
    experienceValue: string;
    experience: string;
  }
  
type ExperienceList = workExperience[];

interface ExperienceAutocompleteProps {
  name?: string;
  required?:boolean;
  handleBlur:any;
  handleOnChange:(value:any)=>void;
  setFieldValue:any;
  selectedValue:any;
  touched:boolean;
  errors:string;
  placeholder?:string;
  fieldLabel?:string;
}

const ExperienceAutocomplete: React.FC<ExperienceAutocompleteProps> = ({
  name,
  required=false,
  handleBlur,
  handleOnChange,
  setFieldValue,
  selectedValue,
  touched,
  errors,
  placeholder="Select Experience",
  fieldLabel = "Experience"
}) => {

    const [jobWorkExperiencesList, setJobWorkExperiencesList] = useState<ExperienceList | any>([]);

    useEffect(()=>{
        _GetJobWorkExperinecesList();
    },[])
    
    // Get Master Job work experiences
  const _GetJobWorkExperinecesList = () => {
    getJobWorkExperience()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkExperiencesList(response?.data?.entityList);
        } else {
          setJobWorkExperiencesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Autocomplete
      className=""
      id="tags-standard"
      disableClearable={true}
      options={jobWorkExperiencesList.map((x:workExperience) => x)}
      getOptionLabel={(option) => getOptionLabel(option.id,jobWorkExperiencesList)}
      value={selectedValue}
      onChange={(_, experienceId) => {
        handleOnChange(experienceId)
        if (experienceId) {            
            let value = jobWorkExperiencesList?.find((item: any) => item?.id === experienceId)
            setFieldValue("experience", value?.experienceValue);
            setFieldValue("experienceId", experienceId);
            setFieldValue("experienceText", value?.experience);
            
          } else {
            setFieldValue("experience", '');
            setFieldValue("experienceId", "");
            setFieldValue("experienceText", "");
          }
      }}
      onBlur={handleBlur}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="primary"
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          {...params}
          variant="standard"
          label={fieldLabel}
          data-testid="autocomplete-experience"
          error={
            touched &&
            Boolean(errors)
          }
          helperText={
            touched &&
            errors
          }
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default ExperienceAutocomplete;
