import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { ClientAutocomplete } from "../../../../ui/ClientAutocomplete";
import { ErrorMessage } from "../../../../ui/ErrorMessage";

interface ClientDetailsFormSectionProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTrue?: boolean) => void;
  errors: any;
  touched: any;
  handleClientAutocompleteChange: (value: any)=> void,
  handleClientClear: () => void
}

const ClientDetailsFormSection: React.FC<ClientDetailsFormSectionProps> = ({
  values,
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  handleClientAutocompleteChange,
  handleClientClear
}) => {

  return (
    <Paper
      elevation={0}
      style={{ padding: "16px 8px 24px 8px", background: "#fff" }}
    >
      <Grid container spacing={2}>
        {/* Row 1 */}
        <Grid item xs={12}>
          <Typography variant="h6" className="formSectionHeading">Client Details</Typography>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12} sm={6}>
          <ClientAutocomplete
            getListUserStatus= {["Pending","Approved"]}
            isReiqured={true}
            showEmail={true}
            multiple={false}
            label={"Select Client"}
            admin={values.selectedClientDetails}
            onChange={(e: any, value: any) => {
              setFieldValue("selectedClientDetails",value);
              setFieldValue("clientId", value?.id);
              handleClientAutocompleteChange(value);
            }}
            onBlur={(e:any)=>{
              setFieldTouched("clientId",true);
            }}
            readOnly={false}
            handleClear={handleClientClear}
          />
          <ErrorMessage
                errorText={
                  touched?.clientId &&
                  errors?.clientId
                }
              />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientDetailsFormSection;
