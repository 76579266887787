import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert,
} from "@mui/material";
import convertToLocalizedDateTime from "../../../../../utils/AppDateUtils";
import AppInfiniteScroll from "../../../../Common/AppInfiniteScroll";
interface ClientChangeTrackerTableProps {
    dataList: any;
    fetchMore: () => void;
    isLoading: boolean;
    hasMore: boolean;
}



const ClientChangeTrackerTable: React.FC<ClientChangeTrackerTableProps> = ({
    dataList,
    fetchMore,
    hasMore,
    isLoading,
}) => {
     
  const commissionDisplay = (row: any) => {
    if (row.jobRialtoCommission !== null) {
      if (row?.jobRialtoCommissionType === "Percentage") {
        return row?.jobRialtoCommission + "%";
      } else {
        return row?.jobRialtoCommissionType + " " + row?.jobRialtoCommission;
      }
    } else {
      return "NA";
    }
  };

  const getRecruiterCommissionDisplay = (row:any) => {
    let commissionDisplay = "";
  
    if (row.recruiterCommission !== null) {
      if (row?.recruiterCommissionType === 'Percentage') {
        commissionDisplay = `${row.recruiterCommission + "%"}`
      } else {
        commissionDisplay = `${row?.recruiterCommissionType} ${row?.recruiterCommission}`;
      }
    } else {
      commissionDisplay = "NA";
    }
  
    return commissionDisplay;
  };
  

  return (
    <TableContainer>
        <AppInfiniteScroll
        dataLength={dataList?.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={true}
        isLoading={isLoading}
        height="460px"
      >
      <Table stickyHeader aria-label="sticky table" className="clientChangeTrackerListTable"  >
        <TableHead>
          <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>Account Manager</TableCell>
            <TableCell>Job Type</TableCell>
            <TableCell>Tax Terms</TableCell>
            <TableCell>Client Fee</TableCell>
            <TableCell>JobRialto Margin</TableCell>
            <TableCell>Recruiter Commission</TableCell>
            <TableCell>Payroll Overhead</TableCell>
            <TableCell>Client Payment Terms</TableCell>
            <TableCell>Client Payment Mode</TableCell>
            <TableCell>One Time Commission</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Modified By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.map((row: any, index: any) => (
            <TableRow key={ row?.id ?? index} className="hover-row v2-table-row">
              <TableCell>{row.clientName || "N/A"}</TableCell>
              <TableCell>{row.accountManagerDetails?.fullName || "N/A"}</TableCell>
              <TableCell>{row.jobType || "N/A"}</TableCell>
              <TableCell>{row.jobTaxTerm || "NA"}</TableCell>
              <TableCell>
                <span className="noWordWrap">{row?.clientFeeType === "Percentage" ? (row?.clientFee || 0) + "%" : `USD ${row?.clientFee || 0 }`}</span>
              </TableCell>
              <TableCell>
                {commissionDisplay(row)}
              </TableCell>
              <TableCell>
                {getRecruiterCommissionDisplay(row)}
              </TableCell>
              <TableCell>
                {row?.payrollOverhead !== null
                  ? `${row?.payrollOverhead }%`
                  : "NA"}
              </TableCell>
              <TableCell>{row?.paymentTerms || "NA"}</TableCell>
              <TableCell>{row?.paymentMode || "NA"}</TableCell>
              <TableCell>
                {row?.oneTimeCommission !== null
                  ? `USD ${row?.oneTimeCommission }`
                  : "NA"}
              </TableCell>
              <TableCell>
                <span>
                    <span className="createdByNameText">{row?.createdByName}</span>
                    <br />
                    <span className="modifyDateText">
                    {convertToLocalizedDateTime(
                                row?.createdDate,
                                "MMM DD, YYYY,  h:mm A"
                            )}
                            </span>
                </span>
                </TableCell>
                <TableCell>
                  {
                    (row?.modifiedByName && row?.modifiedDate ) ?                  
                    <span>
                        <span className="createdByNameText">{row?.modifiedByName}</span>
                        <br />
                        <span className="modifyDateText">
                        {convertToLocalizedDateTime(
                                    row?.modifiedDate,
                                    "MMM DD, YYYY,  h:mm A"
                                )}
                                </span>
                    </span>
                    : "NA"
                  }
                </TableCell>
            </TableRow>
          ))}
       
            {isLoading && (
                <TableRow>
                    <TableCell colSpan={12}>
                    <p
                    style={{
                        marginTop: "50px",
                        marginBottom: "4px",
                        textAlign: "center",
                    }}
                    >
                    Please wait...
                    </p>
                    </TableCell>
                </TableRow>
            )}
            {!isLoading && dataList?.length === 0 && (
                <>
                 <TableRow>
                    <TableCell colSpan={12}>
                        <Alert severity="info" style={{ marginTop: "5px" }}>
                            No Records Found
                        </Alert>
                    </TableCell>
                </TableRow>
            </>
            )}
            
        </TableBody>
      </Table>
      </AppInfiniteScroll>
    </TableContainer>
  );
};

export default ClientChangeTrackerTable;
