import { CircularProgress, Paper, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GotoTop } from "../../ui/GotoTop";
import { CircularProgressWithLabel } from "../../Common/CircularProgressWithLabel";
import AppSort from "../../Common/AppSort";
import { ternary } from "../../../utils/complexityUtils";

interface IPROPS {
  jobDataList: any[];
  totalRecords?: number;
  hasMoreData: any;
  loading: any;
  recommendationData: any[];
  sort: any;
  handleColumnSort: any;
  onViewScoreCard: (selectedItem: any) => void;
  scorecardPopup?: any;
}

export const FindCandidateRow: React.FunctionComponent<IPROPS> = (props) => {

  return (
    <>
      <TableContainer component={Paper}>
        {/* <AppInfiniteScroll
          dataLength={100}
          next={props?.fetchMoreData}
          hasMore={
            true
          }
          isLoading={props?.loading}
          loader={true}
          classNames=""
        > */}
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "400px",
            marginBottom: "10px",
          }}
        >
          Search Results: {props?.totalRecords} Recommended Candidates Found
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: "1",
            width: "100%",
            background: "#4540DB",
          }}
        />

        <Table className="candidateListTable" aria-label="simple table">
          <TableHead className="custom-Client-fontsizes">
            <TableRow>
              <TableCell sx={{ px: 1 }}>
                <span
                  data-testid="sort-icon-title"
                  onClick={() => props.handleColumnSort("eximScore")}
                  style={{ display: "flex" }}
                >
                  Score
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={(props.sort?.sortBy !== "eximScore")}
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell sx={{ whiteSpace: "noWrap", px: 1 }}>
                <span
                  data-testid="sort-icon-title"
                  onClick={() => props.handleColumnSort("resumeData.userName")}
                  style={{ display: "flex" }}
                >
                  Name
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={(props.sort?.sortBy !== "resumeData.userName")}
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell sx={{ px: 1 }}>Phone </TableCell>
              <TableCell sx={{ px: 1 }}>Email </TableCell>
              <TableCell>Position</TableCell>
              <TableCell sx={{ px: 1 }}>
                <span
                  data-testid="sort-icon-title"
                  onClick={() =>
                    props.handleColumnSort(
                      "resumeData.yearsOfExperienceInNumbers"
                    )
                  }
                  style={{ display: "flex" }}
                >
                  Experience
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={
                      (
                        props.sort?.sortBy !==
                        "resumeData.yearsOfExperienceInNumbers"
                      )
                    }
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell sx={{ px: 1, whiteSpace: "noWrap" }}>
                Location
              </TableCell>
              <TableCell sx={{ px: 1 }}>Distance </TableCell>
              <TableCell style={{ paddingTop: "16px" }}>
                {" "}
                <span
                  data-testid="sort-icon-title"
                  onClick={() =>
                    props.handleColumnSort("resumeData.packageRate.annualRate")
                  }
                  style={{ display: "flex" }}
                >
                  Annual
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={(props.sort?.sortBy !== "resumeData.packageRate.annualRate")}
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell style={{ paddingTop: "16px" }}>
                <span
                  data-testid="sort-icon-title"
                  onClick={() =>
                    props.handleColumnSort("resumeData.packageRate.hourlyRate")
                  }
                  style={{ display: "flex" }}
                >
                  Hourly
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={(props.sort?.sortBy !== "resumeData.packageRate.hourlyRate")}
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell style={{ paddingTop: "16px" }}>Resume </TableCell>
              <TableCell style={{ paddingTop: "16px" }}>Score Card </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.recommendationData?.map((row: any, index: any) => (
              <TableRow
                hover
                key={row?.jobId ?? index}
                className="custom-hover-bgcolor"
              >
                <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}>
                  <Typography
                    style={{ wordBreak: "break-all" }}
                    fontSize={"0.2rem"}
                  >
                    <CircularProgressWithLabel
                      value={Math.round(row?.eximScore)}
                    />
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: 1, width: 150 }}>
                  {row?.resumeData?.userName}
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                <span>{ternary(row?.resumeData?.contactNumber , row.resumeData.contactNumber , "NA")}</span>
                </TableCell>
                <TableCell sx={{ px: 1, width: 250 }}>
                  {row?.resumeData?.emailAddress}
                </TableCell>
                <TableCell sx={{ px: 1, width: 150 }}>
                  {ternary(row?.resumeData?.jobPosition , row?.resumeData?.jobPosition , "NA")}
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  {ternary(row?.resumeData?.yearsOfExperienceInNumbers , row?.resumeData?.yearsOfExperienceInNumbers , "NA")}
                </TableCell>
                <TableCell sx={{ px: 1, width: 150 }}>
                  {ternary(row?.resumeData?.location , row?.resumeData?.location , "NA")}
                </TableCell>
                <TableCell sx={{ px: 1, width: 70 }}>
                  {Math.round(ternary(row?.resumeData?.differenceRadius , row?.resumeData?.differenceRadius , "NA"))}
                </TableCell>

                <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 70 }}>
                  {ternary(row?.resumeData?.packageRate?.annualRate > 0 , row?.resumeData?.packageRate?.annualRate , "NA")}
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 70 }}>
                  {ternary(row?.resumeData?.packageRate?.hourlyRate >= 0 , row?.resumeData?.packageRate?.hourlyRate , "NA") }
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  <Typography
                    data-testid="download-btn"
                    color={"#4540DB"}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(row?.resumeData?.blobStoragePath, "_blank");
                    }}
                  >
                    download
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  <Box sx={{ display: "flex", justifyContent: "left" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="jobs-button jobdetail "
                      sx={{ margin: "0 0 0 0 !important" }}
                      size="small"
                      onClick={() => {
                        props.onViewScoreCard(row);
                      }}
                      data-testid="view-scorecard"
                      disabled={props?.scorecardPopup?.isLoading}
                    >
                      View 
                      {props?.scorecardPopup?.email ===
                        row?.resumeData.emailAddress &&
                        props?.scorecardPopup?.isLoading && (
                          <CircularProgress size={"16px"} color={"inherit"} />
                        )}
                    </Button>{" "}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* </AppInfiniteScroll> */}
        {props?.loading && (
          <p
            style={{
              marginTop: "50px",
              marginBottom: "4px",
              textAlign: "center",
            }}
          >
            Please wait...
          </p>
        )}
        {props?.loading && props?.totalRecords !== 0 && (
          <Alert severity="info" style={{ marginTop: "5px" }}>
            No Records Found
          </Alert>
        )}
      </TableContainer>
      <div className="gotoTopBtn">
        <GotoTop />
      </div>
    </>
  );
};
