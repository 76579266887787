import { Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import "./../popup.scss";


interface Props {
  isOpen: boolean;
  handleOnCancel: any;
  handleOnMoveToPending:any;
  loading:boolean;
}
const PendingJobFeebackPopup :React.FC<Props> = ({ 
    isOpen, 
    handleOnCancel,
    handleOnMoveToPending,
    loading 
}) => {

    const [commentValue, setCommentValue] = useState<string>('');
    const [fieldError, setFieldError] = useState<boolean>(false);
    const maxCharacters = 1000;

    const handleCloseDialog = () => {
        handleOnCancel();
      };
    
      const handleChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCommentValue(event.target.value);
        if(event.target.value?.length > 0){
          setFieldError(false)
        }
      };

      const _handleBtnOnMoveToPending = () =>{
        if(commentValue?.length > 0){
          handleOnMoveToPending(commentValue)
          
        }else{          
          setFieldError(true);
        }
      }

  return (
    <div>
      <Dialog
        className="custom-client-popup"
        open={isOpen}
        aria-labelledby="pop-up-title"
        fullWidth={true}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end",padding:"16px 16px 0 16px" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "center" }}>
          </Typography>
          <Typography className="popup-heading-text">
            Add Feedback
          </Typography>
          <Typography className="feebackCommentInpWrapper">
            <Box className="commentCntLabel">Remaining characters: {maxCharacters - commentValue.length}/{maxCharacters}</Box>
            <TextField
                label=""
                placeholder="Add Feedback here"
                className="pendingFeebackComment"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                value={commentValue}
                onChange={handleChangeTextArea}
                error={fieldError}
                helperText={fieldError
                  ? 'Please enter your feedback.'
                  : `Maximum limit allowed is ${maxCharacters} characters.`}
                inputProps={{ maxLength: maxCharacters }}
            />
          </Typography>
          <Box
          style={{
            display:"flex",
            alignContent:"center",
            justifyContent:"right"
          }}
          >
          <Button
            type="button"
            name="cancel"
            value="saveandcontinue"
            variant="outlined"
            color="primary"
            onClick={handleCloseDialog}
            style={{marginRight:"15px"}}
            className="custBtnPrimaryV2"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            name="movetopending"
            value="movetopending"
            variant="contained"
            color="primary"
            className="custBtnPrimaryV2"
            onClick={_handleBtnOnMoveToPending}
            disabled={loading}
            data-testid="popupMovetopending"
          >
            Move to Pending 
          </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PendingJobFeebackPopup;
