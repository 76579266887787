import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { getJobMasterDomains } from "../../../../../Services/jobService";
import { JobDomain } from "../../../../../models/Job/JobDomain";

interface RadioGroupFieldProps {
    required?:boolean;
    shrink?:boolean;
    name: string;
    label: string;
    value: string;
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    isError?: boolean;
    helperText?: string;
}

const DomainRadioSelection: React.FC<RadioGroupFieldProps> = ({
    shrink=true,
    required=false,
    name,
    label,
    value,
    handleOnChange,
    isError,
    helperText,
}) => {
  const [jobDomainsList, setJobDomainsList] = useState<JobDomain[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const _GetJobDomains = async () => {
    setLoading(true);
    getJobMasterDomains()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobDomainsList(response?.data?.entityList);
        } else {
          setJobDomainsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  useEffect(() => {
    _GetJobDomains();
  }, []);

  return (
    <>
      <InputLabel shrink={shrink} required={required}>{label}</InputLabel>
      {
      loading ?
      <CircularProgress size={18}/>
      :
      <RadioGroup 
        row 
        name={name} 
        value={value} 
        onChange={handleOnChange} 
        data-testid="autocomplete-domain"
        className="FieldRadio"
      >
        {jobDomainsList.map((option:JobDomain) => (
          <FormControlLabel
            key={option?.id}
            value={option?.domain}
            control={<Radio color="primary" />}
            label={option.domain}
          />
        ))}
      </RadioGroup>
      }
      {isError && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default DomainRadioSelection;
