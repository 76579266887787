import React from "react";
import LabelChip from "../LabelChip";

interface ScorecardCandidateInfoProps {
  keywords: string[];
  className: any;
}

const KeywordChip: React.FC<ScorecardCandidateInfoProps> = ({
  keywords,
  className="",
}) => {
  return (
    <>
      {keywords?.length> 0 &&  keywords.map((keywordName) => (
        <span key={keywordName} className="jr-keywordChip">
          <LabelChip label={keywordName} className={className} />
        </span>
      ))}
    </>
  );
};

export default KeywordChip;
