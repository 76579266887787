import React, { useState } from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PermissionsEnums } from "../../../../data/permission";
import { CandidateAuthorizationStatus } from "../../../../models/interfaces";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import "../../NewViewSubmission/styles.scss";
import SnackbarPopup from "../../../Common/Popup/snackbar/SnackbarPopup";

interface RequestRTRButtonProps {
  row: any;
  index: any;
  sendEmailReminder: (rowData: any, index: any) => void;
  emailSend: {
    index: any;
    isSent: boolean;    
  };
  pageType:string;
}

export default function RequestRTRButton({
  row,
  index,
  sendEmailReminder,
  emailSend,
  pageType
} : RequestRTRButtonProps) {
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const isAuthorized =
    (row?.candidateAuthorization === CandidateAuthorizationStatus[0] ||
    row?.candidateAuthorization === CandidateAuthorizationStatus[4]) &&
    pageType === "active";
  const handleClick = () => {
    if (isAuthorized) {
      sendEmailReminder(row, index);
      setSnackbarOpen(true);
    }
  };

  return (
    <ModuleComponent moduleId={PermissionsEnums.Submissions_RTR_Request}>
      {isAuthorized && (
        <Grid item>
          <Button
            className="submission-action-btn"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Request RTR
          </Button>
          {emailSend.isSent && emailSend.index === index && (
            <SnackbarPopup
              open={snackbarOpen && emailSend.isSent}
              message={"Email sent successfully"}
              onClose={() => setSnackbarOpen(false)}
            />
          )}
        </Grid>
      )}
    </ModuleComponent>
  );
};