import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { CircularProgress, Paper, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { GotoTop } from "../../ui/GotoTop";
import { Payrate } from "../../ui/Payrate";
import { CircularProgressWithLabel } from "../../Common/CircularProgressWithLabel";
import AppSort from "../../Common/AppSort";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/AppState";
import { setBreadCrumbsState } from "../../../features/Common/BreadcrumbsStateSlice";
import { PageName } from "../../../models/app/Page/PageModel";

interface IPROPS {
  totalRecords?: number;
  hasMoreData: any;
  loading: any;
  recommendationData: any[];
  sort: any;
  handleColumnSort: any;
  onViewScoreCard: (selectedItem: any) => void;
  scorecardPopup?: any;
}

export const RecommendedJobsSearchRow: React.FunctionComponent<IPROPS> = (
  props
) => {
  const navigate = useNavigate();
  const breadCrumbState = useSelector((state:AppState) => state.BreadcrumbsState);
  const dispatch = useDispatch();
  const onJobDetailClick = (jobId:any)=>{
    dispatch(
      setBreadCrumbsState({
        ...breadCrumbState,
        navigateFromPage: PageName.FINDJOB,
      })
    );
    navigate(`/jobdetail/${jobId}/findjobsExt`);
  }
  return (
    <>
      <TableContainer
        component={Paper}
      >
        {/* <AppInfiniteScroll
          dataLength={100}
          next={props?.fetchMoreData}
          hasMore={
            true
          }
          isLoading={props?.loading}
          loader={true}
          classNames=""
        > */}
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "400px",
            marginBottom: "10px",
          }}
        >
          Search Results: {props?.totalRecords} Recommended Jobs Found
        </Typography>
        <Divider
          sx={{
            borderBottomWidth: "1",
            width: "100%",
            background: "#4540DB",
          }}
        />

        <Table className="candidateListTable" aria-label="simple table">
          <TableHead className="custom-Client-fontsizes">
            <TableRow>
              <TableCell sx={{ px: 1 }}>
                <span
                  data-testid="sort-icon-title"
                  onClick={() => props.handleColumnSort("eximScore")}
                  style={{ display: "flex" }}
                >
                  Job ID
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={(props.sort?.sortBy !== "eximScore")}
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell sx={{ whiteSpace: "noWrap", px: 1 }}>Type</TableCell>
              <TableCell sx={{ whiteSpace: "noWrap", px: 1 }}>
                <span
                  data-testid="sort-icon-title"
                  onClick={() => props.handleColumnSort("jobData.jobPosition")}
                  style={{ display: "flex" }}
                >
                  Job Title
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={(props.sort?.sortBy !== "jobData.jobPosition")}
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell sx={{ px: 1 }}>Description </TableCell>
              <TableCell>Client</TableCell>
              <TableCell sx={{ px: 1 }}>Location </TableCell>
              <TableCell sx={{ px: 1, whiteSpace: "noWrap" }}>
                <span
                  data-testid="sort-icon-payrate"
                  onClick={() =>
                    props.handleColumnSort("jobData.package.packageValue")
                  }
                  style={{ display: "flex" }}
                >
                  Pay Rate
                  <AppSort
                    sortType={props.sort?.sortOrder}
                    disable={
                      (props.sort?.sortBy !== "jobData.package.packageValue")
                    }
                  ></AppSort>
                </span>
              </TableCell>
              <TableCell sx={{ px: 1 }}>Scorecard</TableCell>
              <TableCell style={{ paddingTop: "16px" }}>Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.recommendationData?.map((row: any, index: any) => (
              <TableRow
                hover
                key={row?.jobId ?? index}
                className="custom-hover-bgcolor"
              >
                <TableCell sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}>
                  <span className="jobTableCell">{row?.jobData?.id}</span>
                  <Typography
                    style={{ wordBreak: "break-all" }}
                    fontSize={"0.2rem"}
                  >
                    <CircularProgressWithLabel
                      value={Math.round(row?.eximScore)}
                    />
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  {row?.jobData?.jobType}
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  <span>{row?.jobData?.jobPosition}</span>
                </TableCell>
                <TableCell sx={{ px: 1, width: 250 }}>
                  {row?.jobData?.jobShortDesc}
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  {row?.jobData?.clientName}
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  {row?.jobData?.location}
                </TableCell>

                <TableCell
                  className="custom-payrate-font"
                  sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}
                >
                  <Payrate
                    packageType={row?.jobData?.package?.packageType}
                    packageValue={row?.jobData?.package?.packageValue}
                    currency={row?.jobData?.package?.packageCurrency}
                  />
                </TableCell>
                <TableCell
                  sx={{ px: 1, width: 100 }}
                  data-testid="view-scorecard"
                  color={"#4540DB"}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.onViewScoreCard(row);
                  }}
                >
                  <VisibilityOutlinedIcon
                    color="primary"
                    className="pointer custom-prview-dwn-icon"
                    sx={{ verticalAlign: "bottom" }}
                  />
                  View
                  {props?.scorecardPopup?.jobId === row.jobData?.id &&
                    props?.scorecardPopup?.isLoading && (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    )}
                </TableCell>
                <TableCell sx={{ px: 1, width: 100 }}>
                  <Box sx={{ display: "flex", justifyContent: "left" }}>
                    <Button
                      variant="text"
                      size="small"
                      className="cust-btn-bgClrDetails submit-btn-ht cust-subDetails-btn"
                      sx={{ margin: "0 0 0 0 !important" }}
                      onClick={() => {
                        onJobDetailClick(row?.jobData?.id)
                        
                      }}
                    >
                      Details
                    </Button>{" "}
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "left" }}>
                    <Button
                      variant="outlined"
                      size="small"
                      className="submit-btn-ht cust-subDetails-btn"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        navigate(`/submitresume/${row?.jobData?.id}/findjobs`);
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* </AppInfiniteScroll> */}
        {props?.loading && (
          <p
            style={{
              marginTop: "50px",
              marginBottom: "4px",
              textAlign: "center",
            }}
          >
            Please wait...
          </p>
        )}
        {!props?.loading && props?.totalRecords === 0 && (
          <Alert severity="info" style={{ marginTop: "5px" }}>
            No Records Found
          </Alert>
        )}
      </TableContainer>
      <div className="gotoTopBtn">
        <GotoTop />
      </div>
    </>
  );
};
