import {
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@material-ui/core";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Divider, IconButton, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React,{ useEffect } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import verifiedIcon from "./../../assets/images/verified.svg";
import { getIn } from "formik";
import PhoneInput from "react-phone-input-2";
import {
  GetCompanySizeList,
  GetModeOfDelivery,
  GetPaymentModes,
  GetPaymentTerms,
} from "../../Services/clientService";
import {
  DeleteAttachment,
} from "../../Services/uploadPhotoService";
import { searchOptions } from "../../constants/googlePlaceOption";
import useApi from "../../hooks/useApi";
import { DocumentTypeEnum } from "../../models/interfaces";
import getAddressForAutoFill from "../../utils/getAddressForAutoFill";
import {
  downloadFile,
  getFilePreviewUrl,
} from "../../utils/getFileFromUrl";
import { DeleteDocumentIcon } from "../Common/Icons/DeleteDocument";
import { Asterisk } from "../ui/Asterisk";
import { ErrorMessage } from "../ui/ErrorMessage";
import { IndustryAutocomplete } from "../ui/IndustryAutocomplete";

import formatPhone from "../../Shared/utils/formatPhone";
import { PermissionsEnums } from "../../data/permission";
import constructName from "../../utils/constructName";
import { ModuleComponent } from "../Common/Permission/ModuleComponent";
import PhoneVerify from "../Common/PhoneVerify/PhoneVerify";
import PreviewFileDialog from "../Common/PreviewFileDialog";
import { CustomEmailInput } from "../ui/CustomEmailInput";
import { UpdateConfirmationDialog } from "../ui/UpdateConfirmationDialog";
import LogoUploader from "./ClientSignUp/components/FormFields/logoUploader";
import { TeamsCard } from "./TeamsCard";
import { ternary, valueOrDefault } from "../../utils/complexityUtils";
import { isValidPhoneNumber } from "react-phone-number-input";

interface ClientDetails {
  defaultEmail:string;
  formik?: any;
  selectedFile: any;
  handleLogoChange: any;
  type: string;
  setSelectedFile: any;
  id:string
}
export const ClientDetails: React.FC<ClientDetails> = ({
  defaultEmail,
  formik,
  type,
  selectedFile,
  handleLogoChange,
  setSelectedFile,
  id
}) => {

  const modeOfDelivery = useApi(() => GetModeOfDelivery());
  const paymentTerms = useApi(() => GetPaymentTerms());
  const paymentModes = useApi(() => GetPaymentModes());
  const companySize = useApi(() =>
    GetCompanySizeList({ maxValue: 5000, rangeValue: 500 })
  );
  const [filePreview, setFilePreview] = React.useState<
    | undefined
    | {
        resourceUrl: string;
        fileName: string;
        fileType?: string;
      }
  >(undefined);
  const [confimationData, setConfimationData] = React.useState({
    open: false,
    title: "",
    description: "",
  });
  useEffect(() => {
    modeOfDelivery?.request();
    paymentTerms?.request();
    paymentModes?.request();
    companySize?.request();
  }, []);

  const autoCompleteGetAddress = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    
    formik?.setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));
    formik?.setFieldValue("address.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
    formik?.setFieldValue("address.countryCode", valueOrDefault(resp?.country, ""));
    formik?.setFieldValue("address.state", valueOrDefault(resp?.state, ""));
    formik?.setFieldValue("address.city", valueOrDefault(resp?.city, ""));

    if (resp?.pin && resp?.pin !== "") {
      formik?.setFieldTouched("address.postalCode", false);
    }
    if (resp?.formattedAddress && resp?.formattedAddress !== "") {
      formik?.setFieldTouched("address.streetAddress", false);
    }
    if (resp?.state && resp?.state !== "") {
      formik?.setFieldTouched("address.state", false);
    }
    if (resp?.city && resp?.city !== "") {
      formik?.setFieldTouched("address.city", false);
    }
    if (resp?.country && resp?.country !== "") {
      formik?.setFieldTouched("address.countryCode", false);
    }
  };
  const editMode = type == "edit";
  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <div style={{ width: "100% !important" }}>
      <InputLabel htmlFor="address-input">
        Street Address {editMode && <Asterisk />}
      </InputLabel>

      <FormControl
        variant="standard"
        error={
          formik?.touched.address?.streetAddress &&
          Boolean(formik?.errors.address?.streetAddress)
        }
        fullWidth
        required
      >
        <Input
          {...getInputProps()}
          name="address.streetAddress"
          type="text"
          onBlur={formik?.handleBlur}
          disableUnderline={!editMode}
          readOnly={!editMode}
        />
        <ErrorMessage
          errorText={
            formik?.touched.address?.streetAddress &&
            formik?.errors?.address?.streetAddress
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span
              onChange={autoCompleteGetAddress}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
  const props_Phone1Verify = {
    userId: formik?.values.id,
    phoneNumber: formik?.values.contactDetails.primaryPhoneNumber,
    isPrimaryPhone: true,
    otherPhoneVerifyStatus: false,
    updatePhoneVerify: (isVerified: any) => {
      formik?.setFieldValue("contactDetails.isPrimaryPhoneVerified", isVerified);
    },
  };
  const onPrimaryPhChange = (ev: any) => {
    if (
      formik?.values.contactDetails.primaryPhoneNumber.replace(/-/g, "") != ev
    ) {
      formik?.setFieldValue("contactDetails.isPrimaryPhoneVerified", false);
    }

    formik?.setFieldValue("contactDetails.primaryPhoneNumber", ev);
  };
  const handleConfirmation = () => {
    let resourceName =
      formik?.values?.clientAdditionalDetails?.companyLogo?.fileName;
    setSelectedFile({ file: null, error: "" });
    DeleteAttachment(DocumentTypeEnum.Other, resourceName);
    formik?.setFieldValue("clientAdditionalDetails.companyLogo.fileName", "");
    setConfimationData({
      open: false,
      title: ``,
      description: "",
    });
  };
  const handleConfirmationClose = () => {
    setConfimationData({
      open: false,
      title: ``,
      description: "",
    });
  };

  let resourceUrl =
  formik?.values?.clientAdditionalDetails?.companyLogo
    ?.resourceUrl;
let resourceName =
  formik?.values?.clientAdditionalDetails?.companyLogo
    ?.fileName;
  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={8.5} md={8.5}>
          <Paper elevation={1} >
            <Grid mb={2} container spacing={2} pl={2} pr={2} pb={2}>
              <Grid item xs={12}>
                <Typography
                  className="client-text-verify"
                  fontSize={23}
                  fontStyle="bold"
                >
                  Company Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="companyName" className="client-formLable">
                  Name {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.companyName &&
                    Boolean(formik?.errors.companyName)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.companyName}
                    name="companyName"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.companyName && formik?.errors.companyName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="email" className="client-formLable">
                  Email {editMode && <Asterisk />}
                </InputLabel>

                <FormControl
                  variant="standard"
                  error={Boolean(
                    getIn(formik?.touched, "email") &&
                      getIn(formik?.errors, "email")
                  )}
                  fullWidth
                  required
                >
                  <CustomEmailInput
                    paddingTop={"1px"}
                    name="email"
                    value={formik?.values.email}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                    isverified={
                      defaultEmail === formik?.values.email &&
                      formik?.values.isEmailVerified
                    }
                  />
                  <ErrorMessage
                    errorText={formik?.touched.email && formik?.errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="companyWebsite" className="client-formLable">
                  Website {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  error={
                    formik?.touched.companyWebsite &&
                    Boolean(formik?.errors.companyWebsite)
                  }
                  variant="standard"
                >
                  <Input
                    required
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={ternary(
                      valueOrDefault(editMode, formik?.values.companyWebsite),
                      formik?.values.companyWebsite,
                      "N/A"
                    )}
                    name="companyWebsite"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.companyWebsite &&
                      formik?.errors.companyWebsite
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <InputLabel
                  className="client-formLable"
                  id="demo-simple-select-label"
                >
                  Company Size {editMode && <Asterisk />}
                </InputLabel>

                <FormControl
                  error={
                    formik?.touched.companySize &&
                    Boolean(formik?.errors.companySize)
                  }
                  fullWidth
                  required
                >
                  {companySize?.data?.entityList?.length > 0 && (
                    <Select
                      inputProps={ternary(
                        !editMode,
                        { IconComponent: () => null },
                        {}
                      )}
                      variant="standard"
                      name="companySize" // <-- Add the name attribute to link with Formik? state
                      value={valueOrDefault(formik?.values?.companySize, "")} // <-- Use Formik?'s value instead of local state
                      onChange={formik?.handleChange} // <-- Use Formik?'s handleChange function
                      onBlur={formik?.handleBlur} // <-- Use Formik?'s handleBlur function
                      disableUnderline={!editMode}
                      readOnly={!editMode}
                    >
                      {companySize?.data?.entityList?.length > 0 &&
                        companySize?.data?.entityList?.map((x: any) => {
                          return (
                            <MenuItem key={x} value={x}>
                              {x}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                  <ErrorMessage
                    errorText={
                      formik?.touched.companySize && formik?.errors.companySize
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel
                  htmlFor="companyPseudoName"
                  className="client-formLable"
                >
                  Pseudo Name {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.companyPseudoName &&
                    Boolean(formik?.errors.companyPseudoName)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.companyPseudoName}
                    name="companyPseudoName"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.companyPseudoName &&
                      formik?.errors.companyPseudoName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8}>
                <IndustryAutocomplete
                  label={"Industry"}
                  readOnly={!editMode}
                  industries={formik?.values.industries}
                  onChange={(e: any, value: any) =>
                    formik?.setFieldValue("industries", value)
                  }
                  isRequired={editMode}
                  onBlur={formik?.handleBlur}
                />
                <ErrorMessage
                  errorText={
                    formik?.touched.industries && formik?.errors.industries
                  }
                />
              </Grid>

              <Grid mt={2} item xs={12}>
                <Divider />
                <Typography
                  className="client-text-verify"
                  mt={2}
                  fontSize={23}
                  fontStyle="bold"
                >
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  htmlFor="contactDetails.firstName"
                  className="client-formLable"
                >
                  First Name {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.contactDetails?.firstName &&
                    Boolean(formik?.errors.contactDetails?.firstName)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={ternary(
                      !editMode,
                      constructName(formik?.values.contactDetails?.firstName),
                      formik?.values.contactDetails?.firstName
                    )}
                    name="contactDetails.firstName"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.contactDetails?.firstName &&
                      formik?.errors.contactDetails?.firstName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  htmlFor="contactDetails.middleName"
                  className="client-formLable"
                >
                  Middle Name
                </InputLabel>
                <FormControl
                  fullWidth
                  error={
                    formik?.touched.contactDetails?.middleName &&
                    Boolean(formik?.errors.contactDetails?.middleName)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={ternary(
                      valueOrDefault(
                        editMode,
                        formik?.values.contactDetails?.middleName
                      ),
                      formik?.values.contactDetails?.middleName,
                      valueOrDefault(
                        constructName(formik?.values.contactDetails?.middleName),
                        "N/A"
                      )
                    )}
                    name="contactDetails.middleName"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.contactDetails?.middleName &&
                      formik?.errors.contactDetails?.middleName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  htmlFor="contactDetails.lastName"
                  className="client-formLable"
                >
                  Last Name {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.contactDetails?.lastName &&
                    Boolean(formik?.errors.contactDetails?.lastName)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.contactDetails?.lastName}
                    name="contactDetails.lastName"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.contactDetails?.lastName &&
                      formik?.errors.contactDetails?.lastName
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="contactDetails.position"
                  className="client-formLable"
                >
                  Position {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.contactDetails?.position &&
                    Boolean(formik?.errors.contactDetails?.position)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.contactDetails?.position}
                    name="contactDetails.position"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.contactDetails?.position &&
                      formik?.errors.contactDetails?.position
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className="client-formLable">
                  Phone {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  variant="standard"
                  style={{ width: ternary(editMode, "90%", "65%") }}
                >
                  {!editMode ? (
                    <>
                      <Input
                        value={formatPhone(
                          formik?.values.contactDetails.primaryPhoneNumber
                        )}
                        name="contactDetails.position"
                        type="text"
                        disableUnderline={!editMode}
                        readOnly={!editMode}
                      />
                    </>
                  ) : (
                    <PhoneInput
                      inputProps={{
                        "data-testid": "phone1",
                      }}
                      containerClass={`phoneCountryCustom-adminForm phoneCountryCustom-clientForm`}
                      name={"contactDetails.primaryPhoneNumber"}
                      placeholder="Primary Phone"
                      country={"us"}
                      onChange={(ev: any) => {
                        formik?.setFieldValue(
                          "contactDetails.primaryPhoneNumber",
                          ev
                        );
                        onPrimaryPhChange(ev);
                      }}
                      value={formik?.values.contactDetails?.primaryPhoneNumber}
                      readOnly={!editMode}
                    />
                  )}

                  {formik?.values.contactDetails?.isPrimaryPhoneVerified ??
                  false ? (
                    <img
                      style={{
                        paddingLeft: "10px",
                        position: "absolute",
                        left: "100.28%",
                        right: "-6.31%",
                        top: "20%",
                        height: "20px",
                        width: "20px",
                        bottom: "-7.5%",
                      }}
                      src={verifiedIcon}
                    ></img>
                  ) : null}
                </FormControl>

                <ErrorMessage
                  errorText={
                    formik?.touched?.contactDetails?.primaryPhoneNumber &&
                    formik?.errors?.contactDetails?.primaryPhoneNumber
                  }
                />
              </Grid>
              {editMode && (
                <Grid item xs={2}>
                {!formik?.values.contactDetails?.isPrimaryPhoneVerified &&
                isValidPhoneNumber(
                  "+" + formik?.values.contactDetails?.primaryPhoneNumber
                ) ? (
                  <PhoneVerify {...props_Phone1Verify}></PhoneVerify>
                ) : (
                  ""
                )}
                </Grid>
              )}

              <Grid mt={2} item xs={12}>
                <Divider />
                <Typography
                  className="client-text-verify"
                  mt={2}
                  fontSize={23}
                  fontStyle="bold"
                >
                  Address Details
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <PlacesAutocomplete
                  searchOptions={searchOptions}
                  value={formik?.values?.address?.streetAddress}
                  onChange={(ev: any) => {
                    formik?.setFieldValue("address.streetAddress", ev);
                  }}
                  onSelect={autoCompleteGetAddress}
                >
                  {renderFunc}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="address.city"
                  style={{ color: "#4540db !important" }}
                >
                  City {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched?.address?.city &&
                    Boolean(formik?.errors?.address?.city)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.address.city}
                    name="address.city"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched?.address?.city &&
                      formik?.errors?.address?.city
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  htmlFor="address.state"
                  style={{ color: "#4540db !important" }}
                >
                  State/Province {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.address?.state &&
                    Boolean(formik?.errors.address?.state)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.address.state}
                    name="address.state"
                    type="text"
                    readOnly={!editMode}
                    disableUnderline={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.address?.state &&
                      formik?.errors.address?.state
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  htmlFor="address.postalCode"
                  style={{ color: "#4540db !important" }}
                >
                  Zip/Postal Code {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.address?.postalCode &&
                    Boolean(formik?.errors.address?.postalCode)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.address.postalCode}
                    name="address.postalCode"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.address?.postalCode &&
                      formik?.errors.address?.postalCode
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  htmlFor="address.countryCode"
                  style={{ color: "#4540db !important" }}
                >
                  Country {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.address?.countryCode &&
                    Boolean(formik?.errors.address?.countryCode)
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.address.countryCode}
                    name="address.countryCode"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.address?.countryCode &&
                      formik?.errors.address?.countryCode
                    }
                  />
                </FormControl>
              </Grid>

              <Grid mt={2} item xs={12}>
                <Divider />
                <Typography
                  className="client-text-verify"
                  mt={2}
                  fontSize={23}
                  fontStyle="bold"
                >
                  Additional Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="file"
                  data-testid={"updateResumeInp"}
                  hidden
                  accept={".png, .jpg, .jpeg"}
                  onChange={(e) => handleLogoChange(e, "1")}
                  onClick={(e) => {
                    e.currentTarget.value = ""; // Clear the input value to allow selecting the same file again
                  }}
                />
                <InputLabel className="client-formLable">Company Logo</InputLabel>

                {!formik?.values?.clientAdditionalDetails.companyLogo?.fileName &&
                editMode ? (
                  <>
                    <div style={{ marginTop: "5px" }}></div>
                    <LogoUploader
                      logoFile={selectedFile?.file}
                      handleUploadChange={handleLogoChange}
                    />
                    <Typography mt={1}>
                      (Max file size: 200KB as .png,.jpg,.jpeg)
                    </Typography>
                  </>
                ) : (
                  <>
                    <FormControl variant="standard" style={{ width: "70%" }}>
                      <Input
                        name="fileInput"
                        readOnly
                        type="text"
                        value={selectedFile?.file?.name || "N/A"}
                        disableUnderline={!editMode}
                      />
                      {selectedFile?.error ? (
                        <div style={{ color: "#f44336" }}>{selectedFile.error}</div>
                      ) : null}
                    </FormControl>
                    <>
                      <VisibilityOutlinedIcon
                        data-testid="getfilepreviewurl"
                        onClick={() => {
                          getFilePreviewUrl(resourceUrl)?.then((filePreviewUrl) =>
                            setFilePreview({
                              resourceUrl: filePreviewUrl,
                              fileName: resourceName,
                              fileType: "image/jpeg",
                            })
                          );
                        }}
                        color={ternary(resourceName, "primary", "disabled")}
                        className={ternary(resourceName, "pointer", "")}
                        sx={{ pl: 1, verticalAlign: "bottom" }}
                      />
                      <SaveAltOutlinedIcon
                        data-testid="downloadfile"
                        onClick={() => {
                          downloadFile(resourceUrl, resourceName);
                        }}
                        color={ternary(resourceName, "primary", "disabled")}
                        className={ternary(resourceName, "pointer", "")}
                        sx={{
                          pl: 1,
                          verticalAlign: "bottom",
                          marginBottom: "3px",
                        }}
                      />
                      {editMode && (
                        <IconButton
                          aria-label="delete"
                          data-testid="deleteDoc"
                          sx={{
                            "&:hover": {
                              background: "transparent !important",
                            },
                          }}
                          onClick={() => {
                            setConfimationData({
                              open: true,
                              title: `Are you sure you want to delete this file?`,
                              description: "",
                            });
                          }}
                        >
                          <DeleteDocumentIcon
                            inheritViewBox
                            className="pointer"
                            sx={{
                              pl: 1,
                              verticalAlign: "bottom",
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </IconButton>
                      )}
                    </>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="clientOtherDetails.clientFeeType"
                  className="client-formLable"
                >
                  LinkedIn Profile URL
                </InputLabel>
                <FormControl
                  fullWidth
                  error={
                    formik?.touched.clientAdditionalDetails?.linkedInProfileUrl &&
                    Boolean(
                      formik?.errors.clientAdditionalDetails?.linkedInProfileUrl
                    )
                  }
                  variant="standard"
                >
                  <Input
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={
                      formik?.values.clientAdditionalDetails?.linkedInProfileUrl
                    }
                    name="clientAdditionalDetails.linkedInProfileUrl"
                    type="text"
                    disableUnderline={!editMode}
                    readOnly={!editMode}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.clientAdditionalDetails?.linkedInProfileUrl &&
                      formik?.errors.clientAdditionalDetails?.linkedInProfileUrl
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor="clientOtherDetails.companyOverview"
                  className="client-formLable"
                  style={{ color: "#4540db !important", marginBottom: "8px" }}
                >
                  Company Overview
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.clientAdditionalDetails?.companyOverview &&
                    Boolean(formik?.errors.clientAdditionalDetails?.companyOverview)
                  }
                  variant="standard"
                >
                  <TextField
                    disabled={!editMode}
                    InputProps={{ style: { color: "inherit" } }}
                    variant="outlined"
                    multiline
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.clientAdditionalDetails?.companyOverview}
                    name="clientAdditionalDetails.companyOverview"
                    rows={4}
                    maxRows={7}
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.clientAdditionalDetails?.companyOverview &&
                      formik?.errors.clientAdditionalDetails?.companyOverview
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor="clientAdditionalDetails.hiringProcess"
                  style={{ color: "#4540db !important", marginBottom: "8px" }}
                  className="client-formLable"
                >
                  Hiring Process {editMode && <Asterisk />}
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.clientAdditionalDetails?.hiringProcess &&
                    Boolean(formik?.errors.clientAdditionalDetails?.hiringProcess)
                  }
                  variant="standard"
                >
                  <TextField
                    variant="outlined"
                    multiline
                    disabled={!editMode}
                    InputProps={{ style: { color: "inherit" } }}
                    rows={4}
                    maxRows={7}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values.clientAdditionalDetails?.hiringProcess}
                    name="clientAdditionalDetails.hiringProcess"
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.clientAdditionalDetails?.hiringProcess &&
                      formik?.errors.clientAdditionalDetails?.hiringProcess
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor="firstName"
                  style={{ color: "#4540db !important", marginBottom: "8px" }}
                  className="client-formLable"
                >
                  Company Benefits
                </InputLabel>
                <FormControl
                  fullWidth
                  required
                  error={
                    formik?.touched.clientAdditionalDetails?.companyBenefits &&
                    Boolean(formik?.errors.clientAdditionalDetails?.companyBenefits)
                  }
                  variant="standard"
                >
                  <TextField
                    variant="outlined"
                    disabled={!editMode}
                    InputProps={{ style: { color: "inherit" } }}
                    multiline
                    rows={4}
                    maxRows={7}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={ternary(
                      valueOrDefault(
                        editMode,
                        formik?.values.clientAdditionalDetails?.companyBenefits
                      ),
                      formik?.values.clientAdditionalDetails?.companyBenefits,
                      "N/A"
                    )}
                    name="clientAdditionalDetails.companyBenefits"
                  />
                  <ErrorMessage
                    errorText={
                      formik?.touched.clientAdditionalDetails?.companyBenefits &&
                      formik?.errors.clientAdditionalDetails?.companyBenefits
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sm={3.5} md={3.5}>
          <ModuleComponent moduleId={PermissionsEnums.Members_Listing}>
           
              <Typography
                className="client-text-verify"
                mb={1}
                fontSize={23}
                fontStyle="bold"
              >
                Team Members
              </Typography>
              <div className="teamMemberGridList">
                <TeamsCard clientId={id} editMode={editMode} />
              </div>
          </ModuleComponent>
        </Grid>
        
        {filePreview !== undefined && (
          <PreviewFileDialog
            open={filePreview !== undefined}
            handleOpenClose={() => setFilePreview(undefined)}
            fileName={filePreview.fileName}
            fileURL={filePreview.resourceUrl}
            fileType={filePreview.fileType}
          />
        )}
        <UpdateConfirmationDialog
          message={confimationData.title}
          aggreeText={"Delete"}
          disagreeText={"Cancel"}
          onClose={() => handleConfirmationClose}
          open={confimationData.open}
          onYes={handleConfirmation}
          onNo={handleConfirmationClose}
        />
      </Grid>
    </>
  );
};
