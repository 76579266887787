import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormControl, Input } from "@material-ui/core";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import {
  GetPreferences,
  UpdatePreferences,
} from "../../Services/idProviderService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ErrorMessage } from "../ui/ErrorMessage";
import { CircularProgress } from "@mui/material";
import getUserDetails from "../../Shared/utils/getUserDetails";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { UserAuth } from "../../models/interfaces";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { CustomAlert } from "../ui/CustomAlert";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";

const NotificationSettings: React.FC = () => {
  const userType = getLoggedInUserRole();
  const user = getUserDetails();
  const [isChanged, setIsChanged] = React.useState(false);
  const [isUnsub, setisUnsub] = React.useState(false);
  const [unSubDisabled, setUnSubDisabled] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    isError: false,
    error: "",
    loading: false,
    successMessage: "",
  });
  let initialState = {
    email: user.email,
    weeklyCommunications: false,
    topJobs: false,
    recommendations: false,
    whatsNew: false,
    specialOccasions: false,
  };

  let defalultBreadCrumb = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: "/dashboard" },
    {
      isLeaf: true,
      sequence: 2,
      title: "Notifications Settings",
      leafClasses: " v2LastLeft",
    },
  ];

  const formikSchema = Yup.object({
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    weeklyCommunications: Yup.boolean(),
    topJobs: Yup.boolean(),
    recommendations: Yup.boolean(),
    whatsNew: Yup.boolean(),
    specialOccasions: Yup.boolean(),
  });
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formikSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setPageStatus({
        isSuccess: false,
        isError: false,
        error: "",
        loading: true,
        successMessage: "",
      });
      return UpdatePreferences(values).then((res) => {
        const subscriptionSuccess = res?.data?.status === 200 &&
                                   res?.data?.message?.appStatusCode === "IP_SUB_UP_SUCCESS";
        const subscriptionMessage = isUnsub ? "Unsubscription done successfully" : "You have successfully subscribed";
      
        if (subscriptionSuccess) {

          setPageStatus({
            isSuccess: true,
            isError: false,
            error: "",
            loading: false,
            successMessage: subscriptionMessage,
          });
          setIsChanged(false);
        } else {
          setPageStatus({
            isSuccess: false,
            isError: true,
            error: res?.data?.message?.appStatusDescription,
            loading: false,
            successMessage: "",
          });
        }
      });
      
    },
  });
  const checkAllUnsubscribed = () => {
    if (
      !formik.values?.weeklyCommunications  &&
      !formik.values?.topJobs  &&
      !formik.values?.recommendations  &&
      !formik.values?.whatsNew  &&
      !formik.values?.specialOccasions 
    ) {
      setUnSubDisabled(true);
    } else {
      setUnSubDisabled(false);
    }
  };
  const handleUnsubscribeAll = () => {
    formik.setValues(initialState);
    setisUnsub(true);
    formik.handleSubmit();
  };
  const GetUserPreferences = (email: string) => {
    GetPreferences(email).then((res) => {
      if (
        res?.data?.status === 200 &&
        res?.data?.message.appStatusCode === "IP_RSF"
      ) {
        formik.setValues(res.data?.entity);
        checkAllUnsubscribed();
      } else if (
        res?.data?.status === 404 &&
        res?.data?.message?.appStatusCode === "IP_SUB_NOTFOUND"
      ) {
        // doing nothing, not sure if intentional
      }
    });
    console.log(email);
  };
  React.useEffect(() => {
    GetUserPreferences(user?.email ?? "");
  }, []);
  React.useEffect(() => {
    checkAllUnsubscribed();
  }, [formik.values]);
  return (
    <>
      {(userType === UserAuth.Admin || userType === UserAuth.Recruiter) && (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <AppBreadCrumb
              classes="custom-appbreadcrumb"
              BreadCrumbItems={defalultBreadCrumb}
            ></AppBreadCrumb>
          </Grid>
        </Grid>
      )}
      <Container maxWidth="md" className="jobs-page notificationWrapper">
        <Typography
          variant="h2"
          component="h1"
          className="abelh2"
          sx={{
            textAlign: "center",
          }}
        >
          Settings
        </Typography>
        <br />
        <Box className="custom-email-wrapper">
          <Typography
            variant="inherit"
            component="div"
            className="email-text-wrap roboto-font"
            sx={{
              textAlign: "left",
              width: "100%",
              maxWidth: "280px",
              color: "#4540DB",
            }}
          >
            Enter your email here
          </Typography>
          <FormControl
            variant="standard"
            className="NotificationEmailTextField"
            required
            error={Boolean(formik.errors.email)}
          >
            <Input
              name="email"
              disabled={true}
              value={formik.values?.email}
              onChange={formik.handleChange}
              placeholder=""
              type="email"
              autoComplete="off"
            />
            {formik.touched.email && formik.errors.email && (
              <ErrorMessage errorText={formik.errors.email} />
            )}
          </FormControl>
        </Box>
        <Box
          sx={{
            padding: "0 0 30px",
          }}
          className="custom-flex-between mobile-column"
        >
          <Typography
            variant="h2"
            component="div"
            className="v2h3"
            sx={{
              textAlign: "left",
              fontSize: "26px !important",
            }}
          >
            Marketing Emails:
          </Typography>
          <Button
            variant="outlined"
            data-testid="handleUnsubscribeAll"
            disabled={unSubDisabled || formik.isSubmitting}
            endIcon={
              formik.isSubmitting ? (
                <CircularProgress size={"16px"} color={"inherit"} />
              ) : (
                ""
              )
            }
            onClick={() => {
              handleUnsubscribeAll();
            }}
          >
            Unsubscribe from all
          </Button>
        </Box>
        <Box
          sx={{
            padding: "0 0 30px",
          }}
          className="custom-flex-between mobile-column"
        >
          <Typography
            variant="h2"
            component="div"
            className="v2h3"
            sx={{
              textAlign: "left",
            }}
          >
            Weekly Communications
          </Typography>
          <Switch
            className="Notification-Switch"
            name="weeklyCommunications"
            inputProps={{
              //@ts-ignore
              "data-testid": "weeklyCommunications",
            }}
            checked={formik.values.weeklyCommunications}
            value={formik.values.weeklyCommunications}
            onChange={(e: any) => {
              setIsChanged(true);
              formik.handleChange(e);
            }}
          />
        </Box>
        <Box
          sx={{
            padding: "0 0 30px",
          }}
          className="custom-flex-between mobile-column"
        >
          <Typography
            variant="h2"
            component="div"
            className="v2h3"
            sx={{
              textAlign: "left",
            }}
          >
            Top Jobs Active in JobRialto
          </Typography>
          <Switch
            className="Notification-Switch"
            name="topJobs"
            inputProps={{
              //@ts-ignore
              "data-testid": "topJobs",
            }}
            checked={formik.values.topJobs}
            value={formik.values.topJobs}
            onChange={(e) => {
              setIsChanged(true);
              formik.handleChange(e);
            }}
          />
        </Box>
        <Box
          sx={{
            padding: "0 0 30px",
          }}
          className="custom-flex-between mobile-column"
        >
          <Typography
            variant="h2"
            component="div"
            className="v2h3"
            sx={{
              textAlign: "left",
            }}
          >
            Recommendations based on preferences
          </Typography>
          <Switch
            className="Notification-Switch"
            name="recommendations"
            inputProps={{
              //@ts-ignore
              "data-testid": "recommendations",
            }}
            checked={formik.values.recommendations}
            value={formik.values.recommendations}
            onChange={(e) => {
              formik.handleChange(e);

              setIsChanged(true);
            }}
          />
        </Box>
        <Box
          sx={{
            padding: "0 0 30px",
          }}
          className="custom-flex-between mobile-column"
        >
          <Typography
            variant="h2"
            component="div"
            className="v2h3"
            sx={{
              textAlign: "left",
            }}
          >
            Whats New? Up to date jobs added on day to day basis
          </Typography>
          <Switch
            className="Notification-Switch"
            name="whatsNew"
            inputProps={{
              //@ts-ignore
              "data-testid": "whatsNew",
            }}
            checked={formik.values.whatsNew}
            value={formik.values.whatsNew}
            onChange={(e) => {
              formik.handleChange(e);

              setIsChanged(true);
            }}
          />
        </Box>
        <Box
          sx={{
            padding: "0 0 30px",
          }}
          className="custom-flex-between mobile-column"
        >
          <Typography
            variant="h2"
            component="div"
            className="v2h3"
            sx={{
              textAlign: "left",
            }}
          >
            Special Occasions/Event updates
          </Typography>
          <Switch
            className="Notification-Switch"
            name="specialOccasions"
            inputProps={{
              //@ts-ignore
              "data-testid": "specialOccasions",
            }}
            checked={formik.values.specialOccasions}
            value={formik.values.specialOccasions}
            onChange={(e) => {
              setIsChanged(true);
              formik.handleChange(e);
            }}
          />
        </Box>
        <Box
          sx={{
            width: "auto",
            padding: "0 0 30px",
          }}
        >
          {pageStatus.successMessage && (
            <>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0 0 30px",
          }}
        >
          <Button
            data-testid="Update-button"
            variant="contained"
            disabled={!isChanged || formik.isSubmitting}
            endIcon={
              formik.isSubmitting ? (
                <CircularProgress size={"16px"} color={"inherit"} />
              ) : (
                ""
              )
            }
            onClick={() => {
              setisUnsub(false);
              formik.handleSubmit();
            }}
          >
            Update my preferences
          </Button>
        </Box>
        {pageStatus?.isSuccess && (
          <SnackbarPopup
           open={pageStatus.isSuccess}
           message={pageStatus.successMessage}
           onClose={() => {
            setPageStatus({ ...pageStatus, isSuccess: false });
          }}
        />
        )}
        {pageStatus?.isError && (
          <CustomAlert
            key={4}
            message={pageStatus.error}
            onClose={()=>{}}
            type={"error"}
          />
        )}
      </Container>
    </>
  );
};

export default NotificationSettings;
