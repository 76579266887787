import { valueOrDefault } from "./complexityUtils";

interface JobExperienceProps {
  id: string;
  experience: string;
  experienceValue: number;
}

export const getTextNameEllipse = (textName: string,charLength=15) => {
    return textName?.length > charLength ? textName?.substring(0, charLength) + "..." : textName;
  };
export const  capitalizeFirstLetter = (string: string)=> {
    if (string === undefined || string === "") return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isVarNotEmpty = (string: string) => {
  return Boolean(string);
};

export function getFirstAndLastName(fullName: string) {
  const nameParts = fullName?.trim().split(" ");
  if (nameParts && nameParts?.length < 2) {
    throw new Error(
      "Invalid full name. Please provide both first name and last name."
    );
  }
  const firstName = nameParts && nameParts[0];
  const lastName = nameParts && nameParts[nameParts?.length - 1];
  
  return firstName  + " " + lastName ;
}

export function handleNullValue(string: string, specialCharacter: any){
  if(string){
    return string;
  }else{
    return specialCharacter??"-"
  }
}

export const getOptionLabel = (id:string, List:[])=>{
  const jobExperience: JobExperienceProps | any = List?.find(
      (item: any) => item.id === id
  );
  return valueOrDefault(jobExperience?.experience, "");
  }

  export interface Certification {
    name: string ;
    requirement: any;
  }

  export const getCertificateNames = (certifications: Certification[]): string[] => {
    return certifications?.map(certification => certification?.name);
  };

  export const isRemoteOrHybrid = (input: string): boolean => {
    const normalizedInput = input.trim().toLowerCase();
    return normalizedInput === 'remote' || normalizedInput === 'hybrid';
  };
