import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import TalkIcon from "./../../assets/images/Talk-icon.svg"
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import BlueMagnifying from "./../../assets/images/home-connect-bg.svg";
import { Testimonial } from "./Testimonial";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";
import { useNavigate } from "react-router";

const ClientServicesPage: React.FC = () => {

  const navigate = useNavigate();

  return (
    <div>
     
      <ThemeProvider theme={staticPageTheme}>

      <Container
        maxWidth="xl"
        className="login-banner-text"
        sx={{
          marginTop: "16px",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          className="letter-spacing-normal primary-color-text"
          gutterBottom
          textAlign={"center"}
          sx={{ paddingTop: "10px",}}
        >
          Client services
        </Typography>

        <Typography
          variant="h4"
          color="inherit"
          component="h1"
          sx={{ paddingBottom: "20px", fontWeight: "400" ,fontSize:{xs:"25px !important",sm:"32px !important",md: "34px !important"}}}
          
          textAlign={"center"}
        >
          
          Unlock the Power of JobRialto's Client Onboarding
        </Typography>
        <Container maxWidth="md">
        <Typography
          variant="h6"
          color="inherit"
          component="h1"
          sx={{ paddingBottom: "10px", fontWeight: "400", color: "#0E0E2C99" }}
          textAlign={"center"}
        >
           Welcome to JobRialto - your one-stop solution for seamless and efficient candidate placements.
           As our client, you can now harness the full potential of our marketplace to find the best available candidates in the market,
           precisely when you need them. 
        </Typography>
        </Container>
        <Container sx={{paddingTop:{sm:"10px",md:"23px"}, paddingBottom:{sm:"20px",md:"35px"}}}>
          <Grid container justify="center">
            <Button data-testid="primary" color="primary" onClick={()=>{ navigate('/contactUs')}}  variant="contained" >
            Lets Talk <img style={{paddingLeft:"10px"}} src={TalkIcon}></img>
            </Button>
          </Grid>
        </Container>
        <br></br>

        <Container>
          <Typography className="letter-spacing-normal primary-color-text" style={{fontSize:"22px",marginTop:"0px"}}>
          Act Now
          </Typography>

          <Box paddingTop="20px" >
            <Stack className="roboto-font" direction={{xs:"column",sm:"column",md:"row"}} spacing={2}>
              <div
                style={{
                  fontSize: "36px",
                 
                  fontWeight: "600",
                }}
              >
                How JobRialto Benefits Direct Clients
              </div>
              <Typography
                variant="h6"
                color="inherit"
                component="h1"
                sx={{ paddingBottom: "10px", fontWeight: "400", color: "#0E0E2C99" }}
              >
                With our team’s collective experience of being in the recruiting business, 
                we created the perfect process to help recruiters effortlessly fulfill the 
                contingent and full-time jobs from 100s of clients and employers. 
              </Typography>
            </Stack>
          </Box>
        </Container>
        <Container>
          <div
            className="row p-1"
            style={{ textAlign: "center", paddingTop: "60px" }}
          >
          </div>
        </Container>
        <Container>
          <Stack direction={{xs:"column",sm:"column",md:"row"}} spacing={{sm:2,md:10}}>
            <Stack direction="column" spacing={2}>
              <div
                className="letter-spacing-normal primary-color-text txt-roboto-font"
                style={{
                  fontSize: "20px",
                
                  fontWeight: "800",
                }}
              >
                01 <span style={{color:"#0E0E2C"}}>Create a Profile</span>
              </div>
              <Typography
                color="inherit"
                sx={{
                  paddingBottom: "10px",
                  fontWeight: "400",
                  color: "#0E0E2C99",
                }}
              >
                Sign up and create a personalized client profile using your email or
                social accounts such as Google or LinkedIn
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <div
                className="letter-spacing-normal primary-color-text txt-roboto-font"
                style={{
                  fontSize: "20px",
                 
                  fontWeight: "800",
                }}
              >
                02 <span style={{color:"#0E0E2C"}}>Browse Jobs</span>
              </div>
              <Typography
                color="inherit"
                sx={{
                  paddingBottom: "10px",
                  fontWeight: "400",
                  color: "#0E0E2C99",
                }}
              >
                Explore thousands of job listings on JobRialto. Save the ones that match your needs, and
                let our recruiters source candidates for you
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <div
                className="letter-spacing-normal primary-color-text txt-roboto-font"
                style={{
                  fontSize: "20px",
                
                  fontWeight: "800",
                }}
              >
                03 <span style={{color:"#0E0E2C"}}>Hire Candidates</span>
              </div>
              <Typography
                color="inherit"
                sx={{
                  paddingBottom: "10px",
                  fontWeight: "400",
                  color: "#0E0E2C99",
                }}
              >
                Once you have a job opening, submit it to JobRialto. Our team will prescreen and 
                submit your job listing to our network of recruiters.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2}>
              <div
                className="letter-spacing-normal primary-color-text txt-roboto-font"
                style={{
                  fontSize: "20px",
               
                  fontWeight: "800",
                }}
              >
                04 <span style={{color:"#0E0E2C"}}>Interviewed & Hired</span>
              </div>
              <Typography
                color="inherit"
                sx={{
                  paddingBottom: "10px",
                  fontWeight: "400",
                  color: "#0E0E2C99",
                }}
              >
                If a candidate from our network is successfully hired after interviews, you can start benefiting from our referral fees
              </Typography>
            </Stack>
          </Stack>
        </Container>
        <Container>
          <Stack direction="row" spacing={30}>
          </Stack>
        </Container>
        <Container>
          <Typography
            className="letter-spacing-normal primary-color-text txt-roboto-font"
            paddingTop="35px"
          >
            Expand Your Hiring Potential
          </Typography>
          <Box paddingTop="15px" >
            <Stack direction={{xs:"column",sm:"column",md:"row"}} spacing={{xs:5,sm:5,md:35}}>
              <Stack direction="column" spacing={1}>
                <Typography
                  style={{
                    fontSize: "36px",
                  
                    fontWeight: "700",
                  }}
                >
                  Grow With Ease
                </Typography>
                <Typography
                  color="inherit"
                  sx={{
                    paddingBottom: "10px",
                    fontWeight: "400",
                    color: "#0E0E2C99",
                  }}
                >
                  Research indicates that 90% of the best hires are made through passive recruitment.
                  JobRialto combines the strengths of both passive and active recruitment to help you find top talent.
                  Onboard as a client now and unlock your full hiring potential. 
                </Typography>
              </Stack>
              <div style={{ padding: "10px" }}>
                <img
                  src={BlueMagnifying}
                  alt="BlueMagnifying"
                  className="blue-magnifying-img"
                />
              </div>
            </Stack>
          </Box>
        </Container>
      
      </Container>
      
      <Testimonial/>
      </ThemeProvider>
    
    </div>
  );
};

export default ClientServicesPage;
