import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import React from "react";
import ScorecardResume from "../../Common/Scorecard/ScorecardResume";


interface Props {
  handleOpenClose: () => void;
  open: boolean;
  score:any
  scoreResumeStatus:any
}

const RecommendJobScorecardDialog: React.FC<Props> = ({ 
  handleOpenClose, 
  open, 
  score,
  scoreResumeStatus 
}) => {
  
  return (
    <Dialog
      open={open}
      onClose={() => handleOpenClose()}
      className="preview-file-dialog scorecard-popup"
      fullWidth
      maxWidth="lg"
      data-testid="scorecard-onclose"
    >
      <DialogContent dividers>
        <IconButton
          aria-label="Close Preview"
          onClick={() => handleOpenClose()}
          className="preview-dialog-close-btn"
          sx={{
            position: "absolute",
            right: 32,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
          data-testid="scorecard-onclose"
        >
          <CloseIcon />
        </IconButton>
        <ScorecardResume
          resumeFile={{}}
          onResumeFileChange={()=>{}}
          handleOnSubmit={()=>{}}
          breadCrumbOnClick={() => {}}
          form={{}}
          setFieldValue={{}}
          formValues={{pageName:"FindCandidate"}}
          isFormValid={false}
          jobData={{}}
          glasssquidScore={score}
          isLoading={false}
          display={true}
          isAutoPopulate={false}
          setAutopopulate={false}
          editMode={false}
          showBreadcrumbs={false}
          glasssquidScoreStatus={scoreResumeStatus}
          height="auto"
      />
      </DialogContent>
    </Dialog>
  );
};
export default RecommendJobScorecardDialog;
