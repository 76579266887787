import {
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./PostingJobStyle.scss";
import AddJobValidationSchema from "./components/FormModel/AddJobValidationSchema";
import ApplicationProcessFormSection from "./components/FormSection/ApplicationProcessFormSection";
import CompanyInformationFormSection from "./components/FormSection/CompanyInformationFormSection";
import JobDetailsFormSection from "./components/FormSection/JobDetailsFormSection";
import JobTypeRateAssignmentFormSection from "./components/FormSection/JobTypeRateAssignmentFormSection";

//Step wizard
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// API services
import {
  addJob,
  editJob,
  getJobApplicationMethods,
  getJobMasterDomains,
  getJobPaymentModes,
  getJobRequireDegrees,
  getJobRequireDocuments,
  getJobTaxTerms,
  getJobTypes,
  getJobWorkAuthorization,
  getJobWorkAuthorizationById,
  getJobWorkExperience,
} from "../../../Services/jobService";
// Models
import { AxiosResponse } from "axios";
import moment from "moment";
import { GetClientById, GetCompanySizeList } from "../../../Services/clientService";
import { GetAllCurrencyList } from "../../../Services/masterCurrencyService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { JobApplicationMethod } from "../../../models/Job/JobApplicationMethod";
import { JobDomain } from "../../../models/Job/JobDomain";
import { JobRequiredDocument } from "../../../models/Job/JobRequiredDocument";
import { ClientUserInfoModel } from "../../../models/SystemUser/ClientInfo";
import { JobPostingState } from "../../../models/client/Job/AddJob";
import { jobPostingDetails } from "../../../models/client/Job/jobPostingDetails";
import { UserAuth } from "../../../models/interfaces";
import { AppState } from "../../../store/AppState";
import { CustomAlert } from "../../ui/CustomAlert";
import PreviewJobPopup from "./PreviewJobPopup";
import JobPostPublishSuccess from "./components/popup/JobPostPublishSuccess";
import JobPostSuccessPopup from "./components/popup/JobPostSuccessPopup";
import JobUpdateSuccessPopup from "./components/popup/JobUpdateSuccessPopup";
import PublishConfirmPopup from "./components/popup/PublishConfirmPopup";
import SaveConfirmPopup from "./components/popup/SaveAndContinueConfirm";
import UpdateConfirmPopup from "./components/popup/UpdateConfirmPopup";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import GenerateJobPostingDetailsPopup from "./components/popup/GenerateJobPostingDetailsPopup";
import { MessageList } from "./components/FormSection/GenerateJobDetailsPreview";
import { findJobTypeByValue, GetSkillNameArray, JobType } from "../../../utils/ArrayUtils"; 
import PreviewAIGeneratedDetailsPopup from "./components/popup/PreviewAIGeneratedDetailsPopup";
import RenderStepActionButton from "./components/Stepper/StepActionButton";
import { handleAddressAutofill } from "./components/JobPostingUtils/JobAddressAutofill";
import { FormAutoPopulateDetailsSet } from "./components/JobPostingUtils/JobFormSetJobDetails";
import { Education } from "../../Common/Form/FormField/EducationAutoComplete";
import { GetStepperStatus, StepperStatus, getClassName } from "./components/JobPostingUtils/StepperUtils";
import CustomStepIcon from "./components/Stepper/CustomStepIcon";

interface JobPostingProps {
  jobPostingDetails?:jobPostingDetails;
  mode?:"EDIT"|"PENDING"|"ADD";
}

type FormHandlers = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, shouldValidate?: boolean) => void;
};

type FormState = {
  values: any;
  errors: any;
  touched: any;
};

const PostingJobForm: React.FC<JobPostingProps> = (props) => {

  // RecruiterData is current logged user details
  const { recruiterData } = useSelector((state: any) => state.recruiter);
  const loggedUserRole = getLoggedInUserRole();

  const loggedUserStatus  = loggedUserRole === UserAuth.ClientTeamMember ? "Approved" :  recruiterData?.entity?.status;
  
  const loggedUserId = getUserDetails()?.id;
  const [displayPreview, setDisplayPreview] = useState(false);
  const addJobInstialState = useSelector(
    (state: AppState) => state.addJobState
  );

  const formRef = React.useRef<FormikProps<JobPostingState>>(null);

  // page states
  const [pageStatus, setPageStatus] = useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
    loading: false,
  });

  const [jobMode, setJobMode] = useState<string>("");

  //popup state

  const [isSavePopup, setIsSavePopup] = useState<boolean>(false);
  const [isSaveSuccessPopup, setIsSaveSuccessPopup] = useState<boolean>(false);
  const [isUpdateConfirm, setIsUpdateConfirm] = useState<boolean>(false);
  const [isPublishConfirm, setIsPublishConfirm] = useState<boolean>(false);
  const [isPublishSuccessPopup, setIsPublishSuccessPopup] =
    useState<boolean>(false);

    const [isUpdateSuccessPopup, setIsUpdateSuccessPopup] =
    useState<boolean>(false);

  //master list states
  const [ClientUserDetails, SetClientUserDetails] = useState<ClientUserInfoModel>();
  const [jobDomainsList, setJobDomainsList] = useState<JobDomain[]>([]);
  const [jobRequireDegreesList, setJobRequireDegreesList] = useState<Education[]>(
    []
  );
  const [jobTypesList, setJobTypesList] = useState<JobType[]>([]);
  const [jobTaxTermsList, setJobTaxTermsList] = useState<string[]>([]);
  const [jobPaymentModesListAll, setJobPaymentModesListAll] = useState<string[]>([]);
  const [jobPaymentModesList, setJobPaymentModesList] = useState<string[]>([]);
  const [jobWorkAuthorizationList, setJobWorkAuthorizationList] = useState<
    string[]
  >([]);
  const [jobApplicationMethodsList, setJobApplicationMethodsList] = useState<
    JobApplicationMethod[]
  >([]);
  const [jobRequireDocumentsList, setJobRequireDocumentsList] = useState<
    JobRequiredDocument[]
  >([]);

  const [jobCurrency, setJobCurrencyList] = useState<string[]>([]);
  const [jobSalaryRangeList, setJobSalaryRangeList] = useState<string[]>([]);
  const [jobWorkExperiencesList, setJobWorkExperiencesList] = useState<
    any[]
  >([]);

  const [isGenerateJobDetailsPopup, setIsGenerateJobDetailsPopup] = useState<boolean>(
    ternary((props?.mode ==="EDIT" || props?.mode ==="PENDING"), false, true)
  )
  const [AIContentPreview, setAIContentPreview] = useState<any>({
    isOpen:false,
    content:[]
  });

  
  //Stepper state
  const [stepperStatus, setStepperState] = useState<any>({
    step1:StepperStatus?.isEmpty,
    step2:StepperStatus?.isEmpty,
    step3:StepperStatus?.isEmpty,
  })
  const steps = [
    'Job Details',
    'Job Type and Rate Assignment',
    'Application Process',
  ];
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const executeSequentially = async (
    functions: (() => Promise<AxiosResponse>)[],
    callback: (response: AxiosResponse) => any
  ): Promise<boolean> => {
    let allApiCallsCompleted = true;
  
    for (const func of functions) {
      try {
        const response = await func();
        callback(response);
      } catch (error) {
        console.error('Error executing API call:', error);
        allApiCallsCompleted = false;
      }
    }
  
    return allApiCallsCompleted;
  };

  function getSalaryRangeRate() : string {
    if (props?.jobPostingDetails?.jobType === "Full Time") {
      return props?.jobPostingDetails?.package?.packageRange?.range;
    } else if (props?.jobPostingDetails?.taxTerms === "C2C") {
      return valueOrDefault(props?.jobPostingDetails?.package?.packageRange?.ctcRate, '');
    } else {
      return valueOrDefault(props?.jobPostingDetails?.package?.packageRange?.w2Rate, '');
    }
  }


  const handleApiCompletion = async () => {
    const apiCalls = [
      _GetClientByIds,
      _GetJobDomains,
      _GetRequireDegrees,
      _GetJobTypes,
      _GetJobTaxTerms,
      _GetJobPaymentModes,
      _GetJobWorkAuthorization,
      _GetJobApplicationMethods,
      _GetJobRequireDocuments,
      _GetMasterCurrencyList,
      _GetJobSalaryRange,
      _GetJobWorkExperinecesList,
    
    ]; // Add more API call functions if needed
    
    const allApiCompleted = await executeSequentially(apiCalls, (response) => {
      // Process the response if needed
    });
    
    if (!allApiCompleted) {
      console.log("Not All API calls completed successfully");
    }

    // All API calls completed successfully

    // transform the required skill object to match request and response payload
    const transformedRequiredSkill = props?.jobPostingDetails?.manualData?.skills
    ? props?.jobPostingDetails?.manualData?.skills.map((item: any) => item.skill)
    : [];

    // transform for required degree object to match request and response payload
    type requiredDegree = {
      id: string;
      degreeName: string;
      // Other properties specific to requiredDegree
    };

    const transformedRequiredDegree: requiredDegree[] = props?.jobPostingDetails?.education
      ? props?.jobPostingDetails?.education.map((item: any) => ({
          id: item.jobId.toString(),
          degreeName: item.degreeName,
        }))
      : [];
    
    // Extracting the certificationsLicenses values from the array
    const certificationsAndLicenses = props?.jobPostingDetails?.manualData?.certificationsAndLicenses?.map((item:any) => item.certificationsLicenses);

    let jobStartDate = props?.jobPostingDetails?.jobStartDate || '';
    let jobEndDate = props?.jobPostingDetails?.jobEndDate || '';

    // set defaults instead of empty
    if (props?.mode === 'ADD')
    {
      let tempDate = moment().add(28, 'days').utc();
      jobEndDate = tempDate.toISOString();
      jobStartDate = tempDate.add(14, 'days').toISOString();
    }

    let salaryRangeRate = getSalaryRangeRate();
    
    formRef.current?.setValues({
      ...formRef.current?.values,
      jobDetails: {
        ...formRef.current?.values.jobDetails,
        jobTitle: valueOrDefault(props?.jobPostingDetails?.jobTitle, ''),
        jobDescription: valueOrDefault(props?.jobPostingDetails?.jobDesc, ''),
        jobDomain: valueOrDefault(props?.jobPostingDetails?.domain, ''),
        jobIndustry: valueOrDefault(props?.jobPostingDetails?.industry, ''),
        primarySkill: valueOrDefault(props?.jobPostingDetails?.jobPrimarySkill, ''),
        jobDepartment: valueOrDefault(props?.jobPostingDetails?.departmentName, ''),
        jobStartDate: jobStartDate,
        shiftTimingFrom: valueOrDefault(
          props?.jobPostingDetails?.shiftTimingFrom,
          formRef.current?.values?.jobDetails?.shiftTimingFrom
        ),
        shiftTimingTo: valueOrDefault(
          props?.jobPostingDetails?.shiftTimingTo,
          formRef.current?.values?.jobDetails?.shiftTimingTo
        ),
        workingHours: valueOrDefault(props?.jobPostingDetails?.workingHours, undefined),
        numberOfOpenings: valueOrDefault(props?.jobPostingDetails?.numberOfOpening, undefined),
        experience: valueOrDefault(props?.jobPostingDetails?.jobExperience, undefined),
        experienceId: valueOrDefault(props?.jobPostingDetails?.experianceId, ''),
        requiredDegree: transformedRequiredDegree[0],
        desiredCertifications: certificationsAndLicenses,
        state: valueOrDefault(props?.jobPostingDetails?.stateName, ''),
        city: valueOrDefault(props?.jobPostingDetails?.city, ''),
        zipCode: valueOrDefault(props?.jobPostingDetails?.zipCode, ''),
        countryName:valueOrDefault(props?.jobPostingDetails?.countryName, ''),
        countryCode:valueOrDefault(props?.jobPostingDetails?.countryCode, ''),
        requiredSkills: transformedRequiredSkill,
        isRemoteJob: ternary(props?.jobPostingDetails?.remoteJob === "y", true, false),
      },
      jobTypeAndRate: {
        jobTypeId: valueOrDefault(props?.jobPostingDetails?.jobTypeId, ''),
        jobType: valueOrDefault(props?.jobPostingDetails?.jobType, ''),
        jobTaxTermId: valueOrDefault(props?.jobPostingDetails?.jobTaxTermId, ''),
        taxTerms: valueOrDefault(props?.jobPostingDetails?.taxTerms, ''),
        jobDuraion: valueOrDefault(
          ternary(props?.jobPostingDetails?.jobDuraion !=="", props?.jobPostingDetails?.jobDuraion, 0),
          formRef.current?.values?.jobTypeAndRate?.jobDuraion
        ),
        billRate: valueOrDefault(
          props?.jobPostingDetails?.billRatePckg?.billRate,
          formRef.current?.values?.jobTypeAndRate?.billRate
        ),
        billRateCurrencyType: valueOrDefault(
          props?.jobPostingDetails?.billRatePckg?.packageCurrency,
          formRef.current?.values?.jobTypeAndRate?.billRateCurrencyType
        ),
        salaryRange: salaryRangeRate,
        salaryRangeCurrencyType: valueOrDefault(
          props?.jobPostingDetails?.package?.packageCurrency,
          formRef.current?.values?.jobTypeAndRate?.salaryRangeCurrencyType
        ),
        paymentMode: valueOrDefault(props?.jobPostingDetails?.paymentMode, ''),
        jobWorkAuthorization: valueOrDefault(props?.jobPostingDetails?.jobWorkAuthorization, []),
        isRateNigotiable: ternary(props?.jobPostingDetails?.rateNegotiable === "y", true, false),
      },
      applicationProcess: {
        jobEndDate: jobEndDate,
        applicationMethod: valueOrDefault(
          props?.jobPostingDetails?.applicationMethod,
          formRef.current?.values?.applicationProcess?.applicationMethod
        ),
        requiredDocuments: valueOrDefault(props?.jobPostingDetails?.requiredDocuments, []),
        applicationInstructions: valueOrDefault(props?.jobPostingDetails?.applicationInstructions, ""),
      }, 
    });
  }

  useEffect(() => {
    handleApiCompletion();
  }, []);

useEffect(()=>{
  let jobPaymentModesListFilter;
  
  if(props?.jobPostingDetails?.jobType)
  {
    if(props?.jobPostingDetails?.jobType === "Full Time"){
      jobPaymentModesListFilter = jobPaymentModesListAll.filter((option:any)=>
      option.id ==="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
      );
      setJobPaymentModesList(jobPaymentModesListFilter)
      
    }else{
      jobPaymentModesListFilter = jobPaymentModesListAll.filter((option:any)=>
      option.id !=="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
      );
      setJobPaymentModesList(jobPaymentModesListFilter)
    }
  }
},[jobPaymentModesListAll])
  // useEffect to set on client login details
  useEffect(() => {
    formRef.current?.setValues({
      ...formRef.current?.values,
      companyInformation: {
        ...formRef.current?.values?.companyInformation,
        companyId: valueOrDefault(ClientUserDetails?.id, ""),
        companyName: valueOrDefault(ClientUserDetails?.companyName, ""),
        companyEmail: valueOrDefault(ClientUserDetails?.email, ""),
        clientPhone: valueOrDefault(ClientUserDetails?.contactDetails?.primaryPhoneNumber, "")
      },      
    })
  }, [ClientUserDetails]);

  useEffect(() => {
    if (props?.jobPostingDetails?.experianceId) {
      let selectedWorkExperience = jobWorkExperiencesList.find(
        (n) => n.id === props?.jobPostingDetails?.experianceId
      );
      formRef.current?.setFieldValue(
        "jobDetails.experienceText",
        selectedWorkExperience?.experience
      );
    }
    
  }, [jobWorkExperiencesList]);

  const handleCustomChange = (selectedTaxtermId: string) => {   

    _GetJobWorkAuthorizationById(selectedTaxtermId);
  };

  const _handleCustomJobTypeChange = (jobType:string)=>{
      formRef.current?.setFieldValue("jobTypeAndRate.taxTerms","");
      formRef.current?.setFieldValue("jobTypeAndRate.jobWorkAuthorization",[]);
      if(jobType === "Full Time"){
        let jobPaymentModesListFullTime = jobPaymentModesListAll.filter((option:any)=>
        option.id ==="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
        );        
        setJobPaymentModesList(jobPaymentModesListFullTime)
        formRef.current?.setFieldValue("jobTypeAndRate.paymentMode",jobPaymentModesListFullTime.length > 0 ? jobPaymentModesListFullTime[0].paymentMode : "");
        formRef.current?.setFieldValue("jobTypeAndRate.jobDuraion","");
      }else{
        let jobPaymentModesListOthers = jobPaymentModesListAll.filter((option:any)=>
        option.id !=="e3f1acf4-cb51-419f-a3f9-c3a76d7d3f34"
        );
        setJobPaymentModesList(jobPaymentModesListOthers)
        formRef.current?.setFieldValue("jobTypeAndRate.paymentMode","");
        formRef.current?.setFieldValue("jobTypeAndRate.salaryRange","");
      }
      formRef.current?.setFieldTouched("jobTypeAndRate.paymentMode",false);
      formRef.current?.setFieldTouched("jobTypeAndRate.taxTerms",false);
      formRef.current?.setFieldTouched("jobTypeAndRate.jobWorkAuthorization",false);
      formRef.current?.setFieldTouched("jobTypeAndRate.salaryRange",false);
      formRef.current?.setFieldTouched("jobTypeAndRate.jobDuraion",false);
      _GetJobWorkAuthorization();
  }

  // GET Master domains
  const _GetJobDomains = async () => {
    getJobMasterDomains()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobDomainsList(response?.data?.entityList);
        } else {
          setJobDomainsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // GET Master Education / Degree list
  const _GetRequireDegrees = async () => {
    getJobRequireDegrees()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobRequireDegreesList(response?.data?.entityList);
        } else {
          setJobRequireDegreesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobTypes
  const _GetJobTypes = async () => {
    getJobTypes()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTypesList(response?.data?.entityList);
        } else {
          setJobTypesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobTaxTerms
  const _GetJobTaxTerms = async () => {
    getJobTaxTerms()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTaxTermsList(response?.data?.entityList);
        } else {
          setJobTaxTermsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobPaymentModes
  const _GetJobPaymentModes = async () => {
    getJobPaymentModes()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobPaymentModesList(response?.data?.entityList);
          setJobPaymentModesListAll(response?.data?.entityList);
        } else {
          setJobPaymentModesList([]);
          setJobPaymentModesListAll([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobWorkAuthorization
  const _GetJobWorkAuthorization = async () => {
    getJobWorkAuthorization()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkAuthorizationList(response?.data?.entityList);
        } else {
          setJobWorkAuthorizationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master GetJobApplicationMethods
  const _GetJobApplicationMethods = async () => {
    getJobApplicationMethods()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobApplicationMethodsList(response?.data?.entityList);
        } else {
          setJobApplicationMethodsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET Master JobRequireDocuments
  const _GetJobRequireDocuments = async () => {
    getJobRequireDocuments()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobRequireDocumentsList(response?.data?.entityList);
        } else {
          setJobRequireDocumentsList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET get job work authorization by id
  const _GetJobWorkAuthorizationById = async (id: string) => {
    getJobWorkAuthorizationById(id)
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          let jobWorkAuthorizationList: string[] =
            response?.data?.entityList?.map(
              (item: any) => item.workAuthorization
            );
            setJobWorkAuthorizationList(response?.data?.entityList)
            
          formRef.current?.setFieldValue(
            "jobTypeAndRate.jobWorkAuthorization",
            jobWorkAuthorizationList
          );
          
        } 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get Master Currency List
  const _GetMasterCurrencyList = () => {
    GetAllCurrencyList()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobCurrencyList(response?.data?.entityList);
        } else {
          setJobCurrencyList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get salary range size list
  const _GetJobSalaryRange = () => {
    const payload = {
      maxValue: 50000,
      rangeValue: 10000,
    };
    GetCompanySizeList(payload)
      ?.then((response) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          setJobSalaryRangeList(response?.data?.entityList);
        } else {
          setJobSalaryRangeList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get Master Job work experiences
  const _GetJobWorkExperinecesList = () => {
    getJobWorkExperience()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkExperiencesList(response?.data?.entityList);
        } else {
          setJobWorkExperiencesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get client company information details
  const _GetClientByIds = ()=>{   
    
    const clientId = valueOrDefault(getUserDetails()?.clientId, "");

    GetClientById(clientId)
    .then((response: any) => {
      if (response?.data?.message?.appStatusCode === "CL_RSF") {
        SetClientUserDetails(response?.data?.entity);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

   const _handleAutoPopulatedValues = async(AIvalues:MessageList[]) =>{
    setAIContentPreview({
      ...AIContentPreview,
      content:AIvalues
    });
    setIsGenerateJobDetailsPopup(false);
    // set Form field value    
    FormAutoPopulateDetailsSet(
      AIvalues,
      formRef,
      jobWorkExperiencesList,
      jobTypesList,
      jobRequireDegreesList
    );
    const values: MessageList = AIvalues[0];
    const matchedJobType = findJobTypeByValue(
      jobTypesList,
      values?.jobAssignmentType ?? ""
    );
    _handleCustomJobTypeChange(matchedJobType?.jobType ?? "");    
    //Autofill google address for location
    handleAddressAutofill(AIvalues[0]?.location ?? "", formRef);
   }

  function handleEditJob(response: any) {
    if (response?.data?.message?.appStatusCode === "RC_RSF") {
      if (jobMode === "UPDATE") {
        setIsUpdateSuccessPopup(true);
      }else{
        setIsPublishSuccessPopup(true);
      }
      
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError: false,
        message: "",
      });
    } else {
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: true,
        message: response?.data?.message?.appStatusDescription,
      });
    }
  }

  function handleAddJob(response: any) {
    if (response?.data?.message?.appStatusCode === "RC_RSF") {
      if (jobMode === "SAVE") {
        setIsSaveSuccessPopup(true);
      } else {
        setIsPublishSuccessPopup(true);
      }
      
      setPageStatus({
        ...pageStatus,
        isSuccess: true,
        isError: false,
        message: "",
      });
    } else {
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: true,
        message: response?.data?.message?.appStatusDescription,
      });
    }
  }

  // publish, save and continue new job

  const _AddJobSubmit = async (payload: JobPostingState, actions:any) => {
    //send the logged client id in payload
    
    const updatedPayload = {
      ...payload,
      publishJob: ((jobMode === "UPDATE" && props?.mode ==="EDIT") || (jobMode === "PUBLISH")) ? true : false,
      clientId: loggedUserRole === UserAuth.ClientTeamMember? recruiterData?.entity?.clientId  : recruiterData?.entity?.id,
      addedById: recruiterData?.entity?.id,
      jobDetails:{
        ...payload.jobDetails,
        requiredDegree:[{...payload.jobDetails?.requiredDegree}],
        requiredSkills: GetSkillNameArray(payload?.jobDetails?.requiredSkills)
      },
      jobTypeAndRate:{
        ...payload.jobTypeAndRate,
        jobDuraion: !Number.isNaN(Number(payload.jobTypeAndRate?.jobDuraion?.toString())) ? Number(payload.jobTypeAndRate?.jobDuraion) : 0
      },
      companyInformation: {
        ...addJobInstialState.companyInformation,
        companyId: ClientUserDetails?.id,
        companyName: ClientUserDetails?.companyName,
        companyEmail: ClientUserDetails?.email,
        industry: ClientUserDetails?.industries[0]?.industry,
        clientPhone: valueOrDefault(ClientUserDetails?.contactDetails?.primaryPhoneNumber, "")
      },
    };

    setPageStatus({
      ...pageStatus,
      isSuccess: false,
      isError: false,
      message: "",
    });
    
    if(props?.mode ==="EDIT" || props?.mode ==="PENDING"){
      
      const modifiedPayloadForEdit = {
        ...updatedPayload,
        id: props?.jobPostingDetails?.id,
        jobId: props?.jobPostingDetails?.jobId,
        jobStatus: props?.jobPostingDetails?.jobStatus,
        postingDate: props?.jobPostingDetails?.postingDate,
        modifiedById: loggedUserId,
        clientId: props?.jobPostingDetails?.clientId,
        addedById: props?.jobPostingDetails?.addedById,
      }

      await editJob(modifiedPayloadForEdit)
        .then((response: any) => {
          handleEditJob(response);
        })
        .catch((error) => {
          console.log(error);
          setPageStatus({
            ...pageStatus,
            isSuccess: false,
            isError: true,
            message: "Something Went wrong!!!",
          });
        })
        .finally(()=>{
          actions.setSubmitting(false);
        })

    }else{

        await addJob(updatedPayload)
          .then((response: any) => {
            handleAddJob(response);
          })
          .catch((error) => {
            console.log(error);
            setPageStatus({
              ...pageStatus,
              isSuccess: false,
              isError: true,
              message: "Something Went wrong!!!",
            });
          })
          .finally(()=>{
            actions.setSubmitting(false);
          })
    }
  };

  const _handleSave = () => {
    setJobMode("SAVE");

    setIsSavePopup(true);
  };

  const _handleUpdate = () => {
    setJobMode("UPDATE");
    formRef.current?.handleSubmit();
  };

  const _handlePublish = () => {
    setJobMode("PUBLISH");
    formRef.current?.handleSubmit();
  };

  const _handleSavePopup = () => {
    setIsSavePopup(false);
    formRef.current?.handleSubmit();
  };

  const _handleUpdateConfirmation = ()=>{
    setIsUpdateConfirm(false);
    _handleUpdate();
  }

  const _handlePublishConfirmation = ()=>{
    setIsPublishConfirm(false);
    _handlePublish();
  }

  function _handleClosePopup() {
    setIsSavePopup(false);
    setJobMode("");
  }

  const handlePreview = (display: boolean) => {
    setDisplayPreview(display);
  };

  const _handlePublishButton = ()=>{
    setJobMode('PUBLISH');
    setIsPublishConfirm(true);
  }

  const _handleUpdateButton = ()=>{
    setJobMode("UPDATE");
    setIsUpdateConfirm(true);
  }

  useEffect(() => {
    setStepperState({
      step1:GetStepperStatus(formRef, 1),
      step2:GetStepperStatus(formRef, 2),
      step3:GetStepperStatus(formRef, 3),
    })
  }, [formRef?.current?.values, 
    formRef?.current?.touched, 
    formRef?.current?.errors,
    formRef?.current?.handleChange ]);

  const validateFormik = async (values: JobPostingState) => {
    setStepperState({
      step1:GetStepperStatus(formRef, 1),
      step2:GetStepperStatus(formRef, 2),
      step3:GetStepperStatus(formRef, 3),
    })
    const errors: Record<string,any> = {};
    let isPublishing = ((jobMode === "UPDATE" && props?.mode ==="EDIT") || (jobMode === "PUBLISH"));
    
    if (isPublishing) {
      let startDate = moment(values.jobDetails.jobStartDate).startOf('day');
      let endDate = moment(values.applicationProcess.jobEndDate).startOf('day');
      let publishDate = moment().startOf('day'); // as this is being published right now
      
      if (startDate.diff(endDate, 'days') < 14) {
        errors.jobDetails = {jobStartDate: "Job Start Date should be at least 14 days after Job End Date."};
      }

      if (endDate.diff(publishDate, 'days') < 28) {
        errors.applicationProcess = {jobEndDate: "Job End Date should be at least 28 days after Job Publish Date."};
      }

      if (startDate.diff(publishDate, 'days') < 0) {
        errors.jobDetails = {jobStartDate: "Job Start Date cannot be less than Job Publish Date."};
      }

      if (endDate.diff(publishDate, 'days') < 0) {
        errors.applicationProcess = {jobEndDate: "Job End Date cannot be less than Job Publish Date."};
      }
    }

    return errors;
  };


  const _renderStepperHeader = () =>{
    return(
    <Stepper activeStep={activeStep} alternativeLabel nonLinear={false} >
        {steps.map((label, index:number) => (
          <Step key={label} >
            <StepLabel  
               StepIconComponent={renderStepIcon(index, stepperStatus)}
              >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  function _renderStepContent(
    step: number,
    formHandlers: FormHandlers,
    formState: FormState,
    setValues: any
  ) {
    switch (step) {
      case 0:
        return (
          <>
          <JobDetailsFormSection
            handleChange={formHandlers?.handleChange}
            handleBlur={formHandlers?.handleBlur}
            setFieldValue={formHandlers?.setFieldValue}
            setFieldTouched={formHandlers?.setFieldTouched}
            values={formState?.values}
            touched={formState?.touched}
            errors={formState?.errors}
            jobDomainsList={jobDomainsList}
            jobRequireDegreesList={jobRequireDegreesList}
            jobWorkExperiencesList={jobWorkExperiencesList}
            mode={props?.mode}
          />
          <CompanyInformationFormSection
            clientLoginData={ClientUserDetails}
          />
          </>
        );
      case 1:
        return (
          <JobTypeRateAssignmentFormSection
            handleChange={formHandlers?.handleChange}
            handleCustomChange={handleCustomChange}
            _handleCustomJobTypeChange={_handleCustomJobTypeChange}
            handleBlur={formHandlers?.handleBlur}
            setFieldValue={formHandlers?.setFieldValue}
            setFieldTouched={formHandlers?.setFieldTouched}
            values={formState?.values}
            touched={formState?.touched}
            errors={formState?.errors}
            jobTypesList={jobTypesList}
            jobTaxTermsList={jobTaxTermsList}
            jobCurrency={jobCurrency}
            jobPaymentModesList={jobPaymentModesList}
            jobWorkAuthorizationList={jobWorkAuthorizationList}
            jobSalaryRangeList={jobSalaryRangeList}
            mode={props?.mode}
          />
        );
      case 2:
        return (
          <ApplicationProcessFormSection
            handleChange={formHandlers?.handleChange}
            handleBlur={formHandlers?.handleBlur}
            setFieldValue={formHandlers?.setFieldValue}
            setFieldTouched={formHandlers?.setFieldTouched}
            setValues={setValues}
            values={formState?.values}
            touched={formState?.touched}
            errors={formState?.errors}
            jobApplicationMethodsList={jobApplicationMethodsList}
            jobRequireDocumentsList={jobRequireDocumentsList}
          />
        );
      case 3:
        return (
          <CompanyInformationFormSection
            clientLoginData={ClientUserDetails}
          />
        );
      default:
        return <div>Not Step Found</div>;
    }
  }

  function _renderStepActionButton(
  isSubmitting: boolean,
  handleSubmit: any
) {

  return (
    <RenderStepActionButton
        isSubmitting={isSubmitting}
        isValid={valueOrDefault(formRef.current?.isValid,false)} 
        handleSubmit={handleSubmit}
        activeStep={activeStep}
        isGenerateJobDetailsPopup={isGenerateJobDetailsPopup}
        setIsGenerateJobDetailsPopup={setIsGenerateJobDetailsPopup}
        AIContentPreview={AIContentPreview}
        setAIContentPreview={setAIContentPreview}
        handleBack={_handleBack}
        isLastStep={isLastStep}
        handlePreview={handlePreview}
        handleSave={_handleSave}
        handleUpdateButton={_handleUpdateButton}
        handlePublishButton={_handlePublishButton}
        handleNext={_handleNext}
        loggedUserStatus={loggedUserStatus}
        mode={valueOrDefault(props?.mode,"")}
        pageStatus={pageStatus}
        submittedMode={jobMode}
      />
  );
}


  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  function _handleNext() {
    setActiveStep(activeStep + 1);
  }
  

  return (
    <>
      <Grid container mb={8}>
        <Grid item xs={12} p={1}>
          <Paper
            elevation={0}
            style={{ padding: "0px 8px 8px 8px", background: "#fff" }}
          >
            <Formik
              initialValues={addJobInstialState}
              validate={validateFormik}
              validationSchema={AddJobValidationSchema}
              onSubmit={(values, actions) => {             
                _AddJobSubmit(values,actions);
              }}
              innerRef={formRef}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setValues,
                setFieldTouched,
                values,
                touched,
                errors,
                isSubmitting
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {pageStatus?.isError && (
                        <CustomAlert
                          key={21}
                          message={(pageStatus?.message  !=="" )|| ( pageStatus?.message === undefined) ? pageStatus?.message : "Something went wrong!!!"}
                          type={"error"}
                          onClose={()=>{}}
                        />
                      )}
                      
                    </Grid>
                    <Grid item xs={12}>
                      {_renderStepperHeader()}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={1}>
                    {/* Render step content form section */}                     
                    <Grid item xs={12}>
                        {_renderStepActionButton(isSubmitting, handleSubmit)} 
                    </Grid>                                       
                    <Grid item xs={12}>
                    {
                      _renderStepContent(
                        activeStep,
                        {
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          setFieldTouched,
                        },
                        {
                          values,
                          errors,
                          touched,
                        },
                        setValues
                      )
                    }
                    </Grid>                    
                    <Grid item xs={12} sm={12}>
                      {/* Confiramtion dialog for save and continue action */}
                      <SaveConfirmPopup
                        isOpen={isSavePopup}
                        handleSave={_handleSavePopup}
                        handleClose={_handleClosePopup}
                        type={"AddJob"}
                        title={"AddJob"}
                      />
                      {/* Confiramtion dialog for update action on edit case */}
                      <SaveConfirmPopup
                        isOpen={isSavePopup}
                        handleSave={_handleSavePopup}
                        handleClose={_handleClosePopup}
                        type={"AddJob"}
                        title={"AddJob"}
                      />
                      {/* After job posting saved job success popup */}
                      <JobPostSuccessPopup
                        isOpen={isSaveSuccessPopup}
                        userRole={loggedUserRole}
                      />
                      {/* Edit case is update confirm popup dialog */}
                      <UpdateConfirmPopup
                        isOpen={isUpdateConfirm}
                        handleClose={()=>{ setIsUpdateConfirm(false)}}
                        type={"saveJob"}
                        title={"saveJob"}
                        handleUpdateConfirmation={_handleUpdateConfirmation}
                        isSubmitting={isSubmitting}
                      />

                      {/* Edit case is update confirm popup dialog */}
                      <PublishConfirmPopup
                        isOpen={isPublishConfirm}
                        handleClose={()=>{ setIsPublishConfirm(false)}}
                        type={"saveJob"}
                        title={"saveJob"}
                        handlePublishConfirmation={_handlePublishConfirmation}
                      />

                      {/* After job posting publish success popup */}
                      <JobPostPublishSuccess
                        isOpen={isPublishSuccessPopup}
                        userRole={loggedUserRole}
                      />

                  {/* After job update  success popup */}
                      <JobUpdateSuccessPopup
                        isOpen={isUpdateSuccessPopup}
                        mode={props?.mode}
                        userRole={loggedUserRole}
                      />

                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>

      <GenerateJobPostingDetailsPopup
        isOpen={isGenerateJobDetailsPopup}
        handleClose={()=>{setIsGenerateJobDetailsPopup(false)}}
        clientLoginData={ClientUserDetails}
        handleOnContinue={(value)=>{_handleAutoPopulatedValues(value)}}
      />

    <PreviewAIGeneratedDetailsPopup
        isOpen={AIContentPreview?.isOpen}
        handleClose={()=>{
          setAIContentPreview({
            ...AIContentPreview,
            isOpen:false
          });
        }}
        generatedJobDetailsResult={AIContentPreview?.content}
      />

      <PreviewJobPopup
        job={formRef.current?.values}
        display={displayPreview}
        onClose={() => handlePreview(false)}
        onPublish={_handlePublishButton}
        clientLoginData={ClientUserDetails}
        onSave={_handleSave}
        mode={props.mode}
        onUpdate={()=>{
          _handleUpdateButton();
        }}
      ></PreviewJobPopup>
    </>
  );
};

export default PostingJobForm;

const renderStepIcon = (index:number, stepperStatus:any) => (props:any) => (
  <CustomStepIcon
    {...props}
    completed={getClassName(index + 1, stepperStatus) === StepperStatus.completed}
    error={getClassName(index + 1, stepperStatus) === StepperStatus.isError}
    stepNumber={index + 1}
  />
);
