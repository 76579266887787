import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { AppState } from "../../../store/AppState";
import { ManageClientValidationSchema } from "./Components/FormModel/ManageClientValidationSchema";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetClientById, ManageClientSave } from "../../../Services/clientService";
import { PermissionsEnums } from "../../../data/permission";
import { ManageClientFormModel } from "../../../models/client/ManageClient/ManageClientFormModel";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { PhoneVerifyPopup } from "../../Common/PhoneVerify/PhoneVerify";
import VerifyPhoneNumberSelectorPopup from "../../Common/PhoneVerify/VerifyPhoneNumberSelectorPopup";
import SaveConfirmPopup from "../../Common/Popup/AppPopup/SaveConfirmPopup";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import { CustomAlert } from "../../ui/CustomAlert";
import { RequestFormPayload } from "./Components/FormModel/ManageClientModel";
import AccountManagerFormSection from "./Components/FormSection/AccountManagerFormSection";
import ClientDetailsFormSection from "./Components/FormSection/ClientDetailsFormSection";
import OtherInformationFormSection from "./Components/FormSection/OtherInformationFormSection";
import './ManageClient.scss';
import { valueOrDefault } from "../../../utils/complexityUtils";

interface ClientOtherDetailsProps {
  jobType: string;
  jobTypeId: string;
  jobTaxTerm?: string;
  jobTaxTermId?: string;
  payrollOverhead: number;
  paymentTerms: number;
  paymentMode: string;
  clientFee?: number;
  clientFeeType?: string;
  jobRialtoCommission?: number;
  jobRialtoCommissionType?: string;
  recruiterCommission?: number;
  recruiterCommissionType?: string;
  createdDate: string;
  createdById: string;
  createdByName: string;
  createdByRole: string;
  modifiedDate: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedByRole: string;
  modifiedByEmail: string;
}

export interface ManageClientFormProps {
  mode?: "EDIT"|"PENDING"|"ADD";
  onUpdateSuccess: () => void;
  setSelectedClient?: any;
}

const ManageClientForm: React.FC<ManageClientFormProps> = ({
  mode,
  onUpdateSuccess,
  setSelectedClient
}) => {
    const navigate = useNavigate();
    const formRef = useRef<FormikProps<ManageClientFormModel>>(null);

    // Set view mode if only view mode permission
    const { features, modules } = useSelector((state: AppState) => state.userPermissions);
    const combinedArray = [...features, ...modules];
    const permissionsArray: PermissionsEnums[] = combinedArray || [];    
    const viewMode = !permissionsArray.includes(PermissionsEnums.Manage_CLUpdate);
    
    const ManageClientFormState = useSelector(
        (state: AppState) => state.ManageClientFormState
      )
    const [isOpenSaveConfirmDialog, setIsOpenSaveConfirmDialog] = useState<boolean>(false);
    const [opendialog, setOpendialog] = useState(false);
    const [opendialogSelectNumber, setOpenDialogSelectNumber] = useState<boolean>(false);
    const [selectedNumber, setSelectedNumber] = useState(
      process.env.REACT_APP_PRIMARY_NUMBER
    );
    const [selectedClientDetails, setSelectedClientDetails] = useState<any>();
    const  loggedUserStatus = "Approved"

    // component states
    const [isUpdateAction, setIsUpdateAction] = useState<boolean>(false);
    const [pageStatus, setPageStatus] = useState<any>({
      isSuccess: false,
      isError: false,
      message: "",
      loading: false,
    });

    //  Handle Close on success snackbar
    const _handleSuccessClose = () => {
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: false,
        message: "",
        loading: false,
      });
      navigate(getNevLink('/manageclient'));
    }

    const props_VerifyNumberSelector = {
      ...{
        isOpen: opendialogSelectNumber,
        title:"Mobile Number Verification",
        subTitle  :
          "To save the  manage client changes, please select a number to verify with OTP",
        phoneNumbers: {
          primary: process.env.REACT_APP_PRIMARY_NUMBER || '',
          secondary: process.env.REACT_APP_SECONDARY_NUMBER || '',
        },
        selectedNumber: selectedNumber,
        selectedValue: (selectedVal:string) => {
          setSelectedNumber(selectedVal);
        },
        handleVerify: () => {
          setOpenDialogSelectNumber(false);
          setOpendialog(true);
        },
        handleClose: () => {          
          formRef.current?.setSubmitting(false);
          setOpenDialogSelectNumber(false);
        },
      },
    };

    const props_phVerifyPopup = {
      ...{
        phoneNumber: selectedNumber,
        isPrimaryPhone: true,
        isShowTitle: false,
        verifyOTPTitle:
          "To save the manage client changes, please enter OTP below",
        otherPhoneVerifyStatus: true,
        updatePhoneVerify: (isVerified:boolean) => {
          if (isVerified) {
            setOpendialog(false);
            const values = formRef.current?.values as ManageClientFormModel | any;
            const setSubmitting = formRef.current?.setSubmitting as any;
            _submitForm(values, setSubmitting);
          }
        },
        isOpen: opendialog,
        setClose: ()=>{
          formRef.current?.setSubmitting(false);
          setOpendialog(false);
        },
      },
    };

    const _handleClientClear = () =>{
      setSelectedClient([]);
      _clearAll();
    }

    const _clearAll = ()=>{
      const resetValues = {
        ...formRef.current?.values,
        clientId: "",
        selectedClientDetails: {},
        selectedAccountManager:{},
        accountManagerDetails: {
          id: "",
          fullName: "",
          email: "",
          primaryPhone: ""
        },
        otherDetails: {
          jobType: "",
          jobTypeId: "",
          jobTaxTerm: "",
          jobTaxTermId: "",
          payrollOverhead: 0,
          paymentTerms: 30,
          paymentMode: "ACH",
          modeOfDelivery: "",
          clientFee: 0,
          clientFeeType: "USD",
          jobRialtoCommission: 0,
          jobRialtoCommissionType: "Percentage",
          recruiterCommission: 0,
          recruiterCommissionType: "USD",
          oneTimeCommission:undefined
        }
      }
      formRef.current?.setValues(resetValues);
    }

    const _handleConfirmSave = () => {
      setIsOpenSaveConfirmDialog(false);
      setOpenDialogSelectNumber(true);
    }

    const _handleConfirmCancel = () => {
      formRef.current?.setSubmitting(false);
      setIsOpenSaveConfirmDialog(false);
    }

    // Handle the submit button action
    const _handleSubmit = ()=>{
      setIsOpenSaveConfirmDialog(true);
    }

    const _submitForm = (values: RequestFormPayload, setSubmitting: (isSubmitting: boolean) => void) =>{
      setIsUpdateAction(false);
      setPageStatus({
        ...pageStatus,
        isSuccess: false,
        isError: false,
        message: "",
        loading:true
      });

      let requestedPayload:RequestFormPayload = {
        clientId: values.clientId,
        accountManagerDetails: {
          id: values?.accountManagerDetails?.id,
          fullName: values?.accountManagerDetails?.fullName,
          email: values?.accountManagerDetails?.email,
          primaryPhone: values?.accountManagerDetails?.primaryPhone,
        },
        otherDetails: {              
          jobType: values?.otherDetails?.jobType,
          jobTypeId: values?.otherDetails?.jobTypeId,
          payrollOverhead: values?.otherDetails?.payrollOverhead || 0,
          paymentTerms: values?.otherDetails?.paymentTerms,
          paymentMode: values?.otherDetails?.paymentMode,
          modeOfDelivery: values?.otherDetails?.modeOfDelivery,
          jobRialtoCommission: values?.otherDetails?.jobRialtoCommission || 0,
          jobRialtoCommissionType:  "Percentage",
          recruiterCommission: values?.otherDetails?.recruiterCommission,
          recruiterCommissionType: values?.otherDetails?.recruiterCommissionType,
          oneTimeCommission:values?.otherDetails?.oneTimeCommission || 0,
        }
      };

      if (values?.otherDetails?.jobType === "Full Time") {
        requestedPayload.otherDetails.clientFee = values?.otherDetails?.clientFee;
        requestedPayload.otherDetails.clientFeeType = values?.otherDetails?.clientFeeType;
      }else{
        requestedPayload.otherDetails.jobTaxTermId= values?.otherDetails?.jobTaxTermId;
        requestedPayload.otherDetails.jobTaxTerm = values?.otherDetails?.jobTaxTerm;
      }

      ManageClientSave(requestedPayload)
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "CL_RSF") {
          
          setPageStatus({
            ...pageStatus,
            isSuccess: true,
            isError: false,
            message: "Commission information saved successfully!",
            loading:false
          });
          
          setSubmitting(false);
          onUpdateSuccess();
        } else {
          setPageStatus({
            ...pageStatus,
            isSuccess: false,
            isError: true,
            message: response?.data?.message?.appStatusDescription,
            loading:false
          });
          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPageStatus({
          ...pageStatus,
          isSuccess: false,
          isError: true,
          message: "Something Went wrong!!!",
          loading:false
        });
        setSubmitting(false);
      })
      .finally(()=>{
        setIsUpdateAction(true);
        //Refresh the updated selected Client Data
        GetSelectedClientDetails(requestedPayload?.clientId)
      });
    }

  // Handle on client auto complete change
  const _handleClientAutocompleteChange = (selecteClient:any)=>{
    GetSelectedClientDetails(selecteClient?.id);
  }

  // Get Selected Client details
  const GetSelectedClientDetails = (selectedClientId: string) =>{   
    GetClientById(selectedClientId)
    ?.then((response:any)=>{

      if(response?.data?.message?.appStatusCode === "CL_RSF"){        
        setSelectedClientDetails(response?.data?.entity);
        setSelectedClient([response?.data?.entity]);
        
        setFieldValue(response?.data?.entity);
      }

    }).catch((error)=>{
      console.log(error);
    })
  }

  
  const setFieldValue = (values:any) => {

    const sortedData = _sortByModifiedDate(values)

    let otherDetails: any;
    if(sortedData?.length > 0) {
      const mostRecentlyModifiedRow = sortedData[0];      
      
      formRef.current?.setFieldValue('selectedJobType',{
        id:mostRecentlyModifiedRow?.jobTypeId,
        jobType:mostRecentlyModifiedRow?.jobType
      });
      otherDetails = {
        jobTypeId:valueOrDefault(mostRecentlyModifiedRow?.jobTypeId, ""),
        jobType:valueOrDefault(mostRecentlyModifiedRow?.jobType, ""),
        jobTaxTermId:valueOrDefault(mostRecentlyModifiedRow?.jobTaxTermId, ""),
        jobTaxTerm:valueOrDefault(mostRecentlyModifiedRow?.jobTaxTerm, ""),
        jobRialtoCommissionType:mostRecentlyModifiedRow?.jobRialtoCommissionType || "Percentage",
        jobRialtoCommission:valueOrDefault(mostRecentlyModifiedRow?.jobRialtoCommission, 0),
        payrollOverhead:mostRecentlyModifiedRow?.payrollOverhead || undefined,
        recruiterCommissionType:mostRecentlyModifiedRow?.recruiterCommissionType || "USD",
        recruiterCommission:mostRecentlyModifiedRow?.recruiterCommission || undefined,
        clientFeeType:mostRecentlyModifiedRow?.clientFeeType || "USD",
        clientFee:valueOrDefault(mostRecentlyModifiedRow?.clientFee, 0),
      };
    }
  

    formRef.current?.setValues({
      ...formRef.current?.values,
      selectedAccountManager: {
        id:values?.accountManagerDetails?.id,
        firstName:values?.accountManagerDetails?.fullName,
        middleName:"",
        lastName:"",
        fullName:values?.accountManagerDetails?.fullName,
        email:values?.accountManagerDetails?.email,
        primaryPhone:values?.accountManagerDetails?.primaryPhone
      },
      accountManagerDetails:{
        id:values?.accountManagerDetails?.id,
        fullName:values?.accountManagerDetails?.fullName,
        email:values?.accountManagerDetails?.email,
        primaryPhone:values?.accountManagerDetails?.primaryPhone

      },
      otherDetails:{
        ...formRef.current?.values?.otherDetails,
        modeOfDelivery:values?.clientCommonPaymentDetails?.modeOfDelivery,
        oneTimeCommission:values?.clientCommonPaymentDetails?.oneTimeCommission,
        paymentMode:values?.clientCommonPaymentDetails?.paymentMode,
        paymentTerms:values?.clientCommonPaymentDetails?.paymentTerms,
        ... otherDetails
      }
    });
  }

  return (
    <Grid container >
        <Grid item xs={12} p={0}>          
            <Formik
              initialValues={ManageClientFormState}
              validationSchema={ManageClientValidationSchema}
              onSubmit={_handleSubmit}
              innerRef={formRef}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                setValues,
                values,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={0} mt={2} mb={2}>                    
                    {pageStatus?.isSuccess &&
                      <SnackbarPopup 
                      open={pageStatus?.isSuccess} 
                      message={pageStatus?.message} 
                      onClose={_handleSuccessClose} />
                    }

                    {pageStatus?.isError &&
                      <CustomAlert message={pageStatus.message} type={"error"}/>
                    }                    

                    <Grid item xs={12} sm={12} md={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Grid item>
                          <Typography className="PageTitle">Manage Client</Typography>                          
                        </Grid>
                        <ModuleComponent moduleId={PermissionsEnums.Manage_CLUpdate}>
                        <Grid item>
                          <Button
                            disabled={loggedUserStatus !== "Approved" || isSubmitting}
                            className="manageCLientButton"
                            type={undefined}
                            name="saveandcontinue"
                            value="saveandcontinue"
                            variant="outlined"
                            color="primary"
                            onClick={()=>{
                              navigate(getNevLink('/dashboard'));
                            }}
                            data-testid="btn-manageclientform-cancel"
                          >
                            Cancel
                          </Button>
                          { (mode ==="EDIT") && 
                          <Button
                           data-testid="editmanagebutton"
                           disabled={loggedUserStatus !== "Approved" || isSubmitting}
                           endIcon={isSubmitting && <CircularProgress
                            size={"16px"}
                            color={"inherit"}
                          />}
                           className="manageCLientButton"
                           type={undefined}
                           name="update"
                           value="udpate"
                           variant="contained"
                           onClick={() => {                            
                           }}
                          >
                            Update
                          </Button>
                        }

                        { (mode === "ADD" ) && 
                          <Button
                            disabled={loggedUserStatus !== "Approved" || isSubmitting}
                            endIcon={isSubmitting && <CircularProgress
                              size={"16px"}
                              color={"inherit"}
                            />}
                            className="manageCLientButton"
                            type="submit"
                            name="publish"
                            value="publish"
                            variant="contained"
                            color="primary"
                            onClick={ () => {
                              setFieldValue('submitButton', 'SAVE');
                            }}
                            data-testid="btn-manageclientform-submit"
                          >
                            Save
                          </Button>
                        }
                        </Grid> 
                        </ModuleComponent>
                        
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider className="borderedDivider" />

                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} mb={2}>
                      <ClientDetailsFormSection
                       values={values}
                       setFieldValue={setFieldValue}
                       setFieldTouched={setFieldTouched}
                       errors={errors}
                       touched={touched}
                       handleClientAutocompleteChange={_handleClientAutocompleteChange}
                       handleClientClear={_handleClientClear}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} mb={2}>
                      <AccountManagerFormSection
                       handleChange={handleChange}
                       handleBlur={handleBlur}
                       values={values}
                       setFieldValue={setFieldValue}
                       setFieldTouched={setFieldTouched}
                       errors={errors}
                       touched={touched}
                       selectedClientDetails={selectedClientDetails}
                       viewMode={viewMode}
                       isUpdateAction={isUpdateAction}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} mb={2}>
                      <OtherInformationFormSection
                       handleBlur={handleBlur}
                       values={values}
                       setFieldValue={setFieldValue}
                       selectedClientDetails={selectedClientDetails}
                       setValues= {setValues}
                       errors={errors}
                       touched={touched}
                       viewMode={viewMode}
                       isUpdateAction={isUpdateAction}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} pt={0}>
                      {/* Confiramtion dialog for save and continue action */}
                      <SaveConfirmPopup
                        isOpen={isOpenSaveConfirmDialog}
                        handleClose={_handleConfirmCancel}
                        handleSave={_handleConfirmSave}
                        title={"Do you want to save the commission details?"}
                      />
                     
                    </Grid>
                    <Grid item xs={12} p={0}>
                    <PhoneVerifyPopup {...props_phVerifyPopup}></PhoneVerifyPopup>
                  <VerifyPhoneNumberSelectorPopup
                    {...props_VerifyNumberSelector}
                  ></VerifyPhoneNumberSelectorPopup>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          
        </Grid>
      </Grid>
  );
};

export default ManageClientForm;

export const _sortByModifiedDate = (values:any) =>{

  const sortedDataList: ClientOtherDetailsProps[] = values?.otherDetails;
  // sorts descending so latest (udpated or created) date is first
  sortedDataList.sort((a: any, b:any) => {
    // priority to modified date if exists
    const lastUpdatedA = (a?.modifiedDate !== undefined) ? new Date(a.modifiedDate) : new Date(a.createdDate);
    const lastUpdatedB = (b?.modifiedDate !== undefined) ? new Date(b.modifiedDate) : new Date(b.createdDate);
    return lastUpdatedB.getTime() - lastUpdatedA.getTime();
  });

  return sortedDataList;
}
