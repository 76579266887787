import React from 'react';
import { TextField, Grid, FormControl, InputLabel, Input } from '@mui/material';
import { searchOptions } from '../../../../constants/googlePlaceOption';
import PlacesAutocomplete from "react-places-autocomplete";
import { ErrorMessage } from '../../../ui/ErrorMessage';
import getAddressForAutoFill from '../../../../utils/getAddressForAutoFill';
import { valueOrDefault } from '../../../../utils/complexityUtils';

interface Props {
  required?: {
    city:boolean;
    state:boolean;
    countryName:boolean;
  };
  handleBlur?: any;
  handleOnChange?: (value: any) => void;
  setFieldValue:any;
  selectedValue?: {
    city:string;
    state:string;
    countryName:string;
  };
  touched?: {
    city:boolean;
    state:boolean;
    countryName:boolean;
  };
  errors?: {
    city:string;
    state:string;
    countryName:string;
  };
  placeholder?: string;
  disableClearable?:boolean;
  cityFieldName?:string;
  stateFieldName?:string;
  stateCodeFieldName?:string;
  zipCodeFieldName?:string;
  countryNameFieldName?:string;
  countryCodeFieldName?:string;
}

const CityStateCountryAutoComplete: React.FC<Props> = ({
    handleBlur,
    setFieldValue,
    selectedValue={
        city:"",
        state:"",
        countryName:"",
    },
    touched={
        city:false,
        state:false,
        countryName:false,
    },
    errors={
        city:"",
        state:"",
        countryName:"",
    },
    cityFieldName= "city",
    stateFieldName= "state",
    stateCodeFieldName= "stateCode",
    zipCodeFieldName= "zipCode",
    countryNameFieldName= "country",
    countryCodeFieldName= "countryCode",
  }) => {

    const autoCompleteGetJobLocation = async (address: any) => {
        let resp = await getAddressForAutoFill(address);
        setFieldValue(cityFieldName, valueOrDefault(resp?.city, ""));
        setFieldValue(stateFieldName, valueOrDefault(resp?.state, ""));
        setFieldValue(stateCodeFieldName, valueOrDefault(resp?.stateCode, ""));
        setFieldValue(zipCodeFieldName, valueOrDefault(resp?.pin, ""));
        setFieldValue(countryNameFieldName, valueOrDefault(resp?.country, ""));
        setFieldValue(countryCodeFieldName, valueOrDefault(resp?.countryCode, ""));
      };

    const renderFuncCurrentJobLocation = ({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }: any) => (
        <>
          <FormControl
            variant="standard"
            error={touched?.city && Boolean(errors?.city)}
            fullWidth
            className="jrTextField"
            data-testid="cityinp2"
          >
            <InputLabel htmlFor="address-input">City</InputLabel>
            <Input
              {...getInputProps()}
              name={cityFieldName}
              type="text"
              onBlur={handleBlur}
              fullWidth
              data-testid="cityinp"
              placeholder="Enter City"
              required
            />
            <ErrorMessage
              errorText={touched?.city && errors?.city}
            />
            <div className="autocomplete-dropdown-container jrAddressAutoComplete">
              {loading && <div>Loading...</div>}
              {suggestions?.map((suggestion: any) => (
                <div {...getSuggestionItemProps(suggestion)}>
                  <span
                    onChange={autoCompleteGetJobLocation}
                    style={{ cursor: "pointer" }}
                  >
                    {suggestion?.description}
                  </span>
                </div>
              ))}
            </div>
          </FormControl>
        </>
      );

  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={selectedValue?.city}
            onChange={(eventValue: any) => {
                setFieldValue(cityFieldName, eventValue);
            }}
            onSelect={autoCompleteGetJobLocation}
          >
            {renderFuncCurrentJobLocation}
          </PlacesAutocomplete>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="jrTextField"
            id="state"
            name={stateFieldName}
            label="State"
            variant="standard"
            placeholder="Enter State"
            value={selectedValue?.state}
            onChange={(event)=>{
                setFieldValue(stateFieldName, event.target.value)
            }}
            onBlur={handleBlur}
            error={
              touched?.state && Boolean(errors?.state)
            }
            helperText={touched?.state && errors?.state}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            className="jrTextField"
            id="country"
            name={stateFieldName}
            label="Country"
            variant="standard"
            placeholder="Enter country"
            value={selectedValue?.countryName}
            onChange={(event)=>{                
                setFieldValue(countryNameFieldName, event.target.value)
            }}
            onBlur={handleBlur}
            error={
              touched?.countryName && Boolean(errors?.countryName)
            }
            helperText={touched?.countryName && errors?.countryName}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };
  
  export default CityStateCountryAutoComplete;
