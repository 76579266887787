import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetAllSkills } from "../../../../../../Services/recruiterService";
import toTitles from "../../../../../../utils/toTitles";

interface Skill{
  id:string;
  skillName:string;
}
interface JobMutliSkillAutocomepleteProps {
  label:string;
  fieldName:string;
  handleOnChange?:any;
   handleBlur: (event: React.FocusEvent<any>) => void;
    setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  selectedValue: Skill[] | string[];
  isError: boolean;
  isRequired: boolean;
  helperText: string;
  placeholderText: string;
  className?: string;
}

const JobMutliSkillAutocomeplete: React.FC<JobMutliSkillAutocomepleteProps> = ({
  label,
  fieldName,
  handleBlur,
  setFieldValue,
  handleOnChange,
  selectedValue,
  helperText,
  isError,
  isRequired,
  placeholderText,
  className=""
}) => {

    const [searchSkill, setSearchSkill] = React.useState<any>("");
    const [skillList, setSkillList] = React.useState<[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const onSkillInputChange = (event : any) => {
      setSearchSkill(event.target.value);
    };

  const _getJobSkills = ()=>{
    setLoading(true);
    GetAllSkills(searchSkill, 20)
    .then((response)=>{
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setSkillList(response?.data?.entityList)
      }
    })
    .catch((error)=>{
      console.log(error?.data?.message?.appStatusDescription)
    })
    .finally(()=>{
      setLoading(false)
    })
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      _getJobSkills()
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchSkill]);

  const handleFreeSoloChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | Skill[]
  ) => {
    
    const transformedValue = Array.isArray(newValue)
    ? newValue.map(item => 
        typeof item === "string"
          ? { id: null, skillName: item }
          : item
      )
    : [...selectedValue, { id: null, skillName: newValue }];

    handleOnChange(transformedValue);
  };

  const [inputValue, setInputValue] = useState('');
    
    const handleBlurWithChip = (event: React.FocusEvent<HTMLInputElement>) => {
      handleBlur(event);
      if (inputValue && !selectedValue?.includes(inputValue)) {
        handleOnChange([...selectedValue, inputValue]);
        setInputValue('');
      }
    };

  
  return (
    <>
       <Autocomplete
        id="tags-standard"
        multiple={true}
        freeSolo
        disableClearable={false}
        className={`jrAutocomplete ${className}`}
        options={skillList}
        getOptionLabel={(option: Skill | string) => typeof option === 'string' ? option : option?.skillName}
        noOptionsText={loading ? "Loading..." : "No options"}
        value={selectedValue}
        onChange={handleFreeSoloChange}
        onBlur={handleBlurWithChip}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option: Skill | string, index) => (
            <Chip
              color="primary"
              size="small"
              label={toTitles(typeof option === 'string' ? option : option.skillName)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            name={fieldName}
            onChange={onSkillInputChange}
            required={isRequired}
            error={isError}
            helperText={helperText}
            placeholder={placeholderText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default JobMutliSkillAutocomeplete;
