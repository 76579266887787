import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PendingJobFeebackPopup from '../../../Common/Popup/Job/PendingJobFeebackPopup';
import './../JobPage.scss';
import { setJobAsPending } from '../../../../Services/jobService';
import { CustomAlert } from '../../../ui/CustomAlert';

interface MoveToPendingJobProps{
    jobId:string;
    onSuccess:()=>void;
}
const MoveToPendingJob: React.FC<MoveToPendingJobProps> = ({
    jobId,
    onSuccess
}) => {

    const [open, setOpen] = useState(false);
    const [pageStatus, setPageStatus] = useState<any>({
      isSuccess: false,
      isError: false,
      message: "",
      loading: false,
    });

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const _handleCloseDialog = () => {
    setOpen(false);
  };


  const _handleOnSuccess = () =>{
    _handleCloseDialog();
    onSuccess();
  }

  const _handleOnMoveToPending = async(commentValue: string) =>{ 
    let requestPayload = {
        jobId:jobId,
        comment:commentValue
    }

    setPageStatus({
      ...pageStatus,
      isSuccess:false,
      isError: false,
      message: "",
      loading:true,
    });
        
    await setJobAsPending(requestPayload)
    .then((response: any) => {
      if (response?.data?.message?.appStatusCode === "RC_RSF") {
        _handleOnSuccess();
      } else {
        setPageStatus({
          ...pageStatus,
          isError: true,
          message: response?.data?.message?.appStatusDescription,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setPageStatus({
        ...pageStatus,
        isError: true,
        message: "Something Went wrong!!!",
      });
    })
    .finally(()=>{
      setPageStatus({
        ...pageStatus,
        loading:false,
      });
    });
  }
  

  return (
    <div className='moveToPendingWrapper'>
      <Button
        className='custBtnPrimary' 
        variant="outlined" 
        onClick={handleOpenDialog}>
        Move to Pending
      </Button>
      <PendingJobFeebackPopup
        isOpen={open}
        handleOnCancel={_handleCloseDialog}
        handleOnMoveToPending={_handleOnMoveToPending}
        loading={pageStatus.loading}
      />
      {
        pageStatus.isError &&

        <CustomAlert
          type="error"
          message={pageStatus?.message}
        />
      }
    </div>
  );
};

export default MoveToPendingJob;
