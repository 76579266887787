import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField
} from "@material-ui/core";
import { Divider, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { GetReasons, VerifyClient } from "../../Services/clientService";
import getUserDetails from "../../Shared/utils/getUserDetails";
import useApi from "../../hooks/useApi";

import Button from '@mui/material/Button';
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ClientStatusesId } from "../../models/interfaces";
import { getNevLink } from "../../utils/getFileFromUrl";
import { Asterisk } from "../ui/Asterisk";
import ClientPopup from "../ui/ClientPopup";
import { ErrorMessage } from "../ui/ErrorMessage";
import { CustomAlert } from "../ui/CustomAlert";

interface VerificationPopupProps {
  openPopup: boolean;
  handleDocumentsPopupClose: any;
  statusId: string;
  clientId: string;
}

export const VerificationPopup: React.FC<VerificationPopupProps> = ({
  openPopup,
  handleDocumentsPopupClose,
  statusId,
  clientId,
}) => {
  const [pageStatus, setPageStatus] = React.useState({
    isError:false,
    success: false,
    error: "",
    key: Date.now(),
  });
  const navigate = useNavigate();
  const handleDomainChange = (domain: any) => {
    let item: any = { id: domain.id, reason: domain.reason };
    if (formik.values.reasons.some((e: any) => e.id === domain.id)) {
      formik.values.reasons = formik.values.reasons.filter(
        (item: any) => item.id !== domain.id
      );
    } else {
      formik.values.reasons.push(item);
    }
    formik.setFieldTouched("reasons", true);
  };

  let verificationType = "";
  if (ClientStatusesId.Rejected === statusId) {
    verificationType = "Reject";
  } else if (ClientStatusesId.Suspended === statusId) {
    verificationType = "Suspend";
  } else {
    verificationType = "Approve";
  }

  const initialState = {
    commentText: "",
    reasons: [],
  };

  const reasonsAPI = useApi(() => GetReasons(statusId));
  React.useEffect(() => {
    reasonsAPI.request();
  }, []);

 
  let userDetails = getUserDetails();
  const commentSchema = Yup.object({
    commentText: Yup.string()
      .trim()
      .required("Please enter comments")
      .max(1000, "Please enter comments, it must be less than 1000 characters"),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: commentSchema,
    onSubmit: async (values) => {

      let VerificationStatus;
      if (verificationType === "Approve") {
        VerificationStatus = "Approved";
      } else if (verificationType === "Suspend") {
        VerificationStatus = "Suspended";
      } else {
        VerificationStatus = "Rejected";
      }

      let reqPayload = {
        clientId: clientId,
        comments: {
          id: "",
          comment: values.commentText,
          addedById: userDetails?.id,
          userRole: userDetails.userRoleType,
          creatorName: userDetails.userName,
          creatorEmail: userDetails.email,
          reasons: values.reasons,
          createdDate: new Date(),
          status:VerificationStatus,
        },
        redirectUrl: `${window.location.origin}`,
      };

      VerifyClient(reqPayload)
        .then((response) => {
          // Check the response and update the pageStatus state accordingly
          if (response?.data?.message?.appStatusCode === "CL_RSF") {
            setPageStatus({ isError:false, success: true, error: "", key: Date.now() });
          } else {
            setPageStatus({
              isError:true,
              success: false,
              error: response?.data?.message?.appStatusDescription,
              key: Date.now(),
            });
          }
        })
        .catch(() => {
          setPageStatus({
            isError:true,
            success: false,
            error: "API Error",
            key: Date.now(),
          });
        });
    },
  });

  const _getTitleByVerificationType = (verificationType:string) => {
    if (verificationType === "Reject") {
      return "Rejection Reasons";
    } else if (verificationType === "Suspend") {
      return "Suspended Reasons";
    } else {
      return "Approval Reasons";
    }
  };

  const getVerificationButtonConfig = (type:string) => {
    switch (type) {
      case "Reject":
        return { label: "Reject Client", color: "primary" };
      case "Suspend":
        return { label: "Suspend Client", color: "primary" };
      default:
        return { label: "Approve Client", color: "primary" };
    }
  };
 

  return (
    <div>
      <Dialog
        open={openPopup}
        onClose={handleDocumentsPopupClose}
        aria-labelledby="viewRecruiterPopup-title"
        aria-describedby="viewRecruiterPopup-description"
      >
        <Paper elevation={5}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <IconButton
              aria-label="close"
              onClick={handleDocumentsPopupClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="viewRecruiterPopup-description">
              <Grid container spacing={1}>
                <Grid item xs={12}></Grid>

                <Box>
                  <Grid container spacing={1} className="recruiterInfoBox">
                    <Grid item xs={12} sm={12}>
                      {/* Posted on February 02, 2023 02:33:35 PM
                                                Submitted */}
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={1} className="recruiterInfoBox">
                    <Grid item xs={12} sm={12}>
                      <Typography
                        className="custom-robo-font"
                        align="center"
                        fontWeight={"bold"}
                        color={"#4540DB"}
                        fontSize={"32px"}
                        fontFamily={"roboto"}
                      >
                        { _getTitleByVerificationType(verificationType) }
                      </Typography>

                      <FormControl
                        error={
                          formik.touched.reasons &&
                          Boolean(formik.errors.reasons)
                        }
                        fullWidth
                      >
                        {(reasonsAPI?.data)?.entityList.map(
                          (reason: any, index:number) => {
                            return (
                              <Grid key={reason?.id ?? index}>
                                <FormControlLabel
                                  style={{
                                    color: "black",
                                    fontFamily: "Roboto",
                                  }}
                                  key={reason.id}
                                  className="custom-robo-font"
                                  control={
                                    <Checkbox
                                      className="custom-robo-font"
                                      onChange={() =>
                                        handleDomainChange(reason)
                                      }
                                    />
                                  }
                                  label={reason.reason}
                                  checked={formik.values.reasons?.some(
                                    (e: any) => e.id === reason.id
                                  )}
                                />
                              </Grid>
                            );
                          }
                        )}
                        <ErrorMessage
                          errorText={
                            formik.touched.reasons && formik.errors.reasons
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} mt={1} sm={12}>
                      <Grid item>
                        <Divider />
                      </Grid>
                      <Typography
                        className="custom-robo-font"
                        mt={3}
                        style={{
                          color: "black",
                          fontFamily: "Roboto",
                          fontSize: "16px",
                        }}
                      >
                        Add Comments <Asterisk />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        error={
                          formik.touched.commentText &&
                          Boolean(formik.errors.commentText)
                        }
                        fullWidth
                        InputProps={{ style: { color: "inherit" } }}
                        variant="outlined"
                        multiline
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.commentText}
                        inputProps={{"data-testid": 'text-verificationcomment'}}
                        name="commentText"
                        placeholder="Text here"
                        rows={3}
                        maxRows={6}
                      />
                      <ErrorMessage
                        errorText={
                          formik.touched.commentText &&
                          formik.errors.commentText
                        }
                      />
                    </Grid>
                  </Grid>
                  <div
                    className="row"
                    style={{
                      marginTop: "30px",
                      marginBottom:"12px",
                      float: "right",
                    }}
                  >
                    <Button
                      className="custom-robo-font"
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        marginRight: "5px",
                      }}
                      onClick={() => formik.handleSubmit()}
                    >
                      {getVerificationButtonConfig(verificationType).label}
                    </Button>
                  </div>
                  {
                    pageStatus?.isError && 
                    <CustomAlert 
                      message={pageStatus?.error}
                      type="error"
                      onClose={()=>{
                        setPageStatus({ ...pageStatus,isError:false})
                      }}
                    />
                  }
                </Box>
              </Grid>
            </DialogContentText>
          </DialogContent>

          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
      <ClientPopup
        isOpen={pageStatus.success}
        handleClose={() => {
          navigate(getNevLink("/clients"));
        }}
        type={verificationType.toLowerCase()}
        title={verificationType.toLowerCase()}
      ></ClientPopup>
    </div>
  );
};
export default VerificationPopup;
