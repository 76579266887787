import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { getJobTypes } from "../../../../../Services/jobService";

export interface JobType{
    id:string;
    jobType:string;
}
interface RadioGroupFieldProps {
    shrink?:boolean;
    name: string;
    label: string;
    value: string;
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    isError?: boolean;
    helperText?: string;
}

const JobTypeRadioSelection: React.FC<RadioGroupFieldProps> = ({
    shrink=true,
    name,
    label,
    value,
    handleOnChange,
    isError,
    helperText,
}) => {
  const [jobTypesList, setJobTypesList] = useState<JobType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const _GetJobTypes = async () => {
    setLoading(true);
    getJobTypes()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTypesList(response?.data?.entityList);
        } else {
          setJobTypesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  useEffect(() => {
    _GetJobTypes();
  }, []);

  return (
    <>
      <InputLabel shrink={shrink}>{label}</InputLabel>
      {
      loading ?
      <CircularProgress size={18}/>
      :
      <RadioGroup row name={name} value={value} onChange={handleOnChange} className="FieldRadio">
        {jobTypesList?.map((option:JobType) => (
          <FormControlLabel
            key={option?.id}
            value={option?.jobType}
            control={<Radio color="primary" />}
            label={option.jobType}
          />
        ))}
      </RadioGroup>
      }
      {isError && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default JobTypeRadioSelection;
