import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { getJobTypes } from '../../../../Services/jobService';

interface JobTypeAutoCompleteProps {
  disabled?: boolean;
  required?: boolean;
  name: string;
  secondaryFieldName?: string;
  label:string;
  values: any; 
  placeholder:string;
  handleOnChange: (value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  touched?: any;
  errors?: any;
  viewMode?:boolean;
  disableClearable?:boolean;
}

const JobTypeAutoComplete: React.FC<JobTypeAutoCompleteProps> = ({
  disabled,
  required,
  name,
  secondaryFieldName,
  label,
  values,
  placeholder,
  handleOnChange,
  handleBlur,
  touched,
  errors,
  viewMode,
  disableClearable=false
}) => {

    //Component states
    const [jobTypesList, setJobTypesList] = useState<string[]>([]);

    useEffect(()=>{
        _GetJobTypes();
    },[])

  // GET Master GetJobTypes
  const _GetJobTypes = async () => {
    getJobTypes()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobTypesList(response?.data?.entityList);
        } else {
          setJobTypesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Autocomplete
      readOnly={viewMode}
      disabled={disabled}
      id="jobType-standard"
      disableClearable={disableClearable}
      options={jobTypesList?.map((options: any) => options) || []}
      getOptionLabel={(option) => option.jobType}
      value={values}
      onChange={(_, newValue: any) => {
        handleOnChange(newValue);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option: any, index: any) => (
          <Chip
            color="primary"
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          variant="standard"
          label={label}
          name={name}
          onBlur={handleBlur}
          error={
            touched &&
            Boolean(errors)
          }
          helperText={
            touched &&
            errors
          }
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default JobTypeAutoComplete;
