import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Chip, CircularProgress } from '@mui/material';
import { getJobRequireDegrees } from '../../../../Services/jobService';

export interface Education {
  id: string;
  degreeName: string;
}

type EducationList = Education[];

interface EducationAutoCompleteProps {
  name?: string;
  required?: boolean;
  handleBlur: any;
  handleOnChange: (value: any) => void;
  selectedValue: Education[];
  touched: boolean;
  errors: string;
  placeholder?: string;
  fieldLabel?: string;
  disableClearable?:boolean;
  multiple?:boolean;
}

const EducationAutoComplete: React.FC<EducationAutoCompleteProps> = ({
    name,
    required = false,
    handleBlur,
    handleOnChange,
    selectedValue,
    touched,
    errors,
    placeholder = "Select Education",
    fieldLabel = "Education",
    disableClearable=false,
    multiple=true
  }) => {
    const [jobRequireDegreesList, setJobRequireDegreesList] = useState<EducationList>([]);
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
      _GetRequireDegrees();
    }, [])
  
    // Get Master education / degree
    const _GetRequireDegrees = async () => {
        setLoading(true)
         getJobRequireDegrees()
        .then((response: any) => {
          if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setJobRequireDegreesList(response?.data?.entityList);
          } else {
            setJobRequireDegreesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(()=>{
            setLoading(false);
        })
    };
  
    return (
      <Autocomplete
        className="jrAutocomplete"
        id="tags-standard"
        multiple={multiple}
        disableClearable={disableClearable}
        options={jobRequireDegreesList}
        getOptionLabel={(option: Education) => option?.degreeName}
        noOptionsText={loading ? "Loading..." : "No options"}
        value={selectedValue}
        onChange={(_, newValue) => {
          handleOnChange(newValue);
        }}
        onBlur={handleBlur}
        renderTags={(value, getTagProps) =>
          value.map((option: Education, index) => (
            <Chip
              color="primary"
              size="small"
              label={option.degreeName}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            required={required}
            {...params}
            variant="standard"
            label={fieldLabel}
            data-testid="autocomplete-experience"
            error={touched && Boolean(errors)}
            helperText={touched && errors}
            placeholder={placeholder}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
          />
        )}
      />
    );
  };
  
  export default EducationAutoComplete;
