import { FormControl, Grid, Input, InputLabel, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import constructName from "../../../../../utils/constructName";
import { AdminAutocomplete } from "../../../../ui/AdminAutocomplete";
import { Asterisk } from "../../../../ui/Asterisk";
import { ErrorMessage } from "../../../../ui/ErrorMessage";
import FieldUpdateConfirmPopup from "../Popup/FieldUpdateConfirmPopup";
import { valueOrDefault } from "../../../../../utils/complexityUtils";

export interface AccountManagerFormSectionProps {
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, shouldValidate?: boolean) => void;
  errors: any;
  touched: any;
  selectedClientDetails:any;
  viewMode?:boolean;
  isUpdateAction:boolean;
}

const AccountManagerFormSection: React.FC<AccountManagerFormSectionProps> = ({
  handleChange,
  handleBlur,
  values,
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  selectedClientDetails,
  viewMode,
  isUpdateAction
}) => {

  const [FieldEditConsent, setFieldEditConsent] = useState<any>({
    accountManagerDetails : {
    fullName:false
    }
  })

  const [fieldPopup, setfieldPopup] = useState<any>({
    isOpen:false,
    popupTitle:"",
    selectedFieldName:"",
    selectedFieldValue:"",
    selectedName:""
  });

  useEffect(()=>{
    if(isUpdateAction){
      setFieldEditConsent({
        accountManagerDetails : {
          fullName:false
          }
      })
    }
  },[isUpdateAction])

  const _handleOnClose = () =>{
    setfieldPopup({
      ...fieldPopup,
      isOpen:false,
      popupTitle:"",
      selectedField:"",
      selectedName:""
    })
  }

  const _handleYes = () => {

    switch (fieldPopup.selectedName) {
      case 'oneTimeCommission':
        break;
      case 'fullName':
        setFieldEditConsent({
          ...FieldEditConsent,
          fullName: true
        });
        break;
      default:
        // You can add additional cases here if needed
        break;
    }
  
    handleAccountManagerChange(fieldPopup.selectedFieldValue);
    _handleOnClose();
  }
  

  const _handleNo = () =>{
   
    const selectedClientManagerInfo = {
      id:selectedClientDetails?.accountManagerDetails?.id,
      firstName:selectedClientDetails?.accountManagerDetails?.firstName || valueOrDefault(selectedClientDetails?.accountManagerDetails?.fullName, ""),
      middleName:valueOrDefault(selectedClientDetails?.accountManagerDetails?.MiddleName, ""),
      LastName:valueOrDefault(selectedClientDetails?.accountManagerDetails?.LastName, ""),
      phoneNumber:valueOrDefault(selectedClientDetails?.accountManagerDetails?.primaryPhone, ""),
      email:valueOrDefault(selectedClientDetails?.accountManagerDetails?.email, "")

    }
    handleAccountManagerChange(selectedClientManagerInfo);
    _handleOnClose();
  }


  const handleAccountManagerChange = (value: any) => {
    
    setFieldValue("accountManagerDetails.id", valueOrDefault(value?.id, ""));
    setFieldValue(
      "accountManagerDetails.fullName",
      valueOrDefault(
        constructName(value?.firstName, value?.middleName, value?.lastName),
        ""
      )
    );
    setFieldValue("accountManagerDetails.email", valueOrDefault(value?.email, ""));
    setFieldValue(
      "accountManagerDetails.primaryPhone",
      valueOrDefault(value?.phoneNumber, "")
    );

    setFieldTouched("accountManagerDetails.fullName",false);
    setFieldTouched("accountManagerDetails.email",false);
    setFieldTouched("accountManagerDetails.primaryPhone",false);
  };

  const getInputLabel = (option:any)=>{    
    return `${constructName(
      valueOrDefault(option?.firstName, ""),
      valueOrDefault(option?.middleName, ""),
      valueOrDefault(option?.lastName, "")
    )}`;
   }

  return (
    <Paper
      elevation={0}
      style={{ padding: "16px 8px 24px 8px", background: "#fff" }}
    >
      <Grid container spacing={2}>
        {/* Row 1 */}
        <Grid item xs={12}>
          <Typography variant="h6" className="formSectionHeading">Account Manager</Typography>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12} sm={4} md={3} mt={1}>
          {/* Admin user is account Manager for manage client */}
          <AdminAutocomplete
            readOnly={viewMode}
            isRequired={true}
            showEmail={false}
            multiple={false}
            name="accountManagerDetails.fullName"
            onBlur={handleBlur}
            label={"Account Manager"}
            admin={{
              id:values?.accountManagerDetails?.id,
              firstName:values?.accountManagerDetails?.fullName,
              middleName:"",
              lastName:"",
              fullName:values?.accountManagerDetails?.fullName,
              email:values?.accountManagerDetails?.email,
              primaryPhone:values?.accountManagerDetails?.primaryPhone
            }}
            onChange={(e: any, value: any) => {   
              
              if(
                !FieldEditConsent.fullName &&
                selectedClientDetails?.accountManagerDetails?.fullName !== getInputLabel(value)
                && getInputLabel(value) !== "" &&  selectedClientDetails?.accountManagerDetails?.fullName !== undefined
              ){
                setfieldPopup({
                  ...fieldPopup,
                  isOpen:true,
                  popupTitle:"Are you sure you want to assign new Account Manager?",
                  selectedFieldName:"accountManagerDetails.fullName",
                  selectedFieldValue:value,
                  selectedName:"fullName"
                }) 
              }else{
                setFieldValue("selectedAccountManager",value);
                handleAccountManagerChange(value); 
              }

             
            }}
            touched= {touched?.accountManagerDetails?.fullName}
            errors= {errors?.accountManagerDetails?.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} mt={1}>
          
          <FormControl
            fullWidth
            required
            error={
              touched.accountManagerDetails?.email &&
              Boolean(errors.accountManagerDetails?.email)
            }
            variant="standard"
          >
            <InputLabel
            htmlFor="accountManagerDetails.email"
            style={{ color: "#4540db !important" }}
          >
            Email 
          </InputLabel>
            <Input
              readOnly
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.accountManagerDetails?.email}
              name="accountManagerDetails.email"
              type="text"
            />
            <ErrorMessage
              errorText={
                touched.accountManagerDetails?.email &&
                errors.accountManagerDetails?.email
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3} mt={0.5}>
          <InputLabel className="client-formLable">
            Phone <Asterisk/>
          </InputLabel>
          <FormControl variant="standard" fullWidth> 
              <PhoneInput
                disabled
                containerClass={`phoneCountryCustom-adminForm phoneCountryCustom-clientForm`}
                placeholder="Primary Phone"
                country={"us"}
                onChange={(ev: any) => {
                  setFieldValue(
                    "accountManagerDetails.primaryPhone",
                    ev
                  );
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched('accountManagerDetails.primaryPhone', true); 
                }}
                value={values.accountManagerDetails?.primaryPhone}
              />
          </FormControl>
          <ErrorMessage
            errorText={
              touched?.accountManagerDetails?.primaryPhone &&
              errors?.accountManagerDetails?.primaryPhone
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FieldUpdateConfirmPopup 
            isOpen={fieldPopup.isOpen}
            title={fieldPopup?.popupTitle}
            handleClose = {_handleOnClose}
            handleYes= {_handleYes}
            handleNo = {_handleNo}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AccountManagerFormSection;
