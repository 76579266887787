import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../../data/permission";
import {
  ClearClientFilter,
  handleIndustries,
  handleLocations,
  handleSearchKeyword,
  handleStatues,
} from "../../../features/Client/clientSlice";
import { Industry, KeyValue } from "../../../models/app/AppModels";
import { AppState } from "../../../store/AppState";
import formatAmount from "../../../utils/formatAmount";
import { getNevLink } from "../../../utils/getFileFromUrl";
import SuccessSnackBarPopup from "../../ClientTeam/components/Popup/successSnackBarPopup";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { Searchbar } from "../../ui/Searchbar";
import ClientFilter, { ClientFilterRef } from "./ClientFilter";
import IncompleteClientSignup from "./IncompleteClientSignup";
import StatusFilter, { StatusFilterRef } from "./StatusFilter";
import "./client.scss";

interface ClientSearchbarProps {
  totalRecords: number | undefined;
}

export default function ClientSearchbar({
  totalRecords,
}: ClientSearchbarProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const filterRef = React.useRef<ClientFilterRef>(null);
  const statusFilterRef = React.useRef<StatusFilterRef>(null);

  const { industries, locations, statuses, searchKeyword } = useSelector(
    (state: AppState) => state.clientReducer.filter
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusAnchor, setStatusAnchor] = React.useState(null);
  const [search, setSearch] = React.useState(searchKeyword);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [clientDeleteStatus, setClientDeleteStatus] = React.useState<any>({
    isSuccess: null,
    message: "",
    unique: Date.now(),
  });
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const searchBarWidth = isSmallScreen ? "310px" : "383px";

  const handleFilterClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleStatusClick = (event: any) => {
    setStatusAnchor(statusAnchor ? null : event.currentTarget);
  };

  const onLocationChange = (locations: string[]) => {
    dispatch(handleLocations(locations));
  };

  const onIndustryChange = (industries: Industry[]) => {
    dispatch(handleIndustries(industries));
  };

  const onStatusChange = (statues: KeyValue[]) => {
    dispatch(handleStatues(statues));
  };

  const onSearchKeywordChange = () => {
    dispatch(handleSearchKeyword(search ?? ""));
  };

  const clearAllFilter = () => {
    dispatch(ClearClientFilter());
    filterRef.current?.handleClearAll();
    statusFilterRef.current?.handleClearAll();
    setSearch("");
  };

  const haveAnyFilter = () => {
    return (
      (industries && industries?.length > 0) ||
      (locations && locations?.length > 0) ||
      (statuses && statuses?.length > 0) ||
      (searchKeyword && searchKeyword !== "")
    );
  };

  const onClientDeleteSuccess = () => {
    setClientDeleteStatus({
      isSuccess: true,
      message: "Client deleted successfully",
      unique: Date.now(),
    });
  };

  const onDeleteClientClose = () => {
    setClientDeleteStatus({ isSuccess: false, message: "", unique: Date.now() })
  }

  const handleClose = () => {
    setOpenConfirmDelete(false);
  };
  return (
    <>
      <Grid container spacing={2} justifyContent={{ sm: "flex-start", md: "space-between" }}
      >
        <Grid item >
          <Typography className="listing-total-heading">Total Clients ({formatAmount(totalRecords)})</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent={{ sm: "flex-start", md: "flex-end" }}>
            <Grid
              item
              style={{ position: "relative" }}
            >
              <Searchbar
                classes="ClientListingSearchbarContainer"
                isNewSeachBar={true}
                width={searchBarWidth}
                height="1.7rem"
                placeHolderText={"Search"}
                txtFontSize={"14px"}
                marginBottom="16px"
                value={search}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                onEnter={onSearchKeywordChange}
                onIconClick={onSearchKeywordChange}
              />
            </Grid>
            <Grid item>
              <Button
                variant="text"
                component="div"
                className="custom-Btn-fontsizes cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
                startIcon={<DonutLargeOutlinedIcon />}
                onClick={handleStatusClick}
              >
                Status
              </Button>
              <Button
                variant="text"
                component="div"
                size="large"
                className="custom-Btn-fontsizes cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
                startIcon={<FilterAltOutlinedIcon />}
                onClick={handleFilterClick}
                data-testid="filter-button"
              >
                Filter
              </Button>

              <Button
                data-testid="primary"
                variant="contained"
                className="searchbarRow-ht-btn toolbarActionBtn custom-font-roboto"
                onClick={() => {
                  setOpenConfirmDelete(true);
                }}
              >
                Incomplete Signup
              </Button>
              <ModuleComponent moduleId={PermissionsEnums.CLUserAdd}>

              <Button
                data-testid="contained"
                variant="contained"
                startIcon={<AddOutlinedIcon />}
                className="searchbarRow-ht-btn toolbarActionBtn"
                onClick={() => {
                  navigate(getNevLink("/client/add"));
                }}
              >
                <span className="custom-addbtn">Add Client</span>
              </Button>
              </ModuleComponent>

              <Button
                variant="text"
                size="small"
                onClick={clearAllFilter}
                className={`toolbarActionBtn ${
                  haveAnyFilter()
                    ? "custom-Btn-fontsizes"
                    : "disable-custom-Btn-fontsizes"
                } cust-btn-bgClr searchbarRow-ht-btn clear-cust-wrap`}
                data-testid="clear-button"
                disabled={!haveAnyFilter()}
              >
                <ClearIcon className="closeIcon" />
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ClientFilter
        ref={filterRef}
        anchorEl={anchorEl}
        onClose={handleFilterClick}
        selectedLocations={locations ?? []}
        onChange={onLocationChange}
        onIndustryChange={onIndustryChange}
        selectedIndustries={industries ?? []}
      />
      <StatusFilter
        ref={statusFilterRef}
        anchorEl={statusAnchor}
        onClose={handleStatusClick}
        statues={clientStatuses}
        selectedStatuses={statuses ?? []}
        onStatusChange={onStatusChange}
      />
      {openConfirmDelete && (
        <IncompleteClientSignup
          handleConfirmationClose={() => setOpenConfirmDelete(false)}
          handleClose={handleClose}
          onClientDeleteSuccess={onClientDeleteSuccess}
        />
      )}
      {clientDeleteStatus.isSuccess && (
        <SuccessSnackBarPopup
          open={clientDeleteStatus?.isSuccess}
          message={clientDeleteStatus.message}
          onClose={onDeleteClientClose}
        />
      )}
    </>
  );
}

const clientStatuses: KeyValue[] = [
  {
    key: "Approved",
    label: "Approved",
    value: "Approved",
  },
  {
    key: "Pending",
    label: "Pending",
    value: "Pending",
  },
  {
    key: "Rejected",
    label: "Rejected",
    value: "Rejected",
  },
  {
    key: "Suspended",
    label: "Suspended",
    value: "Suspended",
  },
];
