import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ExperienceAutocomplete from '../../../../Common/Form/FormField/ExperienceAutocomplete';
import EducationAutoComplete, { Education } from '../../../../Common/Form/FormField/EducationAutoComplete';
import CertificationsAndLicensesAutoComplete from '../../../../Common/Form/FormField/CertificationsAndLicensesAutoComplete';
import TechnologyAutoComplete from '../../../../Common/Form/FormField/TechnologyAutoComplete';
import ToolAutoComplete from '../../../../Common/Form/FormField/ToolAutoComplete';
import JobMutliSkillAutocomeplete from '../FormFields/MutliSelect/JobMutliSkillAutocomeplete';
import { valueOrDefault } from '../../../../../utils/complexityUtils';
import CityStateCountryAutoComplete from '../../../../Common/Form/FormField/CityStateCountryAutoComplete';
import ShiftTimingStartEndAutoComplete from '../../../../Common/Form/FormField/ShiftTimingStartEndAutoComplete';
import { GenerateJobDetailsContentFormValidationSchema } from '../FormModel/GenerateJobDetailsContentFormValidationSchema';
import { GenerateJobDetailsFormIntialState, JobFormValues } from '../FormModel/GenerateJobDetailsFormIntialState';
import GenerateJobDetailsPreview, { MessageList } from './GenerateJobDetailsPreview';
import DomainRadioSelection from '../../../../Common/Form/FormField/Radio/DomainRadioSelection';
import JobTypeRadioSelection from '../../../../Common/Form/FormField/Radio/JobTypeRadioSelection';
import { GenarateJobDetailsEximius } from '../../../../../Services/jobService';
import { ClientUserInfoModel } from '../../../../../models/SystemUser/ClientInfo';
import AIModelSelect from '../../../../Common/Form/FormField/Select/AIModelSelect';
import { SkillModel } from '../../../../../models/SkillModel';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { Error } from '../../../../Common/Error';
import { ErrorMessage } from '../../../../ui/ErrorMessage';
import { CustomAlert } from '../../../../ui/CustomAlert';

interface Props{
    clientLoginData:ClientUserInfoModel;
    handleOnContinue:(JobDetailsValues: MessageList)=>void;
}

const GenerateJobPostingContentForm: React.FC<Props> = ({
    clientLoginData,
    handleOnContinue
}) => {

    const [generatedJobDetailsResult, setGenerateJobDetailsResult] = useState<any>();
    const [isNewResult, setIsNewResult] = useState<boolean>(false);
    const [apiLoading, setApiLoading] = useState<boolean>(false);

    function buildAddress(values:JobFormValues) {
        // Initialize an empty array to hold the non-empty parts
        const parts = [];
    
        // Check if each part is present and not empty, then add to the array
        if (values?.city) {
            parts.push(values.city);
        }
        if (values?.state) {
            parts.push(values.state);
        }
        if (values?.country) {
            parts.push(values.country);
        }
    
        // Join the array with a comma and space
        return parts.join(', ');
    }

    const handleSubmit = (values: JobFormValues, action:any) => {
        handleOnSubmit(values);
    };


    const handleOnSubmit = (values:any)=>{
        setIsNewResult(false);
        setApiLoading(true);        
        const educationStringValue = values?.education?.map((item: Education) => item?.degreeName)?.join(', ') ?? "";
        const certificationStringValue = values?.certifications?.map((item:string) => item)?.join(', ') ?? "";
        const technologiesStringValue = values?.technologies?.map((item:string) => item)?.join(', ') ?? "";
        const toolsStringValue = values?.tools?.map((item:string) => item)?.join(', ') ?? "";
        const skillsStringValue = values?.skills?.map((item: SkillModel) => item?.skillName)?.join(', ') ?? "";
         
        // Request Payload for Generate the Job details API 
        let formData = new FormData();

        formData.append("ClientName", clientLoginData?.companyName);
        formData.append("ModelName", values?.modelName);
        formData.append("JobTitle", values?.jobTitle)
        formData.append("JobDescription", values?.jobDescription)

        formData.append("JobAssignmentType", values?.jobAssignmentType);
        formData.append("JobType", values?.jobType);
        formData.append("Domain", values?.domain);

        formData.append("Location", buildAddress(values));
        formData.append("jobExperience", values?.jobExperience);
        formData.append("Skills",skillsStringValue);

        formData.append("Education", educationStringValue);
        formData.append("Certifications", certificationStringValue);
        formData.append("Licenses", "");

        formData.append("Tools", toolsStringValue);
        formData.append("Technologies", technologiesStringValue);

        formData.append("JobShiftStart", values?.shiftTimingFrom);
        formData.append("JobShiftEnd",  values?.shiftTimingTo);
        
    GenarateJobDetailsEximius(formData)
    .then((response)=>{
        if(response?.data?.message?.appStatusCode === "RC_RSF"){
            setGenerateJobDetailsResult(response?.data?.entity?.messageList)
        }else{
            setIsNewResult(false);
        }
        
    })
    .finally(()=>{
        setApiLoading(false);
        setIsNewResult(true);
    })
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={GenerateJobDetailsFormIntialState}
        validationSchema={GenerateJobDetailsContentFormValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ 
            errors, 
            values, 
            touched, 
            setFieldValue,
            handleChange,
            handleBlur,
            setValues,
            isSubmitting 
        }) => (
          <Form noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="jobTitle"
                        label="Job Title"
                        placeholder="Enter Title"
                        value={values?.jobTitle}
                        onChange={handleChange}
                        error={touched.jobTitle && Boolean(errors.jobTitle)}
                        helperText={touched.jobTitle && errors.jobTitle}
                        fullWidth
                        margin="none"
                        variant="standard"
                        required
                        autoFocus
                    />            
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <AIModelSelect
                        required={true}
                        handleOnChange={(value)=>{
                            setFieldValue("modelName",value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputLabel shrink={true} required >Client Name:</InputLabel>
                    <InputLabel shrink={false} >{valueOrDefault(clientLoginData?.companyName,"N/A")}</InputLabel>
                </Grid> 
                <Grid item xs={12} sm={6} md={4}>
                    {/* Domain Selection */}
                    <DomainRadioSelection
                        name="domains"
                        label="Domain"
                        value={values?.domain}
                        handleOnChange={(_,value:string)=>{
                            setFieldValue("domain", value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={'auto'}>
                    {/* Job Type Selection */}
                    <JobTypeRadioSelection
                        name="jobAssignmentType"
                        label="Type of Jobs"
                        value={values?.jobAssignmentType}
                        handleOnChange={(_,value:string)=>{
                            setFieldValue("jobAssignmentType", value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={8} >
                    <CityStateCountryAutoComplete
                        setFieldValue={setFieldValue}
                        selectedValue={{
                            city:values.city,
                            state:values?.state,
                            countryName:values?.country
                        }}
                    />
                </Grid>                                
                <Grid item xs={12}  sm={12} md={4} >
                    <ShiftTimingStartEndAutoComplete 
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        selectedValue={{
                            shiftTimingFrom:values?.shiftTimingFrom,
                            shiftTimingTo:values?.shiftTimingTo
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ExperienceAutocomplete 
                         handleBlur={handleBlur}
                         setFieldValue={setFieldValue}
                         handleOnChange={(value) => {
                            setFieldValue('experience', value.id);
                            setFieldValue("jobExperience",value?.experienceValue)
                        }}
                         selectedValue={values.experienceId}
                         touched={false}
                         errors={""}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <EducationAutoComplete 
                         handleBlur={handleBlur}
                         handleOnChange={(value)=>{
                            setFieldValue("education",value)
                            
                         }}
                         selectedValue={values?.education}
                         touched={false}
                         errors={""}
                    />
                </Grid>  
                <Grid item xs={12} sm={6} md={3}>
                    <CertificationsAndLicensesAutoComplete
                        label="Certification And Licenses"
                        handleBlur={handleBlur}
                        handleOnChange={(value) => setFieldValue('certifications', value)}
                        selectedValue={values?.certifications}
                        touched={false}
                        errors={""}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TechnologyAutoComplete
                        label="Technology"
                        handleBlur={()=>{

                        }}
                        handleOnChange={(value) => setFieldValue('technologies', value)}
                        selectedValue={values?.technologies}
                        touched={touched?.technologies && Boolean(errors?.technologies)}
                        errors={touched?.technologies && errors?.technologies}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ToolAutoComplete
                        label="Tool"
                        handleBlur={()=>{

                        }}
                        handleOnChange={(value) => setFieldValue('tools', value)}
                        selectedValue={values?.tools}
                        touched={touched?.tools && Boolean(errors?.tools)}
                        errors={touched?.tools && errors?.tools}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <JobMutliSkillAutocomeplete
                        className=""
                        label="Skills"
                        fieldName="skills"
                        handleBlur={handleBlur}
                        setFieldValue={(value)=>{
                            //setFieldValue("skills",value)
                        }}
                        handleOnChange={(value:any)=>{
                            setFieldValue("skills",value)
                        }}
                        selectedValue={values?.skills}
                        helperText={""}
                        isError={false}
                        isRequired={false}
                        placeholderText={"Select Skills"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {/* Working Location Selection */}
                    <InputLabel shrink={true}>Working Location</InputLabel>
                    <FormControl component="fieldset" margin="none">
                        <FormGroup row>
                            <Field
                            name="jobType"
                            type="radio"
                            value="Remote"
                            as={FormControlLabel}
                            control={<Radio />}
                            label="Remote"
                            />
                            <Field
                            name="jobType"
                            type="radio"
                            value="Onsite"
                            as={FormControlLabel}
                            control={<Radio />}
                            label="Onsite"
                            />
                            <Field
                            name="jobType"
                            type="radio"
                            value="Hybrid"
                            as={FormControlLabel}
                            control={<Radio />}
                            label="Hybrid"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={-1}>
                    <InputLabel shrink={true}>Additional Description</InputLabel>
                    <TextareaAutosize
                        name="jobDescription"
                        minRows={1}
                        maxRows={3}
                        className="V2-textArea"
                        value={values?.jobDescription}
                        onChange={handleChange}
                    />
                </Grid>    
                
                <Grid item xs={12}>
                    <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary"
                    sx={{marginRight:2}}
                    onClick={()=>{ console.log("errors",errors);                        
                    }}
                    disabled={apiLoading }
                    endIcon={(apiLoading ) && <CircularProgress
                        size={"16px"}
                        color={"inherit"}
                    />}
                    >
                     Get Result
                    </Button>
                    <Button 
                        type="button" 
                        variant="outlined" 
                        color="primary"
                        onClick={()=>{
                            setValues(GenerateJobDetailsFormIntialState)
                        }}
                        disabled={apiLoading }
                    >
                     Reset
                    </Button>
                </Grid>
                    {isNewResult && 
                    <>
                    {generatedJobDetailsResult ?
                        <> 
                        <Grid item xs={12}>
                        <GenerateJobDetailsPreview
                        messageList={generatedJobDetailsResult}
                        //messageList={GenerateJobDetailsEximiusMockResponse.messageList}
                        />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                type="button" 
                                variant="outlined" 
                                color="primary"
                                onClick={()=>{                                
                                    handleOnContinue(generatedJobDetailsResult)
                                }}
                                endIcon={ <ArrowRight fontSize={"large"} />}
                            >
                                Continue
                            </Button>                    
                        </Grid>
                        </>
                       : 
                       <Grid item xs={12} sm={12} md={8}>
                            <CustomAlert 
                                    fontSize='14px'
                                    type={"error"} 
                                    autoCloseTime={6000}
                                    message="Please try again. Something went wrong and not able to generate the information."
                            /> 
                        </Grid>
                       }
                    </>
                    }
            </Grid>           
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default GenerateJobPostingContentForm;
