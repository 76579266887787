import { createSlice } from "@reduxjs/toolkit";
import { PermissionsEnums } from "../../data/permission";

interface UserPermissionsState {
  features: PermissionsEnums[];
  modules: PermissionsEnums[];
}

let initialState:UserPermissionsState ={ features: [], modules: [] }

export const userPermissionSlice = createSlice({
  name: "userPermission",
  initialState,
  reducers: {
    loadPermissions: (state, action) => {
      state.features = action.payload.permissions.features;
      state.modules = action.payload.permissions.modules;

    },
    clearPermissions: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { loadPermissions, clearPermissions } =
  userPermissionSlice.actions;

export default userPermissionSlice.reducer;
