import { Button, FormControlLabel, Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  AddUserPermissions,
  GetFeatures,
  GetRoles,
  GetUserPermissionByUserId,
} from "../../../Services/idProviderService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { PermissionsEnums } from "../../../data/permission";
import { BreadCrumbItem } from "../../../models/AppModels";
import { Roles, UserAuth } from "../../../models/interfaces";
import { orValues, valueOrDefault, ternary } from "../../../utils/complexityUtils";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { AdminAutocomplete } from "../../ui/AdminAutocomplete";
import { Asterisk } from "../../ui/Asterisk";
import { ClientAutocomplete } from "../../ui/ClientAutocomplete";
import { CustomAlert } from "../../ui/CustomAlert";
import { RecruiterAutocomplete } from "../../ui/RecruiterAutocomplete";
import { SuperadminAutocomplete } from "../../ui/SuperAdminAutocomplete";
import { TeamMemberAutocomplete } from "../../ui/TeamMemberAutocomplete";
import { AccessSection } from "./AccessSection";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import { getIsClientModuleEnabled } from "../../../utils/AppConfig";

export const RoleManagement = () => {
  const isClientModuleEnabled: boolean = getIsClientModuleEnabled();
  let userRole = getLoggedInUserRole();
  
  const [pageStatus, setPageStatus] = useState({
    message: "",
    error: "",
    isSuccess: false,
    key: Date.now(),
  });
  const [permissionData, setPermissionData] = useState({
    permissionId: "",
    createdBy: "",
    createdDate: "",
  });
  const [activePermissions, setActivePermissions] = useState<{
    moduleIds: string[];
    featureIds: string[];
  }>({
    moduleIds: [],
    featureIds: [],
  });

  const [inactivePermissions, setInactivePermissions] = useState<{
    moduleIds: string[];
    featureIds: string[];
  }>({
    moduleIds: [],
    featureIds: [],
  });
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [allFeatures, setAllFeatures] = useState<any>();
  const initialState = {
    name: "",
    email: "",
    selectedRole: "",
    users: [],
  };
  const roleSchema = Yup.object({});
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: roleSchema,
    onSubmit: async (values, actions) => {},
  });

  let defaultBreadcumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: "/dashboard" },
    { isLeaf: true, sequence: 2, title: "User Access", leafClasses: " v2LastLeft", },
  ];

const[userRoleList, setUserRoleList] = useState([]);
  
useEffect(() => {
  _GetRoles();
  _GetFeatures();
}, []);

  const _GetRoles = () => {
    GetRoles()
      .then((response) => {
        if (response?.data?.message?.appStatusCode === "IP_RSF") {
          const userRoleTypeList = getUserRoleTypeList(response);
          setUserRoleList(userRoleTypeList);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  const getUserRoleTypeList = (response:any) => {
    const entityList = response?.data?.entityList || [];
    if (userRole === UserAuth.Client) {
      return getClientRoleTypeList(entityList);
    } else {
      return getNonClientRoleTypeList(entityList);
    }
  };
  
  const getClientRoleTypeList = (entityList:any) => {
    return entityList.length ? [entityList.slice(-1)[0]] : [];
  };
  
  const getNonClientRoleTypeList = (entityList:any) => {
    return entityList.filter((x:any) => {
      return isRoleAllowed(x.roleName);
    });
  };
  
  const isRoleAllowed = (roleName: string) => {
    if (roleName === "ClientTeamMember") {
      return false;
    }
    if (!isClientModuleEnabled && roleName === "Client") {
      return false;
    }
    return true;
  };

  const _GetFeatures = ()=>{
    GetFeatures()
    .then((response) => {
      if( response?.data?.message?.appStatusCode === "IP_RSF" ){
        setAllFeatures(response)
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    if (e.target.checked) {
      formik.setFieldValue("selectedRole", e.target.value);
    } else {
      formik.setFieldValue("selectedRole", "");
    }
  };
  const handleAccessChange = (
    source: string,
    itemName: string,
    type: string
  ) => {
    if (source === "activePermission") {
      setActivePermissions((prevPermissions) => {
        const updatedSource = { ...prevPermissions };
        const index = updatedSource[type].indexOf(itemName);
        if (index !== -1) {
          updatedSource[type].splice(index, 1);
          setInactivePermissions((prevInactivePermissions) => ({
            ...prevInactivePermissions,
            [type]: [...(prevInactivePermissions[type] || []), itemName],
          }));
        }
        return updatedSource;
      });
    } else if (source === "inactivePermission") {
      setInactivePermissions((prevPermissions) => {
        const updatedSource = { ...prevPermissions };
        const index = updatedSource[type].indexOf(itemName);
        if (index !== -1) {
          updatedSource[type].splice(index, 1);
          setActivePermissions((prevActivePermissions) => ({
            ...prevActivePermissions,
            [type]: [...(prevActivePermissions[type] || []), itemName],
          }));
        }
        return updatedSource;
      });
    }
  };

  const renderUserAutoCompleteBasedOnRole = () => {
    if (formik.values.selectedRole == Roles.SuperAdminId) {
      return (
        <>
          <SuperadminAutocomplete
            showEmail={true}
            multiple={false}
            admin={formik.values.users}
            onChange={(e: any, value: any) => {
              formik.setFieldValue("users", ternary(value?.id,value,''));
                        }}
            readOnly={formik.values.selectedRole == ""}
          />
        </>
      );
    }
    if (formik.values.selectedRole == Roles.AdminId) {
      return (
        <>
          <AdminAutocomplete
            showEmail={true}
            multiple={false}
            admin={formik.values.users}
            onChange={(e: any, value: any) => {
              formik.setFieldValue("users", ternary(value?.id,value,''));
            }}
            readOnly={formik.values.selectedRole == ""}
          />
        </>
      );
    }
    if (formik.values.selectedRole == Roles.RecruiterId) {
      return (
        <>
          <RecruiterAutocomplete
            isRequired={false}
            showEmail={true}
            multiple={false}
            recruiter={formik.values.users}
            onChange={(e: any, value: any) => {
              formik.setFieldValue("users", ternary(value?.id,value,''));
            }}
            readOnly={formik.values.selectedRole == ""}
          />
        </>
      );
    }
    if (formik.values.selectedRole == Roles.UserRole) {
      return (
        <>
          <ClientAutocomplete
            showEmail={true}
            multiple={false}
            admin={formik.values.users}
            onChange={(e: any, value: any) => {
              formik.setFieldValue("users", ternary(value?.id,value,''));
            }}
            readOnly={formik.values.selectedRole == ""}
          />
        </>
      );
    }
    if (formik.values.selectedRole == Roles.TeamMember) {
      return (
        <>
          <TeamMemberAutocomplete
            showEmail={true}
            recruiter={formik.values.users}
            onChange={(e: any, value: any) => {
              formik.setFieldValue("users", ternary(value?.id,value,''));
            }}
            readOnly={false}
            multiple={false}
            isRequired={false}
            disableLabel={true}
          />
        </>
      );
    }
  };

  const setInitialPermissions = async () => {
    try {
      const response = await GetUserPermissionByUserId(
        formik.values.selectedRole == Roles.RecruiterId ||
          formik.values.selectedRole == Roles.UserRole ||
          formik.values.selectedRole == Roles.TeamMember
          ? formik.values?.users?.id
          : formik.values?.users?.userId
      );
      const allowedModulesIds = response?.data?.entity?.allowedModulesIds;
      const allowedFeaturesIds = response?.data?.entity?.allowedFeaturesIds;
      const deniedModulesIds = response?.data?.entity?.deniedModulesIds;
      const deniedFeaturesIds = response?.data?.entity?.deniedFeaturesIds;
      setPermissionData({
        permissionId: response?.data?.entity?.id,
        createdBy: response?.data?.entity?.createdBy,
        createdDate: response?.data?.entity?.createdDate,
      });
      setActivePermissions({
        moduleIds: allowedModulesIds || [],
        featureIds: allowedFeaturesIds || [],
      });
      setInactivePermissions({
        featureIds: deniedFeaturesIds || [],
        moduleIds: deniedModulesIds || [],
      });
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  const getAllowedFeatures = (type: string): string[] => {
    const { moduleIds, featureIds } = activePermissions;
    const allowedFeatures: string[] = [];
    //id which are disabled extra
    const givenIds: string[] = [
      PermissionsEnums.Admins_View,
      PermissionsEnums.Admins_Add,
      PermissionsEnums.Admins_Edit,
      PermissionsEnums.Admins_Verify,
      PermissionsEnums.Compensation_View,
      PermissionsEnums.Compensation_Update,
      PermissionsEnums.Privileged,
      PermissionsEnums.Members_Add,
      PermissionsEnums.Members_Verify,
      PermissionsEnums.Members_Listing,
      PermissionsEnums.Members_Edit,
      PermissionsEnums.Manage_CLUser,
      PermissionsEnums.Manage_CLUpdate
    ];

    const activeIds = type === "features" ? featureIds : moduleIds;

    givenIds.forEach((id) => {
      if (activeIds.includes(id)) {
        allowedFeatures.push(id);
      }
    });

    return allowedFeatures;
  };

  const handleSubmitConfirm = () => {
    let model = {
      id: permissionData?.permissionId,
      userId:
        formik.values.selectedRole == Roles.RecruiterId ||
        formik.values.selectedRole == Roles.UserRole ||
        formik.values.selectedRole == Roles.TeamMember
          ? formik.values?.users?.id 
          : formik.values?.users?.userId,
      allowedFeaturesIds: getAllowedFeatures("features"),
      allowedModulesIds: getAllowedFeatures("modules"),
      deniedModulesIds: inactivePermissions.moduleIds,
      deniedFeaturesIds: inactivePermissions.featureIds,
      viewOnly: false,
      privilegeAccess: false,
      createdDate: permissionData?.createdDate || new Date().toISOString(),
      modifiedDate: new Date().toISOString(),
      createdBy: orValues(
        permissionData?.createdBy,
        JSON.parse(valueOrDefault(localStorage.getItem("user"), ""))?.userId,
        ""
      ),
      modifiedBy: valueOrDefault(
        JSON.parse(valueOrDefault(localStorage.getItem("user"), ""))?.userId,
        ""
      ),
    };

    AddUserPermissions(model)
      .then((response) => {
        const entity = response?.data?.entity;
        if (entity) {
          setPermissionData({...permissionData, permissionId:entity?.id})
          setPageStatus({
            message: "User permissions updated successfully.",
            error: "",
            isSuccess: true,
            key: Date.now(),
          });
        }
      })
      .catch((error) => {
        setPageStatus({
          message: "Error occurred while adding user permissions.",
          error: "Error occurred while adding user permissions.",
          isSuccess: false,
          key: Date.now(),
        });
        console.error(error);
      });
  };

  const selectAllHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    setSelectAllChecked((prevChecked) => isChecked);

    if (isChecked) {
      // Checkbox is checked
      setActivePermissions((prevActivePermissions) => ({
        featureIds: [
          ...prevActivePermissions.featureIds,
          ...inactivePermissions.featureIds,
        ],
        moduleIds: [
          ...prevActivePermissions.moduleIds,
          ...inactivePermissions.moduleIds,
        ],
      }));
      setInactivePermissions({
        featureIds: [],
        moduleIds: [],
      });
    } else {
      // Checkbox is not checked
      setInitialPermissions();
    }
  };

  useEffect(() => {
    formik.setFieldValue("users", []);
    setSelectAllChecked(false);
  }, [formik.values.selectedRole]);

 
  useEffect(() => {
    
    if (formik.values.users && formik.values.users?.id != undefined) {
      setInitialPermissions();
      setSelectAllChecked(false);
    } else {
      setActivePermissions({ moduleIds: [], featureIds: [] });
      setInactivePermissions({ moduleIds: [], featureIds: [] });
    }
    setPageStatus({ ...pageStatus, isSuccess: false });
  }, [formik.values.users]);
  

  const getRoleLabel = (roleName: string): string => {
    if (roleName === "SuperAdmin") {
      return "Super Admin";
    } else if (roleName === "ClientTeamMember") {
      return "Team Member";
    } else {
      return roleName;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBreadCrumb classes="custom-appbreadcrumb" BreadCrumbItems={defaultBreadcumbs}></AppBreadCrumb>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4.3} md={4.3} lg={4.3}>
              <Grid>
                <Typography className="role-management-heading">
                  Roles and User Selection

                </Typography>

                <Typography mt={2} className="all-form-label">
                  Roles
                  <Asterisk />{" "}
                </Typography>
                {userRoleList?.length > 0 &&  userRoleList?.map((x: any, index:number) => {
              
                  return (
                    <FormControlLabel
                      key={x.id}
                      control={
                        <Checkbox
                          value={x.id}
                          onChange={handleCheckboxChange}
                          checked={formik.values.selectedRole == x.id}
                          data-testid={`checkbox-rolemanagement-${index}`}
                        />
                      }
                      label={getRoleLabel(x.roleName)}
                    />
                  );
                })}
              </Grid>
              {formik.values.selectedRole !== "" && (
                <Typography mt={2} className="all-form-label">
                  Select User <Asterisk />{" "}
                </Typography>
              )}
              {renderUserAutoCompleteBasedOnRole()}
            </Grid>

            {(formik.values.users && formik.values.users.length !== 0) ? (
              <>
                <Grid xs={12} item mt={1} sm={7.5} md={7.5} lg={7.5}>
                  <Typography className="role-management-heading">
                    Manage Access <Asterisk />{" "}
                  </Typography>

                  <FormControlLabel
                    key={"7u"}
                    control={
                      <Checkbox
                        disabled={
                          inactivePermissions.moduleIds.length === 0 &&
                          inactivePermissions.featureIds.length === 0 &&
                          !selectAllChecked
                        }
                        id="checkbox-1"
                        checked={selectAllChecked}
                        onClick={selectAllHandler}
                      />
                    }
                    label={"Select All"}
                  />
                  <br />

                  <AccessSection
                    allFeatures={allFeatures}
                    activePermissions={activePermissions}
                    inactivePermissions={inactivePermissions}
                    handleAccessChange={handleAccessChange}
                  />
                  {pageStatus.isSuccess && (
                    <SnackbarPopup
                      open={pageStatus.isSuccess}
                      message={pageStatus.message}
                      onClose={() => {
                        setPageStatus({ ...pageStatus, isSuccess: false });
                      }}
                    />
                  )}
                  {!pageStatus.isSuccess && pageStatus.error!=='' && (
                    <CustomAlert
                      key={pageStatus.key}
                      message={pageStatus.message}
                      type={'error'}
                    />
                  )}

                  <Grid item mt={2} ml={0.1} container spacing={0}>
                    <Button
                      onClick={() => formik.setFieldValue("users", [])}
                      style={{ marginRight: "6px" }}
                      variant="contained"
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={
                        !formik.values.users || formik.values.users.length === 0
                      }
                      onClick={handleSubmitConfirm}
                      variant="contained"
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </>
            ):""}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
