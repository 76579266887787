import React, { useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

interface Props {
  name?: string;
  required?: boolean;
  handleBlur: any;
  handleOnChange: (value: any) => void;
  selectedValue: string[];
  touched: boolean;
  errors: string;
  placeholder?: string;
  label?: string;
  disableClearable?: boolean;
  multiple?: boolean;
}

const CertificationsAndLicensesAutoComplete: React.FC<Props> = ({
  name,
  required = false,
  handleBlur,
  handleOnChange,
  selectedValue,
  touched,
  errors,
  placeholder = "Enter Certifications and Licences",
  label = "Desired Certifications",
  disableClearable = false,
  multiple = true
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleBlurWithChip = (event: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(event);
    if (inputValue && !selectedValue.includes(inputValue)) {
      handleOnChange([...selectedValue, inputValue]);
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      freeSolo={true}
      className="jrAutocomplete"
      id="tags-standard"
      multiple={multiple}
      disableClearable={disableClearable}
      options={[]}
      value={selectedValue?.length > 0 ? selectedValue : []}
      onChange={(_, newValue) => {
        handleOnChange(newValue);
      }}
      onBlur={handleBlurWithChip}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option: any, index: any) => (
          <Chip
            color="primary"
            size="small"
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          name={name}
          required={required}
          {...params}
          variant="standard"
          label={label}
          data-testid="autocomplete-certifications"
          error={touched && Boolean(errors)}
          helperText={touched && errors}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default CertificationsAndLicensesAutoComplete;
