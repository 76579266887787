import { Checkbox, FormControl, Input, TextField } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import SquareIcon from "@mui/icons-material/Square";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Alert,
  AppBar,
  Card,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Form, Formik, FormikProps, useFormik } from "formik";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import axios from "../../../Services/CommonHttpRequest";
import {
  getScoreCardPdfFile,
  getSubmissionChangeLogPdfFile,
} from "../../../Services/getPdfReportService";
import { GetJobById } from "../../../Services/jobService";
import {
  CandidateAuthorize,
  CandidateDecline,
  EximiusMatchProfile,
  GetRecruiterById,
  GetSubmissionData,
  SendEmailForUpdateSubmission,
  UpdateResumeByRecruiter,
} from "../../../Services/recruiterService";
import {
  DocumentType,
  UploadAttachment,
  UploadResourceModel,
} from "../../../Services/uploadPhotoService";
import {
  searchCities,
  searchOptions,
} from "../../../constants/googlePlaceOption";
import { editModeKey } from "../../../data/editModeKey/editModeKey";
import { preferredCountries } from "../../../data/phoneInput/preferredCountries";
import rawCountries from "../../../data/phoneInput/rawCountries";
import { toggleIndividualEdit } from "../../../features/editMode/editModeSlice";
import { JobOriginIds } from "../../../models/Job/JobDetailsModel";
import {
  DocumentTypeEnum,
  UserAuthByRoleName,
} from "../../../models/interfaces";
import { EximiusScoreDataReturn, } from "../../../models/recruiterModel";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import getAddressForAutoFill from "../../../utils/getAddressForAutoFill";
import {
  downloadFile,
  getFileFromUrl,
  getFilePreviewUrl,
} from "../../../utils/getFileFromUrl";
import { isEmployerNameRequired } from "../../../utils/isEmployerNameRequired";
import { jobLocation } from "../../../utils/jobUtils";
import truncateString from "../../../utils/truncateString";
import { Clock as ClockIcon } from "../../Common/Icons/Clock";
import { Edit as EditIcon } from "../../Common/Icons/Edit";
import { Eye as EyeIcon } from "../../Common/Icons/Eye";
import { MapMarker as MapMarkerIcon } from "../../Common/Icons/MapMarker";
import JobTitle from "../../Common/JobTitle";
import PhoneVerify from "../../Common/PhoneVerify/PhoneVerify";
import PreviewFileDialog from "../../Common/PreviewFileDialog";
import { UploadAttachments } from "../../Common/UploadAttachments";
import { CustomAlert } from "../../ui/CustomAlert";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { Payrate } from "../../ui/Payrate";
import { RecruiterInformation } from "../RecruiterInformation";
import logo from "./../../../assets/images/logo.svg";
import verifiedIcon from "./../../../assets/images/verified.svg";
import { CircularProgressWithLabel } from "./Components/CircularProgressWithLabel";
import { ValidationSchemaCandidateAcknowledgement } from "./Components/ValidationSchemaCandidateAcknowledgement";
import { initialStateCandidateAcknowledgment } from "./Components/initialStateCandidateAcknowledgment";
import { AxiosResponse } from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import ScorecardResume from "../../Common/Scorecard/ScorecardResume";
import { JobStatus, getJobStatusColor } from "../../../utils/AppUtils";
import { getIsScorecardFeatureActive } from "../../../utils/AppConfig";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const labelFont = {
  fontWeight: "400",
  marginTop: "2px",
  color: "#000000",
};

interface CandidateAcknowledgeProps {
  // currently not using any props
}

export const CandidateAcknowledge: React.FC<CandidateAcknowledgeProps> = () => {

  const isScorecardFeatureActive = getIsScorecardFeatureActive();
  const dispatch = useDispatch();
  const isResumeEditable = useSelector(
    (state: any) =>
      state.editMode.individualEditMode.find(
        (x: any) => x.name === editModeKey.CandidateAcknResume
      ).editMode
  );

  const [isCandidateInfoEditable, setIsCandidateInfoEditable] =
    useState<boolean>(false);
  const initialState = initialStateCandidateAcknowledgment;

  const [searchParams] = useSearchParams();
  const [recruiterDetails, setRecruiterDetails] = useState<any>();
  const [jobDetails, setJobDetails] = useState<any>();
  const [submissionData, setSubmissionData] = useState<any>();
  const [submissionResponse, setSubmissionResponse] = useState<any>();

  const [isloading, setIsloading] = useState(true);
  const [resumeFile, setResumeFile] = React.useState<{
    file: File;
    fileName: string;
  }>();

  const [isActionTaken, setIsActionTaken] = useState({
    action: "",
    flag: false,
    isSuccess:false
  });
  const formRef = React.useRef<FormikProps<typeof initialState>>(null);
  const [scoreResume, setscoreResume] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isScorecardLoading, setIsScorecardLoading] = React.useState<any>(null);
  const [glasssquidScore, setGlasssquidScore] =
    React.useState<typeof EximiusScoreDataReturn>();
  const [isAutoPopulatePending, setIsAutoPopulatePending] =
    React.useState(false);
  const [emp_countryShort, setEmpCountryShort] = React.useState("us");
  const [isNewResumeFile, setIsNewResumeFile] = React.useState(false);
  const [defaultOtherDoc, setDefaultOtherDoc] = React.useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);

  const [scoreCardPdf, setScoreCardPdf] = React.useState<{
    pdfFile?: File;
    isGenerating: boolean;
  }>({ isGenerating: false });
  const [filePreview, setFilePreview] = React.useState<
    | undefined
    | {
        resourceUrl: string;
        fileName: string;
        fileType?: string;
      }
  >(undefined);

  const [verifiedPhoneDetails, setVerifiedPhoneDetails] = React.useState({
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    },
  });


  const [existingSubmission, setExistingSubmission] = React.useState<any>({});

  const [commentsList, setCommentsList] = React.useState([]);
  const [candidateCommentsList, setCandidateCommentList] = React.useState([]);
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    isError: false,
    error: "",
    loading: false,
    msgLocation: "",
  });
  const [updateMode, setUpdateMode] = React.useState("CandidateInfo");
  const [glasssquidScoreStatus, setGlasssquidScoreStatus] = React.useState<{
    statusCode?: number;
    statusMsg?: string;
  }>();

  const [queryParam, setQueryParam] = useState({
    submissionId: searchParams.get("submissionId"),
    email: searchParams.get("email"),
    messageType: searchParams.get("messageType"),
    jobId: "",
    recruiterGuid: "",
    authToken: searchParams.get("authToken"),
  });

  axios.defaults.headers.common["Authorization"] =
    "Bearer " + searchParams.get("authToken");

  const [phoneNumbers, setPhoneNumbersDetails] = React.useState({
    primaryPhoneNumber: "",
    isPrimaryVerified: false,
  });
  const [dataLoadComplete, setDataLoadComplete] = React.useState(false);


  const validateFormik = async (values: typeof initialState) => {
    const errors: Record<string, any> = {};
  
    const resumeData = glasssquidScore?.messageList?.[0]?.resumeData;
  
    if (resumeData) {
      validateEmail(values.email, resumeData.emailAddress, errors);
      validateName(values.firstName, values.lastName, resumeData.name, errors);
      validateExperience(values.experience, resumeData.yearsOfExperienceInNumbers, errors);
    }
  
    return errors;
  };
  
  const validateEmail = (value: string, resumeEmail: string, errors: Record<string, any>) => {
    if (value !== resumeEmail) {
      errors.email = "Email does not match with the resume. Please update the email";
    }
  };
  
  const validateName = (firstName: string, lastName: string, resumeName: string, errors: Record<string, any>) => {
    const nameParts = resumeName.split(" ");
    const resumeFirstName = nameParts[0];
    const resumeLastName = nameParts[nameParts.length - 1];
  
    if (nameParts.length === 1) {
      if (firstName !== resumeFirstName) {
        errors.firstName = "First Name does not match with the resume. Please update the first name";
      }
    } else {
      if (firstName !== resumeFirstName) {
        errors.firstName = "First Name does not match with the resume. Please update the first name";
      }
      if (lastName !== resumeLastName) {
        errors.lastName = "Last Name does not match with the resume. Please update the last name";
      }
    }
  };
  
  const validateExperience = (value: string, resumeExperience: number, errors: Record<string, any>) => {
    if (resumeExperience !== undefined && value?.toString() !== resumeExperience.toString()) {
      errors.experience = "Experience does not match with the resume. Please update the experience";
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validate: validateFormik,
    validationSchema: ValidationSchemaCandidateAcknowledgement,
    onSubmit: async (values, actions) => {
      console.log("In formik hook");

      let updatedComments = [];
      updatedComments = candidateCommentsList?.map(
        (row: any, index: number) => {
          if (
            values.candidateComments[index] !== undefined &&
            values.candidateComments[index]?.comment !== ""
          ) {
            return {
              id: row?.id,
              createdDate: row?.createdDate,
              lastModifiedDate: row?.lastModifiedDate,
              comment: valueOrDefault(
                values.candidateComments[index]?.comment,
                ""
              ),
            };
          } else {
            return {
              id: row?.id,
              createdDate: row?.createdDate,
              lastModifiedDate: row?.lastModifiedDate,
              comment: row?.comment,
            };
          }
        }
      );
      values.candidateComments = updatedComments;

      handleOnSubmit(values);
    },
  });

  const onPrimaryPhChange = (ev: any) => {
    if (formik.values.phoneNumbers?.primary?.replace(/-/g, "") != ev) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", false);
    }

    if (
      phoneNumbers?.primaryPhoneNumber?.replace(/-/g, "") === ev &&
      ev !== ""
    ) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", true);
    }
    formik.setFieldValue("phoneNumbers.primary", ev);
  };

  function displayPhoneVerifiedIcon() : boolean {
    return (
      (verifiedPhoneDetails.phoneNumbers?.primary ===  formik.values.phoneNumbers?.primary) ||
      formik.values.phoneNumbers?.isPrimaryVerified
    );
  }

function displayPhoneVerifyComponent() : boolean {
  return (
    (verifiedPhoneDetails.phoneNumbers?.primary !==  formik.values.phoneNumbers?.primary) && 
    !formik.values.phoneNumbers?.isPrimaryVerified && 
    isValidPhoneNumber('+'+ formik.values.phoneNumbers?.primary)
  );
}

  function getGlasssquidScore() {
    let formData = new FormData();

    formData.append("JobId", jobDetails?.id);
    formData.append("File", resumeFile?.file as File);
    formData.append("skillsPct", "14.2");
    formData.append("jobTitlePct", "14.2");
    formData.append("technologyPct", "14.2");
    formData.append("toolsPct", "14.2");
    formData.append("educationPct", "14.2");
    formData.append("certificationPct", "14.2");
    formData.append("industryPct", "14.2");
    formData.append("feature", "ScoreCard");

    // Append data to the FormData object for V2 Version
    formData.append("EximScore", "");
    formData.append("JobData", valueOrDefault(jobDetails?.jobDescriptionNoHtml,""));
    formData.append("ResumeData", "");
    formData.append("UnweightedMatchedScores", "");
    formData.append("JobTitle", valueOrDefault(jobDetails?.jobTitle, ""));

    // For arrays, 
    jobDetails?.manualData?.skills?.map((item:any) => {      
     return  formData.append("Skills", valueOrDefault(item.skill, "")) 
    })

    jobDetails?.manualData?.CertificationAndLicenses?.map((item:any) => {      
      return  formData.append("CertificationAndLicenses", valueOrDefault(item.certification, "")) 
     })

     jobDetails?.education?.map((item:any) => {      
      return  formData.append("Education", valueOrDefault(item?.degreeName, "")) 
     })
    
    if(jobDetails?.tools && jobDetails?.tools !==""){
      formData.append("Tools", "");
    }
    if(jobDetails?.technology !==""){
      formData.append("Technology", valueOrDefault(jobDetails?.technology, ""));
    }
    
    formData.append("Industry", valueOrDefault(jobDetails?.industry, ""));
    formData.append("Domain", jobDetails?.domain);

    setIsScorecardLoading(true);
    setScoreCardPdf({ isGenerating: true });
    EximiusMatchProfile(formData)
      .then((response) => {
        if (response?.data?.status === 200) {
          setGlasssquidScore(response?.data?.entity);
        }

        setIsAutoPopulatePending(true);        
        setGlasssquidScoreStatus({
          statusCode: response?.data?.status,
          statusMsg: response?.data?.message,
        });
      })
      .finally(() => {
        setIsScorecardLoading(false);
      });
  }

  const props_Phone1Verify = {
    phoneNumber: formik.values.phoneNumbers?.primary,
    isPrimaryPhone: true,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isSecondaryVerified,
    updatePhoneVerify: (isVerified: any) => {
      setPhoneNumbersDetails({
        ...phoneNumbers,
        primaryPhoneNumber: formik.values.phoneNumbers?.primary,
      });
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", isVerified);
      setVerifiedPhoneDetails({
        ...verifiedPhoneDetails,
        phoneNumbers: {
          ...verifiedPhoneDetails.phoneNumbers,
          primary:formik.values.phoneNumbers?.primary,
          isPrimaryVerified: isVerified,
        },
      });
    },
  };

  const handleScoreResume = async () => {
    dispatch(toggleIndividualEdit({ pageName: editModeKey.CandidateAcknInfo }));
    setIsCandidateInfoEditable(!isCandidateInfoEditable);
  };

  useEffect(() => {
    _GetSubmissionData();
  }, [queryParam.submissionId]);

  useEffect(() => {
    setPageStatus({ ...pageStatus, msgLocation: updateMode });
  }, [updateMode]);

  useEffect(() => {
    formik?.setFieldTouched("firstName", false);
    formik?.setFieldTouched("lastName", false);
    formik?.setFieldTouched("address", false);
    formik?.setFieldTouched("address.streetAddress", false);
    formik?.setFieldTouched("city", false);
    formik?.setFieldTouched("state", false);
    formik?.setFieldTouched("zip", false);
    formik?.setFieldTouched("country", false);
    formik?.setFieldTouched("phoneNumbers.isPrimaryVerified", false);
    formik?.setFieldTouched("experience", false);
    formik?.setFieldTouched("currentWorkLocation.city", false);
    formik?.setFieldTouched("currentWorkLocation.state", false);
    formik?.setFieldTouched("skills", false);
  }, [!isCandidateInfoEditable]);

  const setCandidateSubmissionDetailsData = (submissionData: any) => {
    setQueryParam({
      ...queryParam,
      jobId: submissionData?.jobId,
      recruiterGuid: submissionData?.recruiterGuid,
    });

    setPayloadData(submissionData);
    setExistingSubmission(submissionData);

    formik.setFieldValue("phoneNumbers.primary", submissionData?.phone);
  };

  useEffect(() => {
    
    if (isNewResumeFile) {
      isScorecardFeatureActive && getGlasssquidScore();
    } else {
      if (
        jobDetails?.id !== "" &&
        jobDetails?.id !== undefined &&
        resumeFile?.file
      ) {
        isScorecardFeatureActive && getGlasssquidScore();
      }
    }
  }, [isNewResumeFile, jobDetails?.id, resumeFile]);

  const downloadScoreCard = () => {
    if (scoreCardPdf?.pdfFile) {
      let csvURL = window.URL.createObjectURL(scoreCardPdf.pdfFile);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "Score-card.pdf");
      tempLink.click();
    }
  };

  useEffect(() => {
    if ( isScorecardFeatureActive && glasssquidScore) {
      setTimeout(async () => {
        let scoreCardFile = await getScoreCardPdfFile(
          {
            data: glasssquidScore.messageList[0],
            status: "",
            jobId: jobDetails?.id,
            jobTitle: jobDetails?.jobTitle,
          },
          queryParam?.authToken ?? ""
        );
        setScoreCardPdf({ pdfFile: scoreCardFile, isGenerating: false });
      }, 1000);
    }
  }, [glasssquidScore]);

  useEffect(() => {
    formik.setFieldValue("phone", formik.values.phone);
    formik.setFieldValue("phoneNumbers.primary", formik.values.phone);
  }, [formik.values.phone]);

  const setPayloadData = async (submissionData: any) => {
    formik.setFieldValue(
      "firstName",
      valueOrDefault(submissionData?.firstName, "")
    );
    formik.setFieldValue(
      "middleName",
      valueOrDefault(submissionData?.middleName, "")
    );
    formik.setFieldValue(
      "lastName",
      valueOrDefault(submissionData?.lastName, "")
    );
    formik.setFieldValue("email", submissionData?.email);
    formik.setFieldValue("phone", submissionData?.phone);
    formik.setFieldValue(
      "address.streetAddress",
      valueOrDefault(submissionData?.address?.streetAddress, "")
    );
    formik.setFieldValue(
      "address.city",
      valueOrDefault(submissionData?.address?.city, "")
    );
    formik.setFieldValue(
      "address.state",
      valueOrDefault(submissionData?.address?.state, "")
    );
    formik.setFieldValue(
      "address.countryCode",
      valueOrDefault(submissionData?.address?.countryCode, "")
    );
    formik.setFieldValue(
      "address.postalCode",
      valueOrDefault(submissionData?.address?.postalCode, "")
    );
    formik.setFieldValue(
      "experience",
      valueOrDefault(submissionData?.experience, "")
    );
    formik.setFieldValue(
      "packageCurrency",
      valueOrDefault(submissionData?.packageCurrency, "")
    );
    formik.setFieldValue(
      "payRate",
      valueOrDefault(submissionData?.payRate, "")
    );
    formik.setFieldValue(
      "payRateType",
      valueOrDefault(submissionData?.payRateType, "")
    );
    formik.setFieldValue(
      "workAuthorizationStatus",
      valueOrDefault(submissionData?.workAuthorizationStatus, "")
    );
    formik.setFieldValue(
      "employmentType",
      valueOrDefault(submissionData?.employmentType, "")
    );
    formik.setFieldValue(
      "employerName",
      valueOrDefault(submissionData?.employerName, "")
    );
    formik.setFieldValue(
      "employerDetails",
      valueOrDefault(submissionData?.employerDetails, [])
    );
    formik.setFieldValue(
      "canRelocate",
      submissionData?.canRelocate ?? false
    );
    formik.setFieldValue(
      "currentWorkLocation.city",
      valueOrDefault(submissionData?.currentWorkLocation?.city, "")
    );
    formik.setFieldValue(
      "currentWorkLocation.state",
      valueOrDefault(submissionData?.currentWorkLocation?.state, "")
    );
    setCandidateCommentList(
      valueOrDefault(submissionData?.candidateComments, [])
    );
    formik.setFieldValue("resumeInfoObj", {
      resourceUrl: valueOrDefault(submissionData?.resumeInfo?.resourceUrl, ""),
      fileName: valueOrDefault(submissionData?.resumeInfo?.fileName, ""),
      newFileName: valueOrDefault(submissionData?.resumeInfo?.newFileName, ""),
    });

    formik.setFieldValue(
      "phoneNumbers.isPrimaryVerified",
      valueOrDefault(submissionData?.isPhoneVerified, false)
    );
    formik.setFieldValue("skills", submissionData?.skillsMatch);

    if (submissionData?.resumeInfo?.resourceUrl) {
      let file = await getFileFromUrl(
        submissionData?.resumeInfo?.resourceUrl,
        submissionData?.resumeInfo?.fileName,
        valueOrDefault(queryParam?.authToken, "")
      );
      setResumeFile({
        file: file,
        fileName: submissionData?.resumeInfo?.fileName,
      });
    }

    let otherDocInfoList = submissionData?.otherDocInfo?.filter(
      (doc: any) => doc.documentType !== "ResumeScoreCard"
    );
    formik.setFieldValue("otherDocInfo", submissionData?.otherDocInfo);
    setDefaultOtherDoc(otherDocInfoList);

    setCommentsList(submissionData?.comments);
  };

  useEffect(() => {
    if (isResumeEditable === true) {
      dispatch(
        toggleIndividualEdit({ pageName: editModeKey.CandidateAcknResume })
      );
    }
    if (isCandidateInfoEditable === true) {
      dispatch(
        toggleIndividualEdit({ pageName: editModeKey.CandidateAcknInfo })
      );
      setIsCandidateInfoEditable(!isCandidateInfoEditable);
    }
  }, [isActionTaken.flag]);

  const _GetRecruiterById = (recruiterGuid: string) => {
    if (recruiterGuid) {
      GetRecruiterById(recruiterGuid)
        .then((response) => {
          if (response.data?.message?.appStatusCode === "RC_RSF") {
            setDataLoadComplete(true);
            setRecruiterDetails(response.data?.entity);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };

  const _GetJobById = (jobId: string) => {
    if (jobId) {
      GetJobById(jobId)
        .then((response) => {
          if (response.data?.message?.appStatusCode === "RC_RSF") {
            setJobDetails(response.data?.entity);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    }
  };

  const _GetSubmissionData = () => {
    if (queryParam.submissionId) {
      setIsloading(true);
      GetSubmissionData({ submissionId: queryParam.submissionId })
        .then((response) => {
          if (response.data?.message?.appStatusCode === "RC_RSF") {
            setIsloading(false);
            setSubmissionData(response?.data?.entity);
            setSubmissionResponse(response?.data ?? null);
            setCandidateSubmissionDetailsData(response?.data?.entity);
            if(response?.data?.entity?.isPhoneVerified){
              setVerifiedPhoneDetails({
                ...verifiedPhoneDetails,
                phoneNumbers: {
                  ...verifiedPhoneDetails.phoneNumbers,
                  primary: response?.data?.entity?.phone ?? "",
                  isPrimaryVerified:
                    response?.data?.entity?.isPhoneVerified ?? false,
                },
              });
            }
            

            //RecruiterDetails
            _GetRecruiterById(response?.data?.entity?.recruiterGuid);

            //Get job details
            _GetJobById(response?.data?.entity?.jobId);
          }else{
            setSubmissionResponse(response?.data ?? null);
          }
        })
        .catch((er) => {
          console.log(er);
        })
        .finally(() => {
          setIsloading(false);
        });
    }
  };

  const Authorize = () => {
    CandidateAuthorize({
      submissionId: submissionData?.id,
      recruiterName:
        recruiterDetails?.firstName +
        " " +
        recruiterDetails?.middleName +
        " " +
        recruiterDetails?.lastName,
      recruiterEmail: recruiterDetails?.email,
      jobTitle: jobDetails?.jobTitle,
    }).then(() => {
      setIsActionTaken({
        flag: true,
        action:
          "You have successfully authorized the right to represent for this Job",
        isSuccess:true
      });
    });
  };

  const Decline = () => {
    CandidateDecline({
      submissionId: submissionData?.id,
      recruiterName:
        recruiterDetails?.firstName +
        " " +
        recruiterDetails?.middleName +
        " " +
        recruiterDetails?.lastName,
      recruiterEmail: recruiterDetails?.email,
      jobTitle: jobDetails?.jobTitle,
    }).then((res) => {
      setIsActionTaken({
        flag: true,
        action: "You have declined the right to represent for this Job",
        isSuccess:true
      });
    });
  };

  const handleUploadAttachmentsOnChange = (files: never[]) => {
    formik?.setFieldValue("otherDocInfo", files);
  };

  const handleOnSubmit = (values: any) => {
    handleResumeSubmit(values);
  };

  const handleSelect = async (address: any) => {
    let resp = await getAddressForAutoFill(address);

    formik?.setFieldValue(
      "addresss",
      valueOrDefault(resp?.formattedAddress, "")
    );
    formik.setFieldValue(
      "address.streetAddress",
      valueOrDefault(resp?.formattedAddress, "")
    );
    formik.setFieldValue("address.city", valueOrDefault(resp?.city, ""));
    formik.setFieldValue("address.state", valueOrDefault(resp?.state, ""));
    formik.setFieldValue(
      "address.countryCode",
      valueOrDefault(resp?.country, "")
    );
    formik.setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));

    if (resp?.formattedAddress) {
      formik?.setFieldTouched("address.streetAddress", false);
    }
    if (resp?.country) {
      formik?.setFieldTouched("address.countryCode", false);
    }
    if (resp?.pin) {
      formik?.setFieldTouched("address.postalCode", false);
    }
    if (resp?.state) {
      formik?.setFieldTouched("address.state", false);
    }
    if (resp?.city) {
      formik?.setFieldTouched("address.city", false);
    }
  };
  const handleSelect2 = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik?.setFieldValue(
      "employerDetails.address.streetAddress",
      valueOrDefault(resp.formattedAddress, "")
    );
    setEmpCountryShort(resp?.countryCode?.toLowerCase());
    formik?.setFieldValue(
      "employerDetails.address.postalCode",
      valueOrDefault(resp.pin, "")
    );
    formik?.setFieldValue(
      "employerDetails.address.countryCode",
      valueOrDefault(resp.country, "")
    );
    formik?.setFieldValue(
      "employerDetails.address.state",
      valueOrDefault(resp.state, "")
    );
    formik?.setFieldValue(
      "employerDetails.address.city",
      valueOrDefault(resp.city, "")
    );
    if (resp.country) {
      formik?.setFieldTouched("employerDetails.address.countryCode", false);
    }
    if (resp.pin) {
      formik?.setFieldTouched("employerDetails.address.postalCode", false);
    }
    if (resp.state) {
      formik?.setFieldTouched("employerDetails.address.state", false);
    }
    if (resp.city) {
      formik?.setFieldTouched("employerDetails.address.city", false);
    }
  };
  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <div className="submit-resume-address">
      <FormControl
        variant="standard"
        className="autocomplete-formControl-jr"
        fullWidth
        required
      >
        <InputLabel htmlFor="address-input" shrink={true}>
          Address<span className="required-asterisk">*</span>
        </InputLabel>
        <Input
          {...getInputProps()}
          name="address.streetAddress"
          type="text"
          disabled={!isCandidateInfoEditable}
        />
        <ErrorMessage
          errorText={
            formik?.touched?.address?.streetAddress &&
            formik?.errors?.address?.streetAddress
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            key={suggestion.description}
            {...getSuggestionItemProps(suggestion)}
          >
            <span onChange={handleSelect} style={{ cursor: "pointer" }}>
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
  const renderFunc2 = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <div className="submit-resume-address">
      <TextField
        {...getInputProps()}
        required={isEmployerNameRequired(
          formik.values.workAuthorizationStatus,
          formik.values.employmentType
        )}
        label="Address"
        fullWidth
        name="employerDetails.address.streetAddress"
        type="text"
        disabled={!isCandidateInfoEditable}
        error={
          formik.touched.employerDetails?.address?.streetAddress &&
          Boolean(formik.errors.employerDetails?.address?.streetAddress)
        }
        helperText={
          formik.touched.employerDetails?.address?.streetAddress &&
          formik.errors.employerDetails?.address?.streetAddress
        }
      />
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            key={suggestion.description}
            {...getSuggestionItemProps(suggestion)}
          >
            <span onChange={handleSelect2} style={{ cursor: "pointer" }}>
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
  const renderFuncCurrentWorkLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <>
      {" "}
      <TextField
        {...getInputProps()}
        required
        label="City"
        fullWidth
        name="currentWorkLocation.city"
        type="text"
        disabled={!isCandidateInfoEditable}
        error={
          formik.touched?.currentWorkLocation?.city &&
          Boolean(formik.errors?.currentWorkLocation?.city)
        }
        helperText={
          formik.touched?.currentWorkLocation?.city &&
          formik.errors?.currentWorkLocation?.city
        }
      />
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span
              onChange={autoCompleteGetWorkLocation}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </>
  );

  const autoCompleteGetWorkLocation = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik?.setFieldValue(
      "currentWorkLocation.city",
      valueOrDefault(resp?.city, "")
    );
    formik?.setFieldValue(
      "currentWorkLocation.state",
      valueOrDefault(resp?.state, "")
    );
    formik?.setFieldValue(
      "currentWorkLocation.id",
      valueOrDefault(resp?.city, "")
    );
  };

  const isValidFileExtention = (fileName: string) => {
    let fileExt = fileName.split(".").pop();
    let validExt = ["txt", "rtf", "doc", "docx", "pdf"];
    return validExt.some((item) => item === fileExt);
  };

  const handleUploadClick = (files: FileList | null, type: string) => {
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    if (type === "Resume") {
      setResumeFile({
        file: files[0],
        fileName: files[0].name,
      });
      setIsNewResumeFile(true);
      setUpdateMode("NewResumeUplaod");
      dispatch(
        toggleIndividualEdit({ pageName: editModeKey.CandidateAcknInfo })
      );
      setIsCandidateInfoEditable(!isCandidateInfoEditable);
      handleScoreResume();
    }
  };

  const onResumeFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(toggleIndividualEdit({ pageName: editModeKey.CandidateAcknInfo }));
    setIsCandidateInfoEditable(!isCandidateInfoEditable);
    setPageStatus({ ...pageStatus, msgLocation: "NewResumeUplaod" });
    if (e.currentTarget.files) {
      if (!isValidFileExtention(e.currentTarget.files[0].name)) {
        formRef?.current?.setFieldError("resume", "Invalid file extension");
      } else if (e.currentTarget.files[0].size > 2000000) {
        console.log("error fiel", e.currentTarget.files[0].size);
        formRef?.current?.setFieldError("resume", "File size too large");
      } else {
        handleUploadClick(e.currentTarget.files || null, "Resume");
        formRef?.current?.setFieldValue(
          "resume",
          ternary(
            Boolean(e.currentTarget.files),
            e.currentTarget.files[0].name,
            ""
          )
        );
        setScoreCardPdf({ pdfFile: undefined, isGenerating: false });
        if(isScorecardFeatureActive){
          setscoreResume(true);
        }
        
      }
    }
  };

  const handleResumeSubmit = async (values: typeof initialState) => {
    setIsFormSubmitting(true);

    let scoreCardDoc = submissionData?.otherDocInfo?.filter(
      (doc: any) => doc.documentType === "ResumeScoreCard"
    );
    let model = {
      recruiterGuid: recruiterDetails.id,
      recruiterId: recruiterDetails.recruiterId,
      recruiterEmail: recruiterDetails.email,
      recruiterFirstName: recruiterDetails.firstName,
      recruiterMiddleName: recruiterDetails.middleName,
      recruiterLastName: recruiterDetails.lastName,
      accountManagerDetails: {
        recruiterEmail: jobDetails?.recruiterEmail,
        recruiterName: jobDetails?.recruiterName,
        recruiterWorkPhoneNo: jobDetails?.recruiterWorkPhoneNo,
        salesPersonEmail: jobDetails?.salesPersonEmail,
        salesPersonWorkPhoneNo: jobDetails?.salesPersonWorkPhoneNo,
        salesPersonName: jobDetails?.salesPersonName,
      },
      jobId: jobDetails.id,
      jobTitle: jobDetails.jobTitle,
      jobClientName: jobDetails.clientName,
      jobEndClientName: jobDetails.endClientName,
      jobCity: jobDetails.city,
      jobStateCode: jobDetails.stateCode,
      jobStateName: jobDetails.stateName,
      externalSourceJOBID: jobDetails.jobId.toString(),
      email: values.email,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      address: {
        id: values?.address?.id,
        streetAddress: values?.address?.streetAddress,
        city: values?.address?.city,
        state: values?.address?.state,
        postalCode: values?.address?.postalCode,
        countryCode: values?.address?.countryCode,
      },
      phone: values.phoneNumbers.primary,
      experience: values.experience,
      workAuthorizationStatus: values?.workAuthorizationStatus,
      isRemoteUSA: values?.isRemoteUSA,

      employerName: submissionData?.employerName,
      employerDetails: {
        name: values?.employerDetails?.name,
        email: values?.employerDetails?.email,
        phoneNumber: values?.employerDetails?.phoneNumber,
        reference: values?.employerDetails?.reference,
        address: {
          id: values?.employerDetails?.address?.city,
          streetAddress: values?.employerDetails?.address?.streetAddress,
          city: values?.employerDetails?.address?.city,
          postalCode: values?.employerDetails?.address?.postalCode,
          countryCode: values?.employerDetails?.address?.countryCode,
          state: values?.employerDetails?.address?.state,
        },
      },
      currentWorkLocation: {
        id: values?.currentWorkLocation?.id,
        city: values?.currentWorkLocation?.city,
        state: values?.currentWorkLocation?.state,
      },
      canRelocate: values.canRelocate,
      skillsMatch: values?.skills,
      payRate: values.payRate,
      payRateType: values.payrateType,
      packageCurrency: jobDetails.package.packageCurrency,
      employmentType: submissionData?.employmentType,
      recruiterComments: submissionData?.recruiterComments?.comment,
      resumeInfo: {
        resourceUrl: submissionData?.resumeInfo?.resourceUrl,
        fileName: submissionData?.resumeInfo?.fileName,
        newFileName: submissionData?.resumeInfo?.newFileName,
      },
      otherDocInfo: values.otherDocInfo,
      iConfirm: true,
      redirectUrl: window.location.origin + "/CandidateAcknowledge",
      candidateId: submissionData?.candidateId,
      candidateGuid: submissionData?.candidateGuid,
      id: submissionData?.id,
      //required field needs to be added
      recruiterFullName:
        submissionData?.recruiterFirstName +
        " " +
        submissionData?.recruiterMiddleName +
        " " +
        submissionData?.recruiterLastName,
      contractType: jobDetails.jobType,
      jobEndDate: jobDetails.jobEndDate,
      jobPostedDate: jobDetails.jobStartDate,
      remoteJob: jobDetails?.remoteJob,
      isUpdateCase: true,
      modifiedBy: submissionData?.candidateId,
      isUpdatedByCandidate: true,
      isPhoneVerified: values?.phoneNumbers?.isPrimaryVerified,
      jobProviderReferenceId: jobDetails.referenceNumber,
      jobProviderName: jobDetails.jobOrigin,
      jobOriginId: jobDetails.jobOriginId,
      ...(jobDetails.jobOriginId === JobOriginIds.jobRialto && {
        clientId: valueOrDefault(jobDetails?.clientId, ""),
        clientEmail: valueOrDefault(
          jobDetails.companyInformation?.companyEmail,
          ""
        ),
        clientPhone: valueOrDefault(
          jobDetails.companyInformation?.clientPhone,
          ""
        ),
      }),
    };

    if (values.comment !== "") {
      let objToPush = {
        comment: {
          id: "",
          comment: values.comment,
          commentHeading: "Candidate Comment",
          createdDate: "",
          userRole: UserAuthByRoleName.Candidate,
          addedBy: submissionData?.candidateId,
        },
      };
      model = { ...model, ...objToPush };
    }

    if (scoreCardDoc?.length > 0) {
      model.otherDocInfo.push({
        resourceUrl: scoreCardDoc[0].resourceUrl,
        fileName: scoreCardDoc[0].fileName,
        newFileName: scoreCardDoc[0].newFileName,
        documentType: scoreCardDoc[0].documentType,
      });
    }

    const resumeUploadModel: UploadResourceModel = {
      documentType: DocumentTypeEnum.Resume,
      file: resumeFile?.file as File,
    };

    if (isNewResumeFile) {
      UploadAttachment(resumeUploadModel)
        .then(async (response) => {
          await handleResumeResponse(response, model);
        })
        .catch(() => {
          console.log("Unexpected error, try again");
          setIsFormSubmitting(false);
        });
    } else {
      submitResume(model);
    }
  };

  const handleResumeResponse = async (
    response: AxiosResponse<any, any>,
    model: any
  ) => {
    model.resumeInfo.fileName = response.data.entity.fileName;
    model.resumeInfo.resourceUrl = response.data.entity.absoluteUri;
    model.resumeInfo.newFileName = response.data.entity.newFileName;

    if (
      glasssquidScoreStatus?.statusCode &&
      glasssquidScoreStatus?.statusCode === 200 &&
      glasssquidScore
    ) {
      let scorePdf = await getScoreCardPdfFile(
        {
          data: glasssquidScore.messageList[0],
          status: "",
          jobId: jobDetails?.id,
          jobTitle: jobDetails?.jobTitle,
        },
        valueOrDefault(queryParam?.authToken, "")
      );

      UploadAttachment({
        documentType: DocumentTypeEnum.Resume,
        file: scorePdf,
      })
        .then(async (pdfRes) => {
          model.otherDocInfo.push({
            resourceUrl: pdfRes.data.entity.absoluteUri,
            fileName: pdfRes.data.entity.fileName,
            newFileName: pdfRes.data.entity.newFileName,
            documentType: "ResumeScoreCard",
          });

          if (response.data.entity.fileName) {
            await submitResume(model);
          }
        })
        .catch(() => {
          console.log("Unexpected error, try again");
        });
    } else {
      await submitResume({ ...model, IsScoreCardPending: true });
    }
  };

  const submitResume = async (model: any) => {
    setPageStatus({
      isSuccess: false,
      isError: false,
      error: "",
      loading: false,
      msgLocation: "",
    });

    UpdateResumeByRecruiter(model)
      .then(async (response) => {
        if (response.status === 200) {
          if (response?.data?.message?.appStatusCode === "RC_RES_UPD") {
            let responseData = response.data.entity;
            let jobDetailPdf = await getSubmissionChangeLogPdfFile(
              {
                currentDetails: existingSubmission,
                modifiedDetails: responseData,
              },
              queryParam?.authToken ?? ""
            );
            let formData = new FormData();
            formData.append("recruiterEmail", responseData?.recruiterEmail);
            formData.append("candidateEmail", responseData?.email);
            formData.append(
              "recruiterFirstName",
              responseData.recruiterFirstName
            );
            formData.append(
              "recruiterLastName",
              responseData.recruiterLastName
            );
            formData.append("candidateFirstName", responseData?.firstName);
            formData.append("candidateLastName", responseData.lastName);
            formData.append("jobTitle", responseData.jobTitle);
            formData.append("jobId", responseData.jobId);
            formData.append("submissionDetailFile", jobDetailPdf);
            formData.append("role", "Candidate");
            formData.append(
              "recruiterPhoneNumber",
              responseData.recruiterPhoneNumbers?.primary
            );
            formData.append("candidatePhoneNumber", responseData.phone);
            SendEmailForUpdateSubmission(formData).finally(() => {
              setCandidateCommentList(
                response?.data?.entity?.candidateComments
              );
              formik.setFieldValue("comment", "");
              setCommentsList(response?.data?.entity?.comments);
              setPageStatus({
                isSuccess: true,
                isError: false,
                error: response.data.message.appStatusDescription,
                loading: false,
                msgLocation: updateMode,
              });
              dispatch(
                toggleIndividualEdit({
                  pageName: editModeKey.CandidateAcknInfo,
                })
              );
              setIsCandidateInfoEditable(!isCandidateInfoEditable);
              setIsFormSubmitting(false);
            });
          } else if (response?.data?.message?.appStatusCode === "RC_MdER") {
            setPageStatus({
              isSuccess: false,
              isError: true,
              error: response?.data?.message?.appStatusDescription + "RC_MdER",
              loading: false,
              msgLocation: updateMode,
            });
            setIsFormSubmitting(false);
          } else if (response?.data?.message?.appStatusCode === "RC_SUB_EXTS") {
            setPageStatus({
              isSuccess: false,
              isError: true,
              error: response?.data?.message?.appStatusDescription,
              loading: false,
              msgLocation: updateMode,
            });
          } else {
            setPageStatus({
              isSuccess: false,
              isError: true,
              error: response?.data?.message?.appStatusDescription,
              loading: false,
              msgLocation: updateMode,
            });
            setIsFormSubmitting(false);
          }
        } else {
          setPageStatus({
            isSuccess: false,
            isError: true,
            error: response?.data?.message?.appStatusDescription,
            loading: false,
            msgLocation: updateMode,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  function renderScoreCardPdf() {
    return (
      <>
        {scoreCardPdf !== undefined ? (
          <>
            <Tooltip
              title={
                glasssquidScoreStatus?.statusCode !== 200 && !isScorecardLoading
                  ? "We apologize for the inconvenience. The score card generation service is currently unavailable, but rest assured that your score card will be generated once the system is back online. Thank you for your understanding."
                  : "Score card"
              }
            >
              <Typography
                variant="subtitle1"
                component="div"
                style={{ display: "inline-flex", alignItems: "flex-end" }}
              >
                <div
                  className={ternary(
                    scoreCardPdf.isGenerating,
                    "disabled-div",
                    "pointer"
                  )}
                  style={{ paddingLeft: "30px" }}
                >
                  <span style={{ paddingRight: 5, verticalAlign: "middle" }} className="fileNameTxt">
                    Score-card.pdf
                  </span>
                  <VisibilityOutlinedIcon
                    onClick={() => {
                      if (scoreCardPdf?.pdfFile) {
                        setFilePreview({
                          resourceUrl: window.URL.createObjectURL(
                            scoreCardPdf?.pdfFile
                          ),
                          fileName: "Score-card.pdf",
                          fileType: "application/pdf",
                        });
                      }
                    }}
                    color="primary"
                    className="pointer custom-dwnload-prview-icon"
                    sx={{
                      pl: 1,
                      pr: 1,
                      verticalAlign: "bottom",
                      paddingBottom: "4px",
                    }}
                  />
                  <SaveAltOutlinedIcon
                    onClick={downloadScoreCard}
                    color="primary"
                    sx={{
                      verticalAlign: "middle",
                      pr: 1,
                      paddingBottom: "4px",
                    }}
                    className="custom-dwnload-prview-icon"
                  />
                  {scoreCardPdf.isGenerating &&
                    !glasssquidScoreStatus?.statusCode && (
                      <CircularProgress size={"16px"} color={"inherit"} />
                    )}
                  {glasssquidScoreStatus?.statusCode &&
                  glasssquidScoreStatus?.statusCode !== 200 ? (
                    <WarningAmberIcon
                      style={{ color: "#FFCC00" }}
                      sx={{
                        verticalAlign: "bottom",
                        pr: "5px",
                        fontSize: "20px",
                        pb: "6px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Typography>
            </Tooltip>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderGlassSquidScores() {
    
  let skills_keywords_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "skills_keywords"
  );

  let technology_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "technology"
  );

  let education_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "education"
  );

  let certification_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "certification"
  );

  let jobTitle_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "jobTitle"
  );

  let tools_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "tools"
  );

  let domain_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "domain"
  );

  let industry_score =  glasssquidScore?.messageList[0]?.unweightedMatchedScores?.find(
    (n: any) => n.categoryName === "industry"
  );
    return (
      <>
        {glasssquidScoreStatus?.statusCode === 200 ? (
          <>
            <Grid container spacing={4} mt={0}>              
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      skills_keywords_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Skills</Typography>
              </Grid>
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      jobTitle_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Title</Typography>
              </Grid>
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      technology_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Technology</Typography>
              </Grid>
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      tools_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Tools</Typography>
              </Grid>
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      education_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Education</Typography>
              </Grid>
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      domain_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Domain</Typography>
              </Grid>

              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      industry_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Industry</Typography>
              </Grid>
              <Grid item style={{ textAlignLast: "center" }}>
                <Typography pt={"5px"}>
                  <CircularProgressWithLabel
                    value={
                      certification_score?.unWeightedMatchedScore ?? 0
                    }
                  />
                </Typography>
                <Typography>Certification</Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  function renderCanRelocate() {
    
    return (
      <>
        {!isCandidateInfoEditable ? (
          <>
            <Box sx={{ ...labelFont, marginTop: "4px", color: "#00000099" }}>
              {ternary(formik.values?.canRelocate, "Yes", "No")}
            </Box>
          </>
        ) : (
          <>
            <FormControl variant="standard" required>
              <FormControlLabel
                control={
                  <Checkbox
                    name="canRelocate"
                    checked={ternary(formik.values?.canRelocate, true, false)}
                    onChange={formik.handleChange}
                  />
                }
                label=""
                className="relocate-ackn"
              />
            </FormControl>
          </>
        )}
      </>
    );
  }

  function renderSkillsMatch() {
    return (
      <>
        {jobDetails?.manualData?.skills.length > 0 ? (
          jobDetails?.manualData?.skills.map((item: any, i: any) => (
            <>
              <FormControlLabel
                key={item?.jobId ?? i}
                control={
                  <Checkbox
                    name={"skills"}
                    checked={formik.values?.skills?.some(
                      (x) => x === item.skill
                    )}
                    value={item.skill}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!isCandidateInfoEditable}
                  />
                }
                label={item.skill}
                className="checkbox-sml"
              />
            </>
          ))
        ) : (
          <>N/A</>
        )}
      </>
    );
  }

  function renderOtherDocInfo() {
    return (
      <>
        {!isCandidateInfoEditable ? (
          <>
            <Box
              className="main-wrapper-uploader ackn-otherDocBox-view"
              sx={{ width: "max-content" }}
            >
              {formik?.values?.otherDocInfo?.filter(
                (x: any) => x.documentType === DocumentType.OtherDoc
              ).length > 0
                ? formik?.values?.otherDocInfo
                    ?.filter(
                      (x: any) => x.documentType === DocumentType.OtherDoc
                    )
                    ?.map((otherDoc) => {
                      return (
                        <Box
                          key={otherDoc.fileName}
                          className="upload-wrapper-text success-upload"
                        >
                          <Typography
                            variant="subtitle1"
                            component="div"
                            className="title-text"
                          >
                            {otherDoc.fileName}
                          </Typography>

                          {otherDoc.resourceUrl !== undefined &&
                            otherDoc.resourceUrl !== "" && (
                              <>
                                {otherDoc.fileName
                                  .split(".")[1]
                                  ?.toLowerCase() !== "zip" && (
                                  <VisibilityOutlinedIcon
                                    onClick={() => {
                                      getFilePreviewUrl(
                                        otherDoc.resourceUrl,
                                        valueOrDefault(
                                          queryParam?.authToken,
                                          ""
                                        )
                                      ).then((filePreviewUrl) =>
                                        setFilePreview({
                                          resourceUrl: filePreviewUrl,
                                          fileName: otherDoc.fileName,
                                        })
                                      );
                                    }}
                                    color="primary"
                                    className="pointer"
                                    sx={{ pl: 2, verticalAlign: "bottom" }}
                                  />
                                )}

                                <SaveAltOutlinedIcon
                                  data-testid="downloadFile"
                                  onClick={() => {
                                    downloadFile(
                                      otherDoc.resourceUrl,
                                      otherDoc.fileName,
                                      valueOrDefault(queryParam?.authToken, "")
                                    );
                                  }}
                                  color="primary"
                                  className="pointer"
                                  sx={{ pl: 1, verticalAlign: "bottom" }}
                                />
                              </>
                            )}
                        </Box>
                      );
                    })
                : "N/A"}
            </Box>
          </>
        ) : (
          <Grid item md={12} className="acknRenderOtherDocWrapper">
            {renderOtherDocUpload()}
          </Grid>
        )}
      </>
    );
  }

  function renderOtherDocUpload() {
    return (
      <>
        {
          <UploadAttachments
            onChange={handleUploadAttachmentsOnChange}
            setFilePreview={setFilePreview}
            documents={defaultOtherDoc}
            handleLoading={(val: any) => {
              if (defaultOtherDoc?.length > 0) {
                setIsLoading(val);
              }
            }}
            classNames="ackn-file-uploader"
            acceptedFormats={
              ".txt, .rtf, .doc, .docx, .pdf, .zip, .png, .jpg, .jpeg, .pptx"
            }
            isHideLabel={true}
            authToken={valueOrDefault(queryParam?.authToken, "")}
          />
        }
      </>
    );
  }

  function renderPhoneGrid() {
    return (
      <>
        <Item className="phn">
          <FormControl variant="standard">
            <InputLabel htmlFor="phone-input" shrink={true} required>
              Phone
            </InputLabel>
            <PhoneInput
              inputProps={{ "data-testid": "primaryPhone" }}
              containerClass={`phoneCountryCustom custPhoneInput ${ternary(
                !isCandidateInfoEditable,
                "viewMode",
                ""
              )}`}
              placeholder="Phone"
              onChange={(ev) => {
                formik.setFieldValue("phoneNumbers.primary", ev);
                onPrimaryPhChange(ev);
              }}
              value={formik.values?.phone}
              onlyCountries={rawCountries.map((x) => x[2].toString())}
              preserveOrder={["preferredCountries"]}
              disabled={!isCandidateInfoEditable}
            />
          </FormControl>
          {ternary(
            displayPhoneVerifiedIcon(),
            <img
              className="marginTBL200510 verifyRightStickImg"
              src={verifiedIcon}
            ></img>,
            null
          )}
          { isCandidateInfoEditable && ternary(
            displayPhoneVerifyComponent(),
            <PhoneVerify {...props_Phone1Verify}></PhoneVerify>,
            null
          )}
        </Item>

        <p className="ackn-phone-error">
          {formik.touched.phoneNumbers?.primary && (
            <ErrorMessage errorText={formik.errors.phoneNumbers?.primary} />
          )}
          {!formik.errors.phoneNumbers?.primary &&
            formik.touched.phoneNumbers?.isPrimaryVerified &&
            !formik.values.phoneNumbers?.isPrimaryVerified && (
              <ErrorMessage
                errorText={formik.errors.phoneNumbers?.isPrimaryVerified}
              />
            )}
        </p>
      </>
    );
  }

  function renderCommentsList() {
    return (
      <>
        {commentsList?.filter(
          (x: any) => x?.userRole === UserAuthByRoleName.Candidate
        )?.length > 0 || isCandidateInfoEditable ? (
          <>
            <Grid container spacing={2}>
              <Grid item md={10} mt={2}>
                <InputLabel>Comments/ Feedback</InputLabel>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        <Grid container>
          <Grid item xs={12} md={12}>
            {commentsList
              ?.filter((x: any) => x?.userRole === UserAuthByRoleName.Candidate)
              ?.map((item: any, index: number) => {
                return (
                  <Typography
                    key={item?.createdDate ?? index}
                    mt={1}
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginBottom: "12px",
                    }}
                  >
                    <Typography style={{ color: "black" }}>
                      {item?.userRole === UserAuthByRoleName.Recruiter
                        ? recruiterDetails?.firstName +
                          " " +
                          recruiterDetails?.lastName
                        : "Your comment"}
                      <span className="rec-comment-date">
                        {" "}
                        Posted on (
                        {moment(item?.createdDate)
                          ?.format(" MMMM DD, YYYY, h:mm A")
                          ?.trim()}
                        )
                      </span>
                    </Typography>
                    <Typography className="letter-spacing-normal primary-color-text"></Typography>
                    {item?.comment}
                  </Typography>
                );
              })}
          </Grid>
        </Grid>
      </>
    );
  }


  function getColor(isCandidateInfoEditable:boolean) {
    return !isCandidateInfoEditable ? "#00000099" : "inherit";
  }

  return (
    <>
      <div>
        <AppBar
          className="main-header"
          style={{ background: "#fff", color: "#000" }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <Link to={"/"}>
                  <img src={logo} className="logo" alt="logo" />
                </Link>
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="span"
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              >
                <Link to={"/"}>
                  <img src={logo} className="logo" alt="logo" />
                </Link>
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>

        <Formik
          initialValues={initialState}
          validationSchema={ValidationSchemaCandidateAcknowledgement}
          validate={validateFormik}
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
          innerRef={formRef}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Container
                maxWidth="xl"
                className="jobs-page"
                sx={{
                  marginTop: "70px",
                  padding: 1,
                }}
              >
                <div style={{ display: ternary(scoreResume, "none", "block") }}>
                  {isloading ? (
                    ""
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        justifyContent: "space-around",
                        paddingTop: "20px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          marginBottom: "10px",
                          marginTop: "0px",
                          paddingTop: "0px",
                        }}
                      >
                        <Item
                          elevation={0}
                          sx={{
                            paddingTop: "20px",
                          }}
                        >
                          {submissionResponse?.message?.appStatusCode ===
                          "RC_EXP" ? (
                            <Alert severity="error">
                              {
                                submissionResponse?.message
                                  ?.appStatusDescription
                              }
                            </Alert>
                          ) : (
                            <Grid
                              container
                              spacing={2}
                              sx={{ justifyContent: "left" }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                style={{ paddingTop: "0px" }}
                                mb={2}
                              >
                                <RecruiterInformation
                                  Token={queryParam?.authToken ?? ""}
                                  jobDetails={jobDetails}
                                  recruiterDetails={recruiterDetails}
                                  onAuthorize={Authorize}
                                  onDecline={Decline}
                                  actionTaken={isActionTaken}
                                  setIsActionTaken={setIsActionTaken}
                                  isCandidateInfoEditable={
                                    isCandidateInfoEditable
                                  }
                                  dataLoadComplete={dataLoadComplete}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={9}
                                style={{ paddingTop: "0px" }}
                              >
                                <Card sx={{ padding: "10px" }}>
                                  <Grid
                                    container
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Grid item>
                                      <SectionHeading
                                        title="Job Details"
                                        classes="primaryColorText textCenter fontsize24"
                                        level="h2"
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Grid container mt={1}>
                                      <Grid item>
                                          <SquareIcon
                                            style={{
                                              color: getJobStatusColor(
                                                jobDetails?.jobStatus as JobStatus
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        <Grid item sx={{ padding: "2px" }}>
                                          Job Status: {jobDetails?.jobStatus}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Divider
                                      sx={{
                                        borderColor: "#4540DB",
                                        borderBottomWidth: "unset",
                                        width: "100%",
                                      }}
                                    />
                                  </Grid>
                                  <Grid container>
                                    <Grid container>
                                      <Grid item md={12} mt={2}>
                                        <Typography style={{ color: "black" }}>
                                          <JobTitle
                                            title={jobDetails?.jobTitle}
                                            isFeatured={jobDetails?.isFeatured}
                                            jobId={jobDetails?.id}
                                          />
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container justifyContent="">
                                      <Grid item xs={12} md={12} mt={2}>
                                        <Stack
                                          direction={{
                                            xs: "column",
                                            sm: "row",
                                          }}
                                          spacing={{ xs: 3, md: 6 }}
                                          className="activity-chips"
                                        >
                                          <Chip
                                            icon={<MapMarkerIcon />}
                                            label={jobLocation(
                                              jobDetails?.remoteJob,
                                              jobDetails?.city,
                                              jobDetails?.stateName
                                            )}
                                            variant="outlined"
                                          />
                                          <Chip
                                            icon={<EyeIcon />}
                                            label={
                                              jobDetails?.jobExperience +
                                              " Years"
                                            }
                                            variant="outlined"
                                          />
                                          <Chip
                                            icon={<ClockIcon />}
                                            label={ternary(
                                              jobDetails?.jobType ===
                                                "Contract",
                                              `Contract (${jobDetails?.jobDuraion} Months)`,
                                              jobDetails?.jobType
                                            )}
                                            variant="outlined"
                                          />
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container mt={2}>
                                    <Grid md={12} item>
                                      <Typography>
                                        {jobDetails?.jobShortDesc}
                                      </Typography>
                                    </Grid>
                                    <Grid item mt={2}>
                                      <Typography sx={{ color: "blue" }}>
                                        <Link
                                          to={`/jobPosting/${queryParam.jobId}`}
                                          target={"_blank"}
                                          style={{ textDecoration: "none" }}
                                        >
                                          {" "}
                                          View Job Posting
                                        </Link>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Card>
                                <br />
                                <Card sx={{ padding: "10px" }}>
                                  <Grid
                                    container
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Grid item md={10}>
                                      <SectionHeading
                                        title="Resume"
                                        classes="primaryColorText fontsize24"
                                        level="h2"
                                      />
                                    </Grid>
                                  </Grid>
                                  <Divider
                                    sx={{
                                      borderColor: "#4540DB",
                                      borderBottomWidth: "unset",
                                      width: "100%",
                                    }}
                                  />

                                  <Grid container pt={0.5}>
                                    <Grid item xs={12} sm={7} md={6}>
                                      <FormControl
                                        fullWidth
                                        variant="standard"
                                        required
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={11}
                                            md={11}
                                            className="resume-gridItem"
                                          >
                                            <span className="sucessIcon-resume">
                                              <CheckCircleIcon
                                                color="success"
                                                className="successDefaultIcon"
                                                sx={{
                                                  marginRight: 0.5,
                                                  verticalAlign: "text-top",
                                                }}
                                              />
                                            </span>
                                            <span className="sucessIcon-resume res-textname">
                                              <Tooltip
                                                title={valueOrDefault(
                                                  resumeFile?.fileName,
                                                  ""
                                                )}
                                                placement="bottom-end"
                                              >
                                                <span className="rec-email-text-super">
                                                  {" "}
                                                  {truncateString(
                                                    valueOrDefault(
                                                      resumeFile?.fileName,
                                                      ""
                                                    ),
                                                    30
                                                  )}
                                                </span>
                                              </Tooltip>

                                              {submissionData &&
                                                !isNewResumeFile && (
                                                  <>
                                                    <VisibilityOutlinedIcon
                                                      onClick={() => {
                                                        getFilePreviewUrl(
                                                          submissionData
                                                            ?.resumeInfo
                                                            .resourceUrl,
                                                          valueOrDefault(
                                                            queryParam?.authToken,
                                                            ""
                                                          )
                                                        ).then(
                                                          (filePreviewUrl) =>
                                                            setFilePreview({
                                                              resourceUrl:
                                                                filePreviewUrl,
                                                              fileName:
                                                                submissionData
                                                                  ?.resumeInfo
                                                                  .fileName,
                                                            })
                                                        );
                                                      }}
                                                      color="primary"
                                                      className="pointer custom-dwnload-prview-icon"
                                                      sx={{
                                                        pl: 1,
                                                        verticalAlign: "bottom",
                                                        paddingBottom: "5px",
                                                      }}
                                                      data-testid="view-resume"
                                                    />
                                                    <SaveAltOutlinedIcon
                                                      onClick={() => {
                                                        downloadFile(
                                                          submissionData
                                                            ?.resumeInfo
                                                            .resourceUrl,
                                                          submissionData
                                                            ?.resumeInfo
                                                            .fileName,
                                                          valueOrDefault(
                                                            queryParam?.authToken,
                                                            ""
                                                          )
                                                        );
                                                      }}
                                                      color="primary"
                                                      className="pointer custom-dwnload-prview-icon"
                                                      sx={{
                                                        pl: 1,
                                                        verticalAlign: "bottom",
                                                        marginBottom: "3px",
                                                        paddingBottom: "4px",
                                                      }}
                                                      data-testid="download-resume"
                                                    />
                                                  </>
                                                )}
                                            </span>

                                            { isScorecardFeatureActive && renderScoreCardPdf()}
                                          </Grid>
                                        </Grid>
                                      </FormControl>
                                    </Grid>
                                    {
                                      isScorecardFeatureActive && 
                                    
                                      <Grid item md={1} style={{ textAlignLast: "center" }}>
                                      <Typography pt={"5px"}>
                                        <CircularProgressWithLabel
                                          value={
                                            glasssquidScore?.messageList[0]?.eximScore ?? 0
                                          }
                                        />
                                      </Typography>
                                      <Typography>Overall</Typography>
                                    </Grid>
                                    }
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      sx={{ textAlign: "right" }}
                                    >
                                      <Grid container pt={1}>

                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          sx={{ textAlign: "right" }}
                                        >
                                          <Button
                                            variant="contained"
                                            component="label"
                                            disabled={isFormSubmitting || isActionTaken?.flag}
                                            style={{ minWidth: "160px" }}
                                            endIcon={ternary(
                                              isFormSubmitting,
                                              <CircularProgress
                                                size={"16px"}
                                                color={"inherit"}
                                              />,
                                              <></>
                                            )}
                                          >
                                            <FileUploadIcon /> Attach
                                            Resume
                                            <input
                                              type="file"
                                              hidden
                                              accept={
                                                ".txt,.rtf,.doc,.docx,.pdf"
                                              }
                                              onChange={(e: any) => {
                                                onResumeFileChange(e);
                                              }}
                                              onClick={(e) => {
                                                e.currentTarget.value = "";
                                              }}
                                              data-testid="attach-resume"
                                            />
                                          </Button>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                          <Box style={{ margin: "0 0 0 px" }}>
                                            {pageStatus.isSuccess === true &&
                                              pageStatus.msgLocation ===
                                                "NewResumeUplaod" && (
                                                <div
                                                  style={{
                                                    margin: "12px 0 0 0px",
                                                  }}
                                                >
                                                  <CustomAlert
                                                    message={pageStatus.error}
                                                    type="success"
                                                    onClose={() => {}}
                                                  />
                                                </div>
                                              )}

                                            {pageStatus.isError === true &&
                                              pageStatus.msgLocation ===
                                                "NewResumeUplaod" && (
                                                <div
                                                  style={{
                                                    margin: "12px 0 0 0px",
                                                  }}
                                                >
                                                  <CustomAlert
                                                    message={pageStatus.error}
                                                    type="error"
                                                    onClose={() => {}}
                                                  />
                                                </div>
                                              )}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                      {renderGlassSquidScores()}
                                    </Grid>
                                  </Grid>
                                </Card>
                                <Card
                                  sx={{ padding: "10px", marginTop: "20px" }}
                                >
                                  <Grid
                                    container
                                    className="cand-container"
                                    justifyContent={"space-between"}
                                  >
                                    <Grid item md={10}>
                                      <SectionHeading
                                        title="Candidate Information"
                                        classes="primaryColorText fontsize24"
                                        level="h2"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={2}
                                      sx={{ textAlign: "right" }}
                                    >
                                      {isActionTaken.flag === false &&
                                        !isCandidateInfoEditable && (
                                          <span className="edit-icon">
                                            <IconButton
                                              data-testid="editButtonIcon"
                                              className={`iconEditbtn ${ternary(
                                                isCandidateInfoEditable,
                                                "edit-icon-btn",
                                                ""
                                              )}`}
                                              aria-label="Edit"
                                              onClick={() => {
                                                setIsCandidateInfoEditable(
                                                  !isCandidateInfoEditable
                                                );
                                                dispatch(
                                                  toggleIndividualEdit({
                                                    pageName:
                                                      editModeKey.CandidateAcknInfo,
                                                  })
                                                );
                                              }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </span>
                                        )}
                                    </Grid>
                                    <Divider
                                      sx={{
                                        borderColor: "#4540DB",
                                        borderBottomWidth: "unset",
                                        width: "100%",
                                      }}
                                    />
                                  </Grid>
                                  <Box
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                    sx={{
                                      flexDirection: "row",
                                      "& .MuiFormControl-root": {
                                        marginTop: "15px",
                                        marginBottom: "3px",
                                      },
                                    }}
                                  >
                                    <Grid
                                      className="form-three-child column-ftc"
                                      container
                                      spacing={2}
                                      xs={12}
                                      md={12}
                                    >
                                      <Grid item xs={12} sm={4} md={3}>
                                        <TextField
                                          required
                                          label="First Name"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik?.values?.firstName}
                                          name="firstName"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.firstName &&
                                            Boolean(formik.errors.firstName)
                                          }
                                          helperText={
                                            formik.touched.firstName &&
                                            formik.errors.firstName
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={3}>
                                        <TextField
                                          label="Middle Initial"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik?.values?.middleName}
                                          name="middleName"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.middleName &&
                                            Boolean(formik.errors.middleName)
                                          }
                                          helperText={
                                            formik.touched.middleName &&
                                            formik.errors.middleName
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={3}>
                                        <TextField
                                          required
                                          label="Last Name"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik?.values?.lastName}
                                          name="lastName"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.lastName &&
                                            Boolean(formik.errors.lastName)
                                          }
                                          helperText={
                                            formik.touched.lastName &&
                                            formik.errors.lastName
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      className="form-three-child column-ftc"
                                      container
                                      spacing={1}
                                      xs={12}
                                      md={12}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        md={4}
                                        className="ackn-email-fieldControl"
                                      >
                                        <TextField
                                          required
                                          label="Your Email"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik?.values?.email}
                                          name="lastName"
                                          type="text"
                                          disabled={true}
                                          error={
                                            formik.touched.email &&
                                            Boolean(formik.errors.email)
                                          }
                                          helperText={
                                            formik.touched.email &&
                                            formik.errors.email
                                          }
                                          InputProps={{
                                            endAdornment: (
                                              <IconButton className="VerifyIconButton">
                                                <img
                                                  className="verifyRightStickImg"
                                                  src={verifiedIcon}
                                                />
                                              </IconButton>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={8}
                                        md={5}
                                        data-testid="phone-input"
                                      >
                                        {renderPhoneGrid()}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={12} md={10}>
                                        <PlacesAutocomplete
                                          searchOptions={searchOptions}
                                          value={
                                            formik.values?.address
                                              ?.streetAddress
                                          }
                                          onChange={(ev: any) => {
                                            formik.setFieldValue(
                                              "address.streetAddress",
                                              ev
                                            );
                                          }}
                                          onSelect={handleSelect}
                                          data-testid="place-autocomplete"
                                        >
                                          {renderFunc}
                                        </PlacesAutocomplete>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                          required
                                          label="City"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik?.values?.address?.city}
                                          name="address.city"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.address?.city &&
                                            Boolean(formik.errors.address?.city)
                                          }
                                          helperText={
                                            formik.touched.address?.city &&
                                            formik.errors.address?.city
                                          }
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                          required
                                          label="State"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik?.values?.address?.state}
                                          name="address.state"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.address?.state &&
                                            Boolean(
                                              formik.errors.address?.state
                                            )
                                          }
                                          helperText={
                                            formik.touched.address?.state &&
                                            formik.errors.address?.state
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                          required
                                          label="Country"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.address?.countryCode
                                          }
                                          name="address.countryCode"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.address
                                              ?.countryCode &&
                                            Boolean(
                                              formik.errors.address?.countryCode
                                            )
                                          }
                                          helperText={
                                            formik.touched.address
                                              ?.countryCode &&
                                            formik.errors.address?.countryCode
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                          required
                                          label="Zip"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.address?.postalCode
                                          }
                                          name="address.postalCode"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.address
                                              ?.postalCode &&
                                            Boolean(
                                              formik.errors.address?.postalCode
                                            )
                                          }
                                          helperText={
                                            formik.touched.address
                                              ?.postalCode &&
                                            formik.errors.address?.postalCode
                                          }
                                        />
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2} mt={0}>
                                      <Grid item xs={6} sm={4} md={4}>
                                        <InputLabel
                                          htmlFor="firstName"
                                          shrink={true}
                                          required
                                          error={
                                            formik.touched?.experience &&
                                            Boolean(formik.errors?.experience)
                                          }
                                        >
                                          Total Experience
                                        </InputLabel>
                                        <Input
                                          name="experience"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          onKeyDown={(evt) =>
                                            evt.key === "-" &&
                                            evt.preventDefault()
                                          }
                                          value={formik?.values?.experience}
                                          endAdornment={
                                            <InputAdornment position="start">
                                              Years
                                            </InputAdornment>
                                          }
                                          style={{ width: "60%" }}
                                          disabled={!isCandidateInfoEditable}
                                          data-testid="experience-change"
                                          error={
                                            formik.touched?.experience &&
                                            Boolean(formik.errors?.experience)
                                          }
                                        />
                                        <ErrorMessage
                                          errorText={
                                            formik.touched?.experience &&
                                            formik.errors?.experience
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={2}
                                        data-testid="test"
                                      >
                                        <InputLabel
                                          htmlFor="payrate"
                                          shrink={true}
                                        >
                                          Pay rate
                                        </InputLabel>
                                        <Box
                                          sx={{ ...labelFont }}
                                          className="akcn-payrate-val"
                                        >
                                          <Payrate
                                            highLightCurrency={false}
                                            packageValue={
                                              submissionData?.payRate
                                            }
                                            packageType={
                                              submissionData?.payRateType
                                            }
                                            currency={
                                              submissionData?.packageCurrency
                                            }
                                            fontSize="14px"
                                            color={getColor(isCandidateInfoEditable)}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={0}>
                                      <Grid item md={4} xs={6}>
                                        <InputLabel htmlFor="work-auth">
                                          Work Authorization Status{" "}
                                          <span className="required-asterisk">
                                            *
                                          </span>
                                        </InputLabel>
                                      </Grid>
                                      <Grid item md={2} xs={6}>
                                        <Box
                                          sx={{
                                            ...labelFont,
                                            marginLeft: "0px",
                                            color: ternary(
                                              !isCandidateInfoEditable,
                                              "#00000099",
                                              "inherit"
                                            ),
                                          }}
                                        >
                                          {
                                            submissionData?.workAuthorizationStatus
                                          }
                                          {ternary(
                                            submissionData?.isRemoteUSA,
                                            " (Remote USA)",
                                            ""
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item md={3}>
                                        <InputLabel htmlFor="candidate-emp">
                                          Candidate Employment
                                        </InputLabel>
                                      </Grid>
                                      <Grid item md={3}>
                                        <Box
                                          sx={{
                                            ...labelFont,
                                            marginTop: "0px",
                                            color: ternary(
                                              !isCandidateInfoEditable,
                                              "#00000099",
                                              "inherit"
                                            ),
                                          }}
                                        >
                                          {getEmploymentType(
                                            submissionData?.employmentType
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={0}>
                                      <Grid item xs={12} sm={10} md={10}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="Employer Name (if H1B, TN Visa and E3 Visa)"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.name
                                          }
                                          name="employerDetails.name"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.name &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.name
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.name &&
                                            formik.errors.employerDetails?.name
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2} mt={0}>
                                      {/* Employer address information row */}
                                      <Grid item xs={12} sm={6} md={6}>
                                        <PlacesAutocomplete
                                          searchOptions={searchOptions}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.address?.streetAddress
                                          }
                                          onChange={(ev: any) => {
                                            formik.setFieldValue(
                                              "employerDetails.address.streetAddress",
                                              ev
                                            );
                                          }}
                                          onSelect={handleSelect2}
                                          data-testid="autocomplete-address"
                                        >
                                          {renderFunc2}
                                        </PlacesAutocomplete>
                                      </Grid>
                                      <Grid item xs={12} sm={3} md={1.5}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="City"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.address?.city
                                          }
                                          name="employerDetails.address.city"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.address?.city &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.address?.city
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.address?.city &&
                                            formik.errors.employerDetails
                                              ?.address?.city
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3} md={1.5}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="State"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.address?.state
                                          }
                                          name="employerDetails.address.state"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.address?.state &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.address?.state
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.address?.state &&
                                            formik.errors.employerDetails
                                              ?.address?.state
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3} md={1.5}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="Country"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.address?.countryCode
                                          }
                                          name="employerDetails.address.countryCode"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.address?.countryCode &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.address?.countryCode
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.address?.countryCode &&
                                            formik.errors.employerDetails
                                              ?.address?.countryCode
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3} md={1.5}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="Zip"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.address?.postalCode
                                          }
                                          name="employerDetails.address.postalCode"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.address?.postalCode &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.address?.postalCode
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.address?.postalCode &&
                                            formik.errors.employerDetails
                                              ?.address?.postalCode
                                          }
                                        />
                                      </Grid>
                                      {/* Employer contact details information row */}
                                      <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="Employer Contact Person"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.reference
                                          }
                                          name="employerDetails.reference"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.reference &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.reference
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.reference &&
                                            formik.errors.employerDetails
                                              ?.reference
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          label="Employer Email"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.email
                                          }
                                          name="employerDetails.email"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.employerDetails
                                              ?.email &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.email
                                            )
                                          }
                                          helperText={
                                            formik.touched.employerDetails
                                              ?.email &&
                                            formik.errors.employerDetails?.email
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={3.9}>
                                        <InputLabel
                                          htmlFor="firstName"
                                          shrink={true}
                                          required={isEmployerNameRequired(
                                            formik.values
                                              .workAuthorizationStatus,
                                            formik.values.employmentType
                                          )}
                                          error={
                                            formik.touched.employerDetails
                                              ?.phoneNumber &&
                                            Boolean(
                                              formik.errors.employerDetails
                                                ?.phoneNumber
                                            )
                                          }
                                        >
                                          Employer Phone
                                        </InputLabel>

                                        <PhoneInput
                                          containerClass={`phoneCountryCustom custPhoneInput ${ternary(
                                            !isCandidateInfoEditable,
                                            "viewMode",
                                            ""
                                          )}`}
                                          placeholder="Phone"
                                          country={emp_countryShort}
                                          onChange={(ev: any) => {
                                            formik?.setFieldValue(
                                              "employerDetails.phoneNumber",
                                              ev
                                            );
                                          }}
                                          onBlur={(ev: any) => {
                                            formik?.setFieldValue(
                                              "employerDetails.phoneNumber",
                                              ev
                                            );
                                          }}
                                          onlyCountries={rawCountries.map((x) =>
                                            x[2].toString()
                                          )}
                                          value={
                                            formik?.values?.employerDetails
                                              ?.phoneNumber
                                          }
                                          preferredCountries={
                                            preferredCountries
                                          }
                                          preserveOrder={["preferredCountries"]}
                                          disabled={!isCandidateInfoEditable}
                                        />
                                        {
                                          <ErrorMessage
                                            errorText={
                                              formik.touched.employerDetails
                                                ?.phoneNumber &&
                                              formik.errors.employerDetails
                                                ?.phoneNumber
                                            }
                                          />
                                        }
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} mb={0}>
                                        <InputLabel>
                                          Current work location
                                        </InputLabel>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={2}>
                                        <PlacesAutocomplete
                                          searchOptions={searchCities}
                                          value={
                                            formik?.values?.currentWorkLocation
                                              ?.city
                                          }
                                          onChange={(ev: any) => {
                                            formik?.setFieldValue(
                                              "currentWorkLocation.city",
                                              ev
                                            );
                                          }}
                                          onSelect={autoCompleteGetWorkLocation}
                                        >
                                          {renderFuncCurrentWorkLocation}
                                        </PlacesAutocomplete>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                          required
                                          label="State"
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik?.values?.currentWorkLocation
                                              ?.state
                                          }
                                          name="currentWorkLocation.state"
                                          type="text"
                                          disabled={!isCandidateInfoEditable}
                                          error={
                                            formik.touched.currentWorkLocation
                                              ?.state &&
                                            Boolean(
                                              formik.errors.currentWorkLocation
                                                ?.state
                                            )
                                          }
                                          helperText={
                                            formik.touched.currentWorkLocation
                                              ?.state &&
                                            formik.errors.currentWorkLocation
                                              ?.state
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={3}>
                                        <InputLabel
                                          shrink={true}
                                          sx={{ marginTop: "4px" }}
                                        >
                                          Willing to relocate
                                        </InputLabel>
                                        {renderCanRelocate()}
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel shrink={true}>
                                          Skills Match
                                        </InputLabel>
                                        {renderSkillsMatch()}
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} lg={12} mt={1}>
                                        <InputLabel shrink={true}>
                                          Other Documents
                                        </InputLabel>
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                        {renderOtherDocInfo()}
                                      </Grid>
                                    </Grid>

                                    {renderCommentsList()}

                                    {candidateCommentsList?.length > 0 && (
                                      <Grid item xs={12} md={12}>
                                        <Box>
                                          <Box>
                                            <Typography className="letter-spacing-normal primary-color-text cand-comment-heading">
                                              Your Comments
                                            </Typography>
                                          </Box>
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              padding: "0px 10px 10px 10px",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {( !isCandidateInfoEditable && candidateCommentsList?.length > 0 )
                                              &&
                                              <Grid item xs={12} md={12}>
                                                <Box
                                                  style={{
                                                    backgroundColor: "white",
                                                  }}
                                                >
                                                  {candidateCommentsList?.map(
                                                    (row: any, index: any) => {
                                                      return (
                                                        <Box
                                                          key={
                                                            row?.createdDate ??
                                                            index
                                                          }
                                                          className="cand-commentRows"
                                                          style={{
                                                            marginLeft: "-18px",
                                                          }}
                                                        >
                                                          <Typography
                                                            className="ackn-candidate-feedback ackn-date-cand-comment"
                                                            color="text.secondary"
                                                          >
                                                            Posted on
                                                            {" " +
                                                              moment(
                                                                row?.createdDate
                                                              ).format(
                                                                "MMMM DD, YYYY,  h:mm:ss A"
                                                              )}
                                                          </Typography>
                                                          <Typography
                                                            style={{
                                                              marginTop: "5px",
                                                              paddingLeft:
                                                                "25Apx",
                                                            }}
                                                            variant="body2"
                                                            color="text.secondary"
                                                            className="ackn-candidate-feedback ackn-comment"
                                                          >
                                                            {row?.comment}
                                                          </Typography>
                                                        </Box>
                                                      );
                                                    }
                                                  )}
                                                </Box>
                                              </Grid>
                                             }
                                          </Box>
                                        </Box>
                                      </Grid>
                                    )}
                                    {isCandidateInfoEditable && (
                                      <Grid item xs={12} md={12}>
                                        <InputLabel shrink={true}>
                                          Write your comment & share your
                                          feedback with Recruiter
                                        </InputLabel>
                                        <TextField
                                          placeholder="Enter your comments/ feedback here"
                                          multiline
                                          minRows={1}
                                          maxRows={5}
                                          fullWidth
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          name="comment"
                                          value={formik.values.comment}
                                          disabled={!isCandidateInfoEditable}
                                          inputProps={{
                                            "data-testid": "commentBox",
                                          }}
                                          error={
                                            formik.touched?.comment &&
                                            Boolean(formik.errors?.comment)
                                          }
                                          helperText={
                                            formik.touched?.comment &&
                                            formik.errors?.comment
                                          }
                                        />

                                        {isCandidateInfoEditable && (
                                          <>
                                            <Box
                                              style={{
                                                float: "left",
                                                marginTop: "15px",
                                              }}
                                            >
                                              <Button
                                                type="button"
                                                onClick={(e: any) => {
                                                  formik.handleSubmit();
                                                  
                                                  setUpdateMode(
                                                    "CandidateInfo"
                                                  );
                                                }}
                                                variant="contained"
                                                disabled={
                                                  isFormSubmitting || isLoading
                                                }
                                                endIcon={ternary(
                                                  isFormSubmitting,
                                                  <CircularProgress
                                                    size={"16px"}
                                                    color={"inherit"}
                                                  />,
                                                  <>{""}</>
                                                )}
                                                data-testid="updateButton"
                                              >
                                                Update
                                              </Button>
                                              <Button
                                                disabled={
                                                  isFormSubmitting || isLoading
                                                }
                                                variant="outlined"
                                                style={{ marginLeft: 6 }}
                                                onClick={() => {
                                                  setIsCandidateInfoEditable(
                                                    !isCandidateInfoEditable
                                                  );
                                                  dispatch(
                                                    toggleIndividualEdit({
                                                      pageName:
                                                        editModeKey.CandidateAcknInfo,
                                                    })
                                                  );
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                            </Box>
                                          </>
                                        )}
                                      </Grid>
                                    )}
                                    <Grid item xs={7} md={7}>
                                      <Box style={{ margin: "0 0 0 10px" }}>
                                        <div style={{ margin: "12px 0 0 0px" }}>
                                          {pageStatus.isSuccess && (
                                            <SnackbarPopup
                                              message={pageStatus.error}
                                              open={pageStatus.isSuccess}
                                              onClose={() => {
                                                setPageStatus({
                                                  ...pageStatus,
                                                  isSuccess: false,
                                                });
                                              }}
                                            />
                                          )}
                                        </div>
                                        {pageStatus.isError === true &&
                                          pageStatus.msgLocation ===
                                            "CandidateInfo" && (
                                            <CustomAlert
                                              message={pageStatus.error}
                                              type="error"
                                              onClose={() => {
                                                setPageStatus({
                                                  ...pageStatus,
                                                  isError: false,
                                                });
                                              }}
                                            />
                                          )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Card>
                              </Grid>
                            </Grid>
                          )}
                        </Item>
                      </Grid>
                    </Grid>
                  )}
                </div>
                { (isScorecardFeatureActive && scoreResume && isNewResumeFile ) && (
                  <>
                    <ScorecardResume
                    resumeFile={resumeFile}
                    onResumeFileChange={() => {}}
                    handleOnSubmit={null}
                    showBreadcrumbs={false}
                    breadCrumbOnClick={() => {
                      setscoreResume(false);
                    }}
                    form={formik}
                    setFieldValue={formik.setFieldValue}
                    formValues={formik.values}
                    isFormValid={formik.isValid}
                    jobData={jobDetails}
                    glasssquidScore={glasssquidScore}
                    glasssquidScoreStatus={glasssquidScoreStatus}
                    isLoading={isScorecardLoading}
                    display={scoreResume}
                    isAutoPopulate={isAutoPopulatePending}
                    setAutopopulate={setIsAutoPopulatePending}
                    editMode={false}
                    classes="score-wrapper ackn-scorecard-wrapper"
                    />
                  </>
                )}
              </Container>
            </Form>
          )}
        </Formik>

        {filePreview !== undefined && (
          <PreviewFileDialog
            open={filePreview !== undefined}
            handleOpenClose={() => setFilePreview(undefined)}
            fileName={filePreview.fileName}
            fileURL={filePreview.resourceUrl}
            fileType={filePreview.fileType}
          />
        )}
      </div>
    </>
  );
};

const getEmploymentType = (type: string) => {
  if (type === "DirectContract") return "Direct Contract (C2C)";
  else if (type === "W2_Employee") return "W2";
  else return type;
};
