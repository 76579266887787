import React from 'react';
import { Grid, Typography, Card, CardContent, Chip, List, ListItem } from '@mui/material';
import { ListItemText } from '@material-ui/core';
import { Certification } from '../../../../../utils/StringUtil';
import { valueOrDefault } from '../../../../../utils/complexityUtils';

interface GenerateJobDetailsResponseProps {
  message?: string;
  messageList: MessageList[];
  model_name?: string;
  response?: string;
  status_code?: number;
}

export interface MessageList {
  aboutClient?: string;
  certifications?: Certification[];
  description?: string;
  domain?: Domain;
  education?: Education[];
  functionalSkills?: Skill[];
  industry?: Domain;
  jobAssignmentType?: string;
  jobTitle?: string;
  jobType?: string;
  jobShiftEnd?: string;
  jobShiftStart?: string;
  languages?: string[];
  licenses?: string[];
  location?: string;
  rolesAndResponsibilities?: string[];
  technicalSkills?: Skill[];
  yearsOfExperience?: number;
}

interface Domain {
  keywords?: string[];
  name?: string;
}

interface Education {
  degree: string;
  requirement: string;
}

interface Skill {
  level: string;
  requirement: string;
  skill: string;
}

const GenerateJobDetailsPreview: React.FC<GenerateJobDetailsResponseProps> = ({ messageList }) => {

  return (
    <>
    <Typography variant="h5" fontWeight="bold" sx={{mb:2}}>
      Generated Job Details Result Preview
    </Typography>
      {messageList?.map((job, index) => {  

          let functionalSkill = job?.functionalSkills?.map(
            (item: any) => item?.skill?.name
          );
          let technicalSkills = job?.technicalSkills?.map(
            (item: any) => item?.skill?.name
              );

          return(
          <Card
          key={job?.jobTitle}
          >
          <CardContent>
            <Grid container>
              {job?.jobTitle && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Job Title:{" "}
                  </Typography>
                  <Typography>{job?.jobTitle}</Typography>
                </Grid>
              )}
              {job?.domain && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Domain:{" "}
                  </Typography>
                  <Typography>{job?.domain?.name}</Typography>
                </Grid>
              )}
              {job?.industry && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Industry:{" "}
                  </Typography>
                  <Typography>{valueOrDefault(job?.industry?.name, "N/A")}</Typography>
                </Grid>
              )}
              {job?.jobType && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Working Location:{" "}
                  </Typography>
                  <Typography>{job?.jobType}</Typography>
                </Grid>
              )}
              {job?.yearsOfExperience && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Years Of Experience:{" "}
                  </Typography>
                  <Typography>{job?.yearsOfExperience}</Typography>
                </Grid>
              )}
              {job?.location && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Location:{" "}
                  </Typography>
                  <Typography>{job?.location}</Typography>
                </Grid>
              )}
              {job?.jobShiftStart && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Shift:{" "}
                  </Typography>
                  <Typography>{job?.jobShiftStart} - {job?.jobShiftEnd}</Typography>
                </Grid>
              )}
              {job?.jobAssignmentType && (
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                  Job Type:{" "}
                  </Typography>
                  <Typography>{job?.jobAssignmentType}</Typography>
                </Grid>
              )}
  
              {JobDetailsDecription(job)}
              {job?.education && job?.education.length > 0 && (
                <Grid item xs={12} pt={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Education:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {job?.education?.map((n: any) => (
                          <>
                            - {n?.degree}{" "}
                            {n?.major &&
                              n?.major !== "" &&
                              "- Major: " + n?.major}
                            <br />
                          </>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {functionalSkill && functionalSkill.length > 0 && (
                <Grid item xs={12} pt={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Functional skills:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ marginTop: 0, marginBottom: 0 }}>
                        {renderSkillsByRequirement(job?.functionalSkills)}
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {technicalSkills && technicalSkills.length > 0 && (
                <Grid item xs={12} pt={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Technical skills:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ marginTop: 0, marginBottom: 0 }}>
                        {renderSkillsByRequirement(job?.technicalSkills)}
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {job?.certifications && job?.certifications.length > 0 && (
                <Grid item xs={12} pt={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Certifications:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ul style={{ marginTop: 0, marginBottom: 0 }}>
                        {renderCertificateByRequirement(job?.certifications)}
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
              )}
  
              {job?.licenses && job?.licenses.length > 0 && (
                <Grid item xs={12} pt={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">
                        Licenses:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {job.licenses?.map((n: any) => (
                          <>
                            <Chip
                              key={n?.name}
                              color="primary"
                              label={n?.name}
                              style={{
                                marginTop: "10px",
                                marginLeft: "16px",
                              }}
                            />
                          </>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>)}
      )}   
    </>
  );
};

export default GenerateJobDetailsPreview;

const renderSkillsByRequirement = (skillData: any) => {
  const groupedSkills: { [key: string]: Skill[] } = {};

  // Group skills by requirement
  skillData.forEach((skill: Skill) => {
    if (!groupedSkills[skill.requirement]) {
      groupedSkills[skill.requirement] = [];
    }
    groupedSkills[skill.requirement].push(skill);
  });

  // Render skills for each requirement
  return Object.entries(groupedSkills).map(([requirement, skills]) => (
    <>
      <li>
        <p key={requirement}>
          <strong>{requirement}:</strong>
          <List style={{ paddingTop: 0, paddingBottom: 0 }}>
            {skills.map((skill:any) => (
              <ListItem
                key={skill.skill}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary={
                    <React.Fragment>
                      - <strong>{skill?.skill}</strong>:{" "}
                      {skill?.keywords?.join(", ")}
                    </React.Fragment>
                  }
                  secondary={`Level: ${skill.level}`}
                />
              </ListItem>
            ))}
          </List>
        </p>
      </li>
    </>
  ));
};

const renderCertificateByRequirement = (sertificateData: any) => {
  const groupedSkills: { [key: string]: any[] } = {};

  // Group skills by requirement
  sertificateData.forEach((skill: any) => {
    if (!groupedSkills[skill.requirement]) {
      groupedSkills[skill.requirement] = [];
    }
    groupedSkills[skill.requirement].push(skill);
  });

  // Render skills for each requirement
  return Object.entries(groupedSkills).map(([requirement, certificates]) => (
    <>
      <li>
        <p key={requirement}>
          <strong>{requirement}:</strong>
          <List style={{ paddingTop: 0, paddingBottom: 0 }}>
            {certificates.map((certificate) => (
              <ListItem
                key={certificate.name}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary={
                    <React.Fragment>- {certificate?.name}</React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </p>
      </li>
    </>
  ));
};

export const JobDetailsDecription = (job:any) => {
  
  return (
    <>
      <Grid item xs={12} pt={3}>
        <Typography variant="h6" fontWeight="bold">
          Description:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{job?.description}</Typography>
      </Grid>
      {job?.rolesAndResponsibilities && job?.rolesAndResponsibilities.length > 0 && (
        <Grid item xs={12} pt={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" fontWeight="bold">
                Roles & Responsibilities:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <ul>
                  {job?.rolesAndResponsibilities.map((row:string) => (
                    <React.Fragment key={row}>
                      <li>{row}</li>
                      <br />
                    </React.Fragment>
                  ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} pt={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight="bold">
              Client:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{job?.aboutClient}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};


export const JobDetailsDescriptionWithoutHtml = (job:any) => {
  const description = `Description:\n${job?.description || ''}\n`;

  let rolesAndResponsibilities = '';

if (job?.rolesAndResponsibilities && job.rolesAndResponsibilities.length > 0) {
    rolesAndResponsibilities += '\nRoles & Responsibilities:\n';
    job.rolesAndResponsibilities.forEach((row: any) => {
        rolesAndResponsibilities += `- ${row}\n`;
    });
    rolesAndResponsibilities += '\n';
}

  const client = `Client:\n${job?.aboutClient || ''}\n`;

  return description + rolesAndResponsibilities + client;
};

export const JobDetailsDescriptionWithHtml = (job:any) => {
  
  const description = `
    <p>
      <strong>Description: </strong>
    </p>
    <p>
      <p>${job?.description || ''}</p>
    </p>
  `;

  const rolesAndResponsibilities = job?.rolesAndResponsibilities && job.rolesAndResponsibilities.length > 0 ? `
    <p>
      <p>
       <strong>Roles & Responsibilities: </strong>
      </p>
      <p>
        <ul>
           ${job.rolesAndResponsibilities.map((row:string) => `<li>${row}</li>`).join('')}
        </ul>
      </p>
    </p>
  ` : '';

  const client = `
    <p>
      <p>
        <strong>Client:</strong>
      </p>
      <p>
        <p>${job?.aboutClient || ''}</p>
      </p>
    </p>
  `;

  return description + rolesAndResponsibilities + client;
};

