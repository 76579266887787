import React from 'react';
import './Scorecard.scss'; 
import Grid from '@mui/material/Grid';
import ScorecardPageHeader from './Components/ScorecardSection/ScorecardPageHeader';
import { Alert, Box, Button, Card, CardContent, CircularProgress, CardHeader, Divider, Typography } from '@mui/material';
import AppBreadCrumb from '../AppBreadCrumb';
import { BreadCrumbItem } from '../../../models/AppModels';
import { getNevLink } from '../../../utils/getFileFromUrl';
import JobTitle from '../JobTitle';
import RenderScorecardContent from './RenderScorecardContent';
import getAddressForAutoFill from '../../../utils/getAddressForAutoFill';
import { EximiusScoreData } from '../../../models/recruiterModel';
import { ternary, valueOrDefault } from '../../../utils/complexityUtils';

interface ScorecardResumeProps {
    resumeFile: any;
    showBreadcrumbs:boolean;
    onResumeFileChange: any;
    handleOnSubmit: any;
    breadCrumbOnClick: any;
    setFieldValue: any;
    formValues: any;
    jobData: any;
    isFormValid: boolean | undefined;
    glasssquidScore: any;
    isLoading: boolean;
    display: boolean;
    isAutoPopulate: boolean;
    setAutopopulate: any;
    form:any,
    editMode:boolean,
    glasssquidScoreStatus?:any,
    classes?:string
    height?:string
}

const ScorecardResume: React.FC<ScorecardResumeProps> = ({ 
    resumeFile,
    showBreadcrumbs,
    onResumeFileChange,
    handleOnSubmit,
    breadCrumbOnClick,
    setFieldValue,
    formValues,
    jobData,
    isFormValid,
    glasssquidScore,
    isLoading,
    display,
    isAutoPopulate,
    setAutopopulate,
    form,
    editMode,
    glasssquidScoreStatus,
    classes,
    height="calc(100vh - 40vh)"
}) => {

  const eximiusScore:any = (glasssquidScore?.messageList && glasssquidScore?.messageList[0]) as typeof EximiusScoreData;

  const breadCrumbItems: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "All Jobs", to: "/jobs/all" },
    {
      isLeaf: false,
      sequence: 3,
      title: "Job Detail",
      to: `/jobdetail/${jobData?.id}/jobs`,
    },
    {
      isLeaf: false,
      sequence: 4,
      title: "Submit Candidate",
      onClick: breadCrumbOnClick,
    },
    { isLeaf: true, sequence: 5, title: "Score Resume", leafClasses: " v2LastLeft" },
  ];

  function isGlassSquidFailed() {
    return !(eximiusScore && glasssquidScoreStatus?.statusCode === 200);
  }

  function handlePhoneAutofill(resume_details : typeof eximiusScore.resumeData) {
    if (resume_details.contactNumber === "") {
      return;
    }

    let phoneNumber = '';
    if (resume_details && resume_details?.contactNumber?.length > 0) {
        phoneNumber = resume_details?.contactNumber[0]?.replace(/[^0-9 ]/gi, "")?.replace(/\s/g, '');
    }

    const normalizedPhoneNumber = phoneNumber?.length < 11 ? "1" + phoneNumber : phoneNumber;
    setFieldValue("phone", normalizedPhoneNumber);

    form?.setFieldTouched("phone",false);
  }

  function handleSkillAutofill(key_skills: string[]) {
    if (!Boolean(key_skills)) {
      setFieldValue("skills", []);
      return;
    }

    const skills: string[] = [];

    key_skills.forEach((item) => {
      if (jobData?.manualData?.skills?.some(
        (n: any) => n.skill === item
      )) {
        skills?.push(item);
      }
    });

    setFieldValue("skills", skills);
  }

  function emptyAddressAutofill() {
    if(formValues?.pageName === "ackn") {
      setFieldValue("address.streetAddress", "");
      setFieldValue("address.postalCode", "");
      setFieldValue("address.countryCode", "");
      setFieldValue("address.state", "");
      setFieldValue("address.city", "");
    } else {
      setFieldValue("address", "");
      setFieldValue("zip", "");
      setFieldValue("country", "");
      setFieldValue("state", "");
      setFieldValue("city", "");
    }
  }

  function handleAddressAutofill(address: string) {
    if (address === "") {
      emptyAddressAutofill();
      return;
    }

    getAddressForAutoFill(address).then((resp) => {
      if (!Boolean(resp)) {
        return;
      }

      if(formValues?.pageName === "ackn") {
        setFieldValue("address.streetAddress", valueOrDefault(resp?.formattedAddress, ""));
        form?.setFieldTouched("address.streetAddress",false);
        
        setFieldValue("address.postalCode", valueOrDefault(resp?.pin, ""));
        form?.setFieldTouched("address.postalCode",false);

        setFieldValue("address.countryCode", valueOrDefault(resp?.country, ""));
        form?.setFieldTouched("address.countryCode",false);

        setFieldValue("address.state", valueOrDefault(resp?.state, ""));
        form?.setFieldTouched("address.state",false);

        setFieldValue("address.city", valueOrDefault(resp?.city, ""));
        form?.setFieldTouched("address.city",false);
      } else {
        setFieldValue("address", valueOrDefault(resp?.formattedAddress, ""));
        form?.setFieldTouched("address",false);
        
        setFieldValue("zip", valueOrDefault(resp.pin, ""));
        form?.setFieldTouched("zip",false);

        setFieldValue("country", valueOrDefault(resp.country, ""));
        form?.setFieldTouched("country",false);

        setFieldValue("state", valueOrDefault(resp.state, ""));
        form?.setFieldTouched("state",false);

        setFieldValue("city", valueOrDefault(resp.city, ""));
        form?.setFieldTouched("city",false);
      }
    });
  }


  function handleAutofillScoreData(resume_details : typeof eximiusScore.resumeData) {
    let name = resume_details.name.split(" ");
    if (name.length === 1) {
      setFieldValue("firstName", name[0]);
      form?.setFieldTouched("firstName",false);
    }else{

      const lastName = name.pop();
      const firstName = name.join(' ');
      setFieldValue("firstName", firstName);
      form?.setFieldTouched("firstName",false);
  
      setFieldValue("lastName", lastName);
      form?.setFieldTouched("lastName",false);
    }
    
    setFieldValue("experience", resume_details?.yearsOfExperienceInNumbers ?? "");
    form?.setFieldTouched("experience",false);

    // do not fill email for Update Submission
    if(!editMode || formValues?.pageName !== "ackn") {
      setFieldValue("email", resume_details.emailAddress);
      form?.setFieldTouched("email",false)
    }

    handlePhoneAutofill(resume_details);

    handleSkillAutofill(resume_details.skillsKeywords);

    handleAddressAutofill(resume_details.location);

  }

  function validateAndAutofillScoreData() {
    if (!(display && isAutoPopulate)) {
      return;
    }

    if(isGlassSquidFailed()) {
      return;
    }

    setAutopopulate(false);
    const resume_details = eximiusScore?.resumeData;

    if (!Boolean(resume_details)) {
      return;
    }

    handleAutofillScoreData(resume_details);
  }

  React.useEffect(() => {
    validateAndAutofillScoreData();
  }, [eximiusScore, display]);

  const renderContent = () => {
    if (glasssquidScoreStatus?.statusCode !== 200) {
      return (
          <Alert severity="error">
            We apologize for the inconvenience. The score card generation service is currently unavailable, but rest assured that your score card will be generated once the system is back online. You will receive an email notification when it is ready for download. Thank you for your understanding.
          </Alert>
      );
    } else if (glasssquidScore?.status === "failure") {
      return (
          <Alert severity="error">
            Invalid Resume, try again
          </Alert>
      );
    } else {

        const RenderScorecardContentProps = {
            resumeFile: resumeFile,
            showBreadcrumbs: showBreadcrumbs,
            onResumeFileChange: onResumeFileChange,
            handleOnSubmit: handleOnSubmit,
            breadCrumbOnClick: breadCrumbOnClick,
            setFieldValue: setFieldValue,
            formValues: formValues,
            jobData: jobData,
            isFormValid: isFormValid,
            glasssquidScore: glasssquidScore,
            isLoading: isLoading,
            display: display,
            isAutoPopulate: isAutoPopulate,
            setAutopopulate: setAutopopulate,
            form: form,
            editMode: editMode,
            glasssquidScoreStatus: glasssquidScoreStatus,
            classes: classes
          };

        return (
            <RenderScorecardContent  {...RenderScorecardContentProps} />
        );
    }
}


const renderAction = () => {
  if(formValues?.pageName === "ackn"){
    return (
          <Button
            type="submit"
            variant="contained"
            className="roboto-font button-large"
            onClick={() => {
              breadCrumbOnClick();
            }}
            style={{float:"right"}}
            data-testid="back-btn"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={"16px"} color={"inherit"} /> : ""}
          >
            {isLoading ? "Processing" : "Continue Submission" }
          </Button>
          )
  } else if(formValues?.pageName === "FindCandidate"){
    return null
  } else {
    return (
      <Card sx={{ maxWidth: "100%" }}>
        <CardHeader
          title={
            <Typography
              variant="h6"
              component="span"
              className="v2-score-label letter-spacing-normal primary-color-text"
            >
              Revise Score Now
            </Typography>
          }
        ></CardHeader>
        <Divider></Divider>
        <CardContent>
          <Grid container>
            <Grid item sm={12} className="revise-score-wrapper">
              <Typography mr={1}>
                If you feel the candidate has the necessary skills
                identified as "missing" by this scoring tool, you can
                choose to upload an updated resume, or proceed with the
                current resume.
              </Typography>
              <Box className="btn-wrapper">
                <Button
                  type="submit"
                  variant="contained"
                  className="roboto-font button-large"
                  onClick={() => {
                    breadCrumbOnClick();
                  }}
                  data-testid="back-btn"
                  disabled={isLoading}
                  endIcon={isLoading ? <CircularProgress size={"16px"} color={"inherit"} /> : ""}
                >
                  {isLoading ? "Processing" : "Continue Submission" }
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
          
}
  return (
    <>
      <Grid container spacing={2}>
        {
          showBreadcrumbs &&        
            <Grid item xs={12} >
              <AppBreadCrumb  
                  classes="custom-appbreadcrumb" 
                  BreadCrumbItems={breadCrumbItems}
              />
            </Grid>
              }
           <Grid item xs={12} >
            <Card 
              style={{
                maxHeight:  ternary(height === "auto", "auto", "calc(100vh - 40vh)") ,
                minHeight: ternary(height === "auto", "auto" ,  "calc(100vh - 40vh)"),
                overflowY: "auto",
              }}
            >
                <Grid container spacing={2} mt={0}>
                    {/* Row 1 for page heading */}
                    <Grid item xs={12} className='ScorecardPageHeading' p={ ternary(formValues?.pageName !== "FindCandidate", 0, 2)}>
                    {
                      formValues?.pageName !== "FindCandidate" && 
                        <ScorecardPageHeader>
                            You are submitting candidate details for &nbsp;
                                <JobTitle 
                                    title={" "+jobData?.jobTitle} 
                                    isFeatured={jobData?.isFeatured} 
                                    jobId={jobData?.id}
                                /> 
                        </ScorecardPageHeader>
                    }
                    </Grid>
                    
                    {isLoading ? (
                          <Grid item md={12} style={{textAlign:"center"}}>
                          <Typography
                          variant="h5"
                          component="div"
                          className="roboto-font letter-spacing-normal primary-color-text"
                          sx={{
                            paddingTop: "20px",
                            textUnderlinePosition: "under",
                            textDecorationColor: "#4540db",
                            placeContent: "center",
                          }}
                        >
                          Processing Resume...
                          <CircularProgress size={"25px"} color={"inherit"} />
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12} style={{ paddingTop: 0 }}>
                          {renderContent()}                
                      </Grid>
                    )}
                </Grid>
            </Card>
        </Grid>
        <Grid item md={12} pb={ ternary(formValues?.pageName === "FindCandidate" , "0px", "20px")}>
            { renderAction()}
            </Grid>
      </Grid>
    </>
  );
};

export default ScorecardResume;

