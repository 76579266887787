import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { Error } from "../Common/Error";
import Faq from "../Faq/Faq";
import Home from "../Home/Home";
import AdminSignin from "../Login/AdminSignin";
import Login from "../Login/Login";
import SignUp from "../Login/SignUp";
import Providers from "../Providers/Providers";
import SignupComplete from "../Recruiters/SignupComplete/SignupComplete";
import AboutUs from "../StaticPages/AboutUs";
import ContactUs from "../StaticPages/ContactUs";
import PrivacyPolicy from "../StaticPages/PrivacyPolicy";
import RecruitmentServices from "../StaticPages/RecruitmentServices";
import TermsAndConditions from "../StaticPages/TermsAndConditions";
import NotificationSettings from "../NotificationSettings/NotificationSettings";

import JobPosting from "../Jobs/JobPosting";
import { JobListingTest } from "../ui/Filters/TestComponent";
import { AppLogout } from "../Common/appLogout";
import EmailVerificationLinkExpired from "../Common/ExpireLinks/ExpireLink";
import { CandidateAcknowledge } from "../Candidate/CandidateAuthorization/CandidateAcknowledge";
import JobAlerts from "../Jobs/JobAlert/JobAlerts";
import { PermissionsEnums } from "../../data/permission";
import ClientSignupComplete from "../Client/ClientSignUp/ClientSignupComplete";
import JobDetails from "../Jobs/JobDetails/JobDetails";
import SetPassword from "../Common/SetPassword/SetPassword";
import ClientJobs from "../Jobs/JobListing/AllJobPage";
import ClientServicesPage from "../StaticPages/ClientServicesPage";
import ScorecardResume from "../Common/Scorecard/ScorecardResume";
import { getIsClientModuleEnabled } from "../../utils/AppConfig";
import { ModuleDisabledMessage } from "../Common/ModuleDisabledMessage";
import SignRecruiterContract from "../Recruiters/components/ShareContractDemoContract/SignRecruiterContract";
import SignaturePage from "../Recruiters/Contract/RecruiterContractSignaturePage";
import ContractSuccessPage from "../Recruiters/Contract/ContractSuccessPage";

//for all public routes in  project
// hideDefaultHeader : if true, hides the header navigation component
const isClientModuleEnabled = getIsClientModuleEnabled();

const publicRoutes = [
  {
    path: "/signcontract",
    element: <SignaturePage />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/signcontractsuccess",
    element: <ContractSuccessPage />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/moduledisabled",
    element: <ModuleDisabledMessage />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/clientsignup",
    element: <ClientSignupComplete />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/signupcomplete",
    element: <SignupComplete />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/recruitercontract",
    element: <SignRecruiterContract />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/home",
    element: <Home />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/",
    element: <Home />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/linkedin",
    element: <LinkedInCallback />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: true,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: true,
  },
  {
    path: "/termsandconditions",
    element: <TermsAndConditions />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: true,
  },

  {
    path: "/aboutus",
    element: <AboutUs />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },

  {
    path: "/contactus",
    element: <ContactUs />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: true,
  },

  {
    path: "/recruitmentservices",
    element: <RecruitmentServices />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: true,
  },
  {
    path: "/recruitmentservices/:id",
    element: <RecruitmentServices />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: true,
  },
  {
    path: "/candidateacknowledge",
    element: <CandidateAcknowledge />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },

  {
    path: "/login",
    element: <Login />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/setpassword",
    element: <SetPassword />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/logout",
    element: <AppLogout />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/adminsignin",
    element: <AdminSignin />,
    exact: true,
    showHeader: false,
    showLeftNav: false,

    showFooter: false,
  },

  {
    path: "/signup",
    element: <SignUp />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/providers",
    element: <Providers />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/error",
    element: <Error />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  

  {
    path: "/jobs/:page",
    element: <ClientJobs />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
  },
  {
    path: "/testpage",
    element: <JobListingTest />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/notificationsettings",
    element: <NotificationSettings />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/jobposting/:id",
    element: <JobPosting />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/emailvericationlinkexpired",
    element: <EmailVerificationLinkExpired />,
    exact: true,
    showHeader: false,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: "/jobAlerts",
    element: <JobAlerts />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  }

  // and so on for other publicRoutes in  project
];
//public routes which requires permission if logged in
const publicProtectedRoutes = [
  {
    path: "/jobs/:page",
    element: <ClientJobs />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
    permissionId: PermissionsEnums.Jobs_View,
  },
  {
    path: "/jobdetail/:id/:fromPage",
    element: <JobDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
    permissionId: PermissionsEnums.Jobs_Job_Details,
  },
  {
    path: "/clientjobdetail/:id/:fromPage",
    element: <JobDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: true,
    permissionId: PermissionsEnums.Jobs_Job_Details,
  },
  {
    path: "/faqs",
    element: <Faq />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId: PermissionsEnums.FAQ,
  }
  ,
  {
    path: "/scorecard",
    element: <ScorecardResume />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
]

if(isClientModuleEnabled){
  publicRoutes.push(
    {
      path: "/clientservices",
      element: <ClientServicesPage />,
      exact: true,
      showHeader: true,
      showLeftNav: false,
      showFooter: true,
    },
  );
}

const publicRoutePaths = [...publicRoutes.map(n=>n.path)];
const publicProtectedRoutePaths = [...publicProtectedRoutes.map(n=>n.path)]

export { publicRoutes, publicProtectedRoutes, publicRoutePaths, publicProtectedRoutePaths };

