import React, { useEffect } from "react";
import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import useApi from "../../hooks/useApi";
import ClearIcon from "@mui/icons-material/Clear";
import { GetJobs } from "../../Services/jobService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import { UserAuth } from "../../models/interfaces";

interface Props {
  onBlur: any;
  onChange: any;
  handleClear: () => void;
  selected: any;
  label?: any;
  multiple:boolean
  isReiqured:boolean
}
const userRole = getLoggedInUserRole();
const JobAutocomplete = (props:Props) => {
  const initialJobilterState = {
    pageIndex: 1,
    pageSize: 20,
    sortColumn: "jobStartDate",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
    industries: [],
    locations: [],
    ...(userRole === UserAuth.Recruiter) && {recruiterStatus: "Approved"},
  };

  const [filter, setFilter] = React.useState(initialJobilterState);
  const onIndustryInputChange = (e:any, value:any) => {
    setFilter({ ...filter, searchKeywords: value });
  };

  const jobs = useApi(() => GetJobs(filter));
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      jobs.request();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [filter.searchKeywords]);

  const getInputLabel = (option:any) => {
    return `${option.jobTitle} (${option.id})`;
  };
  return (
    <Autocomplete
      freeSolo={props.multiple} //remove dropdown arrow
      data-testid="clear-icon"
      autoComplete={false}
      clearOnEscape
      noOptionsText={jobs?.loading ? "Loading..." : "No options"}
      multiple={props.multiple}
      id="size-small-standard-multi3"
      size="medium"
      onInputChange={onIndustryInputChange}
      onBlur={props.onBlur}
      onChange={props.onChange}
      options={jobs?.data?.entityList || []}
      value={props.selected ?? null}
      defaultValue={props.selected}
      getOptionLabel={(option) => getInputLabel(option)}
      clearIcon={
        <ClearIcon
          data-testid="clear-icon"
          fontSize="small"
          onClick={() => {
            props?.handleClear();
          }}
        />
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="primary"
            size="medium"
            label={getInputLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search by Title or Job Id"
          variant="standard"
          label={props.label}
          data-testid={"autocomplete-jobs"}
          InputProps={{
            ...params.InputProps,
            style:{
              paddingBottom:"0px"
            },
            endAdornment: (
              <React.Fragment>
                {jobs?.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default JobAutocomplete;
