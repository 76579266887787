
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { InputLabel } from '@mui/material';
import { ErrorMessage } from '../../../../ui/ErrorMessage';

interface AppTextEditorProps {
  label: string;
  value:string;
  handleOnChange:any;
  handleOnBlur? :any;
  shrink?:boolean;
  required?:boolean;
  isError?:boolean;
  helperText?: string;
  placeholder?:string;
  className?:string;
}

const AppTextEditor: React.FC<AppTextEditorProps> = ({ 
    label,
    value,
    handleOnChange,
    shrink=true,
    required=false,
    isError=false,
    helperText="",
    placeholder="",
    className="",
    handleOnBlur = ()=>{}, 
}) => {

  return (
    <>
     { label && 
        <InputLabel 
            shrink={shrink} 
            required={required}
            error={isError}
        >
            {label}
        </InputLabel> 
    }

        <ReactQuill
            className={`${className} jrAppTextEditor`}        
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            placeholder={placeholder}
        />
        <ErrorMessage 
        errorText={helperText}
        />
      </>
  );
};

export default AppTextEditor;
