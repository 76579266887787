import { Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getNevLink } from "../../utils/getFileFromUrl";
import Approved from "./../../assets/images/Approved.svg";
import ClientUpdate from "./../../assets/images/ClientUpdate.svg";
import rejected from "./../../assets/images/rejected.svg";
import setup from "./../../assets/images/setup.svg";
import suspended from "./../../assets/images/suspended.svg";
import "./ClientPopup.scss";

interface Props {
  isOpen:boolean;
  title: string;
  type: string;
  handleClose: any;
}

const ClientPopup: React.FC<Props> = ({ isOpen, handleClose, title, type }) => {
  const navigate = useNavigate();


  const getImage = (type: string): React.ReactElement => {
    switch (type) {
      case "update":
        return <img src={ClientUpdate} alt="Update" />;
      case "approve":
      case "newrecruiter":
        return <img src={Approved} alt="Approve" />;
      case "reject":
        return <img src={rejected} alt="Reject" />;
      case "suspend":
        return <img src={suspended} alt="Suspend" />;
      case "setup":
      case "AddJob":
        return <img src={setup} alt="Setup" />;
      default:
        return <></>;
    }
  };  

  const getMessageTitle = (type: string): React.ReactElement => {
    switch (type) {
      case "update":
        return <div>Client details have been updated successfully</div>;
      case "approve":
        return (
          <div>
            Client has been{" "}
            <span className="custom-success-clr">approved</span>{" "}
            successfully
          </div>
        );
      case "reject":
        return (
          <div>
            Client has been{" "}
            <span className="custom-reject-clr">rejected</span> <br />
            successfully
          </div>
        );
      case "suspend":
        return (
          <div>
            Client has been{" "}
            <span className="custom-reject-clr">suspended</span> <br />
            successfully
          </div>
        );
      case "setup":
        return <div>Your account is successfully setup</div>;
      case "newrecruiter":
        return (
          <div>
            New Recruiter{" "}
            <span className="custom-success-clr">Successfully</span> <br />
            Added
          </div>
        );
      case "AddJob":
        return (
          <div>
            Job Details saved{" "}
            <span className="custom-success-clr">Successfully</span>
          </div>
        );
      default:
        return <div>Something Went Wrong</div>;
    }
  };
  
  const getMessageDescription = (type: string): string => {
    switch (type) {
      case "update":
        return "The Client profile has been successfully updated. They will receive an email for the profile updates.";
      case "approve":
        return "The client has been successfully approved and added to the JobRialto. Thank you for your feedback";
      case "reject":
        return "The client has been successfully rejected. Thank you for your feedback";
      case "suspend":
        return "The client has been successfully suspended. Thank you for your feedback";
      case "setup":
        return "One of our account representatives will contact you shortly for further verification.";
      case "newrecruiter":
        return "The new recruiter added will receive the verify account email in their mailbox sent to aditya.chopra@gmail.com within the next 15 minutes.";
      default:
        return "Something Went Wrong";
    }
  };


  const getButton = (type: string): JSX.Element => {
    switch (type) {
      case "setup":
        return <Button variant="contained">Go back to Dashboard</Button>;
      case "newrecruiter":
        return <Button variant="contained">Not the correct email address</Button>;
      default:
        return (
          <Button
            data-testid="contained"
            variant="contained"
            onClick={() => navigate(getNevLink("/dashboard"))}
          >
            {" "}
            Go back to Homepage
          </Button>
        );
    }
  };

  return (
    <div>
      <Dialog
        className="custom-client-popup"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="pop-up-title"
      >
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "center" }}>
            {getImage(type)}
          </Typography>
          <Typography className="custom-popup-txt">
            {" "}
            {getMessageTitle(type)}
          </Typography>
          <Typography className="custom-popup-txt1">
           {getMessageDescription(type)}
          </Typography>
          <Typography className="custom-popup-txt2">
            {getButton(type)}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClientPopup;
