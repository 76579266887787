import {
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { CircularProgress, Slider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  GetFindCandidate,
  GetJobById,
  getJobWorkAuthorization,
  getJobWorkExperience,
  getScoreCard,
} from "../../../Services/jobService";
import { defaultDataAutocomplete } from "../../../data/defaultDataAutocomplete";
import { BreadCrumbItem } from "../../../models/AppModels";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getLabel from "../../../utils/getLabelForAutoComplete";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { Asterisk } from "../../ui/Asterisk";
import { ErrorMessage } from "../../ui/ErrorMessage";
import { valueOrDefault } from "../../../utils/complexityUtils";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { SortType } from "../../../models/app/AppModels";
import { sortByProperty } from "../../../utils/randomUtils";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";
import JobAutocomplete from "../../ui/JobAutocomplete";
import { FindCandidateRow } from "./FindCandidateRow";
import RecommendJobScorecardDialog from "../FindJob/RecommendJobScorecardDialog";

interface IPROPS {}
interface SliderState {
  [key: string]: number;
}

const FindCandidate: React.FunctionComponent<IPROPS> = (props) => {
  const findJobsSearchStateRTK = useSelector(
    (state: any) => state.findJobsSearchSlice
  );

  let defaultBreadcrumb: BreadCrumbItem[] = [
    {
      isLeaf: false,
      sequence: 1,
      title: "Dashboard",
      to: getNevLink("/dashboard"),
    },
    { isLeaf: true, sequence: 3, title: "Find Candidates" },
  ];

  const [sliderValues, setSliderValues] = React.useState<SliderState>({
    skills_pct: 14.2,
    job_title_pct: 14.2,
    technology_pct: 14.2,
    tools_pct: 14.2,
    education_pct: 14.2,
    certification_pct: 14.2,
    industry_pct: 14.2,
  });
  const [job, setJob] = useState();
  const [autoCompleteSuggesions, setautoCompleteSuggesions] = useState<any[]>(
    []
  );
  const [locationSearchOptionList, setLocationSearchOptionList] = useState<
    any[]
  >([]);
  const [candidateDataList, setCandidateDataList] = useState<any[]>();
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const [loading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [hasMoreData] = useState<any>(null);

  const [scorecardPopup, setScorecardPopup] = React.useState<{
    jobData?: any;
    isLoading?: boolean;
    display?: boolean;
    email?: string;
    selectedRowJobDetails?:any
  }>();
  const [scoreResumeStatus, setScoreResumeStatus] =
    React.useState<{statusCode?:number, statusMsg?:string}>();

  const [sort, setSort] = React.useState<{
    sortBy: string;
    sortOrder: SortType;
  }>({ sortBy: "eximScore", sortOrder: SortType.Descending });

  const [jobWorkExperiencesList, setJobWorkExperiencesList] = useState<any[]>(
    []
  );
  const [jobWorkAuthorizationList, setJobWorkAuthorizationList] = useState<
    string[]
  >([]);


  const formik = useFormik({
    initialValues: {...findJobsSearchStateRTK,id:""},
    validationSchema: UserValidtionSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      event.stopImmediatePropagation();
    });
    if (findJobsSearchStateRTK?.searchJobsId?.length > 0) {
      setCandidateDataList(findJobsSearchStateRTK?.recommendationData);
    }
    _GetJobWorkAuthorization();
    _GetJobWorkExperiencesList();
  }, []);

  const handleReset = () => {
    _handleJobAutompleteClear()
    formik.setFieldValue("jobType", []);
    formik.setFieldValue("id", "");
    formik.setFieldValue("domains", []);
    formik.setFieldValue("industries", []);
    formik.setFieldValue("jobTitle", []);
    formik.setFieldValue("location", []);
    formik.setFieldValue("minimumPayRate", "");
    formik.setFieldValue("maximumPayRate", "");
    formik.setFieldValue("currency", "");
    formik.setFieldValue("searchRadius", "");
    formik.setFieldValue("remote", "");
    formik.setFieldValue("workExp", "");
    formik.setFieldValue("experienceId", "");
    formik.setFieldValue("experienceText", "");
    formik.setFieldValue("maxWorkExp", "");
    formik.setFieldValue("maxExperienceId", "");
    formik.setFieldValue("maxExperienceText", "");
    formik.setFieldValue("payType", "");
    formik.setFieldValue("workAuthorizations", []);
    formik.setFieldValue("n", "10");
    formik.setFieldValue("resumeFile", {
      file: File,
      fileName: String,
    });
    formik.setFieldValue("isSearchResult", false);
    setautoCompleteSuggesions([]);
    setLocationSearchOptionList([]);
    localStorage.removeItem("findjobsSearchCriteria");

    formik.setFieldTouched("resume", false);
    formik.setFieldTouched("domains", false);
    formik.setFieldTouched("jobTitle", false);
    setSliderValues({
      skills_pct: 14.2,
      job_title_pct: 14.2,
      technology_pct: 14.2,
      tools_pct: 14.2,
      education_pct: 14.2,
      certification_pct: 14.2,
      industry_pct: 14.2,
    });
    setTotalRecords(0);
    setCandidateDataList(undefined)
  };

  const handleSubmit = (values: any) => {
    let body = {} as any;
    body.id=values?.id;
    body.searchParams = {} as any;
    
    let formData = new FormData();
    formData.append("id", values?.id);
    if (values?.location && values?.location.length > 0) {
      body.searchParams.location = values?.location[0]?.location?.city +
          ", " +
          values?.location[0]?.location?.state +
          ", " +
          values?.location[0]?.location?.country
    } else {
      body.searchParams.location = ""
      formData.append("search_params.location", "");
    }
    body.searchParams.searchRadius = values?.searchRadius;
    body.searchParams.maxPackageRate = values?.maximumPayRate !== "" ? values?.maximumPayRate : undefined;
    body.searchParams.minExperience =  values?.workExp !== "" ? values?.workExp : undefined;
    body.searchParams.maxExperience = values?.maxWorkExp !== "" ? values?.maxWorkExp : undefined;
    body.searchParams.minPackageRate = values?.minimumPayRate !== "" ? values?.minimumPayRate : undefined;
    body.searchParams.n = values?.n;
    body.searchParams.workAuth = values?.workAuthorizations;
    body.searchParams.allowRelocation = values?.allowRelocation;
    body.searchParams.remote = values?.remote;
    body.searchParams.payType = values?.payType;

    body.scoringParams = {
      "certificationPct": sliderValues?.certification_pct.toString(),
      "educationPct": sliderValues?.education_pct.toString(),
      "jobTitlePct": sliderValues?.job_title_pct.toString(),
      "skillsPct": sliderValues?.skills_pct.toString(),
      "technologyPct": sliderValues?.technology_pct.toString(),
      "toolsPct": sliderValues?.tools_pct.toString(),
      "industryPct": sliderValues?.industry_pct.toString()
    }
    getFindCandidateAPI(body);

  };

  const getFindCandidateAPI = (model: any) => {
    setIsFormSubmitting(true);
    GetFindCandidate(model)
      .then((response: any) => {
        setCandidateDataList(
          sortByProperty(
            response?.data?.entity?.messageList || [],
            sort.sortBy,
            sort.sortOrder
          )
        );

        setIsFormSubmitting(false);
        setTotalRecords(response.data?.totalRecords);
      })
      .catch((error: any) => {
        setIsFormSubmitting(false);
      })
      .finally(() => {
      });
  };

  const renderPreferredGeographies = ({ getInputProps, suggestions }: any) => (
    <Autocomplete
      className="findjobs-chips"
      multiple
      id="size-small-standard-multi1"
      size="medium"
      onKeyPress={(e) => {
        formik.values.location?.length === 5
          ? e.preventDefault()
          : console.log();
      }}
      onChange={(e, value: any) => {
        formik.setFieldValue("location", value);
        setLocationSearchOptionList(value);
      }}
      options={
        suggestions.length > 0
          ? suggestions.map((str: any) => ({
              id: str.placeId,
              location: {
                city: getAddressForAutoComplete(str.description).city,
                state: getAddressForAutoComplete(str.description).state,
                country: getAddressForAutoComplete(str.description).country,
              },
            }))
          : defaultDataAutocomplete
      }
      getOptionDisabled={(option: any) =>
        formik.values.location.some((e: any) => e.id === option.id) ||
        formik.values.location?.length === 5
          ? true
          : false
      }
      value={locationSearchOptionList}
      getOptionLabel={(option: any) =>
        getLabel(option.location.city, option.location.state)
      }
      renderTags={(value, getTagProps) =>
        value?.map((option: any, index: any) => (
          <Chip
            color="primary"
            size="small"
            label={getLabel(option.location.city, option.location.state)}
            {...getTagProps({ index })}
          />
        ))
      }
      onBlur={(e: any) => {
        if (e.target.value && e.target.value !== "") {
          setLocationSearchOptionList([
            ...locationSearchOptionList,
            e.target.value,
          ]);
        }
      }}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          data-testid="cities-autosuggestion"
          {...params}
          {...getInputProps()}
          variant="standard"
          label=""
        />
      )}
    />
  );

  // GET Master GetJobWorkAuthorization
  const _GetJobWorkAuthorization = async () => {
    getJobWorkAuthorization()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkAuthorizationList(response?.data?.entityList);
        } else {
          setJobWorkAuthorizationList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get Master Job work experiences
  const _GetJobWorkExperiencesList = () => {
    getJobWorkExperience()
      .then((response: any) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
          setJobWorkExperiencesList(response?.data?.entityList);
        } else {
          setJobWorkExperiencesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExperienceLabel = (id: string) => {
    const jobExperience: any = jobWorkExperiencesList?.find(
      (item: any) => item.id === id
    );
    return valueOrDefault(jobExperience?.experience, "");
  };


  const handleSliderChange = (key: string, value: number) => {
    const updatedValues = { ...sliderValues, [key]: value };

    // Calculate total value of all sliders excluding the current one
    const totalOtherSliders = Object.values(updatedValues).reduce(
      (acc, curr, currIndex) => {
        if (Object.keys(updatedValues)[currIndex] !== key) {
          return acc + curr;
        }
        return acc;
      },
      0
    );
    const remainingPercentage = 100 - value;
    // If all other sliders are zero, distribute the value equally
    if (totalOtherSliders === 0) {
      const numOtherSliders = Object.keys(updatedValues).length - 1;
      for (const [k] of Object.entries(updatedValues)) {
        if (k !== key) {
          updatedValues[k] = parseFloat(
            (remainingPercentage / numOtherSliders).toFixed(2)
          );
        }
      }
    } else {
      // Distribute remaining percentage proportionally
      for (const [k] of Object.entries(updatedValues)) {
        if (k !== key) {
          updatedValues[k] = parseFloat(
            ((updatedValues[k] / totalOtherSliders) * (100 - value)).toFixed(2)
          );
        }
      }
    }

    setSliderValues(updatedValues);
  };

  const handleViewScoreCard = (n: any) => {    
    let formData = new FormData();
    let blob = new Blob();
    formData.append("file",blob);
    formData.append("skillsPct", sliderValues?.skills_pct.toString());
    formData.append("jobTitlePct", sliderValues?.job_title_pct.toString());
    formData.append("technologyPct", sliderValues?.technology_pct.toString());
    formData.append("toolsPct", sliderValues?.tools_pct.toString());
    formData.append("educationPct", sliderValues?.education_pct.toString());
    formData.append(
      "certificationPct",
      sliderValues?.certification_pct.toString()
    );
    formData.append("industryPct", sliderValues?.industry_pct.toString());
    formData.append("jobId", n?.jobData?.id.toString());
    formData.append("feature", "CandidateSearch");
    formData.append("eximScore", n?.eximScore);
    formData.append("jobData", JSON.stringify(n?.jobData));
    formData.append("resumeData", JSON.stringify(n?.resumeData));
    formData.append(
      "unweightedMatchedScores",
      JSON.stringify(n?.unweightedMatchedScores)
    );
    setScorecardPopup({ isLoading: true, email: n?.resumeData?.emailAddress.toString() });

    getScoreCard(formData)
      .then((res) => {
        if (res.status !== 200) {
          setScorecardPopup({ isLoading: false });
          setScoreResumeStatus({
            statusCode:res.status,
            statusMsg: "Failed"
          })
        } else {
          setScorecardPopup({
            jobData: res.data?.entity,
            display: true,
            isLoading: false,
            selectedRowJobDetails:n
          });
          setScoreResumeStatus({
            statusCode:200,
            statusMsg: "Success"
          })
        }
      })
      .catch(() => {
        setScorecardPopup({ isLoading: false });
      });
  };

  const onSort = (sortBy: string) => {
    let sortOrderBy =
      sort?.sortOrder === SortType.Ascending
        ? SortType.Descending
        : SortType.Ascending;

    setSort({
      sortBy: sortBy,
      sortOrder: sortOrderBy,
    });
    let sortedData = sortByProperty(
      candidateDataList ?? [],
      sortBy,
      sortOrderBy
    );

    setCandidateDataList(sortedData);
  };

  const handleJobChange = (item: any) => {
    GetJobById(item?.id??"").then((response) => {
      if (response.data.status == 200) {
        let jobDetail = response.data.entity;
        console.log(response);
        const address = {
          city: jobDetail?.city,
          state: jobDetail?.stateCode,
          country: jobDetail?.countryName,
        };
        formik.setFieldValue("location", [{ location: address }]);
        setLocationSearchOptionList([{ location: address }]);
        let experience = jobWorkExperiencesList.find(
          (n) =>
            n.experienceValue ===
          jobDetail?.jobExperience
        );
        if (experience) {
          formik.setFieldValue("workExp", experience?.experienceValue);
          formik.setFieldValue("experienceId", experience?.id);
          formik.setFieldValue("experienceText", experience?.experience);
        }
        formik.setFieldValue("workAuthorizations", jobDetail?.jobWorkAuthorization);
        formik.setFieldValue("maximumPayRate", jobDetail?.package?.packageValue);
        formik.setFieldValue("currency", jobDetail?.package?.packageCurrency);
        formik.setFieldValue("payType", jobDetail?.package?.packageType);
        formik.setFieldValue("id", jobDetail?.id);
      }
    });

    setJob(item);
  };

  const _handleJobAutompleteClear = ()=>{
    setJob(undefined)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={defaultBreadcrumb}
              ></AppBreadCrumb>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "400px",
                }}
              >
                Find suitable Candidates for Job
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={1} className="editRecruter-wt">
            <Grid item xs={12} md={12} lg={12}>
              <Paper elevation={1} sx={{ padding: 2 }}>
                <Grid container xs={12} md={12} spacing={2}>
                  <Grid item xs={6} style={{ paddingTop: "13px" }}>
                    <Typography className="">
                      Job <Asterisk />
                    </Typography>
                    <JobAutocomplete
                      handleClear={_handleJobAutompleteClear}
                      isReiqured={false}
                      multiple={false}
                      onBlur={(_: any, newValue: any) => {}}
                      onChange={(_: any, newValue: any) => {
                        if(newValue?.id){
                          handleJobChange(newValue);
                        }
                        
                      }}
                      selected={job}
                    />
                    <ErrorMessage
                      errorText={formik.touched?.id && formik.errors?.id}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="">Location</Typography>
                    <PlacesAutocomplete
                      value={autoCompleteSuggesions}
                      onChange={(ev: any) => {
                        setautoCompleteSuggesions(ev);
                      }}
                      searchOptions={{ types: ["(cities)"] }}
                    >
                      {renderPreferredGeographies}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={1} md={1} pl={1}>
                    <Typography className="">Radius</Typography>
                    <TextField
                      fullWidth
                      id="searchRadius"
                      name="searchRadius"
                      type="number"
                      value={formik.values.searchRadius}
                      onChange={formik.handleChange}
                      inputProps={{ style: { paddingTop: 2 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography className="">Work Authorization</Typography>
                    <InputLabel shrink={true}></InputLabel>
                    <Autocomplete
                      className="findjobs-chips"
                      multiple
                      id="tags-standard"
                      options={jobWorkAuthorizationList?.map(
                        (option: any) => option?.workAuthorization
                      )}
                      getOptionLabel={(option: any) => option}
                      value={formik.values?.workAuthorizations}
                      onChange={(_, newValue) => {
                        formik.setFieldValue("workAuthorizations", newValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any, index: any) => (
                          <Chip
                            color="primary"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          label="Tell us about your Work Authorization Preference"
                          data-testid={"autocomplete-workauthorization"}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography className="">Work Experience</Typography>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={12}>
                        <Autocomplete
                          className="findjobs-chips"
                          id="tags-standard"
                          disableClearable={true}
                          options={jobWorkExperiencesList.map((x: any) => x.id)}
                          getOptionLabel={(option: any) =>
                            getExperienceLabel(option)
                          }
                          placeholder="Test"
                          value={formik.values?.experienceId}
                          onChange={(_, newValue: any) => {
                            if (newValue) {
                              let value = jobWorkExperiencesList?.find(
                                (item: any) => item.id === newValue
                              );
                              formik.setFieldValue(
                                "workExp",
                                value?.experienceValue
                              );
                              formik.setFieldValue("experienceId", newValue);
                              formik.setFieldValue(
                                "experienceText",
                                value?.experience
                              );
                            } else {
                              formik.setFieldValue("workExp", "");
                              formik.setFieldValue("experienceId", "");
                              formik.setFieldValue("experienceText", "");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          renderTags={(value, getTagProps) =>
                            value.map((option: any, index: any) => (
                              <Chip
                                color="primary"
                                size="small"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              data-testid={"autocomplete-workexperience-min"}
                              label="Minimum"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <Autocomplete
                          className="findjobs-chips"
                          id="tags-standard"
                          disableClearable={true}
                          options={jobWorkExperiencesList.map((x: any) => x.id)}
                          getOptionLabel={(option: any) =>
                            getExperienceLabel(option)
                          }
                          placeholder="Test"
                          value={formik.values?.maxExperienceId ?? ""}
                          onChange={(_, newValue: any) => {
                            if (newValue) {
                              let value = jobWorkExperiencesList?.find(
                                (item: any) => item.id === newValue
                              );
                              formik.setFieldValue(
                                "maxWorkExp",
                                value?.experienceValue
                              );
                              formik.setFieldValue("maxExperienceId", newValue);
                              formik.setFieldValue(
                                "maxExperienceText",
                                value?.experience
                              );
                            } else {
                              formik.setFieldValue("maxWorkExp", "");
                              formik.setFieldValue("maxExperienceId", "");
                              formik.setFieldValue("maxExperienceText", "");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          renderTags={(value, getTagProps) =>
                            value.map((option: any, index: any) => (
                              <Chip
                                color="primary"
                                size="small"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              label="Maximum"
                              variant="standard"
                              data-testid={"autocomplete-workexperience-max"}
                              {...params}
                            />
                          )}
                        />
                        <ErrorMessage
                          errorText={
                            formik.touched?.maxWorkExp &&
                            formik.errors?.maxWorkExp
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="">Pay Rate</Typography>
                    <Grid container maxWidth={"xl"}>
                      <Grid item xs={3} md={3.7} pr={1}>
                        <TextField
                          fullWidth
                          id="minimumPayRate"
                          name="minimumPayRate"
                          label="Minimum"
                          type="number"
                          value={formik.values.minimumPayRate}
                          onChange={formik.handleChange}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                      </Grid>
                      <Grid item xs={3} md={3.7} pr={1}>
                        <TextField
                          fullWidth
                          id="maximumPayRate"
                          name="maximumPayRate"
                          label="Maximum"
                          type="number"
                          value={formik.values.maximumPayRate}
                          onChange={formik.handleChange}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                         <ErrorMessage
                      errorText={
                        formik.touched?.maximumPayRate && formik.errors?.maximumPayRate
                      }
                    />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={2.2}
                        className="custom-currency-box"
                      >
                        <FormControl
                          fullWidth
                          variant="standard"
                          sx={{ minWidth: 80 }}
                          className="CurrencyControl"
                        >
                          <InputLabel id="payrateCurrency">Pay Type</InputLabel>
                          <Select
                            value={formik.values.payType ?? ""}
                            onChange={(e) => {
                              formik.setFieldValue("payType", e.target.value);
                            }}
                            className="CurrencySelect"
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {/* <MenuItem value={""}>All</MenuItem> */}
                            {job_type.map((name) => (
                              <MenuItem key={name} value={name}>
                                {capitalizeFirstLetter(name)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} md={2} pl={1}>
                        <TextField
                          required
                          fullWidth
                          id="n"
                          name="n"
                          label="Results"
                          type="number"
                          value={formik.values.n}
                          onChange={formik.handleChange}
                          error={formik.touched.n && Boolean(formik.errors.n)}
                          helperText={formik.touched.n && formik.errors.n}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="">Working location </Typography>

                    <RadioGroup
                      row
                      name="remote"
                      id="remote"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.remote}
                    >
                      {" "}
                      <FormControlLabel
                        value={"y"}
                        control={<Radio />}
                        label="Remote"
                      />
                      <FormControlLabel
                        value={"n"}
                        control={<Radio />}
                        label="Onsite"
                      />
                      {formik.values?.remote === "n" ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="allowRelocation"
                              id="allowRelocation"
                              value={"y"}
                              onChange={formik.handleChange}
                              size="small"
                            />
                          }
                          label="Allow Relocation"
                        />
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} md={12} mb={1}>
                        <SectionHeading title="Modify the search results" />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Skill</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          onChange={(e: any) => {
                            handleSliderChange("skills_pct", e.target.value);
                          }}
                          name="skills_pct"
                          valueLabelDisplay="auto"
                          value={sliderValues.skills_pct}
                          data-testid={"skill-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Title</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="job_title_pct"
                          onChange={(e: any) => {
                            handleSliderChange("job_title_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.job_title_pct}
                          data-testid={"job_title_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Technology</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="technology_pct"
                          onChange={(e: any) => {
                            handleSliderChange(
                              "technology_pct",
                              e.target.value
                            );
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.technology_pct}
                          data-testid={"technology_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Tools</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="tools_pct"
                          onChange={(e: any) => {
                            handleSliderChange("tools_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.tools_pct}
                          data-testid={"tools_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Education</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="education_pct"
                          onChange={(e: any) => {
                            handleSliderChange("education_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.education_pct}
                          data-testid={"education_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Certification</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="certification_pct"
                          onChange={(e: any) => {
                            handleSliderChange(
                              "certification_pct",
                              e.target.value
                            );
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.certification_pct}
                          data-testid={"certification_pct-slider"}
                        />
                      </Grid>
                      <Grid md={3} pr={5}>
                        <Typography className="">Industry</Typography>
                        <Slider
                          max={100}
                          min={0}
                          aria-label="Volume"
                          name="industry_pct"
                          onChange={(e: any) => {
                            handleSliderChange("industry_pct", e.target.value);
                          }}
                          valueLabelDisplay="auto"
                          value={sliderValues.industry_pct}
                          data-testid={"industry_pct-slider"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box className="text-Align-Right">
                      <Button
                        type="button"
                        size="medium"
                        style={{
                          fontSize: "0.875rem",
                          marginRight: "10px",
                          marginTop: "10px",
                        }}
                        variant="outlined"
                        className="abel button-large"
                        onClick={(e) => {
                          handleReset();
                        }}
                        disabled={isFormSubmitting}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        size="medium"
                        style={{
                          fontSize: "0.875rem",
                          marginRight: "10px",
                          marginTop: "10px",
                        }}
                        variant="contained"
                        className="abel button-large"
                        onClick={(e) => {
                          console.log(formik);
                          formik.handleSubmit();
                        }}
                        disabled={isFormSubmitting}
                        endIcon={
                          isFormSubmitting ? (
                            <CircularProgress size={"16px"} color={"inherit"} />
                          ) : (
                            ""
                          )
                        }
                      >
                        Search
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {!isFormSubmitting && candidateDataList && (
                <FindCandidateRow
                  jobDataList={[]}
                  totalRecords={totalRecords}
                  hasMoreData={hasMoreData}
                  loading={loading}
                  recommendationData={candidateDataList ?? []}
                  sort={sort}
                  handleColumnSort={onSort}
                  onViewScoreCard={(selectedItem: any) =>
                    handleViewScoreCard(selectedItem)
                  }
                  scorecardPopup={scorecardPopup}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RecommendJobScorecardDialog
        handleOpenClose={() => {
          setScorecardPopup({ ...scorecardPopup, display: false });
        }}
        open={scorecardPopup?.display ?? false}
        score={scorecardPopup?.jobData}
        scoreResumeStatus={scoreResumeStatus}
      ></RecommendJobScorecardDialog>
    </>
  );
};

const UserValidtionSchema = Yup.object().shape({
  id: Yup.string().required("Job is required"),
  n: Yup.number()
    .max(100, "Result must be smaller or equal to 100")
    .min(1, "Result must be greater than 0")
    .required("Result is required"),
  maxWorkExp: Yup.number()
    .nullable()
    .when("workExp", (workExp, schema) => {
      return workExp
        ? schema.min(
            workExp,
            "Maximum Experience should be greater than Minimum Experience"
          )
        : schema;
    }),
    maximumPayRate: Yup.number()
    .nullable()
    .when("minimumPayRate", (minimumPayRate, schema) => {
      return minimumPayRate
        ? schema.min(
          minimumPayRate,
            "Maximum Pay Rate should be greater than Minimum Pay Rate"
          )
        : schema;
    }),
});

export default FindCandidate;

const jobFilter = {
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "score",
  sortOrder: 0,
  isSorted: true,
  jobIds: [],
  totalRecord: 0,
  searchKeywords: "",
  domains: [],
  showFeaturedJobs: false,
  showDisabledJobs: false,
  showRemoteJobs: false,
  advanceSearch: {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate: 0,
    maximumPayRate: 0,
    currency: "",
  },
  recruiterStatus: "",
};

export const job_type = ["annually", "hourly"];

export function capitalizeFirstLetter(n: string) {
  return n?.charAt(0).toUpperCase() + n?.slice(1);
}