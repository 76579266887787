import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import "./popup.scss";
import GenerateJobPostingContentForm from "../FormSection/GenerateJobPostingContentForm";
import { MessageList } from "../FormSection/GenerateJobDetailsPreview";

interface Props {
  isOpen: boolean;
  handleClose:()=>void;
  clientLoginData:any
  handleOnContinue:(JobDetailsValues: MessageList)=>void;
}
const GenerateJobPostingDetailsPopup :React.FC<Props> = ({ 
  isOpen, 
  handleClose, 
  clientLoginData, 
  handleOnContinue 
  }) => {

  return (
    <div>
      <Dialog
        
        open={isOpen}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="pop-up-title"
      >
         
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          <Typography className="PopupPrimaryTitle"> Generate Job Details </Typography> 
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className="closePopupCommon"
            data-testid="JDPopupClose"
          >
            
            <CloseIcon />
          </IconButton>         
        </DialogTitle>
        <DialogContent>
          <GenerateJobPostingContentForm
            clientLoginData={clientLoginData}
            handleOnContinue={(value=>{
              handleOnContinue(value)})}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GenerateJobPostingDetailsPopup;
