import { GetAllIndustries } from "../Services/recruiterService";

interface Skills{
    id:string;
    skillName:string;
}

export interface Experience {
    id: string;
    experience: string;
    experienceValue: number;
  }
  

 export interface JobType{
    id:string;
    jobType:string;
 } 

 export interface EducationType{
    id:string;
    degreeName:string;
 } 
  
  
 
 export const GetSkillNameArray = (skills: Skills[]): (string | Skills)[] => {
    return skills?.map((item: Skills) => {
      return item.skillName !== undefined ? item.skillName : item;
    }) ?? [];
  };

export const findExperienceByValue = (experiences: Experience[], value: number): Experience | undefined => {
    return experiences?.find(experience => experience?.experienceValue?.toString() === value?.toString());
  };

export const findJobTypeByValue = (JobTypeList: JobType[], value: string): JobType | undefined => {
    return JobTypeList?.find(JobTypeList => JobTypeList?.jobType?.toString() === value?.toString());
};

// Helper function to normalize the degree names
const normalizeDegreeName = (degreeName: string): string => {
  // Convert to lower case and remove extra words or characters
  const standardizedName = degreeName
    ?.toLowerCase()
    ?.replace(/bachelor.*degree/, 'bachelors degree')
    ?.replace(/master.*degree/, 'masters degree')
    ?.replace(/doctor.*degree/, 'doctoral degree')
    ?.replace(/high.*degree/, 'high schooll')
    ?.replace(/\s+in\s+.*/i, '') // remove "in" and anything following it
    ?.trim();
  return standardizedName;
};

export const findEducationByValue = (EducationTypeList: EducationType[], value: string): EducationType | undefined => {
    const normalizedValue = normalizeDegreeName(value);
    return EducationTypeList?.find(education => normalizeDegreeName(education?.degreeName) === normalizedValue);
  };


  export interface IndustryObject {
    id: string;
    industryName: string;
  }
  // Function to find the first matched industry
export const findMatchingIndustry = (inputIndustry: string, allIndustries: IndustryObject[]): IndustryObject | undefined => {
    if (!allIndustries) {
      return undefined;
    }
  
    return allIndustries.find(industry =>
      industry.industryName.toLowerCase().includes(inputIndustry.toLowerCase())
    );
  };
  
  // Separate function to handle the promise and fetch the industries
  export const fetchAndFindMatchingIndustry = async (inputIndustry: string): Promise<IndustryObject | undefined> => {
    const allIndustriesResponse = await GetAllIndustries(inputIndustry);
    const allIndustries = allIndustriesResponse?.data?.entityList || [];
    
    return findMatchingIndustry(inputIndustry, allIndustries);
  };
  
 