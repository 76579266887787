import { FormControl, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useFormik } from "formik";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";
import * as Yup from "yup";
import { SendCode, VerifyCode } from "../../../Services/PhoneVarifyService";
import formatPhone from "../../../Shared/utils/formatPhone";
import Call from "../../../assets/images/call.svg";
import Sms from "../../../assets/images/sms.svg";
import { CustomAlert } from "../../ui/CustomAlert";
import { ErrorMessage } from "../../ui/ErrorMessage";
import "./PhoneVerify.scss";
import { valueOrDefault } from "../../../utils/complexityUtils";


export default function PhoneVerify(props) {
  const [opendialog, setOpendialog] = useState(false);

  const props_phVerifyPopup = {
    ...props,
    ...{
      isOpen: opendialog,
      setClose: setOpendialog,
    },
  };

  return (
    <>
      <Box className="phone-verify-btn" sx={{ display: "inline-block" }}>
        <Button
          data-testid={props?.testId ? props?.testId : "verifyPhone"}
          size="small"
          onClick={() => {
            setOpendialog(true);
          }}
          variant="contained"
          style={{ padding: "3px 6px" }}
        >
          Verify Phone
        </Button>
      </Box>

      <PhoneVerifyPopup {...props_phVerifyPopup}></PhoneVerifyPopup>
    </>
  );
}

export function VerifyCodePopup(props) {
  const validateionSchema = Yup.object({
    code: Yup.string().required("Please enter your verification code"),
  });
  let [isCodeValid, setisCodeValid] = useState(0);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validateionSchema,
    onSubmit: (values) => {
      VerifyCode(props.phoneNumber, values.code)
        .then((r) => {
          if (r.data.status == 200) {
            setisCodeValid(1);
            props.updatePhoneVerify(true);
            setTimeout(() => {
              props.setClose(false);
              setisCodeValid(0);
              formik.setFieldValue("code", "");
              formik.setFieldTouched("code", false);
            }, 2000);
          } else {
            setisCodeValid(-1);
            console.log("api returned unsuccessful");
          }
        })
        .catch(() => {
          setisCodeValid(-1);
          setTimeout(() => {
            setisCodeValid(0);
          }, 2000);
          formik.setSubmitting(false);
        });
    },
  });

  const getCustomAlert = (codeValid)=> {
    let alertMessage = "";
    let alertType = "info";
  
    if (codeValid === 1) {
      alertMessage = "phone successfully verified";
      alertType = "success";
    } else if (codeValid === -1) {
      alertMessage = "Invalid verification code";
      alertType = "error";
    }
  
    return alertMessage && <CustomAlert message={alertMessage} type={alertType} onClose={()=>{ setisCodeValid(0)}}/>;
  };

  const handleOtpChange = (newValue) => {
    formik.setFieldValue("code", newValue);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={props.isOpen}
      onClose={() => {
        props.setClose(false);
      }}
      aria-labelledby="pop-up-title"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "15px 15px 0 15px",
        }}
      >
        <IconButton className="custom-crose-right" edge="end" color="inherit" aria-label="close">
          <CloseIcon
            onClick={() => {
              props.setClose(false);
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className="custom-popup-txt">Enter OTP </Typography>
        <Typography style={{ paddingTop: "6px" }} className="custom-popup-txt1">
          Please enter the 6 digit code sent to your phone
        </Typography>
        <form style={{ marginTop: "-20px" }} onSubmit={formik.handleSubmit}>
          <div className="custom-call-txt">
            <FormControl className="custom-otp-input" variant="standard" required margin="normal">
              <MuiOtpInput
                className="custom-otp-gap"
                id="code"
                name="code"
                type="text"
                variant="standard"
                value={formik.values.code}
                onChange={handleOtpChange}
                length={6}
                data-testId="muiOTPinput"
                autoFocus
              />
              <ErrorMessage
                errorText={formik.touched.code && formik.errors.code}
              />
            </FormControl>
          </div>
          <Grid
            style={{ maxWidth: "100%", paddingBottom: "10px" }}
            item
            xs={1}
            textAlign={"center"}
          >
            {getCustomAlert(isCodeValid)}
          </Grid>
          <Typography className="custom-popup-txt2">
            <Button
              type="submit"
              variant="contained"
              className="custom-submit-otp"
            >
              Submit OTP
            </Button>
          </Typography>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export function PhoneVerifyPopup(props) {
  const [isVerifyCodeOpen, setIsVerifyCodeOpen] = useState(false);
  const props_verifyCodePopup = {
    ...props,
    ...{
      isOpen: isVerifyCodeOpen,
      setClose: setIsVerifyCodeOpen,
    },
  };
  let [isCodeValid, setisCodeValid] = useState(0);
  const[errrorMsg, setErrorMsg] = useState("")
  const onBtnClick = (method, phoneNumber) => {
    SendCode(phoneNumber, method)
      ?.then((response) => {
        if (response.data.status == 200) {
          setIsVerifyCodeOpen(true);
        } else {
          console.log("api returned unsuccessful");
          setErrorMsg(response?.data?.entity)
          setisCodeValid(-2);
        }
      })
      .catch((err) => {
        setisCodeValid(-1);
        setTimeout(() => {
          setisCodeValid(0);
        }, 2000);
      });
  };
  const props_buttons = { onClick: onBtnClick, phoneNumber: props.phoneNumber };

  const getVerificationAlert = (codeValid)=> {
    if (codeValid === 1) {
      return <CustomAlert message={"code sent. please check the phone"} />;
    } else if (codeValid === -1) {
      return (
        <CustomAlert
          message={"could't send. Please check the number and retry."}
          type="error"
          onClose={()=>{ setisCodeValid(0)}}
        />
      );
    }else if (codeValid === -2) {
      return (
        <CustomAlert
          message={errrorMsg}
          type="error"
          onClose={()=>{ setisCodeValid(0)}}
        />
      );
    }
  
    return null;
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={() => {
          props.setClose(false);
        }}
        aria-labelledby="pop-up-title"
        className="custom-client-popup"
      >
        <DialogTitle
          align="center"
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="phn-dailog-padding"
        >
          <IconButton edge="end" color="inherit" aria-label="close">
            <CloseIcon
              data-testid="icon-button"
              onClick={() => {
                props.setClose(false);
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} columns={1}>
            <Grid item xs={1}>
              <Typography className="custom-popup-txt">
                Verify Phone Number
              </Typography>
            </Grid>
            <Grid style={{ paddingTop: "0px", marginTop: "-22px" }} item xs={1}>
              <Typography className="custom-popup-txt1 custom-verify-mobile">
                Please verify your mobile number{" "}
                <span style={{ fontWeight: 700 }}>
                  {formatPhone(props.phoneNumber)}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              className="custom-call-txt custom-margin-call"
            >
              <PhoneCallRow {...props_buttons}></PhoneCallRow>
              <PhoneSMSRow {...props_buttons}></PhoneSMSRow>
            </Grid>
            <Grid style={{ marginTop: "10px" }} item xs={1}>
              {getVerificationAlert(isCodeValid)}
            </Grid>
            <Grid
              style={{ marginTop: "10px", marginBottom: "6px" }}
              item
              xs={1}
            >
              <Typography className="custom-popup-txt2">
                <Link
                  data-testid="phnnumber"
                  onClick={() => {
                    props.setClose(false);
                  }}
                >
                  Change Phone Number
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <VerifyCodePopup {...props_verifyCodePopup}></VerifyCodePopup>
    </div>
  );
}

export function PhoneCallRow(props) {
  return (
    <Grid
      style={{ marginLeft: "26px" }}
      container
      spacing={1}
      columns={2}
      sx={{ verticalAlign: "center" }}
    >
      <Grid item xs={1} sx={{ textAlign: "right" }}>
        <Button
          className="custom-call-txt-btn custom-margin-callbtn"
          color="primary"
          onClick={() => {
            props.onClick("call", props.phoneNumber);
          }}
          variant="contained"
        >
          <img
            src={Call}
            width="24px"
            height="24px"
            style={{ marginRight: "16px" }}
          />
          Call
        </Button>
      </Grid>
    </Grid>
  );
}

export function PhoneSMSRow(props) {
  return (
    <Grid
      style={{ marginRight: "105px" }}
      container
      spacing={1}
      columns={2}
      sx={{ verticalAlign: "center" }}
    >
      <Grid item xs={1} sx={{ textAlign: "right" }}>
        <Button
          data-testid="textOTPBtn"
          className="custom-call-txt-btn"
          color="primary"
          onClick={() => {
            props.onClick("sms", props.phoneNumber);
          }}
          variant="contained"
        >
          <img
            src={Sms}
            width="24px"
            height="24px"
            style={{ marginRight: "16px" }}
          />
          Text
        </Button>
      </Grid>
    </Grid>
  );
}

export function VerifyNumberSelector(props) {
  props.selectedValue(props?.selectedNumber);

  return (
    <>
      <Dialog
        open={props?.isOpen}
        onClose={() => {
          props.setClose(false);
        }}
        className={"phone-verify-dialog"}
        maxWidth={"600px"}
      >
        <DialogTitle align="center" sx={{ bgcolor: "#E6E9FB" }}>
          {valueOrDefault(props?.popupTitle, "")}
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#E6E9FB" }}>
          <Grid container spacing={1} direction="column" alignItems="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ minWidth: "60%" }}
            >
              <FormControl fullWidth>
                <RadioGroup
                  fullWidth
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e, value) => {
                    props.selectedValue(value);
                  }}
                  defaultValue={props?.selectedNumber}
                  data-testid="changeBtnNumberSelector"
                >
                  <Grid
                    container
                    fullWidth
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item xs={6}>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{ display: "block" }}
                      >
                        Primary
                      </FormLabel>
                      <FormControlLabel
                        value={props?.phoneNumbers?.primary}
                        control={<Radio />}
                        label={formatPhone(props?.phoneNumbers?.primary)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{ display: "block" }}
                      >
                        Secondary
                      </FormLabel>
                      <FormControlLabel
                        value={props?.phoneNumbers?.secondary}
                        control={<Radio />}
                        label={formatPhone(props?.phoneNumbers?.secondary)}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  props?.isProceedBtnClick();
                }}
                variant="contained"
                style={{ marginRight: "15px" }}
                data-testid="verifyBtnNumberSelector"
              >
                Verify
              </Button>

              <Button
                onClick={() => {
                  props.setIsNumberSelectorOpen(false);
                }}
                variant="outlined"
                data-testid="cancelBtnNumberSelector"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
