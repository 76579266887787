import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface AIModelOption {
  isDefault: boolean;
  id: string;
  label: string;
}

interface props{
    handleOnChange:(value: string)=>void;
    required?:boolean;
}

// Define the array of AIModelOption
const items: AIModelOption[] = [
  {
    isDefault: true,
    id: "mixtral-8x7b",
    label: "OpenRouter:Mixtral 8x7B"
  },
  {
    isDefault: false,
    id: "mixtral-8x22b-instruct",
    label: "OpenRouter:Mixtral 8x22B"
  },
  {
    isDefault: false,
    id: "openai_openrouter",
    label: "OpenRouter:OpenAI"
  },
  {
    isDefault: false,
    id: "claude-haiku",
    label: "OpenRouter:Claude Haiku"
  },
  {
    isDefault: false,
    id: "gemini-flash",
    label: "OpenRouter: Gemini-Flash"
  }
];

const AIModelSelect: React.FC<props> = ({
    handleOnChange,
    required=false
}) => {
  // Find the default key
  const defaultKey = items.find(item => item.isDefault)?.id || "";

  // State to manage selected item
  const [selectedKey, setSelectedKey] = React.useState(defaultKey);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedKey(event.target.value as string);
    handleOnChange(event.target.value as string)
  };

  return (
    <FormControl fullWidth>
      <InputLabel 
        id="model-select-label" 
        shrink={true} 
        className='AIselctIPLabel'
        required={required}
        >
          Select Model
        </InputLabel>
      <Select
        labelId="model-select-label"
        value={selectedKey}
        onChange={handleChange}
        variant='standard'
      >
        {items.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AIModelSelect;
