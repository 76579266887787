import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { ClientSignupByRole } from "../../../Services/clientService";
import React from "react";
import { CustomAlert } from "../../ui/CustomAlert";
import { getNevLink } from "../../../utils/getFileFromUrl";
import { ClientSignupByRoleModel } from "../../../models/ClientSignupByRoleModel";
import { AddClientForm } from "./AddClientForm";
import "./AddClient.scss";
import { Divider } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { companyNameRegex, urlRegExp } from "../../../constants/regex";
import AddClientSuccessPopup from "./AddClientSuccessPopup";

export default function AddClient() {
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
    message: "",
    key: "",
  });

  const initialValues: ClientSignupByRoleModel = {
    email: "",
    companyName: "",
    companyWebsite: "",
    redirectUrl: "",
  };

  const [uniQue, setuniQue] = React.useState(Date.now());

  let addClientBreadCrumb: BreadCrumbItem[] = [
    {
      isLeaf: false,
      sequence: 1,
      title: "Dashboard",
      to: getNevLink("/dashboard"),
    },
    {
      isLeaf: false,
      sequence: 2,
      title: "Clients",
      to: getNevLink("/admin/clients"),
    },
    { isLeaf: true, sequence: 3, title: "Add Client" },
  ];

  const AddClientSchema = Yup.object({
    companyName: Yup.string()
      .required("Please enter company name")
      .matches(companyNameRegex, "Company name contains invalid characters")
      .max(100, "Company name must be at most ${max} characters"), // eslint-disable-line no-template-curly-in-string
    companyWebsite: Yup.string()
      .required("Please enter company website")
      .matches(urlRegExp, "Please enter valid URL starting with http or https"),
    email: Yup.string()
      .email("Entered email address is invalid")
      .required("Please enter a valid email address"),
    redirectUrl: Yup.string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: AddClientSchema,
    onSubmit: (values, actions) => {
      values.redirectUrl = `${window.location.origin}/ClientSignup`;
      onSubmit(values);
    },
  });

  const onSubmit = async (values: ClientSignupByRoleModel) => {
    setPageStatus({
      ...pageStatus,
      loading: true,
    });
    try {
      const response = await ClientSignupByRole(values);
      if (response.data.status == 200) {
        setPageStatus({
          isSuccess: true,
          error: "",
          loading: false,
          message: "Client Added Successfully",
          key: Date.now().toString(),
        });
        setuniQue(Date.now());
      } else {
        setPageStatus({
          isSuccess: false,
          error: response.data?.message?.appStatusDescription,
          loading: false,
          message: "",
          key: Date.now().toString(),
        });
      }
    } catch (er: unknown) {
      setPageStatus({
        isSuccess: false,
        error: (er as Error)?.message,
        loading: false,
        message: "",
        key: Date.now().toString(),
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={addClientBreadCrumb}
          ></AppBreadCrumb>
        </Grid>
        <Grid item xs={12} mt={-1}>
          <Typography className="Client-txt-fontwt">
            Company Information
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12} mt={-2}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiFormControl-root": {
                  marginTop: "15px",
                  marginBottom: "3px",
                },
              }}
            >
              <AddClientForm
                formik={formik}
                pageStatus={pageStatus}
                key={uniQue}
              />

              {pageStatus.error && (
                <CustomAlert
                  message={pageStatus.error}
                  type={"error"}
                  key={pageStatus.key}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {pageStatus.isSuccess && (
        <AddClientSuccessPopup
          pageStatus={pageStatus}
          setPageStatus={setPageStatus}
          formik={formik}
        />
      )}
    </>
  );
}
