import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';
import addClintIcon from "./../../../../assets/images/addUserIcon.svg";
import approveUserIcon from "./../../../../assets/images/approvedUserIcon.svg";
import earnMoneyGraphIcon from "./../../../../assets/images/earnMoneyGraphIcon.svg";
import roadMapIcon from "./../../../../assets/images/roadMap.svg";
import validateUserIcon from "./../../../../assets/images/validateUserIcon.svg";


const useStyles = makeStyles((theme) => ({
  paperContainer: {
    background: `url(${roadMapIcon}) no-repeat left center`,
    backgroundSize: 'auto',
  }
}));

const ClientSignUpRoadmapFlow: React.FC = () => {

  const classes = useStyles();

  const _renderRoadMapFlow = ()=>{
    
    const commonBottomMargin = "30px";
    const signUpStepGuidLine = [
      {
        id:1,
        title:"Complete Sign Up",
        description:"Begin your journey by filling out the form to complete your client profile. This step is designed to gather the essential information we need to assist you in your hiring needs. Sign the contracts digitally. ",
        icon:addClintIcon,
        bottomSpace :commonBottomMargin
      },
      {
        id:2,
        title:"Get Validated",
        description:"Our dedicated Admin Support Team is here to assist you. They will contact you to guide you through the onboarding formalities and ensure that your profile is accurate and ready for action. ",
        icon:validateUserIcon,
        bottomSpace :commonBottomMargin
      },
      {
        id:3,
        title:"Get Approved",
        description:"Once your onboarding process is complete on the JobRialto Marketplace, you will receive a notification confirming your approval. This means you are now ready to unlock the full potential of our platform. ",
        icon:approveUserIcon,
        bottomSpace :commonBottomMargin
      },
      {
        id:4,
        title:"Find the Best Candidates in Record Time",
        description:"Once approved, you gain access to a large pool of quality candidates and independent recruiters. Now, you can tap into our extensive network of recruiters to swiftly find the best candidates for your roles. Our streamlined process ensures that you can identify and secure top talent in the shortest span of time. ",
        icon:earnMoneyGraphIcon,
        bottomSpace :commonBottomMargin
      }
    ]
    
    return (<>
    {
      signUpStepGuidLine?.map( (data, index)=>{      
      return (
        <Grid container spacing={2} mb={3} key={data?.id ?? index} data-testid="roadmap-step">
          <Grid item style={{width:"80px"}}>
              <img src={data?.icon} className='clientUserRoadmap' data-testid="roadmap-icon"/>
          </Grid>
          <Grid item xs>
            <Typography className='customTypographyHeading'>
                {data?.title}
            </Typography>
            <Typography className='customTypographyHeadingSubTitle'>
                {data?.description}
            </Typography>         
          </Grid>
        </Grid>
      )
      })
    }
    </>)
  }

  return (
    <React.Fragment>
      <Box>
        <Paper className={classes.paperContainer} elevation={0}>        
          {_renderRoadMapFlow()}
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default ClientSignUpRoadmapFlow;






