import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearExpiringJobs } from "../../features/dashboard/recruiterDashboardSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface ExpiringJobProp {
  type: string;
}

export const ExpiringJob: React.FunctionComponent<ExpiringJobProp> = (
  props
) => {

  const dispatch = useDispatch<any>();
  const expiringJobs = useSelector(
    (state: any) => state.recruiterDashboard.expiringJobs
  );
  const getDate = (date: string) => {
    return Moment(new Date(date)).format("MM/DD/YYYY").toString();
  };
  const getExpiringInDays = (expiryDate: string) => {
    let nowDate = Moment(Date.now());
    let jobExpiryDate = Moment(new Date(expiryDate));
    return jobExpiryDate.diff(nowDate, "days") + 1;
  };
  const onClose = () => {
    dispatch(clearExpiringJobs());
  };

  const exprire_sort = (a: any, b: any) => {
    return new Date(a.jobEndDate).getTime() - new Date(b.jobEndDate).getTime();
  };

  return (
    <Dialog
      className="custom-popup-border"
      onClose={() => {}}
      open={(expiringJobs?.length !== 0)}
      PaperProps={{
        style: {
          background: "#FFF",
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ marginRight: "20px", marginLeft: "20px" }}>
        <IconButton
          data-testid="close"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText component="div" id="success-dialog-description">
          <Typography
            className="managefeatured-popup"
            my={1}
            textAlign="center"
          >
            {props.type == "Admin" ? "Manage Featured Jobs" : "Saved Jobs"}
          </Typography>
          <Typography textAlign="center" className="actionrequired-txt">
            Action Required
          </Typography>
          <Typography component="div" my={1} className="savedjobpopup-desc">
            {props.type == "Admin"
              ? "The featured jobs require atleast 4 selected jobs marked as featured. As an Admin, your action is required to change the below jobs and add new in the featured jobs category."
              : "Your Saved Jobs are expring soon, take action now if you are still interested in the below jobs."}
          </Typography>
          <Box my={3} sx={{ maxHeight: "200px", overflowY: "auto" }}>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  {[...expiringJobs]?.sort(exprire_sort)?.map((item: any, index:number) => (
                    <StyledTableRow className="custom-table-border" key={item.id ?? index}>
                      <StyledTableCell className="custom-tablecell-border" component="th" scope="row">
                        <Typography className="custom-expiringjobs-table">
                          <Link
                            to={`/jobdetail/${item.id}/jobs`}
                            style={{ textDecoration: "none" }}
                          >
                            {item.jobTitle} (Job ID: {item.id})
                          </Link>
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell className="custom-tablecell-border" component="th" scope="row">
                        <Typography className="custom-expiringjobs-tablebody">
                          Expiring in {getExpiringInDays(item.jobEndDate)} Days
                          ({getDate(item.jobEndDate)})
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
