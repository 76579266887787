import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../../data/permission";
import { useGetClientsMutation } from "../../../features/Client/clientSlice";
import { SortType } from "../../../models/app/AppModels";
import {
  ClientFilter,
  clientFilterDefault,
} from "../../../models/client/ClientModels";
import { AppState } from "../../../store/AppState";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import { getNevLink } from "../../../utils/getFileFromUrl";
import truncateString from "../../../utils/truncateString";
import AppSort from "../../Common/AppSort";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import { PhoneNumber } from "../../ui/PhoneNumber";
import "./client.scss";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import GenericPagination from "../../Common/Table/GenericPagination";
import PleaseWait from "../../Common/Loder/PleaseWait";
import NoRecordsFound from "../../Common/Table/NoRecordsFound";

interface ClientListingTableProps {
  setTotalRecords: any;
  totalRecords:number;
}

export default function ClientListingTable({
  setTotalRecords,
  totalRecords
}:ClientListingTableProps) {
  const { industries, locations, statuses, searchKeyword } = useSelector(
    (state: AppState) => state.clientReducer.filter
  );
  const isMounted = useRef(false);
  const [sort, setSort] = useState(true);
  const navigate = useNavigate();
  const [clientService, clientResult] = useGetClientsMutation();

  const getCity = (description: any) => {
    let city = getAddressForAutoComplete(description).city;
    return city;
  };

  const [paging, setPaging] = useState<ClientFilter>({
    ...clientFilterDefault,
    locations: locations.map((n) => {
      return getCity(n?.description);
    }),
    industries: industries.map((n) => n.id),
    statuses: statuses.map((n) => n.value),
    searchKeyword: searchKeyword,
  });
  const [clients, setClients] = useState<any[]>([]);

  // Pagination state
const [rowsPerPage] = React.useState(paging?.pageSize);

const handleChangePage = (event: unknown, newPage: number) => {    
  handlePaginationIndex(newPage);
};

  React.useEffect(() => {
    clientService(paging);
  }, [paging]);

  React.useLayoutEffect(() => {
    if (isMounted.current) {
      setPaging({
        ...paging,
        pageIndex: 1,
        locations: locations.map((n) => {
          return getCity(n?.description);
        }),
        industries: industries.map((n) => n.id),
        statuses: statuses.map((n) => n.value),
        searchKeyword: searchKeyword,
      });
    } else {
      isMounted.current = true;
    }
  }, [locations, industries, statuses, searchKeyword]);

  React.useEffect(() => {
    setClients(clientResult.data?.entityList ?? []);
  }, [clientResult.data?.entityList]);

  React.useEffect(() => {
    if (clientResult.data?.totalRecords !== undefined) {
      setTotalRecords(clientResult.data?.totalRecords);
    }
  }, [clientResult.data?.totalRecords]);


  const handlePaginationIndex = (index:number) => {
    setPaging({
      ...paging,
      pageIndex: index,
    });
    
  };

  const handleSortClicked = (sortColumnName: string) => {
    setSort(!sort);
    setPaging({
      ...paging,
      sortColumn: sortColumnName,
      isSorted: true,
      sortOrder:
        paging.sortOrder === SortType.Ascending
          ? SortType.Descending
          : SortType.Ascending,
      pageIndex: 1,
      locations: locations,
      industries: industries?.map((n) => n.id),
      statuses: statuses?.map((n) => n.value),
      searchKeyword: searchKeyword,
    });
  };

  const getComment = (comments?: any[]) => {
    if (comments) {
      return comments?.slice(-1)[0]?.comment ?? "N/A";
    }
    return "N/A";
  };

  function getStatusChipClassName(status: any) : string {
    if (status === "Approved") return "Approved";
    else if (status === "Pending") return "Pending";
    else if (status === "Rejected") return "Rejected";
    else if (status === "Suspended") return "Suspended";
    else if (status === "Incomplete") return "Incomplete";
    else return "";
  }

  return (
    <div>
      <Grid
        container
        item
        xs={12}
        md={12}
        spacing={2}
        className="Client-listingTable-wrapper"
        style={{ marginTop: "10px" }}
      >
        <TableContainer component={Paper} className="Client-table">
          <GenericPagination
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={paging?.pageIndex}
            onPageChange={handleChangePage}
          /> 
            <Table
              className="AppTable"
              aria-label="simple table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 4px",
                backgroundColor: "#fafaff",
                borderTop: "1px solid #D2D2F1",
              }}
            >
              <TableHead className="custom-Client-fontsizes">
                <TableRow>
                  <TableCell
                    data-testid="name-sort"
                    className="minWidthROw"
                    onClick={() => handleSortClicked("companyname")}
                  >
                    <Grid container>
                      <Grid className="custom-Client-fontsizes pointer">
                        Name
                      </Grid>
                      <Grid>
                        <AppSort
                          sortType={
                            sort ? SortType.Ascending : SortType.Descending
                          }
                          disable={paging.sortColumn !== "companyname"}
                        ></AppSort>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ px: 1 }} className="minWidthROw">
                    Email
                  </TableCell>
                  <TableCell sx={{ px: 1 }} className="minWidthROw">
                    Phone
                  </TableCell>
                  <TableCell className="minWidthROw rec-Location-width">
                    Location
                  </TableCell>
                  <TableCell
                    data-testid="date-sort"
                    className="minWidthROw"
                    onClick={() => handleSortClicked("createdDate")}
                  >
                    <Grid container>
                      <Grid className="custom-Client-fontsizes pointer">
                        Date
                      </Grid>
                      <Grid>
                        <AppSort
                          sortType={
                            sort ? SortType.Ascending : SortType.Descending
                          }
                          disable={paging.sortColumn !== "createdDate"}
                        ></AppSort>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className="minWidthROw">Feedback</TableCell>
                  <TableCell className="minWidthROw">Status</TableCell>
                  <TableCell className="minWidthROw1">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {clients.map((row: any, index: number) => {
                    return (
                      <TableRow
                        className="custom-hover-bgcolor"
                        hover
                        key={row?.id ?? index}
                        style={{ padding: 20, backgroundColor: "white" }}
                      >
                        <TableCell
                          align="left"
                          width={"13.1%"}
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          {valueOrDefault(row?.companyName, "N/A")}
                        </TableCell>
                        
                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <Tooltip title={row?.email}>
                            <div>
                              {row?.email}{" "}
                              {row?.isEmailVerified && (
                                <CheckCircleRoundedIcon
                                  sx={{
                                    marginLeft: "2px",
                                    color: "#26C296",
                                    fontSize: "14px",
                                  }}
                                />
                              )}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                          align="left"
                        >
                          <div className="phNumber">
                            <PhoneNumber
                              number={
                                row?.contactDetails?.primaryPhoneNumber ?? "N/A"
                              }
                            />
                            {row?.contactDetails?.isPrimaryPhoneVerified && (
                              <CheckCircleRoundedIcon
                                sx={{
                                  marginLeft: "2px",
                                  color: "#26C296",
                                  fontSize: "14px",
                                }}
                              />
                            )}
                          </div>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                          align="left"
                        >
                          {row?.address?.city + ", " + row?.address?.state}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          {convertToLocalizedDateTime(
                            row?.completeSignupDate,
                            "MMMM DD, YYYY,  h:mm A"
                          )}
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "pre-wrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <Tooltip title={getComment(row?.comments)}>
                            <div>
                              {truncateString(getComment(row?.comments), 20)}
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <Chip
                            label={row.status}
                            className={`statusChip custom-client-status ${getStatusChipClassName(row?.status)}`}
                          />
                        </TableCell>

                        <TableCell
                          className="edit-verify-clr"
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "12px !important",
                            border: 0,
                          }}
                        >
                          <ModuleComponent
                            moduleId={PermissionsEnums.CLUserEdit}
                          >
                            <Tooltip title={"Edit"} placement="top">
                              <EditOutlinedIcon
                                data-testid="pointer"
                                style={{ cursor: "pointer", paddingRight: 10 }}
                                onClick={() =>
                                  navigate(
                                    getNevLink(`/client/edit/${row?.id}/edit`)
                                  )
                                }
                              />
                            </Tooltip>
                          </ModuleComponent>
                          <ModuleComponent
                            moduleId={PermissionsEnums.CLUserVerify}
                          >
                            <Tooltip title={"Verify"} placement="top">
                              <TaskAltOutlinedIcon
                                className={ternary(
                                  row?.isVerifyAllowed,
                                  "allowedLink",
                                  "notAllowedLink"
                                )}
                                onClick={() => {
                                  row?.isVerifyAllowed &&
                                    navigate(
                                      getNevLink(
                                        `/client/edit/${row?.id}/verify`
                                      )
                                    );
                                }}
                                data-testid="navigate"
                              />
                            </Tooltip>
                          </ModuleComponent>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              </TableBody>
            </Table>
            {clientResult.isLoading && <PleaseWait />}
            {!clientResult.isLoading && clientResult.data?.totalRecords === 0 && <NoRecordsFound /> }
        </TableContainer>
      </Grid>
    </div>
  );
}


