import { PermissionsEnums } from "../../data/permission";
import SavedJobPage from "../Jobs/JobListing/SavedJobPage";
import ManageProfile from "../ManageProfile/ManageProfile";
import EditRecruiters from "../Recruiters/EditRecruiter/EditRecruiters";
import AddRecruiters from "../Recruiters/AddRecruiters";
import SubmitResume from "../Jobs/Submission/SubmitResume";
import CandidateViewDetails from "../Candidate/CandidateViewDetails";
import SubmissionFilter from "./../SubmissionFilter/SubmissionFilter";
import PendingSubmission from "../PendingSubmission/PendingSubmissionList";
import ViewSubmissions from "../ViewSubmissions/ViewSubmissions";
import { MainDashboard } from "../Dashboard/MainDashboard";
import CandidateOfferDetails from "../Candidate/SubmissionDetails/CandidateOfferDetails";
import NotificationSettings from "../NotificationSettings/NotificationSettings";
import Contracts from "../Contracts/Contracts";
import ContractDetail from "../Contracts/ContractDetail";
import RecommendedJob from "../Jobs/RecommendedJob/RecommendedJob";
import { NewSubmissionPage } from "../ViewSubmissions/NewViewSubmission/NewSubmissionPage";
import { Earnings } from "../MyEarnings/Earnings";
import Submissions from "../ViewSubmissions/Submissions/Submissions";
import CandidateListPage from "../pages/Candidate/CandidateListPage";
import FindJob from "../Jobs/FindJob/FindJob";
import FindCandidate from "../Jobs/FindCandidate/FindCandidate";
import Faq from "../Faq/Faq";

//for other recruiterRoutes in  project where user must be logged in and recruiter role to view the page
const recruiterRoutes = [
  {
    path: "/faq",
    element: <Faq />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId: PermissionsEnums.FAQ,
  },
  {
    path: "/submission/active",
    element: <Submissions />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/submission/archived",
    element: <Submissions />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/manage-profile",
    element: <ManageProfile />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId: PermissionsEnums.Earnings_View
  },

  {
    path: "/savedJobs/:page",
    element: <SavedJobPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Jobs_Saved
  },
  {
    path: "/edit-recruiter/:id",
    element: <EditRecruiters />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/add",
    element: <AddRecruiters />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Recruiter_Add
  },
  {
    path: "/dashboard",
    element: <MainDashboard />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },



  {
    //fromPage required to show breadcrumbs

    path: "/submitresume/:id/:fromPage",
    element: <SubmitResume />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,

  },
  {
    path: "/submitresume/:id",
    element: <SubmitResume />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
    permissionId : PermissionsEnums.Submit_Resume

  },
  {
    path: "/manage-profile",
    element: <ManageProfile />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Manage_Profile
  },
  {
    path: "/submissionfilter",
    element: <SubmissionFilter />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/PendingSubmission",
    element: <PendingSubmission />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/submissions/viewsubmission/:id/:fromPage/",
    element: <ViewSubmissions />,
    exact: true,
    showHeader: true,
    showLeftNav: false,
    showFooter: false,
  },
  {
    path: '/candidates/submissiondetails/offerdetails/:submissionId/:pageId',
    element: <CandidateOfferDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/notificationsettings",
    element: <NotificationSettings />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Settings
  },
  {
    path: "/contracts",
    element: <Contracts />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Contracts_Views_View
  },
  {
    path: "/ContractDetail/:id",
    element: <ContractDetail />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: "/findjobs",
    element: <RecommendedJob />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Jobs_Find
  },  
  {
    path: '/oldsubmission/:id',
    element: <NewSubmissionPage/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false,
    permissionId : PermissionsEnums.Submissions_All



  },
  {
    path: '/oldsubmission/:id',
    element: <NewSubmissionPage/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false,
    permissionId : PermissionsEnums.Submissions_All



  },
  {
    path: '/submission',
    element: <NewSubmissionPage/>,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter : false,
    permissionId : PermissionsEnums.Submissions_All



  },
  {
    path: "/candidates",
    element: <CandidateListPage />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
    permissionId : PermissionsEnums.Candidates_View
  },
  {
    path: "/candidate-detail/:id",
    element: <CandidateViewDetails />,
    exact: true,
    showHeader: true,
    showLeftNav: true,
    showFooter: false,
  },
  {
    path: '/earnings',
    element: <Earnings />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Earnings_View
  },
  {
    path: '/findJobsExt',
    element: <FindJob />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Earnings_View
  },
  {
    path: '/findCandidate',
    element: <FindCandidate />,
    exact: true,
    showHeader: true,
    showLeftNav : true,
    showFooter : false,
    permissionId : PermissionsEnums.Earnings_View
  }
  
];

export const recruiterRoutePaths = [...recruiterRoutes.map(n=>n.path)];

export default recruiterRoutes;
