import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@material-ui/core";
import formatPhone from "../../../Shared/utils/formatPhone";
import './PhoneVerify.scss';

interface Props {
    isOpen: boolean;
    title: string;
    subTitle: string;
    phoneNumbers: {
        primary: string;
        secondary: string;
    };
    selectedNumber: string | undefined;
    selectedValue: (selectedVal: string) => void;
    handleVerify: () => void;
    handleClose: () => void;
}
const VerifyPhoneNumberSelectorPopup :React.FC<Props> = ({ 
    isOpen, 
    handleClose, 
    title, 
    subTitle, 
    handleVerify,
    phoneNumbers,
    selectedNumber,
    selectedValue 
}) => {

  return (
    <div>
      <Dialog
        className="custom-client-popup"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="pop-up-title"
      >
        <DialogTitle style={{ display: "flex", justifyContent: "flex-end", }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ textAlign: "center" }}>
          </Typography>
          <Typography className="titlePopupText">
            {title}
          </Typography>
            { subTitle && 
                <Typography className="subTitlePopupText">
                    {subTitle}
                </Typography>
                }
          <Typography className="selectNumberControl">
          <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e, value) => {
                    selectedValue(value);
                  }}
                  defaultValue={selectedNumber}
                  data-testid="changeBtnNumberSelector"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item >
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{ display: "block" }}
                        className="radioBtnLabelText"
                      >
                        Primary Number
                      </FormLabel>
                      <FormControlLabel
                        value={phoneNumbers?.primary}
                        control={<Radio />}
                        label={formatPhone(phoneNumbers?.primary)}
                      />
                    </Grid>
                    <Grid item >
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{ display: "block" }}
                        className="radioBtnLabelText"
                      >
                        Secondary Number
                      </FormLabel>
                      <FormControlLabel
                        value={phoneNumbers?.secondary}
                        control={<Radio />}
                        label={formatPhone(phoneNumbers?.secondary)}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
          </Typography>
          <Box
          style={{
            display:"flex",
            alignContent:"center",
            justifyContent:"center"
          }}
          >
          <Button
            type="submit"
            name="saveandcontinue"
            value="saveandcontinue"
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{marginRight:"15px"}}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            name="saveandcontinue"
            value="saveandcontinue"
            variant="contained"
            color="primary"
            onClick={handleVerify}
          >
            Verify 
          </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VerifyPhoneNumberSelectorPopup;
