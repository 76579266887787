import React from 'react';
import { Button, CircularProgress, Stack } from '@mui/material';
import { ModuleComponent } from '../../../../Common/Permission/ModuleComponent';
import { PermissionsEnums } from '../../../../../data/permission'; 
import { ternary } from '../../../../../utils/complexityUtils';

type Props = {
  isSubmitting: boolean;
  isValid: boolean;
  handleSubmit: any;
  activeStep: number;
  isGenerateJobDetailsPopup: boolean;
  setIsGenerateJobDetailsPopup: (value: boolean) => void;
  AIContentPreview: any;
  setAIContentPreview: (value: any) => void;
  handleBack: () => void;
  isLastStep: boolean;
  handlePreview: (value: boolean) => void;
  handleSave: () => void;
  handleUpdateButton: () => void;
  handlePublishButton: () => void;
  handleNext: () => void;
  loggedUserStatus: string;
  mode: string;
  pageStatus?: any;
  submittedMode:string;
};

const RenderStepActionButton: React.FC<Props> = ({
  isSubmitting,
  isValid,
  handleSubmit,
  activeStep,
  isGenerateJobDetailsPopup,
  setIsGenerateJobDetailsPopup,
  AIContentPreview,
  setAIContentPreview,
  handleBack,
  isLastStep,
  handlePreview,
  handleSave,
  handleUpdateButton,
  handlePublishButton,
  handleNext,
  loggedUserStatus,
  mode,
  pageStatus,
  submittedMode
}) => {
  const renderGenerateJobDetailsButtons = () => {
    if (!isGenerateJobDetailsPopup && activeStep === 0) {
      return (
        <>
          <Button
            onClick={() => setIsGenerateJobDetailsPopup(true)}
            size="medium"
            className="backBtn"
            variant="contained"
          >
            Generate Job Details
          </Button>
          {AIContentPreview?.content?.length > 0 && (
            <Button
              onClick={() =>
                setAIContentPreview({
                  ...AIContentPreview,
                  isOpen: true,
                })
              }
              size="medium"
              className="backBtn"
              variant="contained"
            >
              Preview AI Generated Details
            </Button>
          )}
        </>
      );
    }
    return null;
  };

  const renderBackButton = () => {
    if (activeStep !== 0) {
      return (
        <Button
          onClick={handleBack}
          size="medium"
          className="backBtn"
          variant="outlined"
          disabled={isSubmitting}
        >
          Back
        </Button>
      );
    }
    return null;
  };

  const renderSaveAndContinueButton = () => {
    if (mode === "ADD") {
      return (
        <ModuleComponent moduleId={PermissionsEnums.Jobs_Add_Save}>
          <Button
            data-testid="saveandcontinue"
            disabled={loggedUserStatus !== "Approved" || isSubmitting}
            endIcon={
              (isSubmitting && submittedMode === "SAVE") && (
                <CircularProgress size={"16px"} color={"inherit"} />
              )
            }
            variant="outlined"
            color="primary"
            size="medium"
            onClick={(event: any) => {
              event?.preventDefault();
              if (isValid) {
                handleSave();
              } else {
                handleSubmit();
              }
            }}
          >
            Save and Continue Later
          </Button>
        </ModuleComponent>
      );
    }
    return null;
  };

  const renderUpdateButton = () => {
    if (mode === "EDIT" || mode === "PENDING") {
      return (
        <ModuleComponent
          moduleId={
            mode === "PENDING"
              ? PermissionsEnums.Jobs_Pending_Publish_Save
              : ""
          }
        >
          <Button
            data-testid="update"
            size="medium"
            disabled={loggedUserStatus !== "Approved" || isSubmitting}
            endIcon={
              (isSubmitting && submittedMode === "UPDATE") && (
                <CircularProgress size={"16px"} color={"inherit"} />
              )
            }
            variant="contained"
            onClick={handleUpdateButton}
          >
            Update
          </Button>
        </ModuleComponent>
      );
    }
    return null;
  };

  const renderPublishButton = () => {
    if (mode === "ADD" || mode === "PENDING") {
      return (
        <ModuleComponent
          moduleId={
            mode === "PENDING"
              ? PermissionsEnums.Jobs_Pending_Publish_Publish
              : PermissionsEnums.Jobs_Add_Publish
          }
        >
          <Button
            data-testid="publish"
            disabled={loggedUserStatus !== "Approved" || isSubmitting}
            endIcon={
              (isSubmitting && submittedMode === "PUBLISH") && (
                <CircularProgress size={"16px"} color={"inherit"} />
              )
            }
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              if (isValid) {
                handlePublishButton();
              } else {
                handleSubmit();
              }
            }}
          >
            Publish
          </Button>
        </ModuleComponent>
      );
    }
    return null;
  };

  const renderPreviewButton = () => {
    if (isLastStep) {
      return (
        <>
          <Button
            disabled={isSubmitting}
            variant="outlined"
            size="medium"
            color="primary"
            data-testid="preview"
            onClick={() => handlePreview(true)}
          >
            Preview
          </Button>
          {renderSaveAndContinueButton()}
          {renderUpdateButton()}
          {renderPublishButton()}
        </>
      );
    }
    return (
      <div
        onClick={handleNext}
        className="custom-button"
        data-testid="NextButton"
      >
        <span
          color="primary"
          className={`customNextBtn ${ternary(
            pageStatus?.loading,
            "disabledCustomBtn",
            ""
          )}`}
        >
          Next
        </span>
      </div>
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      className="step-nav-button-stack-JobPosting"
    >
      {renderGenerateJobDetailsButtons()}
      {renderBackButton()}
      {renderPreviewButton()}
    </Stack>
  );
};

export default RenderStepActionButton;
