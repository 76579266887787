import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  clientFilter
} from "../../../Services/clientService";
import AppInfiniteScroll from "../../Common/AppInfiniteScroll";
import { DeleteIcon } from "../../Common/Icons/DeleteIcon";
import ConfirmClientDelete from "./ConfirmClientDelete";

interface IncompleteClientSignupProps {
  handleConfirmationClose: any;
  onClientDeleteSuccess: any;
  handleClose: any;
}

export const IncompleteClientSignup: React.FC<IncompleteClientSignupProps> = ({
  handleConfirmationClose,
  onClientDeleteSuccess,
  handleClose,
}) => {
  const initialRecruiterFilterState = {
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "modifiedDate",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    searchKeyword: "",
    industries: [],
    locations: [],
    statuses: [],
    showIncompleteSignUps: true,
  };
  const [loading, setLoading] = React.useState(false);
  const [hasMoreData, setHasMoreFlag] = React.useState(false);
  const [filter, setFilter] = React.useState<
    typeof initialRecruiterFilterState
  >(initialRecruiterFilterState);
  const [incompleteData, setIncompleteData]: any = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [item, setItem] = React.useState();

  useEffect(() => {
    getInCompleteClientDetails();
  }, [filter.pageIndex]);

  const getInCompleteClientDetails = () => {
    setLoading(true);

    clientFilter(filter)
      .then((response: any) => {
        if (response?.data.entityList.length < filter.pageSize) {
          setHasMoreFlag(false);
        } else {
          setHasMoreFlag(true);
        }

        setIncompleteData([...incompleteData, ...response?.data.entityList]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    setFilter({ ...filter, pageIndex: filter.pageIndex + 1 });
  };

  return (
    <div>
      <Dialog
        className="custom-popup-border"
        open={true}
        onClose={handleConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Paper elevation={5} style={{ backgroundColor: "#FFF" }}>
          <DialogTitle id="alert-dialog-title">
            <IconButton
              aria-label="close"
              onClick={handleConfirmationClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="center" className="custom-incomplete-txt">
                    Incomplete Signup
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1 }}>
                <TableContainer>
                  <AppInfiniteScroll
                    dataLength={incompleteData?.length}
                    next={fetchMoreData}
                    hasMore={hasMoreData}
                    isLoading={loading}
                    loader={true}
                    height="300px"
                  >
                    <Table sx={{ minWidth: 250 }} aria-label="simple table">
                      <TableHead className="custom-tablehead">
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell sx={{ minWidth: 170 }}>
                            Signed Up On
                          </TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="custom-tablebody">
                        {incompleteData?.map((row: any, index:number) => (
                          <TableRow
                          key={row?.createdDate ?? index}
                          >
                            <TableCell>{row?.companyName || "N/A"}</TableCell>
                            <TableCell>{row?.email}</TableCell>
                            <TableCell>
                              {moment(row?.createdDate).format(
                                "MMMM DD, YYYY  h:mm A"
                              )}
                            </TableCell>
                            <TableCell>
                              <DeleteIcon
                                data-testid="delete-icon"
                                style={{ cursor: "pointer", color: "#d32f2f" }}
                                onClick={() => {
                                  setOpenConfirmDelete(true);
                                  setItem(row);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {loading && (
                      <p
                        style={{
                          marginTop: "50px",
                          marginBottom: "4px",
                          textAlign: "center",
                        }}
                      >
                        Please wait...
                      </p>
                    )}
                    {!loading && incompleteData.length === 0 && (
                      <Alert severity="info" style={{ marginTop: "5px" }}>
                        No Records Found
                      </Alert>
                    )}
                  </AppInfiniteScroll>
                </TableContainer>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Paper>
      </Dialog>
      {openConfirmDelete && (
        <ConfirmClientDelete
          item={item}
          openPopup={openConfirmDelete}
          handleConfirmationClose={() => setOpenConfirmDelete(false)}
          handleClose={handleClose}
          onClientDeleteSuccess={onClientDeleteSuccess}
        />
      )}
    </div>
  );
};

export default IncompleteClientSignup;
