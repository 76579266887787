import Grid from "@mui/material/Grid";
import * as React from "react";
import ClientListingTable from "./ClientListingTable";
import ClientSearchbar from "./ClientSearchbar";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { getNevLink } from "../../../utils/getFileFromUrl";



export default function Clients() {

  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink("/dashboard") },
    { isLeaf: true, sequence: 2, title: "Clients", leafClasses:" v2LastLeft"},
  ];

  const [totalRecords, setTotalRecords] = React.useState<number>(0);

  return (
    <Grid container >
      <Grid item xs={12}>
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={DefaultBreadcrumbs}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <ClientSearchbar totalRecords={totalRecords}/>
      </Grid>
      <Grid item xs={12} data-testid="client-listing-table" >
        <ClientListingTable
          totalRecords={totalRecords}
          setTotalRecords={setTotalRecords}/>
        </Grid>
      </Grid>
  );
}
