import React, { useState } from "react";
import { Container, Box, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";

// Model
import { BreadCrumbItem } from "../../../../models/AppModels";

//Util functions or components
import AppBreadCrumb from "../../../Common/AppBreadCrumb";
import ManageClientForm from "../../../Client/ManageClient/ManageClientForm";
import ClientChangeTracker from "../../../Client/ManageClient/ClientChangeTracker/ClientChangeTracker";
import { getNevLink } from "../../../../utils/getFileFromUrl";

const ManageClientPage: React.FC = () => {

  const [reloadTracker, setReloadTracker] = useState<boolean>(false);
  const [selectedClientDetails, setSelectedClientDetails] = useState<any>([]);
  const DefaultBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    {
      isLeaf: true,
      sequence: 2,
      title: "Manage Client",
      leafClasses: " v2LastLeft",
    },
  ];

  const _onUpdateSuccess = () =>{
    setReloadTracker(true)
  }

  return (
    <>
      <Box className="PageWrapper">
        <Container maxWidth={false} disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={DefaultBreadCrumbs}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Manage Client form */}
              <ManageClientForm 
                mode="ADD"  
                onUpdateSuccess={_onUpdateSuccess}                
                setSelectedClient={setSelectedClientDetails}
              />
            </Grid>
            <Grid item xs={12} mb={8}>
              <Paper elevation={0}>
                <ClientChangeTracker 
                  reloadTracker={reloadTracker} 
                  setReloadTracker={setReloadTracker}
                  selectedClientDetails={selectedClientDetails}
                  />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ManageClientPage;
