import { SortType } from "../models/app/AppModels";

export const getRandomInt = () : number => {
  const crypto : Crypto = window.crypto || (window as any)['msCrypto'];
  return crypto.getRandomValues(new Uint32Array(1))[0];
};

export function sortByProperty(
  array: any[],
  property: keyof any | string,
  direction: SortType
): any[] {
  const sortedArray = [...array];

  sortedArray.sort((a, b) => {
    const propParts = (property as string).split(".");
    let aValue: any = a[propParts[0]] as any;
    let bValue: any = b[propParts[0]];

    for (let i = 1; i < propParts.length; i++) {
      aValue = aValue[propParts[i]];
      bValue = bValue[propParts[i]];
    }

    if (direction === SortType.Ascending) {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    } else {
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
      return 0;
    }
  });

  return sortedArray;
}