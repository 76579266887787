import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { JobPostingState } from "../../../models/client/Job/AddJob"; 
import { handleNullValue } from "../../../utils/StringUtil"; 
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import { PermissionsEnums } from "../../../data/permission"; 
import { ModuleComponent } from "../../Common/Permission/ModuleComponent"; 
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
interface PreviewJobPopupProps {
  job?: JobPostingState;
  display: boolean;
  onClose: () => void;
  onPublish?: () => void;
  onSave?: () => void;
  onUpdate?: () => void;
  clientLoginData?: any;
  mode?:"EDIT"|"PENDING"|"ADD"
}

const PreviewJobPopup: React.FunctionComponent<PreviewJobPopupProps> = ({
  display,
  onClose,
  job,
  onPublish,
  onSave,
  onUpdate,
  clientLoginData,
  mode
}) => {

  const renderButtons = () => {
    const commonButtonProps = {
      onClick: () => {
        onClose();
        if (onUpdate) onUpdate();
      },
      "data-testid": "previewjob-update",
    };
  
    const updateButton = (
      <Grid item key="update">
        <Button variant={mode === "PENDING" ? "outlined" : "contained"} {...commonButtonProps}>
          Update
        </Button>
      </Grid>
    );
  
    const saveButton = (
      <Grid item key="save">
        <ModuleComponent moduleId={PermissionsEnums.Jobs_Add_Save}>
          <Button variant="outlined" onClick={() => { onClose(); if (onSave) onSave(); }} data-testid="previewjob-save">
            Save & Continue Later
          </Button>
        </ModuleComponent>
      </Grid>
    );
  
    const publishButton = (
      <Grid item key="publish">
        <ModuleComponent moduleId={ternary(mode === "PENDING", PermissionsEnums.Jobs_Pending_Publish_Publish, PermissionsEnums.Jobs_Add_Publish)}>
          <Button variant="contained" onClick={() => { onClose(); if (onPublish) onPublish(); }} data-testid="previewjob-publish">
            Publish
          </Button>
        </ModuleComponent>
      </Grid>
    );

    let buttonsToShow;

    if (mode === "EDIT") {
      buttonsToShow = [updateButton];
    } else if (mode === "PENDING") {
      buttonsToShow = [updateButton, publishButton];
    } else {
      buttonsToShow = [saveButton, publishButton];
    }
  
    return buttonsToShow;
  };

  return (
    <>
      <Dialog
        open={display}
        onClose={onClose}
        className="client-job-preview"
        fullWidth
        maxWidth="lg"
        PaperProps={{ style: { backgroundColor: "#FFFFFF" } }}
      >
        <DialogContent dividers>
          <IconButton
            aria-label="Close Preview"
            onClick={onClose}
            className="preview-dialog-close-btn"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            data-testid="previewjob-close"
          >
            <CloseIcon />
          </IconButton>

          <Grid container pb={4} spacing={2}>
            <Grid item md={12} xs={12} pt={2}>
              <Typography
                className="client-text"
                style={{ fontSize: 24, fontWeight: 600 }}
              >
                Job Preview
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} pt={4}>
              <Typography
                className="client-text"
                fontSize={20}
                fontWeight="600"
              >
                Job Details
              </Typography>
            </Grid>

            
            {/* Second Line */}
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Job Title
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {valueOrDefault(job?.jobDetails.jobTitle,"N/A")}
              </Typography>
                
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Job Domain
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.jobDomain)}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Industry
              </Typography>
              <Typography
                className="client-text breakword"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.jobIndustry)}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Primary Skills
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {job?.jobDetails.primarySkill ? (
                  <Chip
                    color="primary"
                    size="small"
                    label={job?.jobDetails.primarySkill}
                  />
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Job Start Date
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {
                  job?.jobDetails.jobStartDate
                    ? convertToLocalizedDateTime(
                        job?.jobDetails.jobStartDate,
                        "MMMM DD, YYYY"
                      )
                    : "-"
                }
              </Typography>
            </Grid>
            {/* End Second Line */}

            {/* 3rd Line */}
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Shift Timings
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                From: {handleNullValue(job?.jobDetails.shiftTimingFrom)}{" - "}
                To: {handleNullValue(job?.jobDetails.shiftTimingTo)}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Hours of Working
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.workingHours)}
              </Typography>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Number of Openings
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.numberOfOpenings)}
              </Typography>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Experience
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.experienceText)}
              </Typography>
            </Grid>
            {/* End 3rd Line */}

            {/* 4th Line */}
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Required Degree/Education
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {ternary(
                  Boolean(job?.jobDetails?.requiredDegree),
                  job?.jobDetails?.requiredDegree?.degreeName,
                  "-"
                )}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Desired Certifications
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {ternary(
                  Boolean(job?.jobDetails.desiredCertifications),
                  job?.jobDetails.desiredCertifications?.join(","),
                  "-"
                )}
              </Typography>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                State
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                
                {handleNullValue(job?.jobDetails.state)}
              </Typography>
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                City
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.city)}
                
              </Typography>
            </Grid>
            {/* End 4th Line */}

            {/* 5th Line */}
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                ZIP Code
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {handleNullValue(job?.jobDetails.zipCode)}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Remote
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                <Switch
                  className={`switch-v2-small ${ternary(
                    Boolean(job?.jobDetails.isRemoteJob),
                    "switch-v2-checked",
                    "switch-v2"
                  )}`}
                  size="small"
                  name="jobDetails.isRemoteJob"
                  color="secondary"
                  checked={job?.jobDetails.isRemoteJob}
                />{" "}
                {ternary(Boolean(job?.jobDetails.isRemoteJob), "Yes", "No")}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Required Skills
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                {job?.jobDetails.requiredSkills.map((x, i) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={x}
                    key={ x ?? i}
                    style={{ margin: 2 }}
                  />
                ))}
              </Typography>
            </Grid>
            <Grid item  xs={12}>
              <Typography
                className="client-text"
                fontSize={14}
                fontWeight={400}
                color={"#696969"}
              >
                Job Description
              </Typography>
              <Typography
                className="client-text"
                fontSize={16}
                fontWeight={400}
                color={"#353535"}
              >
                  <div
                  className="custom-button-Robo custom-jobDesc"
                  dangerouslySetInnerHTML={{ __html: valueOrDefault(job?.jobDetails.jobDescription,"-") }}
                />
                
              </Typography>
            </Grid>
            {/* End 5th Line */}
          </Grid>

          <Divider />

          <Grid container pb={4}>
            <Grid item md={12} xs={12} pt={4}>
              <Typography
                className="client-text"
                fontSize={20}
                fontWeight="600"
              >
                Job Type and Rate Assignment
              </Typography>
            </Grid>
            {/* 1st Line */}
            <>
              <Grid item md={12} xs={12} pt={3}>
                <Grid container>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Job Type
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(job?.jobTypeAndRate.jobType)}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Tax Terms
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(job?.jobTypeAndRate.taxTerms)}
                    </Typography>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Job Duration
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(job?.jobTypeAndRate.jobDuraion)} {ternary(Boolean(job?.jobTypeAndRate.jobDuraion),"Months","")}
                      {/* 2 Years 0 Months */}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Bill Rate
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(job?.jobTypeAndRate.billRate)}{" "}
                      {(job?.jobTypeAndRate.billRate !== undefined)
                      &&
                      handleNullValue(
                        job?.jobTypeAndRate.billRateCurrencyType
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            {/* End 1st Line */}

            {/* 2nd Line */}
            <>
              <Grid item md={12} xs={12} pt={3}>
                <Grid container>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Salary Range
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(job?.jobTypeAndRate.salaryRange)}{" "}
                      {job?.jobTypeAndRate.salaryRange !=="" &&
                      handleNullValue(
                        job?.jobTypeAndRate.salaryRangeCurrencyType
                      )}
                      {/* 10K-20K USD */}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Payment Mode
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(job?.jobTypeAndRate.paymentMode)}
                    </Typography>
                  </Grid>

                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Work Authorization
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {job?.jobTypeAndRate.jobWorkAuthorization.map((x, i) => (
                        <Chip
                          color="primary"
                          size="small"
                          label={x}
                          key={ x ?? i}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Is it negotiable?
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      <Switch
                        className={`switch-v2-small ${ternary(
                          Boolean(job?.jobTypeAndRate.isRateNigotiable),
                          "switch-v2-checked",
                          "switch-v2"
                        )}`}
                        size="small"
                        name="obTypeAndRate.isRateNigotiable"
                        color="secondary"
                        checked={job?.jobTypeAndRate.isRateNigotiable}
                      />{" "}
                      {ternary(Boolean(job?.jobTypeAndRate.isRateNigotiable), "Yes", "No")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            {/* End 1st Line */}
          </Grid>

          <Divider />

          <Grid container pb={4}>
            <Grid item md={12} xs={12} pt={4}>
              <Typography
                className="client-text"
                fontSize={20}
                fontWeight="600"
              >
                Application Process
              </Typography>
            </Grid>
            {/* 1st Line */}
            <>
              <Grid item md={12} sm={6} xs={12} pt={3}>
                <Grid container>
                  <Grid item md={3}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Job End Date
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {job?.applicationProcess.jobEndDate
                        ? convertToLocalizedDateTime(
                            job?.applicationProcess.jobEndDate,
                            "MMMM DD, YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Application Method
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(
                        job?.applicationProcess.applicationMethod
                      )}
                    </Typography>
                  </Grid>

                  <Grid item md={3}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Required Documents
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {job?.applicationProcess.requiredDocuments.map((x, i) => (
                        <Chip
                          color="primary"
                          size="small"
                          label={x}
                          key={ x ?? i}
                          style={{ margin: 2 }}
                        />
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            {/* End 1st Line */}

            {/* 2nd Line */}
            <>
              <Grid item md={12} xs={12} pt={3}>
                <Grid container>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Application Instruction
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {handleNullValue(
                        job?.applicationProcess.applicationInstructions
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            {/* End 1st Line */}
          </Grid>

          <Divider />

          <Grid container pb={4}>
            <Grid item md={12} xs={12} pt={4}>
              <Typography
                className="client-text"
                fontSize={20}
                fontWeight="600"
              >
                Company Information
              </Typography>
            </Grid>
            {/* 1st Line */}
            <>
              <Grid item md={12} pt={3}>
                <Grid container>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Company Name
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {clientLoginData?.companyName}
                    </Typography>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <Typography
                      className="client-text"
                      fontSize={14}
                      fontWeight={400}
                      color={"#696969"}
                    >
                      Industry
                    </Typography>
                    <Typography
                      className="client-text"
                      fontSize={16}
                      fontWeight={400}
                      color={"#353535"}
                    >
                      {valueOrDefault(clientLoginData?.industries?.[0]?.industry, "")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            {/* End 1st Line */}

            {/* 2nd Line */}
            <>
              <Grid item md={12} xs={12} pt={3}>
                <Grid container spacing={2}>
                  {renderButtons()}
                </Grid>
              </Grid>
            </>
            {/* End 1st Line */}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewJobPopup;
