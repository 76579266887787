import {
  Autocomplete,
  Chip,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import React, { useEffect, useState } from "react";
import { onlyNumberRegex, uptoTwoDecimalsRegx } from "../../../../../constants/regex";
import { Asterisk } from "../../../../ui/Asterisk";
import { JobType } from "../../../../../utils/ArrayUtils";

export interface JobTypeRateAssignmentProps {
  handleChange: any;
  handleCustomChange:any;
  _handleCustomJobTypeChange:(val:string)=> void
  handleBlur: (event: React.FocusEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  setFieldTouched(field: string, isTouched?: boolean, shouldValidate?: boolean): void;
  values: any;
  touched: any;
  errors: any;
  jobTypesList:JobType[];
  jobTaxTermsList:string[];
  jobCurrency:string[];
  jobPaymentModesList:string[];
  jobWorkAuthorizationList:string[];
  jobSalaryRangeList:string[];
  mode: string | undefined;
}

const JobTypeRateAssignmentFormSection: React.FC<JobTypeRateAssignmentProps> = ({
  handleChange,
  handleCustomChange,
  _handleCustomJobTypeChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  jobTypesList,
  jobTaxTermsList,
  jobCurrency,
  jobPaymentModesList,
  jobWorkAuthorizationList,
  jobSalaryRangeList,
  mode
}) => {

  const [isJobDurationDisabled, setIsJobDurationDisabled] = useState<boolean>(false);
  const [isFullTimeJob, setIsFullTimeJob] = useState<boolean>(false);
  useEffect(()=>{
      setIsJobDurationDisabled(_isJobDurationVisible(values?.jobTypeAndRate?.jobType))
  },[values?.jobTypeAndRate?.jobType]);

  const _isJobDurationVisible = (jobType: string): boolean => {
    const validJobTypes: string[] = ["Full Time"];
    const isValid = validJobTypes.includes(jobType)
    if(isValid){
      setFieldTouched("jobTypeAndRate.jobDuraion",false);
    }else{
      setFieldTouched("jobTypeAndRate.salaryRange",false);
      setFieldTouched("jobTypeAndRate.salaryRangeCurrencyType",false);
    }
    setIsFullTimeJob(isValid);
    return isValid;
  }
  
  return (
    <>
      <Grid container spacing={2} >
         {/* row 2 */}
         <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5" className="postingJobFormHeading">
            Job Type and Rate Assignment
            </Typography>
        </Grid>
        {/* row 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
              id="jobType-standard"
              disableClearable={true}
              options={jobTypesList?.map((options: any) => options)}
              getOptionLabel={(option) => option.jobType}
              value={{
                id:values?.jobTypeAndRate?.jobTypeId,
                jobType:values?.jobTypeAndRate?.jobType
              }}
              onChange={(_, newValue:any) => {
                setFieldValue("jobTypeAndRate.jobTypeId", newValue.id);
                setFieldValue("jobTypeAndRate.jobType", newValue.jobType);
                _handleCustomJobTypeChange(newValue.jobType)
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Job Type"
                  name="jobTypeAndRate.jobType"
                  data-testid="autocomplete-jobtype"
                  error={
                    touched?.jobTypeAndRate?.jobType &&
                    Boolean(errors?.jobTypeAndRate?.jobType)
                  }
                  helperText={
                    touched?.jobTypeAndRate?.jobType &&
                    errors?.jobTypeAndRate?.jobType
                  }
                  placeholder="Select Job Type"
                />
              )}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
              disabled={isFullTimeJob}
              id="taxTerms-standard"
              disableClearable={true}
              options={jobTaxTermsList?.map((options: any) => options)}
              getOptionLabel={(option) => option?.taxTerm}
              value={{
                id: values?.jobTypeAndRate?.jobTaxTermId,
                taxTerm: values?.jobTypeAndRate?.taxTerms
              }}
              onChange={(_, newValue:any) => {    
                setFieldValue("jobTypeAndRate.jobTaxTermId", newValue.id);            
               setFieldValue("jobTypeAndRate.taxTerms", newValue.taxTerm);
               handleCustomChange(newValue.id);
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                required={!isFullTimeJob}
                  {...params}
                  variant="standard"
                  label="Tax Terms"
                  name="jobTypeAndRate.taxTerms"
                  error={
                    touched?.jobTypeAndRate?.taxTerms &&
                    Boolean(errors?.jobTypeAndRate?.taxTerms)
                  }
                  helperText={
                    touched?.jobTypeAndRate?.taxTerms &&
                    errors?.jobTypeAndRate?.taxTerms
                  }

                  placeholder="Select Tax Terms"
                />
              )}
            />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
        <TextField
            required={!isJobDurationDisabled}
            id="jobDuraion"
            data-testid="text-jobDuraion"
            name="jobTypeAndRate.jobDuraion"
            value={values?.jobTypeAndRate?.jobDuraion}
            onChange={(event:any)=>{
              if (onlyNumberRegex?.test(event.target.value)) {
                setFieldValue("jobTypeAndRate.jobDuraion",event.target.value);
              }             
            }}
            onBlur={handleBlur}
            error={
              touched?.jobTypeAndRate?.jobDuraion &&
              Boolean(errors?.jobTypeAndRate?.jobDuraion)
            }
            helperText={
              touched?.jobTypeAndRate?.jobDuraion &&
              errors?.jobTypeAndRate?.jobDuraion
            }
            fullWidth
            type="number"
            InputLabelProps={
              (mode === "EDIT" || mode === "PENDING") ? { shrink: true } : {}
            }
            variant="standard"
            className="cust-field-style1 AdditionInfoTxtArea"
            label="Job Duration"
            placeholder="Enter Duration"
            disabled={isJobDurationDisabled}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={1}>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 20 }}
            error={
              touched?.jobTypeAndRate?.jobDuraion &&
              Boolean(errors?.jobTypeAndRate?.jobDuraion)
            }
            fullWidth
          >
            <InputLabel id="jobDuraion-label" shrink={false} className="cust-field-style1">Months</InputLabel>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} md={1.5}>
        <TextField
            className="cust-field-style1"
            required
            id="billRate"
            name="jobTypeAndRate.billRate"
            label="Bill Rate"
            placeholder="Enter Bill Rate"
            variant="standard"
            value={values?.jobTypeAndRate?.billRate}
            onChange={(event:any)=>{

              if (uptoTwoDecimalsRegx?.test(event.target.value)) {
                setFieldValue("jobTypeAndRate.billRate",event.target.value);
              }
            }}
            onBlur={handleBlur}
            error={
              touched?.jobTypeAndRate?.billRate &&
              Boolean(errors?.jobTypeAndRate?.billRate)
            }
            helperText={
              touched?.jobTypeAndRate?.billRate && errors?.jobTypeAndRate?.billRate
            }
            fullWidth
            type="number"
            InputLabelProps={
              (mode === "EDIT" || mode === "PENDING") ? { shrink: true } : {}
            }
          />
        </Grid>
        <Grid item xs={6} sm={2} md={1.5}>
          <Autocomplete
              id="billRateCurrencyType-standard"
              style={{marginTop:"16px"}}
              disableClearable={true}
              options={jobCurrency?.map((options: any) => options?.currencyCode)}
              getOptionLabel={(option) => option}
              value={values?.jobTypeAndRate?.billRateCurrencyType}
              onChange={(_, newValue:any) => {
               setFieldValue("jobTypeAndRate.billRateCurrencyType", newValue);
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label=""
                  name="jobTypeAndRate.billRateCurrencyType"
                  error={
                    touched?.jobTypeAndRate?.billRateCurrencyType &&
                    Boolean(errors?.jobTypeAndRate?.billRateCurrencyType)
                  }
                  helperText={
                    touched?.jobTypeAndRate?.billRateCurrencyType &&
                    errors?.jobTypeAndRate?.billRateCurrencyType
                  }

                  placeholder="Select Currency"
                />
              )}
            />
        </Grid>

        {/* row 3 */}
        <Grid item xs={6} sm={6} md={1.5}>  
          <Autocomplete
              disabled={!isFullTimeJob}
              id="salaryRange-standard"
              className="cust-field-style"
              disableClearable={true}
              options={jobSalaryRangeList?.map((options: any) => options)}
              getOptionLabel={(option) => option}
              value={values?.jobTypeAndRate?.salaryRange}
              onChange={(_, newValue:any) => {
               setFieldValue("jobTypeAndRate.salaryRange", newValue);
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required={isFullTimeJob}
                  {...params}
                  variant="standard"
                  label="Salary Range"
                  name="jobTypeAndRate.salaryRange"
                  error={
                    touched?.jobTypeAndRate?.salaryRange &&
                    Boolean(errors?.jobTypeAndRate?.salaryRange)
                  }
                  helperText={
                    touched?.jobTypeAndRate?.salaryRange &&
                    errors?.jobTypeAndRate?.salaryRange
                  }

                  placeholder="Select Salary"
                />
              )}
            />       
        </Grid>
        <Grid item xs={6} sm={6} md={1.5}>
          <Autocomplete
              disabled={!isFullTimeJob}
              id="salaryRangeCurrencyType-standard"
              className="jobCurrencyDropdown"
              disableClearable={true}
              options={jobCurrency?.map((options: any) => options?.currencyCode)}
              getOptionLabel={(option) => option}
              value={values?.jobTypeAndRate?.salaryRangeCurrencyType}
              onChange={(_, newValue:any) => {
               setFieldValue("jobTypeAndRate.salaryRangeCurrencyType", newValue);
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required={isFullTimeJob}
                  {...params}
                  variant="standard"
                  label=""
                  name="jobTypeAndRate.salaryRangeCurrencyType"
                  error={
                    touched?.jobTypeAndRate?.salaryRangeCurrencyType &&
                    Boolean(errors?.jobTypeAndRate?.salaryRangeCurrencyType)
                  }
                  helperText={
                    touched?.jobTypeAndRate?.salaryRangeCurrencyType &&
                    errors?.jobTypeAndRate?.salaryRangeCurrencyType
                  }

                  placeholder="Select Currency"
                />
              )}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
              id="paymentMode-standard"
              className="cust-field-style"
              disableClearable={true}
              options={jobPaymentModesList?.map((options: any) => options?.paymentMode)}
              getOptionLabel={(option) => option}
              value={values?.jobTypeAndRate?.paymentMode}
              onChange={(_, newValue:any) => {
               setFieldValue("jobTypeAndRate.paymentMode", newValue);
              }}
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: any) => (
                  <Chip
                    color="primary"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Payment Mode"
                  name="jobTypeAndRate.paymentMode"
                  error={
                    touched?.jobTypeAndRate?.paymentMode &&
                    Boolean(errors?.jobTypeAndRate?.paymentMode)
                  }
                  helperText={
                    touched?.jobTypeAndRate?.paymentMode &&
                    errors?.jobTypeAndRate?.paymentMode
                  }

                  placeholder="Select Payment Mode"
                />
              )}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            className="cust-field-style"
            multiple
            id="tags-standard"
            options={jobWorkAuthorizationList?.map((option:any)=> option?.workAuthorization)}
            getOptionLabel={(option:any) => option}
            value={values?.jobTypeAndRate?.jobWorkAuthorization}
            onChange={(_, newValue) => {
              setFieldValue("jobTypeAndRate.jobWorkAuthorization", newValue);
            }}
            onBlur={()=>{
              setFieldTouched("jobTypeAndRate.jobWorkAuthorization",true)
            }}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: any) => (
                <Chip
                  color="primary"
                  size="small"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="Work Authorization"
                placeholder={values?.jobTypeAndRate?.jobWorkAuthorization?.length > 0 ? `` : `Select Work Authorization`}
                error={
                  touched?.jobTypeAndRate?.jobWorkAuthorization &&
                  Boolean(errors?.jobTypeAndRate?.jobWorkAuthorization)
                }
                helperText={
                  touched?.jobTypeAndRate?.jobWorkAuthorization &&
                  errors?.jobTypeAndRate?.jobWorkAuthorization
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputLabel id="state-label" shrink={true}>
          Is it negotiable? <Asterisk />
          </InputLabel>
          <FormControl className="cust-field-style-switch" size="medium">
            <FormControlLabel
              control={
                <Switch
                className={`switch-v2-small ${
                  values?.jobTypeAndRate?.isRateNigotiable
                    ? "switch-v2-checked"
                    : "switch-v2"
                }`}
                size="medium"
                name="jobTypeAndRate.isRateNigotiable"
                color="secondary"
                checked={values?.jobTypeAndRate?.isRateNigotiable}
                onChange={handleChange}
                
              />
              }
              label=""
            />
            <FormHelperText>
              {touched.isRateNigotiable && errors.isRateNigotiable
                ? errors.isRateNigotiable.toString()
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default JobTypeRateAssignmentFormSection;
