import { FormikProps } from 'formik';
import { valueOrDefault } from '../../../../../utils/complexityUtils';
import getAddressForAutoFill from '../../../../../utils/getAddressForAutoFill';

export async function handleAddressAutofill(
  address: string,
  formRef: React.RefObject<FormikProps<any>>,
): Promise<void> {
  if (address === "") {
    return;
  }

  const resp = await getAddressForAutoFill(address);
  if (!resp) {
    return;
  }

  const fieldsToUpdate = [
    { field: "jobDetails.zipCode", value: resp.pin },
    { field: "jobDetails.countryName", value: resp.country },
    { field: "jobDetails.countryCode", value: resp.countryCode },
    { field: "jobDetails.state", value: resp.state },
    { field: "jobDetails.stateCode", value: resp.stateCode },
    { field: "jobDetails.city", value: resp.city }
  ];

  fieldsToUpdate.forEach(({ field, value }) => {
    formRef.current?.setFieldValue(field, valueOrDefault(value, ""));
    formRef.current?.setFieldTouched(field, false);
  });
}
