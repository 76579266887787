  
export enum StepperStatus {
    isEmpty = "isEmpty",
    completed = "completed",
    incompleted = "incompleted",
    isError = "isError",
  }
  
  const evaluateFieldStatus = (
    fieldValue: any,
    fieldTouched: any,
    fieldError: any,
    field: string,
    isCompleted: boolean,
    isEmpty: boolean,
    isIncompleted: boolean
  ): [boolean, boolean, boolean, boolean] => {
    let error = false;
  
    if (field === 'requiredSkills' || field === 'jobWorkAuthorization' || field === 'requiredDocuments') {
      if (Array.isArray(fieldValue) && fieldValue.length > 0) {
        isEmpty = false;
        isIncompleted = true;
      } else {
        isCompleted = false;
      }
    } else {
      if (fieldValue === '' || fieldValue === undefined || fieldValue === null) {
        isCompleted = false;
      } else {
        isEmpty = false;
        isIncompleted = true;
      }
    }
  
    if (fieldTouched && fieldError) {
      error = true;
    }
  
    return [isCompleted, isEmpty, isIncompleted, error];
  };
  
  const processFields = (
    fields: string[],
    formSection: any,
    formValues: any,
    formTouched: any,
    formErrors: any
  ): string | undefined => {
    let isCompleted = true;
    let isEmpty = true;
    let isIncompleted = false;
  
    for (const field of fields) {
      const fieldValue = getNestedValue(formValues[formSection], field);
      const fieldTouched = getNestedValue(formTouched?.[formSection], field);
      const fieldError = getNestedValue(formErrors?.[formSection], field);
  
      const [completed, empty, incompleted, error] = evaluateFieldStatus(
        fieldValue,
        fieldTouched,
        fieldError,
        field,
        isCompleted,
        isEmpty,
        isIncompleted
      );
  
      isCompleted = completed;
      isEmpty = empty;
      isIncompleted = incompleted;
  
      if (error) {
        return StepperStatus.isError;
      }
    }
  
    if (isEmpty) return StepperStatus.isEmpty;
    if (isCompleted) return StepperStatus.completed;
    if (isIncompleted) return StepperStatus.incompleted;
  };
  
  const getFieldsForStep = (stepNumber: number, formValues: any) => {
    switch (stepNumber) {
      case 1:
        return [
          'jobTitle', 'jobDescription', 'jobDomain', 'jobIndustry', 'primarySkill',
          'jobStartDate', 'shiftTimingFrom', 'shiftTimingTo',
          'workingHours', 'numberOfOpenings', 'experience', 'experienceId',
          'requiredDegree.id', 'requiredDegree.degreeName',
          'state', 'city', 'zipCode', 'requiredSkills',
        ];
      case 2:
        return [
          'jobTypeId', 'jobType', 'jobTaxTermId', 'taxTerms', 'jobDuraion',
          'billRate', 'billRateCurrencyType', 'salaryRange',
          'salaryRangeCurrencyType', 'paymentMode', 'jobWorkAuthorization'
        ].filter(field => {
          const jobType = formValues.jobTypeAndRate.jobType;
          if (jobType === "Full Time" && ['jobTaxTermId', 'taxTerms', 'jobDuraion'].includes(field)) {
            return false; // Skip these fields for Full Time job type
          }
          if (jobType !== "Full Time" && ['salaryRange', 'salaryRangeCurrencyType'].includes(field)) {
            return false; // Skip these fields for non-Full Time job type
          }
          return true;
        });
      case 3:
        return [
          'jobEndDate', 'applicationMethod', 'requiredDocuments', 'applicationInstructions'
        ];
      default:
        return [];
    }
  };
  

  export const GetStepperStatus = (formRef: any, stepNumber: number): string | undefined => {
    const formValues = formRef.current.values;
    const formTouched = formRef.current.touched;
    const formErrors = formRef.current.errors;
  
    const fields = getFieldsForStep(stepNumber, formValues);
    const section = getSectionForStep(stepNumber);
  
    return processFields(fields, section, formValues, formTouched, formErrors);
  };
  
  const getSectionForStep = (stepNumber: number): string => {
  if (stepNumber === 1) return 'jobDetails';
  if (stepNumber === 2) return 'jobTypeAndRate';
  return 'applicationProcess';
}
  const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((value, key) => value && value[key], obj);
  };

  export const getClassName = (stepNumber: number, stepperStatus: any) => {
    const status = statusStep(stepNumber, stepperStatus);
  
    if (status === StepperStatus.isError) {
      return StepperStatus.isError;
    }
  
    if (status === StepperStatus.completed) {
      return StepperStatus.completed;
    }
  
    if (status === StepperStatus.incompleted) {
      return StepperStatus.incompleted;
    }
  
    return StepperStatus.isEmpty;
  };
  

  export const statusStep = (stepNumber:number, stepperStatus:any)=>{
    const statusKey = `step${stepNumber}` as keyof typeof stepperStatus;
    return stepperStatus[statusKey];
  }
  